import { Action } from 'redux';

export enum NotificationDataActionTypes {
    NOTIFICATION_DATA_LOAD = 'NOTIFICATION_DATA_LOAD',
    NOTIFICATION_DATA_LOAD_SUCCESS = 'NOTIFICATION_DATA_LOAD_SUCCESS',
    NOTIFICATION_DATA_LOAD_FAILURE = 'NOTIFICATION_DATA_LOAD_FAILURE'
}

export interface NotificationData extends Action {
    type: NotificationDataActionTypes.NOTIFICATION_DATA_LOAD;
    data: any;
}

export const notificationData = (data: string): NotificationData => ({
    type: NotificationDataActionTypes.NOTIFICATION_DATA_LOAD,
    data
});

export interface NotificationDataSuccess extends Action {
    type: NotificationDataActionTypes.NOTIFICATION_DATA_LOAD_SUCCESS;
    data: any;
}

export const notificationDataSuccess = (data: any): NotificationDataSuccess => ({
    type: NotificationDataActionTypes.NOTIFICATION_DATA_LOAD_SUCCESS,
    data
});

export interface NotificationDataFailure extends Action {
    type: NotificationDataActionTypes.NOTIFICATION_DATA_LOAD_FAILURE;
    data?: any;
}

export const notificationDataFailure = (data: string): NotificationDataFailure => ({
    type: NotificationDataActionTypes.NOTIFICATION_DATA_LOAD_FAILURE,
    data
});

export type NotificationDataActions = NotificationData | NotificationDataSuccess | NotificationDataFailure;