import { GDMSCONSTANT } from './adobe-analytics-gdms-constants';

const EVENTS = {
  CLICK: 'click',
  HOVER: 'hover',
  SCROLL: 'scroll',
};

const ACTION_ON : {[key: string]: any} = {
  LANDING_GO_BUTTON: {
    id: 'LANDING_GO_BUTTON',
    name: 'landingButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  SIGNIN__BUTTON: {
    id: 'SIGNIN__BUTTON',
    name: 'SigninButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  RESET__BUTTON: {
    id: 'RESET__BUTTON',
    name: 'ResetButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  RESET_CONTINUE: {
    id: 'RESET_CONTINUE',
    name: 'ResetContinue',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  REGISTER__BUTTON: {
    id: 'REGISTER__BUTTON',
    name: 'RegisterButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  NEXT_STEP_BUTTON: {
    id: 'NEXT_STEP_BUTTON',
    name: 'NextStepButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  LOGIN_BUTTON: {
    id: 'LOGIN_BUTTON',
    name: 'LoginButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  REGISTER_USER_DETAIL: {
    id: 'REGISTER_USER_DETAIL',
    name: 'RegisterUserDetailButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  ORG_PRIMARY_USER_DETAILS_BACK_BUTTON: {
    id: 'ORG_PRIMARY_USER_DETAILS_BACK_BUTTON',
    name: 'OrgPrimaryUserDetailsBackButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  ORG_PRIMARY_USER_DETAILS_CANCEL_BUTTON: {
    id: 'ORG_PRIMARY_USER_DETAILS_CANCEL_BUTTON',
    name: 'OrgPrimaryUserDetailsCancelButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CONFIRM_REGISTER: {
    id: 'CONFIRM_REGISTER',
    name: 'ConfirmRegisterButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  ORGANIZATION_BUTTON: {
    id: 'ORGANIZATION_BUTTON',
    name: 'OrganizationButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CREATE_MDONATION_BUTTON: {
    id: 'CREATE_MDONATION_BUTTON',
    name: 'CreateMoneyDonationButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  FILL_MORE_BUTTON_FOR_MONEY: {
    id: 'FILL_MORE_BUTTON_FOR_MONEY',
    name: 'FillMoreButtonMoneyButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  DELETE_INFO: {
    id: 'DELETE_INFO',
    name: 'DeleteButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CONFIRM_BUTTON_FOR_MONEY: {
    id: 'CONFIRM_BUTTON_FOR_MONEY',
    name: 'ConfirmButtonMoneyButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CREATE_GDONATION_BUTTON: {
    id: 'CREATE_GDONATION_BUTTON',
    name: 'CreateGoodsDonationButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CONFIRM_FILLMOREDETAILS: {
    id: 'CONFIRM_FILLMOREDETAILS',
    name: 'ConfirmFillDetailsButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  FILL_MORE_BUTTON_FOR_GOODS: {
    id: 'FILL_MORE_BUTTON_FOR_GOODS',
    name: 'FillMoreButtonGoodsButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CREATE_REPORT_FOR_SOCIAL_IMPACT: {
    id: 'CREATE_REPORT_FOR_SOCIAL_IMPACT',
    name: 'CreateReportForSocialImpactButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  SUBMIT_REPORT_FOR_SOCIAL_IMPACT: {
    id: 'SUBMIT_REPORT_FOR_SOCIAL_IMPACT',
    name: 'SubmitReportForSocialImpactButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  BACK_TO_LIST_BUTTON: {
    id: 'BACK_TO_LIST_BUTTON',
    name: 'BackToListButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CANCEL_REPORT_BUTTON: {
    id: 'CANCEL_REPORT_BUTTON',
    name: 'CancelReportButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  EXPORT_REPORT: {
    id: 'EXPORT_REPORT',
    name: 'ExportReportButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  EXPORT_REPORT_SUMMARY: {
    id: 'EXPORT_REPORT_SUMMARY',
    name: 'ExportReportSummaryButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  DOWNLOAD_EXCEL_REPORT: {
    id: 'DOWNLOAD_EXCEL_REPORT',
    name: 'DownloadExcelReportButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  REQUEST_NEW_DONATION_FOR_ONE_TIME_GOODS: {
    id: 'REQUEST_NEW_DONATION_FOR_ONE_TIME_GOODS',
    name: 'RequestNewDonationForOneTimeGoodsButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  SUBMIT_ONE_TIME_DONATION: {
    id: 'SUBMIT_ONE_TIME_DONATION',
    name: 'SubmitOneTimeDonationButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  CANCEL_ONE_TIME_DONATION: {
    id: 'CANCEL_ONE_TIME_DONATION',
    name: 'CancelOneTimeDonationButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  ADD_NEW_USER_FOR_ORG_USER_MANAGEMENT_BUTTON: {
    id: 'ADD_NEW_USER_FOR_ORG_USER_MANAGEMENT_BUTTON',
    name: 'AddNewUserForOrgUserManagementButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  SUBMIT_USER_MANAGEMENT_DATA: {
    id: 'SUBMIT_USER_MANAGEMENT_DATA',
    name: 'SubmitUserManagementButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  FOOD_SURPLUS_SUBMIT_BUTTON: {
    id: 'FOOD_SURPLUS_SUBMIT_BUTTON',
    name: 'FoodSurplusSubmitButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  FOOD_SURPLUS_CONFIRMATION_BUTTON: {
    id: 'FOOD_SURPLUS_CONFIRMATION_BUTTON',
    name: 'FoodSurplusConfirmationButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  FOOD_SURPLUS_GOBACK_BUTTON: {
    id: 'FOOD_SURPLUS_GOBACK_BUTTON',
    name: 'FoodSurplusGoBackButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  },
  BACK_BUTTON_FOR_SOCIAL_IMPACT_REPORT_BUTTON: {
    id: 'BACK_BUTTON_FOR_SOCIAL_IMPACT_REPORT_BUTTON',
    name: 'BackButtonForSocialImpactReportButton',
    applicationName: GDMSCONSTANT.app_name,
    applicationType : GDMSCONSTANT.app_type
  }
};

export default {
  ACTION_ON,
  EVENTS,
};
