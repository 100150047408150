import LDButton from '../../../commonComponents/uiComponents/LDButton';
import LDModal from '../../../commonComponents/uiComponents/LDModal';
import './ngoDownloadModal.css'

interface ModalProps {
    headerText: string;
    subHeading: string;
    smallText: string;
    bodyText: any;
    cancelBtn: any;
    applyBtn: any;
}
function NgoDownloadModal({ headerText, subHeading, smallText, bodyText, cancelBtn, applyBtn }: ModalProps) {
    return (
        <LDModal className='modal-main cancel-event-modal' 
        title={
            <div className='event-card-modal-header-details'>
                <span className='nc-modal-header' style={{ color: 'black' }}>
                    {headerText}
                </span>
            </div>}
        isOpen={true} onClose={cancelBtn.actionFn}
        size='large'
        closeButtonProps={{
          'data-testid': 'modal-close-btn'
        }}>
            <div className='row download-modal-subheading'>{subHeading}</div>
            <div className='row download-modal-faded-text'>{smallText}</div>
            <div className='modal-bodytext'>{bodyText}</div>
            <div className='download-modal-footer'>
                <LDButton variant='tertiary'
                    data-testid='nc-modal-cancel-btn'
                    onClick={cancelBtn.actionFn}>{cancelBtn.label}
                </LDButton>
                <LDButton className='new-reservation-request-button' variant='primary'
                    data-testid='nc-modal-confirmation-btn'
                    disabled = {applyBtn.isApplyButtonDisabled || false} onClick={applyBtn.actionFn}>{applyBtn.label}</LDButton>
            </div>
        </LDModal>
    );
}

export default NgoDownloadModal;
