import * as React from 'react';
import { connect } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, withStyles, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { GlobalDonationState } from 'src/models/globalDonationState';
import { loggedInUserData } from 'src/state/loggedInUser/loggedInUserActions';
import { saveFormData } from 'dynamic-form-components/lib/state/form/formDataActions';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import './../../styles/inkindReport.css';
import { useState, useEffect } from 'react';
import { contextPath, socialImpactTranslations, uploadFileSize} from './../../constants';
import LoaderComponent from '../LoaderComponent';
import SocialImpactConfirmDialog from './../dialogComponents/SocialImpactConfirmDialog';
import SuccessAlertDailog from '../dialogComponents/SuccessAlertDailog';
import { getDuplicateFacilityDetails, validateTextLimit } from './validationUtil';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
import { uploadAssignment, viewOrDownloadAssignment } from 'src/state/uploadDownloadFile/uploadDownloadFileActions';
import { getUploadFileFormForCreate } from '../common/utils';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import ReportModel from './ReportModel';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import MUISnackBar from '../common/AlertBox/MUISnackBar';

const config = require(`../../config.${process.env.NODE_ENV}.json`);

const styles: any = (_theme: any) => ({
    selectRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)',
    },
    outlined: {
        color: 'red'
    },
    selectIcon: {
        color: 'var(--inputTextColor) !important'
    },
    selectFormControl: {
        color: 'var(--inputTextColor)',
        minWidth: '200px',
        fontFamily: ' var(--appFont)',
        backgroundColor: '#fff',
        marginRight:'1rem',
        marginBottom: '1rem'
    },
    selectLabel: {
        color: 'var(--inputTextColor)',
        fontFamily: ' var(--appFont)',
        marginLeft: '10px',
        backgroundColor:'#fff'
    },
    menuItemRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)'
    },
    menuItemselected: {
        color: 'var(--inputTextColor);'
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonGroup: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonStyles: {
        top: '6px',
        marginBottom:'2rem'
    },
    closeBtn:{
        fontSize:'18px',
        backgroundColor: '#F4F6F9 !important',
        '&:hover': {
            backgroundColor: '#F4F6F9 !important',
            color:'red'
        }
    }
})

const FILE_SIZE = uploadFileSize;
const SUPPORTED_FORMATS = [
    'application/pdf',
    'application/doc',
    'application/docx',
    'application/txt',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain'
]

const validationMsg = {
    ...socialImpactTranslations.form.validationMsg
}

const formLabels = {
    ...socialImpactTranslations.form.labels
}

const formBtns = {
    ...socialImpactTranslations.form.btn
}

const report = {
    ...socialImpactTranslations.report
}
const bulkUpload={
    ...socialImpactTranslations.notificationMsg
}
const months = [...socialImpactTranslations.months]
const monthArr = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

const CreateReportForm = (props: any) => {
    const intialState = {
        year: '',
        month: '',
        socialImpactSummary: '',
        noOfChildBeneficiary: '',
        noOfYoungAdultsBeneficiary: '',
        noOfAdultsBeneficiary: '',
        noOfSeniorBeneficiary: '',
        totalDirectBeneficiary: '',
        noOfFamilies: '',
        noOfWomenBeneficiary: '',
        totalIndirectBeneficiary: '',
        file:'',
        fileDescription:'',
        donationNotCollected:false
    }
    const {
        register,
        unregister,
        handleSubmit,
        control,
        errors,
        formState,
        trigger,
        setValue,
        getValues,
        reset } = useForm({
        mode:'onChange',
        defaultValues:{
            ...intialState,
            ...props.submission,
            donationReceiptSummaryList: [{ facilityNo: '', noOfCartons: '', amountReceived: '' }],
        }
    });

    const { fields, insert, append, remove } = useFieldArray({
        name: 'donationReceiptSummaryList',
        control
    });

    useEffect(() => {
        const year = getValues('year') as string
        const month = getValues('month')
        if ( year && month && props?.donationReceiptSummaryList?.length > 0 ) {
            setValue('donationReceiptSummaryList', props.donationReceiptSummaryList)
        }
    }, [props?.donationReceiptSummaryList])

    const [maxFileSizeExceeded, setMaxFileSizeExceeded] = useState(false);
    const [fieldValidate, setFieldValidate] = useState('');

    const [socialImpactReport, setSocialImpactReport] = useState(intialState);

    const [donationNotCollected, setDonationNotCollected] = useState(false);
    const [totalDirectBeneficiary, setTotalDirectBeneficiary] = useState(0);

    const [totalCartons, setTotalCartons] = useState(0);

    const [totalAmountReceived, setTotalAmountReceived] = useState(0);

    const [totalFacilities, setTotalFacilities] = useState(0);

    const [fileData, setFileData] = useState({
        name: '',
        size: '',
        type: ''
    });

    const [fileList, setFileList] = useState();

    const [modalDialOption, setModalDialOption] = useState({
        showConfirmDialog: false
    });

    const [formData, setFormData] = useState({});

    const [showDonationWasNotCollectedDailog, setShowDonationWasNotCollectedDailog] = useState(false);
    const [showFormSaveSuccess, setShowFormSaveSuccess] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [showGoBackConfirmDialog, setShowGoBackConfirmDialog] = useState(false);
    const [socialImpactSummaryTextCount, setSocialImpactSummaryTextCount] = useState(0);
    const [fileDescriptionTextCount, setFileDescriptionTextCount] = useState(0);

    useEffect(() => {
        if (formState.errors.file && fileData && fileData.name) {
            setValue('file', getValues().file && getValues().file.length > 0 ? getValues().file : fileList);
            trigger('file');
        }
        if(formState.touched && Object.keys(formState.touched).length > 0){
            setFormChanged(true)
        }
    }, [formState]);
    useEffect(() => {
        if (formState && formState.errors && Object.keys(formState.errors).length > 0){
            const donationReceiptSummaryFields:any[string] = [];
            if (formState.errors.donationReceiptSummaryList &&
                formState.errors.donationReceiptSummaryList.length >0){
                formState.errors.donationReceiptSummaryList.map((_fieldName:string, index:number) => {
                    donationReceiptSummaryFields.push(`donationReceiptSummaryList[${index}].facilityNo`),
                    donationReceiptSummaryFields.push(`donationReceiptSummaryList[${index}].noOfCartons`)
                    donationReceiptSummaryFields.push(`donationReceiptSummaryList[${index}].amountReceived`)
                })
            }
            trigger([...Object.keys(formState.errors), ...donationReceiptSummaryFields]);
        }
    }, [props.selectedLang]);

    const registerValidation = () => {
        register('socialImpactSummary', {
            validate: (value: string) => {
                return validateSummaryLen(value, 4000)
            }
        });
        register('noOfChildBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfYoungAdultsBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfAdultsBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfSeniorBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfFamilies', {
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            // validate: validateNumberRange
        });
        register('noOfWomenBeneficiary', {
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            // validate: validateNumberRange
        });
        register('totalIndirectBeneficiary', {
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            // validate: validateNumberRange
        });
        register('file', {
            required: validationMsg.required[props.selectedLang],
            validate: validateFile
        });
        register('fileDescription', {
            validate: (value: string) => {
                return validateFileDescriptionLen(value, 2000)
            }
        })
        fields.map((_entry: any, index: number) => {
            register(`donationReceiptSummaryList[${index}].facilityNo`, {
                required: validationMsg.required[props.selectedLang],
                pattern: {
                    value: /^\d+$/,
                    message: validationMsg.numbersOnly[props.selectedLang],
                },
                validate: validateFacility
            });

            register(`donationReceiptSummaryList[${index}].noOfCartons`, {
                required: validationMsg.required[props.selectedLang],
                pattern: {
                    value: /^\d+$/,
                    message: validationMsg.numbersOnly[props.selectedLang]
                },
                validate: validateNumberRange
            });
            register(`donationReceiptSummaryList[${index}].amountReceived`, {
                required: validationMsg.required[props.selectedLang],
                pattern: {
                    value: /^\d{0,6}(\.\d{1,3})?$/,
                    message: validationMsg.amountReceived[props.selectedLang]
                }
            });
        })
    }
    useEffect(() => {
        if (donationNotCollected) {
            setShowDonationWasNotCollectedDailog(true);
        } else {
            registerValidation()
        }
    }, [donationNotCollected]);

    useEffect(()=>{
        if (!donationNotCollected){
            fields.map((_entry:any, index:number) => {
                register(`donationReceiptSummaryList[${index}].facilityNo`, {
                    required: validationMsg.required[props.selectedLang],
                    pattern: {
                        value: /^\d+$/,
                        message: validationMsg.numbersOnly[props.selectedLang]
                    },
                    validate: validateFacility
                });

                register(`donationReceiptSummaryList[${index}].noOfCartons`, {
                    required: validationMsg.required[props.selectedLang],
                    pattern: {
                        value: /^\d+$/,
                        message: validationMsg.numbersOnly[props.selectedLang]
                    },
                    validate: validateNumberRange
                });
                register(`donationReceiptSummaryList[${index}].amountReceived`, {
                    required: validationMsg.required[props.selectedLang],
                    pattern: {
                        value: /^\d{0,6}(\.\d{1,3})?$/,
                        message: validationMsg.amountReceived[props.selectedLang]
                    }
                });
            })
            registerValidation()
        }
    }, [fields])

    useEffect(() => {
        calculateTotalAmountReceived();
        calculateTotalCartons();
        calculateTotalFaility();
    }, [fields])

    useEffect(()=>{
        if (props.formState.formSaveSuccess){
            setShowFormSaveSuccess(true)
        }
    }, [props.formState])
    useEffect(()=>{
            analytics.trackPageInfoGdmsExternal(ANALYTICS.socialImpact.createNewReportPage);
    }, [])

    const inputChangeHandler = (event:any) => {
        const newValues = {
            ...socialImpactReport,
            [event.target.name]: event.target.value
        }
        setSocialImpactReport(newValues)
        getDirectBeneficiaryTotal(newValues)
    }

    const getDirectBeneficiaryTotal = (data:any) => {
        const { noOfChildBeneficiary, noOfYoungAdultsBeneficiary, noOfAdultsBeneficiary, noOfSeniorBeneficiary } = data;
        if (isValidNum(noOfChildBeneficiary) && isValidNum(noOfYoungAdultsBeneficiary) &&
            isValidNum(noOfAdultsBeneficiary) && isValidNum(noOfSeniorBeneficiary)){
            const total = parseInt(noOfChildBeneficiary, 10) +
                parseInt(noOfYoungAdultsBeneficiary, 10) + parseInt(noOfAdultsBeneficiary, 10) + parseInt(noOfSeniorBeneficiary,10)
            setTotalDirectBeneficiary(total)
        }
    }
    const isValidNum = (num:string) => {
        const regex = RegExp('^[0-9]+$');
        return regex.test(num);
    }
    const onSubmit = (data: any) => {
        const formDetails = {
            ...data,
            month:data.month.value
        }
        setFormData(formDetails)
        setModalDialOption({
            showConfirmDialog: true
        })
    }
    const handleConfirmation = () => {
        const form = new FormData();

        form.append('report', JSON.stringify(formData));
        form.append('langCode', props.selectedLang);
        // @ts-ignore
        form.append('file', formData.file ? formData.file[0] : null)
        // @ts-ignore
        form.append('fileDescription', formData.fileDescription)
        props.onSubmit(form);
        setModalDialOption({
            showConfirmDialog: false
        })
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.SUBMIT_REPORT_FOR_SOCIAL_IMPACT.name,
            location:ANALYTICS.socialImpact.goodsRecurringReportPage
        });
    }
  /* istanbul ignore next */
  const handlErrorClose = () => {
    setFieldValidate('')
    }
    /* istanbul ignore next */
    const toUploadAssignment = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > FILE_SIZE) {
                setMaxFileSizeExceeded(true)
            } else {
                setMaxFileSizeExceeded(false)
                const year = getValues('year') as string
                const month = getValues('month')
                const validateField = toValidateFields(year, month)
                if(validateField){
                const bulkUploadFormData = getUploadFileFormForCreate(file, year,month)
                props.uploadAssignment({
                    formData: bulkUploadFormData,
                    dataUri: `${config.appBaseUrl}/social-impact-reports/readExcelByBulkUpload`
                })
            }
        }
        }
    }
     /* istanbul ignore next */
     const toValidateFields = (year: any, month: any) => {
        if (!year || !month ) {
            setFieldValidate('Please select the Year and Month')
            return false
        }
        return true
    }
    const downloadAssignment = () => {
        const fileInfo = {
            templateKey: 'SOCIAL_IMPACT_GOODS_DONATION_BULK_UPLOAD_BENEFICIARIES_TEMPLATE',
            downloadDir: 'MX'
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `files/privacy-policy?fileInfo=${encryptedFileInfo}`;
        props.viewOrDownloadAssignment({
            name: 'Social Impact Goods Donation Beneficiaries Template',
            type: 'downloadTemplate',
            actionUrl: `${config.appBaseUrl}/${dataUri}`
        });
    }

    const validateSummaryLen = (text: string, size: number) => {
        setSocialImpactSummaryTextCount(text.length);
        if (!text){
            return validationMsg.required[props.selectedLang]
        }
        if (!validateTextLimit(text, size)) {
            return validationMsg.summaryError[props.selectedLang]
        }
        return true;
    }
    const validateFileDescriptionLen = (text: string, size: number) => {
        setFileDescriptionTextCount(text.length)
        if (!text) {
            return validationMsg.required[props.selectedLang]
        }
        if (!validateTextLimit(text, size)) {
            return validationMsg.fileDescError[props.selectedLang]
        }
        return true;
    }
    const validateFile = (data:any) => {
        const file = data && data[0] ? data[0] : {}
        setFileData(file);
        setFileList(data);
        if (file.size > FILE_SIZE){
            return validationMsg.fileSize[props.selectedLang]
        }
        if(!SUPPORTED_FORMATS.includes(file.type)){
            return validationMsg.fileFormat[props.selectedLang]
        }
        return true;
    }
    const validateMonth = (data: any) => {
        const selectedMonth = monthArr.indexOf(data.value);
        const currYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        if (currYear === getValues('year')) {
            if (selectedMonth > currentMonth) {
                return validationMsg.month[props.selectedLang];
            }
        }
        return true;
    }
    const validateFacility = (value:string) => {
        const facilityList: any = getValues().donationReceiptSummaryList;
        const uniqueValues = new Set(facilityList.map( (item:any) => item.facilityNo));
        const iterator = uniqueValues.values();
        const firstEntry = iterator.next();
        const total = uniqueValues && uniqueValues.size === 1 && (firstEntry.value === '0' || !firstEntry.value) ? 0 : uniqueValues.size;
        setTotalFacilities(total);
        if (uniqueValues.size < facilityList.length){
            const duplicateFaclities = getDuplicateFacilityDetails(getValues);
            const duplicateEntry = duplicateFaclities[value]
            if (duplicateEntry && duplicateEntry.isDuplicate && duplicateEntry.value === value) {
                return `${validationMsg.duplicateStoreNo[props.selectedLang]} ${value}`
            }
        }
        return true;
    }
    const validateNumberRange = (value:string) => {
        const val = parseInt(value, 10);
        return (val >= 0 && val <= 999999) ? true : `${validationMsg.numberRange[props.selectedLang]}`;
    }
    const calculateTotalCartons = () => {
        const cartons: any = getValues().donationReceiptSummaryList || [];
        const cartonsCount = cartons.reduce((sum:number, item:any) => {
            return isValidNum(item.noOfCartons) ? sum + parseInt(item.noOfCartons, 10) : sum;
        }, 0);
        setTotalCartons(cartonsCount);
    }
    const calculateTotalAmountReceived = () => {
        const amountDetails: any = getValues().donationReceiptSummaryList || [];
        const regex = /^\d{0,6}(\.\d{1,3})?$/
        const totalAmount = amountDetails.reduce((sum: number, item: any) => {
            return regex.test(item.amountReceived) ? sum + parseFloat(item.amountReceived) : sum;
        }, 0);
        if (!isNaN(totalAmount)) {
            setTotalAmountReceived(totalAmount);
        }
    }
    const calculateTotalFaility = () => {
        const facilityList: any = getValues().donationReceiptSummaryList || [];
        const uniqueValues = new Set(facilityList.map((item: any) => item.facilityNo));
        const iterator = uniqueValues.values();
        const firstEntry = iterator.next();
        const total = uniqueValues && uniqueValues.size === 1 && (firstEntry.value === '0' || !firstEntry.value) ? 0 : uniqueValues.size;
        setTotalFacilities(total);
    }

    const { classes } = props;
    const years: number[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= 2014; i--) {
        years.push(i);
    }
    const handleCancelAction = () => {
        setModalDialOption({
            showConfirmDialog:false
        })
    }
    const handleNoAction = () => {
        setShowDonationWasNotCollectedDailog(false)
        setDonationNotCollected(!donationNotCollected);
    }

    const handleYesAction = () => {
        setShowDonationWasNotCollectedDailog(false)
        reset({ donationNotCollected, year: getValues('year'), month: getValues('month') });
        setTotalDirectBeneficiary(0);
        setTotalCartons(0);
        setTotalAmountReceived(0);
        setTotalFacilities(0);
        setFileDescriptionTextCount(0);
        setSocialImpactSummaryTextCount(0);
        unregister([
            'socialImpactSummary',
            'noOfChildBeneficiary',
            'noOfYoungAdultsBeneficiary',
            'noOfYoungAdultsBeneficiary',
            'noOfAdultsBeneficiary',
            'noOfSeniorBeneficiary',
            'noOfFamilies',
            'noOfWomenBeneficiary',
            'totalIndirectBeneficiary',
            'donationReceiptSummaryList[0].facilityNo',
            'donationReceiptSummaryList[0].noOfCartons',
            'donationReceiptSummaryList[0].amountReceived',
            'file',
            'fileDescription'
        ]);
    }

    const handleSuccesConfirmation = () => {
        props.history.push(`${contextPath}/dashboard/social-impact?type=social-impact`)
        setShowFormSaveSuccess(false)
        setTotalAmountReceived(0)
        setTotalCartons(0)
        setTotalDirectBeneficiary(0)
        setTotalFacilities(0)
        setFileData({name:'',type:'', size:''})
        setFileList(undefined)
        setSocialImpactReport(intialState)
        setDonationNotCollected(false);
        setFileDescriptionTextCount(0);
        setSocialImpactSummaryTextCount(0);
        reset()
        reset({ donationNotCollected:false, year: '', month: '' });
        fields.length = 0;
        insert(0, { facilityNo: 0, noOfCartons: 0, amountReceived: 0 })
        registerValidation()
    }

    const handleGoBackCancelAction = () => {
        setFormChanged(false)
        setShowGoBackConfirmDialog(false)
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.BACK_BUTTON_FOR_SOCIAL_IMPACT_REPORT_BUTTON.name,
            location: ANALYTICS.socialImpact.goodsRecurringReportPage
        });
    }

    const handleGoBackConfirmation = () => {
        setFormChanged(false)
        setShowGoBackConfirmDialog(false)
        props.history.push(`${contextPath}/dashboard/social-impact?type=social-impact`)
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.CANCEL_REPORT_BUTTON.name,
            location:ANALYTICS.socialImpact.goodsRecurringReportPage
        });
    }
    const goBack = () => {
        if(formChanged){
            setShowGoBackConfirmDialog(true)
            setFormChanged(false);
        }
        else{
            props.history.push(`${contextPath}/dashboard/social-impact?type=social-impact`)
             analytics.trackEventInfo({
                type: tracker.EVENTS.CLICK,
                actionOn: tracker.ACTION_ON.BACK_TO_LIST_BUTTON.name,
                location: ANALYTICS.socialImpact.goodsRecurringReportPage
            });
        }
    }
    /* monthList.push(<MenuItem
        classes={{ root: classes.menuItemRoot, selected: classes.menuItemselected }}
        key={'monthnone'} value={''}>{formLabels.month[props.selectedLang]}</MenuItem>)
    yearList.push(<MenuItem
        classes={{ root: classes.menuItemRoot, selected: classes.menuItemselected }}
        key={'yearnone'} value={''}>{formLabels.year[props.selectedLang]}</MenuItem>) */
    return (
        <div>
            <button className={`social-imapct-back-btn mr-bottom-2`}
                onClick={goBack}>
                {formBtns.back[props.selectedLang]}
            </button>
            <div className='report-header'>
                {report.header[props.selectedLang]}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off'>
                <div className='row'>
                    <div className='col-md-12'>
                        <FormControl classes={{ root: classes.selectFormControl }} size='small' variant='outlined'
                            error={errors.year?.message}>
                            <InputLabel classes={{ root: classes.selectLabel }} id='demo-simple-select-label'>
                                {formLabels.year[props.selectedLang]}
                            </InputLabel>
                            <Controller
                                control={control}
                                name='year'
                                as={
                                    <Select
                                        classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                        labelId='year-select-label'
                                        id='year'
                                        name='year'
                                        autoWidth={true}
                                        defaultValue={''}
                                        // value={socialImpactReport.year}
                                        displayEmpty={true}>

                                            {years && years.map((year: any) => {
                                                return (<MenuItem
                                                    classes={{ root: classes.menuItemRoot, selected: classes.menuItemselected }}
                                                    key={year} value={year}>{year}</MenuItem>)
                                            })}
                                            
                                    </Select>
                                }
                                rules={{
                                    required: validationMsg.required[props.selectedLang],
                                    validate: (_val: any) => { trigger('month'); return true}
                                }}
                            />
                            {errors.year?.message && <span className='error-container'>{errors.year?.message}</span>}
                        </FormControl>
                        <FormControl classes={{ root: classes.selectFormControl}} size='small' variant='outlined'
                            error={errors.month?.message}>
                            <InputLabel classes={{ root: classes.selectLabel }} id='demo-simple-select-label'>
                                {formLabels.month[props.selectedLang]}
                            </InputLabel>
                            <Controller
                                control={control}
                                name='month'
                                as={
                                    <Select
                                        classes={{ root: classes.selectRoot, icon: classes.selectIcon}}
                                        labelId='month-select-label'
                                        id='month'
                                        name='month'
                                        displayEmpty={true}
                                        defaultValue={''}
                                        // value={socialImpactReport.month}
                                        autoWidth={true}>

                                        {months && months.map((month: any) => {
                                            return (<MenuItem
                                                classes={{ root: classes.menuItemRoot, selected: classes.menuItemselected }}
                                                key={month.value} value={month}>{month.label[props.selectedLang]}</MenuItem>)
                                        })}
                                    </Select>
                                }
                                rules={
                                    {
                                        required: validationMsg.required[props.selectedLang],
                                        validate: validateMonth
                                    }
                                }
                            />
                            {errors.month?.message && <span className='error-container'>{errors.month?.message}</span>}
                        </FormControl>
                        </div>
                </div>
                <div className='row' style={{marginBottom: '1rem', marginTop: '1rem'}}>
                    <div className='col-md-8'>
                        <div className='form-check-inline'>
                            <input className='form-check-input' type='checkbox'
                                name='donationNotCollected'
                                checked={donationNotCollected}
                                ref={register}
                                onChange={() => {
                                    setDonationNotCollected(!donationNotCollected)
                                }}
                            />
                            <label className='form-check-label col-form-label'>
                                <b>{formLabels.donationNotCollected[props.selectedLang]}</b>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <label className='col-form-label field-required'>
                            {formLabels.summuryTxt[props.selectedLang]}
                        </label>
                        <textarea name='socialImpactSummary'
                            placeholder={formLabels.summaryPlaceHolder[props.selectedLang]} className={`form-control ${donationNotCollected ? 'sip-disabled': ''}`} rows={4}
                            ref={register}
                            style={{ borderColor: errors.socialImpactSummary && 'red' }}
                        />
                        {!donationNotCollected &&
                            <><span>{`${socialImpactSummaryTextCount}/4000`}</span><br/></>
                        }
                        {errors.socialImpactSummary?.message &&
                        <span className='error-container'>{errors.socialImpactSummary?.message}</span>}
                    </div>
                </div>
                <div className='bulkUpload-wrapper social-impact-btn-group'>
                    <div>
                    <label className='upload-assignment-label'>
                            <input
                                style={{ display: 'none' }}
                                id='upload-assignment'
                                className='upload-assignment'
                                name='upload-assignment'
                                type='file'
                                accept='.xlsx'
                                onChange={toUploadAssignment}
                            />
                            <Button variant='outlined' component='span'>
                                {bulkUpload.bulkUpload[props.selectedLang]}
                            </Button>
                        </label>
                        <Button
                            variant='outlined'
                            onClick={downloadAssignment}
                            className='download-assignment'
                        >
                            {bulkUpload.downloadTemplate[props.selectedLang]}
                        </Button>
                    </div>
                    <label className='col-form-label'>
                        {bulkUpload.uploadFileSizeExceeded[props.selectedLang]}
                    </label>
                </div>
                <div className='report-sub-header'>{report.subHeader1[props.selectedLang]}</div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.children[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfChildBeneficiary'
                                onChange={inputChangeHandler}
                                disabled={donationNotCollected}
                                ref={register}
                                style={{ borderColor: errors.noOfChildBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfChildBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfChildBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.younAdults[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfYoungAdultsBeneficiary'
                                onChange={inputChangeHandler}
                                disabled={donationNotCollected}
                                ref={register}
                                style={{ borderColor: errors.noOfYoungAdultsBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfYoungAdultsBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfYoungAdultsBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.adults[props.selectedLang]}
                            </label>
                            <input type='text'
                                ref={register}
                                name='noOfAdultsBeneficiary'
                                onChange={inputChangeHandler}
                                disabled={donationNotCollected}
                                style={{ borderColor: errors.noOfAdultsBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfAdultsBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfAdultsBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.seniors[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfSeniorBeneficiary'
                                onChange={inputChangeHandler}
                                disabled={donationNotCollected}
                                ref={register}
                                style={{ borderColor: errors.noOfSeniorBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfSeniorBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfSeniorBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalDirectBnfcyCount[props.selectedLang]}
                        </label>
                            <input type='text' name='totalDirectBeneficiary'
                                value={totalDirectBeneficiary}
                                ref={register}
                                className='form-control input-text2'
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.noOfFmly[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfFamilies'
                                disabled={donationNotCollected}
                                ref={register}
                                style={{ borderColor: errors.noOfFamilies && 'red' }}
                                className={`form-control input-text`}
                            />
                            {errors.noOfFamilies?.message &&
                                <span className='error-container'>{errors.noOfFamilies?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.womenBnfcy[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfWomenBeneficiary'
                                disabled={donationNotCollected}
                                ref={register}
                                style={{ borderColor: errors.noOfWomenBeneficiary && 'red' }}
                                className={`form-control input-text`}
                            />
                            {errors.noOfWomenBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfWomenBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalIndirectBnfcyCount[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='totalIndirectBeneficiary'
                                disabled={donationNotCollected}
                                ref={register}
                                style={{ borderColor: errors.totalIndirectBeneficiary && 'red' }}
                                className={`form-control input-text`}
                            />
                            {errors.totalIndirectBeneficiary?.message &&
                                <span className='error-container'>{errors.totalIndirectBeneficiary?.message}</span>}
                        </div>
                    </div>
                </div>
                <div className='report-sub-header'>
                    {report.subHeader2[props.selectedLang] }
                </div>
                <div className='row'>
                    {fields.map((field, index) => (
                        <div className='col-md-8 form-group-container' key={field.id}>
                                <div className='mr-right'>
                                {index===0 && <label className='col-form-label field-required'>
                                        {formLabels.facilityNo[props.selectedLang]}
                                    </label>}
                                    <input type='text'
                                        name={`donationReceiptSummaryList[${index}].facilityNo`}
                                        defaultValue={field.facilityNo}
                                        ref={register()}
                                        disabled={donationNotCollected}
                                        placeholder={formLabels.facilityNo[props.selectedLang]}
                                        style={{
                                        borderColor: errors && errors.donationReceiptSummaryList &&
                                            errors?.donationReceiptSummaryList[index]
                                            && errors?.donationReceiptSummaryList[index].facilityNo && 'red' }}
                                        className={`form-control input-text`}
                                    />
                                    {
                                    errors && errors.donationReceiptSummaryList && errors?.donationReceiptSummaryList[index]
                                    && errors?.donationReceiptSummaryList[index].facilityNo &&
                                        <span className='error-container'>
                                            {errors.donationReceiptSummaryList[index].facilityNo.message}
                                        </span>
                                    }
                                </div>
                                <div className='mr-right'>
                                    {index === 0 && <label className='col-form-label field-required'>
                                        {formLabels.noOfCartons[props.selectedLang]}
                                    </label>}
                                    <input type='text' name={`donationReceiptSummaryList[${index}].noOfCartons`}
                                        ref={register()}
                                        onChange={calculateTotalCartons}
                                        disabled={donationNotCollected}
                                        defaultValue={field.noOfCartons}
                                        placeholder={formLabels.noOfCartons[props.selectedLang]}
                                        style={{
                                            borderColor: errors && errors.donationReceiptSummaryList &&
                                            errors?.donationReceiptSummaryList[index]
                                            && errors?.donationReceiptSummaryList[index].noOfCartons && 'red'
                                        }}
                                        className={`form-control input-text`}
                                    />
                                    {
                                        errors && errors.donationReceiptSummaryList && errors?.donationReceiptSummaryList[index]
                                    && errors?.donationReceiptSummaryList[index].noOfCartons &&
                                        <span className='error-container'>
                                            {errors.donationReceiptSummaryList[index].noOfCartons.message}
                                        </span>
                                    }
                                </div>
                                <div className='mr-right'>
                                    { index===0 && <label className='col-form-label field-required'>
                                        {formLabels.amtReceived[props.selectedLang]}
                                    </label>}
                                    <input type='text' name={`donationReceiptSummaryList[${index}].amountReceived`}
                                        ref={register()}
                                        disabled={donationNotCollected}
                                        placeholder={formLabels.amtReceived[props.selectedLang]}
                                        defaultValue={field.amountReceived}
                                        style={{
                                            borderColor: errors && errors.donationReceiptSummaryList &&
                                            errors?.donationReceiptSummaryList[index]
                                                && errors?.donationReceiptSummaryList[index].amountReceived && 'red'
                                        }}
                                        onChange={calculateTotalAmountReceived}
                                        className={`form-control input-text ${errors.donationReceiptSummaryList}`}
                                    />
                                    {
                                        errors && errors.donationReceiptSummaryList && errors?.donationReceiptSummaryList[index]
                                        && errors?.donationReceiptSummaryList[index].amountReceived &&
                                        <span className='error-container'>
                                            {errors.donationReceiptSummaryList[index].amountReceived.message}
                                        </span>
                                    }
                                </div>
                                {
                                    ( fields.length !== 1) ?
                                    <IconButton onClick={() => {
                                        remove(index);
                                    }}
                                        classes={{ root: classes.closeBtn}}>
                                        <CloseIcon classes={{ root: classes.closeBtn }}
                                            style={{marginTop: index ===0 ? '1rem': 0}}/>
                                    </IconButton> : <></>
                                }
                        </div>
                    ))}
                </div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <button type='button' name='addfacility'
                            className={`add-facility-btn`}
                            disabled={donationNotCollected}
                            onClick={() => {append({ facilityNo: '', noOfCartons: '', amountReceived: '' });}}>
                            {formBtns.addFacility[props.selectedLang]}
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalFclty[props.selectedLang]}
                            </label>
                            <input type='text' name='totalFacilities' className='form-control input-text'
                                value={totalFacilities}
                                ref={register}
                                readOnly={true}/>
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalCartons[props.selectedLang]}
                            </label>
                            <input type='text' name='totalCartons' className='form-control input-text'
                                value={totalCartons}
                                ref={register}
                                readOnly={true}
                            />
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalAmountReceived[props.selectedLang]}
                            </label>
                            <input type='text' name='totalAmountReceived' className='form-control input-text'
                                value={totalAmountReceived.toFixed(3)}
                                readOnly={true}
                                ref={register}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6 form-group-container'>
                        <div className='bulk-upload-container'>
                            <div>
                                <div className='upload-file'>
                                    <p className='field-required'>
                                        {formLabels.receipt[props.selectedLang]}
                                    </p>
                                </div>
                                <div className='bulk-upload-button'
                                    style={{ borderColor: errors.file && 'red' }}>
                                    <span>
                                        {(fileData && fileData.name) || formLabels.upload[props.selectedLang]}
                                        <label className='custom-file-upload'>
                                            <input type='file'
                                                name='file'
                                                ref={register}
                                                disabled={donationNotCollected}
                                                className={`form-control ${donationNotCollected ? 'sip-disabled' : ''}`}
                                            />
                                            {formLabels.browse[props.selectedLang]}
                                        </label>
                                    </span>
                                </div>
                                {errors.file?.message &&
                                    <span className='error-container'>{errors.file?.message}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-5'>
                        <div>
                            <label className='col-form-label field-required'>
                                {formLabels.receiptDesc[props.selectedLang]}
                            </label>
                            <input type='text' name='fileDescription'
                                ref={register}
                                disabled={donationNotCollected}
                                style={{
                                    borderColor: errors.fileDescription?.message  && 'red'
                                }}
                                className={`form-control`}
                            />
                            {!donationNotCollected &&
                                <><span>{`${fileDescriptionTextCount}/2000`}</span>
                                <br /></>
                            }
                            {errors.fileDescription?.message &&
                                <span className='error-container'>{errors.fileDescription?.message}</span>}
                        </div>
                    </div>
                </div>
                {
                    props.formState.formSaveFailure && !formChanged?
                        <div className='row'>
                            <div className='col-md-12'>
                                    <span style={{color:'red'}}>{props.formState.formActionMessage.errorMessage}</span>
                            </div>
                        </div>
                    :
                        <></>
                }
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='social-impact-btn-group'>
                            <button className={`social-imapct-cancel-btn mr-right-2`}
                                type='button'
                                onClick={goBack}
                                >
                                {formBtns.cancel[props.selectedLang]}
                            </button>
                            <button className={`sip-submit-btn`}
                                type='submit'>
                                {formBtns.submit[props.selectedLang]}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <SocialImpactConfirmDialog showDialog={modalDialOption.showConfirmDialog}
                isAsdaTheme={false}
                maxWidth={'md'}
                cancelButton={formBtns.cancel[props.selectedLang]}
                confirmButton={formBtns.submit[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.confirmMsg[props.selectedLang]}
                cancel={handleCancelAction}
                confirm={handleConfirmation}
            />
            {/*  Donation was not collected confirmation */}
            <SocialImpactConfirmDialog showDialog={showDonationWasNotCollectedDailog}
                isAsdaTheme={false}
                maxWidth={'sm'}
                cancelButton={formBtns.no[props.selectedLang]}
                confirmButton={formBtns.yes[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.donationNotCollectedMsgEdit[props.selectedLang]}
                cancel={handleNoAction}
                confirm={handleYesAction}
            />
            {/*  Go back / Cancel confirmation */}
            <SocialImpactConfirmDialog showDialog={showGoBackConfirmDialog}
                isAsdaTheme={false}
                maxWidth={'sm'}
                cancelButton={formBtns.no[props.selectedLang]}
                confirmButton={formBtns.yes[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.warningMsg[props.selectedLang]}
                cancel={handleGoBackCancelAction}
                confirm={handleGoBackConfirmation}
            />
            <ErrorSnackbarComponent
                isError={maxFileSizeExceeded}
                message= {bulkUpload.uploadFileSizeExceeded[props.selectedLang]}
            />
            <SuccessAlertDailog
                showDialog={showFormSaveSuccess}
                ok={handleSuccesConfirmation}
                title={socialImpactTranslations.successMsg.title[props.selectedLang]}
                message={socialImpactTranslations.successMsg.createTxt[props.selectedLang]}
            />
             <ErrorSnackbarComponent
                isError={maxFileSizeExceeded}
                message={validationMsg.fileSize[props.selectedLang]}
            />
            <MUISnackBar open={Boolean(fieldValidate.length)} onClose={handlErrorClose} message={fieldValidate} />
        </div>
    );
}

export interface DispatchProps {
    getLoggedInUserDetail: () => void;
    createReport: (data:any) => void;
    uploadAssignment: (data:any) => void;
    viewOrDownloadAssignment: (data:any) => void
}

export interface StateProps {
    selectedLang: string;
    loggedInUserDetail: any;
    formState:any;
    selectedReportData:any;
    uploadDownloadData:any;
}
export interface OwnProps {
    classes?: any;
    loggedInUserDetail: any;
}
class CreateReportComponent extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    constructor(props: any) {
        super(props);
    }
    public state = {
        selectedReport:{},
        showGoBackConfirmDialog:false,
        isLoader: false,
        showDialog: false
    }
    public UNSAFE_componentWillMount(){
        const selectedReport = (this.props.selectedReportData.socialImpactReport) ?
            JSON.parse(JSON.stringify(this.props.selectedReportData.socialImpactReport)) : {};
        this.setState({ selectedReport })
    }

     /* istanbul ignore next */
     public UNSAFE_componentWillReceiveProps(newProps: any) {
        if (newProps?.uploadDownloadData?.uploadingAssignmentLoading) {
            this.setState({ isLoader: true })
        }

        if (this.state.isLoader && (newProps?.uploadDownloadData?.uploadingAssignmentSuccess)) {
            this.setState({
                isLoader: false,
                showDialog: true,
            })
        }
        else if (this.state.isLoader && (newProps?.uploadDownloadData?.uploadingAssignmentFailure)) {
            this.setState({
                isLoader: false,
                showDialog: false,
            })
        }
    }

    private submit = (data:any) => {
        this.props.createReport(
            {
                formAlias: '',
                formData: { data },
                submissionUrl: `${config.appBaseUrl}/social-impact-reports`
            }
        )
    }
    private closeDialog = () => this.setState({ showDialog: false });
    public render() {
        const { classes } = this.props;
        return <div>
            <CreateReportForm
                selectedLang={this.props.selectedLang}
                classes={this.props.classes}
                onSubmit={this.submit}
                history={this.props.history}
                formState={this.props.formState}
                submission={this.state.selectedReport}
                uploadAssignment={this.props.uploadAssignment}
                viewOrDownloadAssignment={this.props.viewOrDownloadAssignment}
                donationReceiptSummaryList={this.props?.uploadDownloadData?.data?.donationReceiptSummaryList}
            />

            <ErrorSnackbarComponent isError={this.props.formState.formSaveFailure} classes={classes}
                message={this.props.formState.formActionMessage.errorMessage ||
                    `Failed to create report, please try again!`}
            />
            <SuccessSnackBarComponent
                isSuccess={this.props.uploadDownloadData.uploadingAssignmentSuccess}
                message={this.props.uploadDownloadData.uploadingAssignmentMessage}
            />

            <ErrorSnackbarComponent
                isError={this.props.uploadDownloadData.downloadDataFailure}
                classes={classes}
                message={bulkUpload.failedToDownload[this.props?.selectedLang]}
            />
            <ErrorSnackbarComponent
                isError={this.props.uploadDownloadData.uploadingAssignmentFailure}
                classes={classes}
                message={bulkUpload.failedToUpload[this.props?.selectedLang]}
            />
            <ReportModel
                showDialog={this.state.showDialog}
                onClose={this.closeDialog}
                content={this.props?.uploadDownloadData?.data?.bulkOperationResult}
            />
        {this.props.uploadDownloadData.downloadDataLoading
        || this.props.formState.formSaveLoading ? <LoaderComponent fullScreen={true} /> : <></>}
        </div>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    loggedInUserDetail: state.loggedInUserState.userDetail,
    formState:state.formState,
    selectedReportData: state.selectedDataState.addDataToStore,
    uploadDownloadData: state.uploadDownloadActionState,
});
const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    createReport: (data: any) => dispatch(saveFormData(data)),
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
    uploadAssignment: (data: any) => dispatch(uploadAssignment(data)),
    viewOrDownloadAssignment: (data: any) => dispatch(viewOrDownloadAssignment(data)),

});
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateReportComponent)));
