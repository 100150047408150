import { createStyles, Tab, Theme, withStyles } from '@material-ui/core';
import React from 'react';

import { TextWithBadge } from '../TextWithBadge/BadgeWithText';
import { StyledTabProps } from './../../../types/formComponentTypes';

const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: 'normal',
      marginRight: theme.spacing(4),
      overflow: 'visible',
      fontFamily: [
        'Bogle',
        'Roboto',
      ].join(','),
      '&:hover': {
        color: '#000',
        opacity: 1,
      },
      '&$selected': {
        color: '#000',
        fontWeight: theme.typography.fontWeightBold,
      },
      '&:focus': {
        color: '#000',
      },
    },
    selected: {},
  }),
)((props: StyledTabProps) => {

  if (props.showBadge) {
    return (<Tab disableRipple={false} {...props} label={<TextWithBadge isSaved={props.isSaved}
      translationKey={props.translationKey}
      text={props.text} />} />);
  }

  return (<Tab disableRipple={false} {...props} />)
});

export default React.memo(CustomTab);

export const a11yProps = (index: any, id?: string, ariaControls?: string) => {
  return {
    id: `${id}-tab-${index}`,
    'aria-controls': `${ariaControls}-tabpanel-${index}`,
  };
}

