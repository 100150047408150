import * as React from 'react';
import { Backdrop, withStyles } from '@material-ui/core';
import CircularProgress from '../../../commonComponents/uiComponents/LDSpinner';


const styles = (theme: any) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#ffc220',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
});



class Spinner extends React.PureComponent<any> {
    public render() {
        const {classes} = this.props;
        return <Backdrop className={classes.backdrop}
            open={true}
            onClick={() => this.props.open}
        >
            <CircularProgress color='inherit' size='large'/>
        </Backdrop>
    }
}

export default withStyles(styles) (Spinner);
