import instance from 'src/i18n';
import * as yup from 'yup';

export const validateInteger = () => {
    return yup.number().integer(instance.t('monetaryDonation.fieldValidation.fractionNotAllowed'))
        .required(instance.t('orgRegForm.validationMsg.requiredField'))
        .typeError(instance.t('orgRegForm.validationMsg.requiredField'))
        .min(0,instance.t('monetaryDonation.fieldValidation.positiveNumbers'));
}

export const validateString = () => {

    return yup.string().required(instance.t('orgRegForm.validationMsg.requiredField')).nullable(true);
}

export const percentageValidate = () => {
    return validateInteger()
        .min(1, instance.t('monetaryDonation.fieldValidation.percentageAtLeastOne'))
        .max(100, instance.t('monetaryDonation.fieldValidation.percentageAtMostHundred'))
}

export const validatePercentageTotals = () => {
    return yup.number().min(100, instance.t('monetaryDonation.fieldValidation.totalPercentageShouldEqualsHundred'))
        .max(100, instance.t('monetaryDonation.fieldValidation.totalPercentageShouldEqualsHundred'));
}

export const validateFloat = () => {
    return yup.number().required(instance.t('orgRegForm.validationMsg.requiredField'))
        .typeError(instance.t('orgRegForm.validationMsg.requiredField'))
        .positive(instance.t('monetaryDonation.fieldValidation.positiveNumbers'));
}

export const validateFloatTest = () => {
  return yup.number().typeError(instance.t('orgRegForm.validationMsg.requiredField'))
      .positive(instance.t('monetaryDonation.fieldValidation.positiveNumbers'));
}

export const validateBoolean = () => {
    return yup.boolean().required(instance.t('orgRegForm.validationMsg.requiredField'))
    .typeError(instance.t('orgRegForm.validationMsg.requiredField'));;
}
export const validateDate = () => {
    return yup.date().required(instance.t('orgRegForm.validationMsg.requiredField'))
        .typeError(instance.t('orgRegForm.validationMsg.requiredField'));
}

export const validateStringWithMaxLength = (maxLength: number) => {
    return validateString().test('len', instance.t('monetaryDonation.fieldValidation.maxAllowedCharLimit')
        .replace('{0}', maxLength.toString()), (val) => {
            if (!val) {
                return false;
            } else {
                return val.length <= maxLength
            }
        });
}

export const validateMaxLength = (maxLength: number) => {
    return yup.string().test('len', instance.t('monetaryDonation.fieldValidation.maxAllowedCharLimit')
        .replace('{0}', maxLength.toString()), (val) => {
            if (!val) {
                return true;
            } else {
                return val.length <= maxLength
            }
        });
}