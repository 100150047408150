import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const appFontFamily = 'var(--appFont)'
export const formErrorEleClass = 'reg-form-error-container'
export const DISPLAY_INLINE_FLEX = 'inline-flex';

export const HighLightedBox = withStyles({
    root: {
        padding: 20,
        marginBlock: 10,
        width: '100%'
    },
})(Paper)