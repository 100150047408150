import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { WizardFooter, WizardHeader } from '../ngoNcCreateEvents/NgoNcSpaceReqStoreListComp';
import { ChevronDown } from '@livingdesign/icons';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import Loader from '../../commonComponents/appComponents/loader/loader';
import { useNavigate, useLocation } from 'react-router';
import {
    DisplayNcDetails,
    DisplayStoreList,
    ContactDetails,
    AgreementCard
} from '../ngoNcCreateEvents/NcNgoCreateEventDetails';
import LDModal from '../../commonComponents/uiComponents/LDModal';
import { contextPath } from 'src/constants';
import { EventContactDetails } from '../ngoNcCreateEvents/NcNgoCreateEventDetails';
import { ContactTooltip } from '../ngoNcCreateEvents/NcNgoCreateEventDetails';

interface NcNgoCreateEventDetailsProp {
    history?: any[]
}
interface NcIDProps {
    pathname: string;
    state: {
        ncid: any
        orgid: any
    };
}

const service: Service = SpaceDonationService;

const NcNgoEditEventDetails: React.FC<NcNgoCreateEventDetailsProp> = () => {
    const history = useNavigate();
    const { state } = useLocation() as NcIDProps;
    const ncid = state?.ncid
    const orgid = state?.orgid
    useEffect(() => {
        if (ncid === undefined || orgid === undefined) {
            history(`${contextPath}/space-donation/events/`
            )
        }
    }, [ncid, orgid])

    const {
        data: eventDetails,
        isLoading } =
        useQuery(['getNcDetailsWithSelectedStoreByOrgForNC', { ncid, orgid }],
            service.getNcDetailsWithSelectedStoreByOrgForNC, { refetchOnWindowFocus: false });

    const [disableStatus, setDisableStatus] = useState(true);
    const [accordion, setAccordion] = useState('1')
    const [updatedContactDetail, setUpdatedContactDetail] = useState({});
    const [updatedEventContactDetail, setUpdatedEventContactDetail] = useState({eventContactObject:[]});
    const mutation = useMutation(service.submitRequestNC);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const handleNextRedirect = () => {
        if(updatedEventContactDetail.eventContactObject.length>0){
        setLoader(true)
        const payload = {
            ncId: ncid,
            spaceOrganizationId: orgid,
            ...updatedContactDetail,
            ...updatedEventContactDetail,
            spaceOrganizationName: eventDetails?.spaceOrganizationResponse?.orgName,
            isUpdate: true,
            status: 'DRAFT'
        }
        mutation.mutate(payload, {

            onSuccess: () => {
                setLoader(false)
                history(`${contextPath}/space-donation/edit/submitted-nc`)
            }, onError: (e: any) => {
                setIsError(true)
                setErrorMessage(e.response.data.errorMessage)
            }
        });
    }
    else{
      setIsError(true)
      setLoader(false)
      setErrorMessage('You have to select atleast one event contact');
    }
    }

    const handlePreviousRedirect = () => {
        history(`${contextPath}/space-donation/edit/store-list`,
            { state: { ncid, orgid } }
        )
    }

    const headerSection = {
        title: 'Add Store',
        progressTrackerItem: ['Select Store', 'Date and Time', 'Summary'],
        activeIndex: 2,
        handlePreviousRedirect
    }
    const footerSection = {
        isContinueDisabled: disableStatus,
        previousButtonText: 'Previous',
        nextButtonText: 'Submit Request',
        handleNextRedirect,
        handlePreviousRedirect
    }
    const storeDateTimeSelected = eventDetails?.spaceStoreAndNationalCampaignEventResponseDTOList?.filter((ele: any) => {
        return ele.proposedEventDates.length > 0
    })
    const storeListLength = storeDateTimeSelected?.length
    const campaignName = eventDetails?.spaceNationalCampaign?.title ?
        eventDetails?.spaceNationalCampaign?.title : 'Fetching name details...'

    const contactMutation = useMutation((data: any) =>
        service.getAllContactDetails(data)
    );
    const ncEventContactMutation = useMutation((data: any) =>
        service.getAllNcEventContactDetails(data)
      );
    const contactPayload: any = {
        orgId: orgid,
        ncId: ncid,
    };

    const [ncLoading, setNcLoading] = useState(true);
    useEffect(() => {
        contactMutation.mutate(contactPayload, {
            onSuccess: (data) => {
                setUpdatedContactDetail({
                    contactFirstName: data[0]?.eventContactDetails?.contactFirstName,
                    contactLastName: data[0]?.eventContactDetails?.contactLastName,
                    spaceOrganizationEmail: data[0].eventContactDetails?.spaceOrganizationEmail,
                    spaceOrganizationPhone: data[0].eventContactDetails?.spaceOrganizationPhone,
                }
                )
            }
        });
        setNcLoading(true);
        ncEventContactMutation.mutate(contactPayload, {
            onSuccess: (data) => {
            setUpdatedEventContactDetail({
                eventContactObject: data,
            }
        )
        setNcLoading(false);
      }
    });
    }, [])
    return (
        <>
            <LDModal isOpen={isError} size='small'
                onClose={() => setIsError(false)}
                title={<div className='submit-error-message'>Error</div>}>
                {errorMessage}
            </LDModal>
            {(isLoading || contactMutation.isLoading) && <Loader />}
            {loader && <Loader />}
            <WizardHeader {...headerSection} />
            <div className='ngo-nc-accordion-list'>
                <div className='ngo-nc-accordion-item'>
                    <Accordion expanded={accordion === '1' && !isLoading} onClick={() => setAccordion('1')} data-testid='first-accordion'>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >
                            <Typography><span className='ngo-nc-accordion-title'>
                                {campaignName}</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DisplayNcDetails details={eventDetails?.spaceNationalCampaign} />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='ngo-nc-accordion-item'>
                    <Accordion expanded={accordion === '2' && !isLoading} onClick={() => setAccordion('2')} data-testid='second-accordion'>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >
                            <Typography><span className='ngo-nc-accordion-title'>
                                Store Selection ({storeListLength ? storeListLength : '...'})</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DisplayStoreList
                                storeList={storeDateTimeSelected}
                                startDate={eventDetails?.spaceNationalCampaign?.eventStartDateString}
                                endDate={eventDetails?.spaceNationalCampaign?.eventEndDateString}
                                eventSlots={eventDetails?.spaceNationalCampaign?.eventSlots}
                                maxAllowedDays={eventDetails?.spaceNationalCampaign?.maxAllowedDays}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='ngo-nc-accordion-item'>
                    <Accordion expanded={accordion === '3' && !isLoading} onClick={() => setAccordion('3')} data-testid='third-accordion'>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >
                            <Typography><span className='ngo-nc-accordion-title'>Organization Contact information</span></Typography>
                            <ContactTooltip/>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContactDetails spaceOrganizationResponse={contactMutation?.data}
                                isContactLoading={contactMutation.isLoading}
                                setUpdatedContactDetail={setUpdatedContactDetail}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
                {(isLoading || ncEventContactMutation.isLoading) && <Loader />}
                {loader && <Loader />}
                <div className='ngo-nc-accordion-item'>
                    <Accordion expanded={accordion === '4' && !isLoading} onClick={() => setAccordion('4')} data-testid='fourth-accordion'>
                        <AccordionSummary
                            expandIcon={<ChevronDown />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >   
                            <Typography><span className='ngo-nc-accordion-title'>Event Contact information</span></Typography>
                            <ContactTooltip/>
                        </AccordionSummary>
                        <AccordionDetails>
                            {!ncLoading &&
                        <EventContactDetails updatedEventContactDetail={updatedEventContactDetail} setUpdatedEventContactDetail={setUpdatedEventContactDetail} isNcContactLoading={ncEventContactMutation.isLoading} requestStatus={eventDetails?.spaceNationalCampaign?.status} setIsError={setIsError} setErrorMessage={setErrorMessage}/>
                            }
                            </AccordionDetails>
                    </Accordion>
                </div>
                <AgreementCard eventDetails={eventDetails} setDisableStatus={setDisableStatus} />
            </div>
            <WizardFooter {...footerSection} />
        </>
    )
}


export default NcNgoEditEventDetails;
