import React, { useContext, useEffect } from "react";

import Card from "../../uiComponents/LDCard";
import CardItem from "../../uiComponents/LDCardItem";
import UserContext from "../../../store/user-context";
import { ChevronRight, Plus } from "../../../commonComponents/icons/icon";
import SpaceDonationService, { Service } from "src/spaceDonationService";
import LoaderComponent from "../../../commonComponents/appComponents/loader/LoaderComponent";
import { useMutation } from "react-query";
import { contextPath } from "src/constants";
import "./styles.css";

const service: Service = SpaceDonationService;

export interface Props {
  data: any;
  setAddNewOrganization: any;
  setAddNewVerifiedOrganization: any;
  setVerifiedOrganizationData: any;
}

class LandingProfileCard extends React.Component<Props> {
  public render() {
    return <ProfileChooser {...this.props} />;
  }
}

const titleCase = (str: any) =>
  str
    ?.trim()
    ?.split(" ")
    ?.map((w: any) => w[0]?.toUpperCase() + w?.substring(1)?.toLowerCase())
    ?.join(" ");

// @ts-ignore
export default LandingProfileCard;

const ProfileChooser = (props: any) => {
  const userContext: any = useContext(UserContext);
  const mutation = useMutation((data: any) => service.setMyOrgData(data));
  useEffect(() => {
    if (mutation.isSuccess) {
      props.history.push({
        pathname: `${contextPath}/space-donation/create`,
        state: "",
      });
    }
  }, [mutation.isSuccess]);
  return (
    <div className="landingProfileCard-container">
      {mutation.isLoading && <LoaderComponent />}
      {!mutation.isLoading && (
        <Card className="landingProfileCard-card">
          <CardItem>
            <div className="onboarding-info">
              Your organization must be verified to request space outside of
              Walmart facilities for the purpose of fundraising or community
              awareness campaigns. If your email address is associated with a
              verified Spark Good account, your organization(s) will appear
              below. If you do not see the organization associated with your
              email, contact your organization’s administrator. {" "}
            </div>
            <br />
            <div className="onboarding-info">
              If you selected “Other Local Group” which means you are only
              eligible to request Space Tool access. Your organization must be
              verified to request space outside of Walmart facilities for the
              purpose of fundraising or community awareness campaigns. Once you
              answer the preliminary questions, you will receive an update on
              your approval status. 
            </div>
            <br />
            <div className="onboarding-info">
              Not sure if your organization is verified ? Check on
              <a
                href="https://www.walmart.com/sparkgood/verify"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                DEED.
              </a>
            </div>
            <br />
            <div className="landingProfileCard-header-content">
              Select an organization to submit a space request
            </div>
          </CardItem>
          <CardItem className="profile-card-list">
            <div className="landingProfileCard-orglist-container">
              {userContext?.organization?.myOrgs &&
                userContext?.organization?.myOrgs &&
                userContext?.organization?.myOrgs.map(
                  (org: any, index: any) => {
                    return (
                      <OrgNameCards
                        key={index}
                        mutation={mutation}
                        {...props}
                        org={org}
                        orgContacts={userContext?.organization?.orgContacts}
                      />
                    );
                  }
                )}
            </div>
          </CardItem>
          <CardItem className="landingProfileCard-card">
            <div
              className="landingProfileCard-addorg"
              onClick={() => props.setAddNewOrganization(true)}
            >
              <Plus
                style={{ fill: "#0072ea", fontSize: "2.5rem", margin: "auto" }}
              />
              <div className="profile-card-orgname">Add a new organization</div>
            </div>
          </CardItem>
        </Card>
      )}
    </div>
  );
};

const OrgNameCards = (props: any) => {
  return (
    <div
      className="landingProfileCard-orgname"
      onClick={() => {
        if (
          props.org.orgAddress &&
          Object.keys(props.org.orgAddress).length > 0
        ) {
          props.setVerifiedOrganizationData({
            cid: props.data.userId,
            orgId: props.org.orgId,
            cybergrantsOrganizationId: props.org.cybergrantsOrganizationId,
            orgPhoneNumber: null,
            orgName: props.org.orgName,
            orgAddressLine1: props.org.orgAddress.addressLineOne,
            orgAddressLine2: props.org.orgAddress.addressLineTwo,
            orgCity: props.org.orgAddress.city,
            orgState: props.org.orgAddress.state,
            orgZip: props.org.orgAddress.zip,
            primaryContactFname: props.data.firstName,
            primaryContactLname: props.data.lastName,
            primaryContactEmail: props.data.email,
            primaryContactNum: props?.data?.phoneNumber,
            orgOwner: props.org.orgOwner,
            isVerified: props.org.isVerified,
            onboardingStatus: props.org.onboardingStatus,
          });
          props.setAddNewVerifiedOrganization(true);
        } else {
          props.mutation.mutate({
            cid: props.data.userId,
            orgId: props.org.orgId,
            contactDetails: {
              contactType: null,
              cid: props.data.userId,
              firstName: props?.data?.firstName,
              lastName: props?.data?.lastName,
              email: props?.data?.email,
              phoneNumber: props?.data?.phoneNumber,
            },
          });
        }
      }}
    >
      <div>{titleCase(props.org.orgName)}</div>
      <div className="landingProfileCard-orglist-icon">
        <ChevronRight />
      </div>
    </div>
  );
};
