import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import withRouter from 'src/polyfils/customRouter';
import { Trans, useTranslation } from 'react-i18next';
import LoginForm from './LoginForm';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import LoaderComponent from '../LoaderComponent';
import { contextPath } from './../../constants';
import GlobalDonationDataService from 'src/service';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import { NavLink } from 'react-router-dom';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        overflow: 'auto',
        height: '95vh',
        backgroundColor: '#ffffff'
    },
    formElement: {
        border: 'solid 1px rgba(102, 123, 155, 0.25)',
        padding: '60px',
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.04)',
        borderRadius: '4px',
    },
    formCenter: {
        display: 'flex',
        marginTop: '16px',
        justifyContent: 'center'
    },
    formContainer: {
        flexGrow: 1,
        maxWidth: '600px'
    },
    headerSection: {
        textAlign: 'center'
    },
    header: {
        color: '#041f41',
        fontSize: '24px',
        fontStyle: 'normal',
        textAlign: 'center',
        fontFamily: 'Bogle',
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        paddingBottom: '15px'
    },
    subHeader: {
        color: 'rgba(4, 31, 65, 0.4)',
        fontSize: '14px',
        fontStyle: 'normal',
        textAlign: 'center',
        fontFamily: 'Bogle',
        fontWeight: 'normal',
        lineHeight: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.25px',
        paddingBottom: '15px'
    },
    linkSubheader: {
        color: 'var(--buttonColor) !important',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.14',
        letterSpacing: '1px',
        textAlign: 'center',
    },
    toolbar: theme.mixins.toolbar,
    logo: {
        textAlign: 'center' as 'center',
        padding: 16,
        color: 'white',
        background: theme.palette.primary.main
    },
    headerLogo: {
        padding: 16,
        color: 'white',
        background: theme.palette.primary.main
    }
}));
const LoginComponent = (props: any) => {

    const classes = { ...useStyles() }
    const { t, i18n } = useTranslation()
    const country = props.countryName
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string>('')

    const handleLogin = (payload: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.SIGNIN__BUTTON.name,
            location:ANALYTICS.login.loginPage
        });
        setLoading(true)
        GlobalDonationDataService.autheticate({ payload: getEncryptedString(payload) }).then(
            async (res: any) => {
                const query = `q=${res.token}`
                setLoading(false)
                const hostName = window.location.origin;
                let url = '';
                if (process.env.NODE_ENV === 'development') {
                    url = `${hostName}${contextPath}/dashboard?${query}`
                } else {
                    url = `${hostName}/auth?${query}`
                }
                await fetch(url);
                props.history.push(`${contextPath}/dashboard`);
            }).catch((error: any) => {
                setLoading(false)
                setErrorMsg(`${error?.response?.data?.errorMessage}:${new Date().getTime()}` || '')
            })
    }

    useEffect(() => {
        if (i18n.language !== props.lang) {
            const lang = localStorage.getItem('countryCode') === 'MX' ?
                localStorage.getItem('country') : props.lang;
            setLoading(true)
            i18n.changeLanguage(lang).then((_res) => {
                setTimeout(() => {
                    setLoading(false)
                }, 250)
            }).catch((_error) => {
                setLoading(false)
            })
        }
        analytics.trackPageInfoGdmsExternal(ANALYTICS.login.loginPage);
    }, [i18n, props])

    const formHeader = (
        <div className={classes.headerSection}>
            <Typography className={classes.header}>
                {t('loginForm.header')}
            </Typography>
            <Typography className={classes.subHeader}>
                {<Trans i18nKey='loginForm.subHeader'>
                    Market Location/Language: {{ country }}. Go to <NavLink to={`${contextPath}/Public/landingPage`}
                        className='link-subheader'>Landing page</NavLink>  to change it.
                </Trans>}
            </Typography>
        </div>
    )

    return (<>
        <HeaderComponent classes={classes} />
        <div className={classes.container}>
            <div className={classes.formCenter}>
                <div className={classes.formContainer}>
                    {formHeader}
                    <LoginForm classes={classes} onSubmit={handleLogin} />
                </div>
            </div>
        </div>
        <FooterComponent />
        {loading && <LoaderComponent fullScreen={true} />}
        <ErrorSnackbarComponent isError={errorMsg ? true : false}
            message={errorMsg}
        />
    </>)
}
export default withRouter(LoginComponent)
