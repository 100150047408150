import { CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core';
import { CloudDownload, CloudUpload, Delete } from '@material-ui/icons';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import GlobalDonationDataService from '../../../service';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import { useTranslation } from 'react-i18next';
import MUISnackBar from '../../common/AlertBox/MUISnackBar';
import GenericConfirmDialog from 'src/components/dialogComponents/GenericConfirmDialog';
import { IFileDetails } from 'src/components/donations/moreDetailsSections/attachments/attachmentsTable';
const config = require(`../../../config.${process.env.NODE_ENV}.json`);

interface OwnProps {
  fileDetails: IFileDetails | null
  disabled: boolean
  showName?: boolean
  supportedFileExtensions?: string[]
  reportId: string
  sno: number,
  readOnly: boolean
  getFileDetails: (sno: number, fileDetails: IFileDetails) => void
}
const validateFileSize = (file: File): boolean => {
  return file.size <= 10 ** 7;
}
let errorMessage = '';
const supportedExtensions = ['.xls', '.xlsx', '.pdf', '.jpeg', '.png'];

const useStyles = makeStyles({
  yesButton: {
    '&:hover': {
      color: '#041E42'
    }
  },
  noButton: {
    '&:hover': {
      color: '#041E42'
    }
  }
});



const TableFileHandler: FC<OwnProps> = ({ fileDetails, sno, getFileDetails, readOnly, reportId,
  disabled, supportedFileExtensions = supportedExtensions, }) => {

  const fileUploadEl = useRef<HTMLInputElement>(null);
  const formEl = useRef<HTMLFormElement>(null);
  const [fileData, setFileData] = useState<IFileDetails | null>(null);
  const [pendingAction, setPendingAction] = useState(false);
  const [showError, setErrorState] = useState(false);
  const { t } = useTranslation();
  const getTranslation = (key: string) => t(`monetaryDonation.attachmentsSection.${key}`);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();
  const handlErrorClose = () => {
    setErrorState(false);
  }

  useEffect(() => {
    setFileData(fileDetails);
  }, [fileDetails]);

  const onUpload = () => {
    if (fileUploadEl.current) {
      fileUploadEl.current.click();
    }
  }

  const deleteFile = () => {
    setDialogOpen(true);
  }

  const closeDialog = () => {
    setDialogOpen(false);
  }

  const onDelete = async () => {
    setDialogOpen(false);
    setPendingAction(true);
    const isDelete = await GlobalDonationDataService.deleteActivityAttachment(reportId, sno)
    setPendingAction(false);
    if (isDelete) {
      setFileData(null);
    } else {
      const fileName = fileData?.fileName ?? '';
      errorMessage = getTranslation('failedToDelete').replace('{0}', fileName);
      setErrorState(true);
    }
  }

  const onDownload = async () => {
    const fileInfo = {
      fileId: fileData?.fileName,
      fileName: fileData?.fileName,
      directory: fileData?.uploadDir,
      container: fileData?.container
    }
    const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
    const dataUri = `files?fileInfo=${encryptedFileInfo}`;
    try {
      setPendingAction(true);
      await GlobalDonationDataService.getFileData({
        name: `${fileData?.fileName}`,
        type: 'downloadFiles',
        actionUrl: `${config.appBaseUrl}/${dataUri}`
      });
      setPendingAction(false);
    } catch {
      setPendingAction(false);
      errorMessage =
        t('monetaryDonation.attachmentsSection.failedToDownload').replace('{0}', fileData?.fileName ?? '');
      setErrorState(true);
    }
  }

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files?.item(0) as File;
    if (file) {
      if (validateFileSize(file)) {
        setErrorState(false);
        const formData = new FormData();
        formData.append('file', file, file.name);
        setPendingAction(true);
        const fileRes = await GlobalDonationDataService.uploadActivityAttachment(reportId, sno, formData);
        setPendingAction(false);
        if (fileRes) {
          setFileData(fileRes)
          getFileDetails(sno, fileRes)
        } else {
          errorMessage = getTranslation('failedToUpload').replace('{0}', file.name);
          setErrorState(true);
        }

      } else {
        errorMessage = getTranslation('fileSizeError');
        setErrorState(true);
      }
      if (formEl.current) {
        formEl.current.reset();
      }
    }
  }

  return <>
    <Typography>{fileData?.fileName}</Typography>
    {pendingAction ? <CircularProgress size={30} /> : <>
      {fileData?.fileName && fileData?.fileName.length > 0 ?
        <>
          <IconButton data-testid='download' onClick={onDownload} disabled={disabled}>
            <CloudDownload />
          </IconButton>
          {!readOnly &&
            <IconButton data-testid='delete' onClick={deleteFile} disabled={disabled}>
              <Delete />
            </IconButton>}
        </> :
        <>
          {!readOnly &&
            <IconButton data-testid='upload' onClick={onUpload} disabled={disabled}>
              <CloudUpload />
            </IconButton>}
          <form ref={formEl}>
            <input data-testid='fileUpload' type='file' accept={supportedFileExtensions.toString()}
              width='0' height='0' ref={fileUploadEl} onChange={onFileChange} />
          </form>
        </>}
    </>}
    <MUISnackBar open={showError} onClose={handlErrorClose} message={errorMessage} />
    <GenericConfirmDialog showDialog={dialogOpen}
      title={getTranslation('deleteFileDialogTitle')}
      message={getTranslation('deleteFileDialogMessage').replace('{0}', fileData?.fileName ?? '')}
      confirmButton={t('common.buttonLabels.yes')}
      cancelButton={t('common.buttonLabels.no')}
      cancel={closeDialog}
      confirm={onDelete}
      cancelBtnClasses={classes.noButton}
      confirmBtnClasses={classes.yesButton}
    />
  </>
}

export default TableFileHandler;
