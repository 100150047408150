import React, { useState, useContext, useEffect } from 'react';
import  { EventCard , EmptyEventCard, LoadingEventCard } from '../../commonComponents/appComponents/eventCard/eventCard';
import withRouter from 'src/polyfils/customRouter';
import './styles.css';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useQuery } from 'react-query';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import UserContext from '../../store/user-context';
import moment from 'moment';
import analytics from '../../../../adobe-analytics/analytics';
import { SPACEANALYTICS } from '../../../../adobe-analytics-space/analytics-space-dataelements';
import _ from 'lodash';
import Loader from '../../commonComponents/appComponents/loader/loader';

const service: Service = SpaceDonationService;

export interface ReservationProps {
    history: any[]
}

export interface DisplayEventsProps {
    header: string,
    data: any,
    setShowAllDetails: any,
    setInitialState: any,
    setSelectedObj: any,
    isDataLoaded: boolean,
    history: any[],
    eventId?: any,
    date?: any,
    slots?: any,
    city?: any,
    storeNo?: any,
    spaceName?: any,
    hostName?: any,
    email?: any,
    eventPurpose?: any,
    focusArea?: any,
    aboutEvent?: any,
    donationData?: any,
    orgId?: any,
}

export interface DisplayAllEventsProps {
    header: string,
    data: any[],
    setShowAllDetails: any,
    setInitialState: any,
    selectedObj: any,
    history: any[],
    eventId?: any,
    date?: any,
    slots?: any,
    city?: any,
    storeNo?: any,
    spaceName?: any,
    hostName?: any,
    email?: any,
    eventPurpose?: any,
    focusArea?: any,
    aboutEvent?: any,
    donationData?: any
}


// tslint:disable-next-line
export interface ReservationState {
}

const eventMapper = (props: any) => {
    /* istanbul ignore next */
    return {
        id: props.spaceDonation.id,
        eventStartDateString: moment(props.spaceDonation.eventStartDateString).format('MMM DD, YYYY'),
        eventEndDateString: moment(props.spaceDonation.eventEndDateString).format('MMM DD, YYYY'),
        eventStartDate: props.spaceDonation.eventStartDate,
        eventEndDate: props.spaceDonation.eventEndDate,
        location: props.spaceDonation.storeInfo.city,
        title: props.spaceDonation.spaceLocation,
        hostName: props.spaceDonation?.eventContact?.firstName || props.spaceDonation?.eventContacts?.[0]?.firstName,
        locationName: props.spaceDonation.eventPurpose,
        focusArea: props.spaceDonation.focusArea,
        donationType: 'SPACE_DONATION',
        storeAddress: props.spaceDonation.storeInfo.storeName,
        addressLine1: props.spaceDonation.storeInfo.addressLine1,
        storeNbr: props.spaceDonation.storeInfo.storeNbr,
        timezone: props.spaceDonation.storeInfo.timeZoneCode,
        spaceDonation: props.spaceDonation,
        waaSTaskMessage : props.waaSTaskMessage
    }
}

const ReservationSection: React.SFC<ReservationProps> = (props: any) => {
    const userContext = useContext(UserContext);
    let reservationUpcomingData: any = [];
    let reservationPendingData: any = [];
    let reservationPastData: any = [];
    // @ts-ignore
    const myOrgId = userContext?.organization?.id;
    const [showAllDetails, setShowAllDetails] = useState(false);
    const [selectedObj, setSelectedObj] = useState({ header: '', data: [] });
    const { data: ongoingEvents, isFetching: isOngoingEventsFetching } =
        useQuery(['fetchEvents', myOrgId, ['CHECKED_IN', 'APPROVED']], service.fetchTopEventsByStatus, { refetchOnWindowFocus: false });
    const { data: pendingEvents, isFetching: isPendingEventsFetching } =
        useQuery(['fetchEvents', myOrgId, ['SUBMITTED']], service.fetchTopEventsByStatus, { refetchOnWindowFocus: false });
    const { data: pastEvents, isFetching: isPastEventsFetching } =
        useQuery(['fetchEvents', myOrgId, ['CHECKED_OUT', 'CANCELLED', 'REJECTED']],
            service.fetchTopEventsByStatus, { refetchOnWindowFocus: false });
    if (!isOngoingEventsFetching && !isPendingEventsFetching && !isPastEventsFetching) {
        /* istanbul ignore next */
        if (ongoingEvents && ongoingEvents.length) {
            reservationUpcomingData = ongoingEvents
                .sort(((a: any, b: any) =>
                    new Date(a.spaceDonation.checkInDateTime).getTime() - new Date(b.spaceDonation.checkInDateTime).getTime()))
                .map((res: any) => (eventMapper(res)));
        }
        /* istanbul ignore next */
        if (pendingEvents && pendingEvents.length) {
            reservationPendingData = pendingEvents
                .sort(((a: any, b: any) =>
                    new Date(a.spaceDonation.checkInDateTime).getTime() - new Date(b.spaceDonation.checkInDateTime).getTime()))
                .map((res: any) => (eventMapper(res)));
        }
        /* istanbul ignore next */
        if (pastEvents && pastEvents.length) {
            reservationPastData = pastEvents
                .sort(((a: any, b: any) =>
                    new Date(a.spaceDonation.checkInDateTime).getTime() - new Date(b.spaceDonation.checkInDateTime).getTime()))
                .map((res: any) => (eventMapper(res)));
        }
    }

    const spaceDonationData = {
        UPCOMING: reservationUpcomingData,
        PAST: reservationPastData,
        PENDING: reservationPendingData
    }
    const setInitialState = () => {
        /* istanbul ignore next */
        setShowAllDetails(false);
        /* istanbul ignore next */
        setSelectedObj({ header: '', data: [] });
    }
    /* istanbul ignore next */
    useEffect(() => {
        analytics.trackPageInfoSpaceExternal(SPACEANALYTICS.displayEventsSpaceExternalPage);
    }, []);
    const { history } = props;
    return <>{!isOngoingEventsFetching && !isPendingEventsFetching && !isPastEventsFetching
        && <div className='reservation-container'>
            {!showAllDetails &&
                <div>
                    <DisplayEvents history={history} isDataLoaded={true}
                        header='Upcoming/Ongoing Events' data={spaceDonationData}
                        setShowAllDetails={setShowAllDetails}
                        setInitialState={setInitialState} setSelectedObj={setSelectedObj} />
                </div>}
            {showAllDetails &&
                <div>
                    <DisplayAllEvents history={history} setShowAllDetails={setShowAllDetails}
                        setInitialState={setInitialState} selectedObj={selectedObj}
                        header={selectedObj.header} data={selectedObj.data} />
                </div>}
        </div>}
        {(isOngoingEventsFetching || isPendingEventsFetching || isPastEventsFetching) && <LoaderComponent />}
    </>
}

class Reservation extends React.Component<ReservationProps, ReservationState> {
    public render() {
        return <ReservationSection {...this.props} />
    }
}

// @ts-ignore
export default withRouter(Reservation);


const DisplayEvents: React.SFC<DisplayEventsProps> = (props: DisplayEventsProps) => {
    const userContext = useContext(UserContext);
    // @ts-ignore
    const myOrgId = userContext?.organization?.id;
    const [loader, setLoader] = useState(true);
    const [ncData, setNcData] = useState<any>(null);
    /* istanbul ignore next */
    useEffect(() => {
        setData();
    }, []);
    const setData = async () => {
        /* istanbul ignore next */
        const result = await service.fetchNcEventsCard({ orgId: _.toNumber( myOrgId ) });
        if(result != undefined){
            setLoader(false);
        }
        const { UPCOMING, PAST, PENDING } = props.data;
        const updatedData = {
            UPCOMING:[...result.UPCOMING,...UPCOMING],
            PAST:[...result.PAST,...PAST],
            PENDING:[...PENDING]
        }
        /* istanbul ignore next */
        setNcData(updatedData);
    }
    return <div className='reservation-mobile-view'>
       {loader && <Loader/>}
       <div className='reservation-card-section-headers'>Upcoming Events</div>
       <div className='show-more-mobile-view'>
        {   ncData?.UPCOMING.length > 0 && (ncData?.UPCOMING?.slice(0,1)).map((cdata: any) => {
            /* istanbul ignore next */
            return <EventCard history={props.history}  key={cdata?.id} ncDataSource={cdata} status ='APPROVED' />
        })}
        {
          ncData?.UPCOMING.length > 1 ? <div className='reservation-card-show-more'
          onClick={() => {
              /* istanbul ignore next */
              props.setSelectedObj({ header: props.header, data: ncData?.UPCOMING });
              /* istanbul ignore next */
              props.setShowAllDetails(true);
          }}>
          Show more....
      </div> : ''
        }
        </div>
        {ncData?.UPCOMING.length === 0 ? <EmptyEventCard
            message='No data'
        /> : ''}
        <div className='reservation-card-section-headers'>Pending Events</div>
        <div className='show-more-mobile-view'>
        {ncData?.PENDING.length>0 && (ncData?.PENDING?.slice(0,1)).map((cdata: any) => {
            /* istanbul ignore next */
            return <EventCard history={props.history} key={cdata?.id} ncDataSource={cdata}  status ='SUBMITTED'  />
        })}
        {
          ncData?.PENDING.length > 1 ? <div className='reservation-card-show-more'
          onClick={() => {
              /* istanbul ignore next */
              props.setSelectedObj({ header: 'Pending Requests', data: ncData?.PENDING });
              /* istanbul ignore next */
              props.setShowAllDetails(true);
          }}>
          Show more....
      </div> : ''
        }
        </div>
        {ncData?.PENDING.length === 0 ? <EmptyEventCard
            message='No data'
        /> : ''}
        <div className='reservation-card-section-headers'>Past Events</div>
        <div className='show-more-mobile-view'>
        {ncData?.PAST.length >0 && (ncData?.PAST?.slice(0,1)).map((cdata: any) => {
            /* istanbul ignore next */
            return <EventCard history={props.history} key={cdata?.id} ncDataSource={cdata} status ='REJECTED' />
        })}
        {
          ncData?.PAST.length > 1 ? <div className='reservation-card-show-more'
          onClick={() => {
              /* istanbul ignore next */
              props.setSelectedObj({ header: 'Past Events', data: ncData?.PAST });
              /* istanbul ignore next */
              props.setShowAllDetails(true);
          }}>
          View all..
      </div> : ''
        }
        </div>
        {ncData?.PAST.length === 0 ? <EmptyEventCard
            message='No data'
        /> : ''}
        {!props.isDataLoaded ? <LoadingEventCard /> : ''}
    </div>
}

const DisplayAllEvents: React.SFC<DisplayAllEventsProps> = (props: DisplayAllEventsProps) => {
    /* istanbul ignore next */
    useEffect(() => {
        return () => props.setInitialState();
    });
    /* istanbul ignore next */
    return <div>
        <div className='reservation-card-headers'>
            <div className='reservation-card-section-headers'>{props.header}</div>
            <div className='reservation-card-go-back' onClick={() => {
                /* istanbul ignore next */
                props.setInitialState()
                }}>Back</div>
        </div>
        {props.data?.map((d , index) => {
            /* istanbul ignore next */
            return <div key={index} ><EventCard
            history={props.history} ncDataSource={d}
            /></div>
        })}
    </div>
}
