import { useEffect, useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { InputBox } from '../index';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { resolvePath } from '../utils';
interface RadioButtonProps {
  required?: boolean,
  noInputBox?: boolean,
  disabled?: boolean,
  radioButtonHeading?: string,
  id?: string,
  inputBoxHeading: string,
  className?: string,
  classes: any,
  formErrors: any,
  control: any,
  fieldName: string,
  fieldNameInput: string,
  yesLabel?: string,
  noLabel?: string,
  defaultValue?: string | boolean | undefined,
  rules?: any,
  setValue?: any,
  radioButtonValue?: any,
  inputBoxValue?: any,
  gridCol?: string
}

const FormRadioButton = (props: RadioButtonProps) => {
  const { t } = useTranslation();
  const [radioButton, setRadioButton] = useState<string | boolean>(`${props.radioButtonValue}`);
  const [textFiledValue, setTextFiledValue] = useState(props.inputBoxValue);

  useEffect(() => {
    setRadioButton(`${props.radioButtonValue}`);
    setTextFiledValue(props.inputBoxValue)
    props.setValue(props.fieldName, `${props.radioButtonValue}`);
    props.setValue(props.fieldNameInput, props.inputBoxValue);
  }, []);


  useEffect(() => {
    setRadioButton(`${props.radioButtonValue}`);
    setTextFiledValue(props.inputBoxValue);
    props.setValue(props.fieldName, `${props.radioButtonValue}`);
    props.setValue(props.fieldNameInput, props.inputBoxValue);
  }, [props.inputBoxValue, props.radioButtonValue]);

  useEffect(() => {
    props.setValue(props.fieldName, `${radioButton}`);
  }, [radioButton]);

  useEffect(() => {
    props.setValue(props.fieldNameInput, textFiledValue);
  }, [textFiledValue]);

  /* istanbul ignore next */
  const validateRadioSelect = (data: any) => {
    const booleanData = (data === false || data === true) ? data : (data === 'true');
    if (booleanData !== radioButton) {
      props.setValue(props.fieldName, `${booleanData}`);
      setRadioButton(`${booleanData}`);
    }
    return true;
  };
  const fieldError = resolvePath(props.fieldName, props?.formErrors, '')?.message;
  return (
    <div
      id={props.id}
      className={props?.className}
    >
      <FormControl
        required={props.required}
        style={{ width: '100%' }}
        error={(props.formErrors?.[props.fieldName]?.message || fieldError)}
        component='fieldset'
      >
        <FormLabel component='legend'>{props?.radioButtonHeading}</FormLabel>
        <Controller
          id={props.fieldName}
          name={props.fieldName}
          control={props.control}
          defaultValue={`${props.radioButtonValue}`}
          as={
            <RadioGroup
              value={`${radioButton}`}
              row={true}
            >
              <FormControlLabel
                className={'form-check-inline'}
                value={'true'}
                disabled={props.disabled}
                control={<Radio
                  color='primary'
                />}
                label={props?.yesLabel ? props.yesLabel : t('common.buttonLabels.yes')}
              />
              <FormControlLabel
                className={'form-check-inline'}
                value={'false'}
                disabled={props.disabled}
                control={<Radio
                  color='primary'
                />}
                label={props?.noLabel ? props.noLabel : t('common.buttonLabels.no')}
              />
            </RadioGroup>
          }
          rules={{
            ...props.rules,
            validate: validateRadioSelect
          }}
        />
        {(props.formErrors?.[props.fieldName]?.message || fieldError) &&
          <span className='reg-form-error-container' style={{ color: 'red' }}>
            {props.formErrors?.[props.fieldName]?.message || fieldError}
          </span>
        }
      </FormControl>
      {(radioButton === 'true' && !props?.noInputBox) &&
        <div className='row'>
          <div className={`${props?.gridCol ? props?.gridCol : 'col-md-9'}`}>
            <InputBox
              id={`${props.id}-input`}
              className={`${props.id}-input`}
              classes={props.classes}
              formErrors={props.formErrors}
              control={props.control}
              label={props.inputBoxHeading}
              fieldName={props.fieldNameInput}
              defaultValue={textFiledValue}
              value={textFiledValue}
              required={true}
              disabled={props?.disabled}
              InputProps={{
                classes: {
                  disabled: props?.disabled ? props?.classes?.disabled : ''
                }
              }}
              rules={{
                required: t('common.validationMsg.requiredField') as string
              }}
              multiline={true}
              rows={4}
            />
          </div>
        </div>}
    </div>
  );
}

export default FormRadioButton;
