import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Navbar from 'react-bootstrap/Navbar';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { contextPath, FOOD_ONE_TIME_ENABLED_COUNTRIES, GOOODS_ONE_TIME_ENABLED_COUNTRIES } from '../../constants';
import { GlobalDonationState } from 'src/models/globalDonationState';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import { loggedInUserData } from 'src/state/loggedInUser/loggedInUserActions';
import analytics from '../../adobe-analytics/analytics';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
import LoaderComponent from '../LoaderComponent';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



  const CustomTooltip = withStyles(() => ({
    root: {
        marginLeft: 5,
        
    },
    tooltip: {
    fontWeight: 'bold',
      fontSize: 12,
    },
  }))(Tooltip);

export interface OwnProps {
    handleDrawerClose: () => void;
    t: (key: string, options?: any) => React.ReactNode;
}
export interface StateProps {
    selectedLang: any;
    loggedInUserDetail: any;
    loggedInUserLoadingSuccess: any;
    loggedInUserloading: any;
    sidebarDetail:any;
}
export interface DispatchProps {
    getLoggedInUserDetail: () => void;
}
const appi18n: any = {
    organizationProfile: {
        en: 'Organization Profile',
        es: 'Perfil de la organización',
        fr: 'Profil de l\'organization',
        zh: '机构简介',
        ja: '組織プロフィール'
    },
    goodsDonation: {
        en: 'Goods/Food Donations',
        es: 'Donaciones de Bienes/Alimentos',
        fr: 'Dons de marchandises/nourriture',
        zh: '商品/食品捐赠',
        ja: '商品/食品の寄付'
    },
    monetaryDonation: {
        en: 'Monetary Donations',
        es: 'Donaciones monetarias',
        fr: 'Dons monétaires',
        zh: '货币捐款',
        ja: '寄付'
    },
    communityGrants: {
        en: 'Community Grants',
        es: 'Subvenciones comunitarias',
        fr: 'Subventions communautaires',
        ja: 'コミュニティ助成金',
        zh: '社区补助'
    },
    unmannedCollection: {
        en: 'Unmanned Collection',
        es: 'Colección no tripulada',
        fr: 'Collection sans pilote',
        ja: '無人コレクション',
        zh: '无人收藏'
    },
    organizationProfileDetails: {
        en: 'Organization Details',
        es: 'Detalles de la organización',
        fr: 'Détails de l\'organization',
        zh: '机构详细资料',
        ja: '組織の詳細'
    },
    communityGrantsDetails: {
        en: 'Community Grants Details',
        es: 'Detalles de las subvenciones comunitarias',
        fr: 'Détails des subventions communautaires',
        ja: 'コミュニティ助成金の詳細',
        zh: '社区补助金详细信息'
    },
    monetaryDonationInfo: {
        en: 'Grants available to charities and non-profits to help further their mission',
        es: 'Montos disponibles para Organizaciones No Gubernamentales para ayudar a promover su objeto social',
        fr: 'Subventions disponibles aux organismes de bienfaisance et aux organismes à but non lucratif pour les aider à poursuivre leur mission',
        zh: '向慈善机构和非营利组织提供赠款，以帮助他们进一步实现使命',
        ja: '慈善団体や非営利団体がその使命を推進するために利用できる助成金'  
    },
    GoodsAndFoodDonationInfo: {
        en: 'Recurring donations of non-saleable product available to charities and non-profits',
        es: 'Donaciones recurrentes de productos no aptos para la venta disponibles para Organizaciones No Gubernamentales',
        fr: `Dons récurrents de produits non vendables disponibles aux associations caritatives et à but non lucratif`,
        zh: '定期向慈善机构和非营利组织捐赠不可销售的产品',
        ja: '慈善団体や非営利団体が利用できる販売不可能な製品の定期的な寄付'  
    },
    monetaryDonationInfoCanada: {
        en: 'Grants larger than $1,000 available to charities and non-profits to help further their mission',
        es: 'Subvenciones superiores a $1000 disponibles para organizaciones benéficas y sin fines de lucro para ayudar a promover su misión',
        fr: 'Subventions de plus de 1000 $ offertes aux organismes de bienfaisance et aux organismes sans but lucratif pour les aider à poursuivre leur mission',
        zh: '向慈善机构和非营利组织提供超过 1,000 美元的赠款，以帮助他们进一步实现使命',
        ja: '慈善団体や非営利団体がその使命を推進するために利用できる 1,000 ドルを超える助成金'  
    },
    GoodsAndFoodDonationInfoCanada: {
        en: 'Recurring donations of non-saleable product available to charities registered with Canadian Revenue Agency (CRA) (RR required)',
        es: 'Donaciones recurrentes de productos no vendibles disponibles para organizaciones benéficas registradas en la Agencia Tributaria Canadiense (CRA) (se requiere RR)',
        fr: `Dons récurrents de produits non vendables offerts aux organismes de bienfaisance enregistrés auprès de l’Agence du revenu du Canada (ARC) (compte RR requis)`,
        zh: '向加拿大税务局 (CRA) 注册的慈善机构定期捐赠不可销售的产品（需要 RR）',
        ja: 'カナダ歳入庁 (CRA) に登録されている慈善団体が利用できる販売不可能な製品の定期的な寄付 (RR が必要)'  
    },
    communityGrantsInfo: {
        en: '$1,000 grants available to charities and non-profits to support programming',
        es: 'Subvenciones de $1,000 disponibles para organizaciones benéficas y sin fines de lucro para apoyar la programación',
        fr: 'Subventions de 1000 $ offertes aux organismes de bienfaisance et aux organismes sans but lucratif pour appuyer les programmes',
        zh: '向慈善机构和非营利组织提供 1,000 美元赠款，以支持规划',
        ja: 'プログラミングを支援する慈善団体や非営利団体が利用できる 1,000 ドルの補助金'  
    },
    monetaryDonationDetails: {
        en: 'Donation Of Money',
        es: 'Donación de dinero',
        fr: 'Don d\'argent',
        zh: '捐钱',
        ja: '寄付'
    },
    goodsDonationDetails: {
        en: 'Goods/Food Donations',
        es: 'Donaciones de Bienes/Alimentos',
        fr: 'Dons de marchandises/nourriture',
        zh: '商品/食品捐赠',
        ja: '商品/食品の寄付'
    },
    oneTimeGoods: {
        en: 'One Time Goods Donation',
        es: 'Donación única de bienes',
        fr: 'Don unique de biens',
        zh: '一次性捐贈',
        ja: 'ワンタイムグッズ寄付'
    },
    oneTimeFood: {
        en: 'One Time Food Donation',
        es: 'Donación de alimentos por única',
        fr: 'Don alimentaire unique',
        zh: '一次性食物捐赠',
        ja: '一度限りの食糧寄付'
    },
    unmannedCollectionDetails: {
        en: 'Unmanned Collection Details',
        es: 'Detalles de la colección no tripulada',
        fr: 'Détails de la collection sans pilote',
        ja: '無人コレクションの詳細',
        zh: '无人收集详细信息'
    },
    emergencyDonation: {
        en: 'Emergency Donation',
        es: 'Donación de Emergencia',
        fr: 'Don d\'urgence',
        ja: '無人コレクションの詳細',
        zh: '无人收集详细信息'
    },
    emergencyDonationDetails: {
        en: 'Emergency Donation Details',
        es: 'Detalles de la donación de emergencia',
        fr: 'Détails du don d\'urgence',
        ja: '緊急寄付の詳細',
        zh: '紧急捐款详情'
    },
    spaceDonation: {
        en: 'Space Donation',
        es: 'Donación de espacio',
        fr: 'Don d\'espace',
        ja: 'スペース寄付',
        zh: '空间捐赠'
    },
    spaceDonationDetails: {
        en: 'Space Donation Details',
        es: 'Detalles de la donación de espacio',
        fr: 'Détails du don d\'espace',
        ja: 'スペース寄付の詳細',
        zh: '空间捐赠详情'
    },
    serviceDonation: {
        en: 'Service Donation',
        es: 'Donación de servicio',
        fr: 'Don de service',
        ja: 'サービスの寄付',
        zh: '服务捐赠'
    },
    serviceDonationDetails: {
        en: 'Service Donation Details',
        es: 'Detalles de la donación de servicio',
        fr: 'Détails du don de service',
        ja: 'サービス寄付の詳細',
        zh: '服务捐赠详情'
    },
    viewMore: {
        en: 'VIEW MORE',
        es: 'VER MÁS',
        fr: 'VOIR PLUS',
        ja: 'もっと見る',
        zh: '查看更多'
    }
}

class DashboardComponent extends React.Component<StateProps & OwnProps & DispatchProps & RouteComponentProps & GlobalDonationState> {

    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.dashboard.dashboardPage);
    }
    public componentWillMount() {
        this.props.getLoggedInUserDetail();
    }

    private prepareTilesLink = () => {
        const organizationTypes = ['org_type_ngo'];
        const dashboardCards: any = [];
        // @ts-ignore
        if (!this.props.loggedInUserloading) {
            dashboardCards.push({
                name: appi18n.organizationProfile[this.props.selectedLang],
                desc: appi18n.organizationProfileDetails[this.props.selectedLang],
                routeLink: `${contextPath}/dashboard/organization-profile?type=organization-profile`
            });
            if (this.props.loggedInUserDetail && this.props.loggedInUserDetail.countryCode === 'CA') {
                dashboardCards.push({
                    name: appi18n.communityGrants[this.props.selectedLang],
                    desc: appi18n.communityGrantsDetails[this.props.selectedLang],
                    routeLink: `${contextPath}/dashboard/community-grant?type=community-grants`,
                    info: appi18n.communityGrantsInfo[this.props.selectedLang],
                });
            }
            if (this.props.loggedInUserDetail && this.props.loggedInUserDetail.organizationType &&
                this.props.loggedInUserDetail.organizationType.keyText === 'org_type_ngo') {
                dashboardCards.push({
                    desc: appi18n.monetaryDonationDetails[this.props.selectedLang],
                    name: appi18n.monetaryDonation[this.props.selectedLang],
                    routeLink: `${contextPath}/dashboard/donation-money?type=donation-money`,
                    info: this.props.loggedInUserDetail.countryCode === "CA" ? appi18n.monetaryDonationInfoCanada[this.props.selectedLang] : appi18n.monetaryDonationInfo[this.props.selectedLang]
                });
            }
            if (this.props.loggedInUserDetail && this.props.loggedInUserDetail.organizationType &&
                this.props.loggedInUserDetail.organizationType.keyText === 'org_type_ngo' &&
                this.props.loggedInUserDetail.countryCode !== 'IN') {
                dashboardCards.push({
                    desc: appi18n.goodsDonationDetails[this.props.selectedLang],
                    name: appi18n.goodsDonation[this.props.selectedLang],
                    routeLink: `${contextPath}/dashboard/donation-goods?type=donation-goods`,
                    info: this.props.loggedInUserDetail.countryCode === "CA" ? appi18n.GoodsAndFoodDonationInfoCanada[this.props.selectedLang] : appi18n.GoodsAndFoodDonationInfo[this.props.selectedLang]
                });
            }
            if (this.props.loggedInUserDetail && this.props.loggedInUserDetail.organizationType &&
                organizationTypes.includes(this.props.loggedInUserDetail.organizationType.keyText) &&
                GOOODS_ONE_TIME_ENABLED_COUNTRIES.includes(this.props.loggedInUserDetail.countryCode)) {
                dashboardCards.push({
                    desc: appi18n.oneTimeGoods[this.props.selectedLang],
                    name: appi18n.oneTimeGoods[this.props.selectedLang],
                    routeLink: `${contextPath}/dashboard/one-time-goods?type=one-time-goods`
                });
            }
            if (this.props.loggedInUserDetail && this.props.loggedInUserDetail.organizationType &&
                organizationTypes.includes(this.props.loggedInUserDetail.organizationType.keyText) &&
                FOOD_ONE_TIME_ENABLED_COUNTRIES.includes(this.props.loggedInUserDetail.countryCode)) {
                dashboardCards.push({
                    desc: appi18n.oneTimeFood[this.props.selectedLang],
                    name: appi18n.oneTimeFood[this.props.selectedLang],
                    routeLink: `${contextPath}/dashboard/one-time-food?type=one-time-food`
                });
            }
            if (this.props.loggedInUserDetail && this.props.loggedInUserDetail.countryCode === 'GB') {
                dashboardCards.push({
                    name: appi18n.unmannedCollection[this.props.selectedLang],
                    desc: appi18n.unmannedCollectionDetails[this.props.selectedLang],
                    routeLink: `${contextPath}/dashboard/unmanned-collection?type=unmanned-collection`
                });
            }
            if (this.props.loggedInUserDetail) {
                

                if(this.props.sidebarDetail?.data){
                    Object.keys(this.props.sidebarDetail?.data).map((value:any)=>{
                        const dashboardRoute = this.props.sidebarDetail?.data[value];
                        dashboardCards.push({
                            name: dashboardRoute.translation?.[this.props.selectedLang] ?? "",
                            desc: dashboardRoute.details?.[this.props.selectedLang] ?? "",
                            routeLink: `${contextPath}/${dashboardRoute.routeLink ?? ""}`
                        });
                    })
                }
            }
        }
        return dashboardCards;
    }

    public render() {
        const dashBoardRowCards = this.prepareTilesLink();
        return (
            <div id='homepage' style={{ backgroundColor: 'inherit' }}>
                <Navbar>
                    <Navbar.Toggle />
                </Navbar>
                <Jumbotron style={{ backgroundColor: 'inherit', textAlign: 'center', minHeight: '100vh' }}>
                    <Row xs={1} lg={3} xl={4} md={2}>
                        <>
                            {dashBoardRowCards.map((item: any) => (
                                <Col key={item.name}>
                                    <Link to={item.routeLink}>
                                        <Card key={item.name} border='primary' className='mb-2' >
                                            <Card.Header className='dashboard-header'>
                                                <div style={{display: "inline-block", marginRight: 5}}>{item.name} </div>
                                                {
                                                   item.info &&
                                                <CustomTooltip title={item.info} arrow >
                                                    <InfoOutlined fontSize= "small" />
                                                </CustomTooltip>
                                                }
                                            </Card.Header>
                                            <Card.Body>
                                                <Card.Text>
                                                    {/* This Text needs to be updated, current I dont see it in the getLoggedInUserDetail object */}
                                                    
                                                    <Button className='view-more' style={{ borderRadius: '25px' }} variant='outline-primary'>
                                                    {appi18n.viewMore[this.props.selectedLang]}</Button> 
                                                </Card.Text>
                                            </Card.Body>
                                        </Card></Link>
                                </Col>
                            ))
                            }
                        </>
                    </Row>

                </Jumbotron>
                {this.props.loggedInUserloading && <LoaderComponent fullScreen={true} />}
            </div>)
    }
}
const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
});
const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    loggedInUserDetail: state.loggedInUserState.userDetail,
    loggedInUserloading: state.loggedInUserState.loggedInUserloading,
    loggedInUserLoadingSuccess: state.loggedInUserState.loggedInUserLoadingSuccess,
    sidebarDetail: state.sidebarState.sidebarData
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DashboardComponent));