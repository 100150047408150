import { useEffect, useState } from 'react';
import { FormBlockHeading, wordLengthValidation } from '../../utils';
import { InputBox } from 'src/components/common/index';
import { useTranslation } from 'react-i18next';
import { validateEmail, Validation } from '../../../common/utils';
import { stringFormat } from '../../../common/utils';
import { countryList } from '../../../../static/countryList';
import KeyValueDropdown from 'src/components/common/Dropdown/keyValueDropdown';

export const PrimaryUserDetails = (props: any) => {
  const { t } = useTranslation();
  const [countryOfResidence, setCountryOfResidence] = useState<string>('')
  const requiredFieldTranslation = t('common.validationMsg.requiredField');

  useEffect(() => {
    props.setValue('primaryOrgUser.userName', props?.data?.primaryOrgUser?.userName ? props.data.primaryOrgUser.userName : '');
    props.setValue('primaryOrgUser.userEmail', props?.data?.primaryOrgUser?.userEmail ? props.data.primaryOrgUser.userEmail : '');
    props.setValue('primaryOrgUser.userTitle', props?.data?.primaryOrgUser?.userTitle ? props.data.primaryOrgUser.userTitle : '');
    props.setValue('primaryOrgUser.birthYear', props?.data?.primaryOrgUser?.birthYear ? props.data.primaryOrgUser.birthYear : '');
    props.setValue('primaryOrgUser.countryOfResidence', props?.data?.primaryOrgUser?.countryOfResidence
      ? props.data.primaryOrgUser.countryOfResidence : '');
    props.setValue('primaryOrgUser.userTelephone', props?.data?.primaryOrgUser?.userTelephone
      ? props.data.primaryOrgUser.userTelephone : '');
    setCountryOfResidence(props?.data?.primaryOrgUser?.countryOfResidence
      ? props.data.primaryOrgUser.countryOfResidence : '')

  }, []);

  const toValidateEmail = (value: any) => {
    return validateEmail(value) ? true : t('orgRegForm.validationMsg.email') as string
  };

  const toValidBirthYear = (value: any) => {
    return Validation.validateYearOfBirth(value, t('orgRegForm.validationMsg.yearOfbirth') as string)
  };

  const wordLengthValidate = (value: any) => {
    const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 100);
    return wordLengthValidation(value, message, 100)
  };

  return (
    <>
      <FormBlockHeading heading={t('oneTimeGoodsDonation.heading.primaryUserDetails')} />
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'user_details_primary_user_name'}
            className={'user_details_primary_user_name'}
            classes={props.classes}
            formErrors={props.errors}
            control={props.control}
            label={t('orgRegForm.labels.primaryuser')}
            fieldName={'primaryOrgUser.userName'}
            defaultValue={''}
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: wordLengthValidate
            }}
            errorMsg={props?.errors?.primaryOrgUser?.userName?.message}
            disabled={true}
            InputProps={{
              readOnly: props?.data?.primaryOrgUser?.userName ? true : false,
              classes: {
                disabled: props?.data?.primaryOrgUser?.userName ? props?.classes.disabled : ''
              }
            }}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'user_details_primary_user_email'}
            className={'user_details_primary_user_email'}
            classes={props.classes}
            formErrors={props.errors}
            control={props.control}
            label={t('orgRegForm.labels.primaryuserEmail')}
            fieldName={'primaryOrgUser.userEmail'}
            defaultValue={''}
            required={true}
            rules={{
              required:requiredFieldTranslation,
              validate: toValidateEmail
            }}
            errorMsg={props?.errors?.primaryOrgUser?.userEmail?.message}
            disabled={true}
            InputProps={{
              readOnly: props?.data?.primaryOrgUser?.userEmail ? true : false,
              classes: {
                disabled: props?.data?.primaryOrgUser?.userEmail ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
      <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'user_details_userTitle'}
            className={'user_details_userTitle'}
            classes={props.classes}
            formErrors={props.errors}
            control={props.control}
            label={t('orgRegForm.labels.userTitle')}
            fieldName={`primaryOrgUser.userTitle`}
            defaultValue={''}
            multiline={true}
            required={true}
            rules={{
              required: requiredFieldTranslation,
            }}
            errorMsg={props?.errors?.primaryOrgUser?.userTitle?.message}
            disabled={(props?.data?.primaryOrgUser?.userTitle && props.disabled) ? true : false}
            InputProps={{
              readOnly: (props?.data?.primaryOrgUser?.userTitle && props.disabled) ? true : false,
              classes: {
                disabled: (props?.data?.primaryOrgUser?.userTitle && props.disabled) ? props?.classes.disabled : ''
              }
            }}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'user_details_year_of_birth'}
            className={'user_details_year_of_birth'}
            classes={props.classes}
            formErrors={props.errors}
            control={props.control}
            label={t('orgRegForm.labels.yearOfBirth')}
            fieldName={'primaryOrgUser.birthYear'}
            defaultValue={''}
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: toValidBirthYear
            }}
            errorMsg={props?.errors?.primaryOrgUser?.birthYear?.message}
            disabled={(props?.data?.primaryOrgUser?.birthYear && props.disabled) ? true : false}
            placeholder={'e.g., 1999'}
            InputProps={{
              readOnly: (props?.data?.primaryOrgUser?.birthYear && props.disabled) ? true : false,
              classes: {
                disabled: (props?.data?.primaryOrgUser?.birthYear && props.disabled) ? props?.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4' style={{ marginTop: '16px', paddingRight: '0px' }}>
          <KeyValueDropdown
            id={'countryOfResidence'}
            inputLabel={t('oneTimeGoodsDonation.labels.countryRegionOfResidence')}
            fieldName={'primaryOrgUser.countryOfResidence'}
            defaultValue={countryOfResidence}
            options={countryList}
            errors={props.errors}
            control={props.control}
            disabled={props?.data?.primaryOrgUser?.countryOfResidence && props.disabled}
            required={true}
            valueKey='label'
            labelKey='label'
            rules={{
              required: requiredFieldTranslation
            }}
            errorKeyName={'primaryOrgUser.countryOfResidence'}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'user_details_telephone'}
            className={'user_details_telephone'}
            classes={props?.classes}
            formErrors={props?.errors}
            control={props?.control}
            label={t('orgRegForm.labels.telephone')}
            fieldName={'primaryOrgUser.userTelephone'}
            defaultValue={''}
            disabled={(props?.data?.primaryOrgUser?.userTelephone && props.disabled) ? true : false}
            InputProps={{
              readOnly: (props?.data?.primaryOrgUser?.userTelephone && props.disabled) ? true : false,
              classes: {
                disabled: (props?.data?.primaryOrgUser?.userTelephone && props.disabled) ? props?.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
    </>
  )
}
