import { FC, memo, useState } from 'react'
import BackWithHeading from './common/BackWithHeading';
import { BreadcrumbsComponent } from '../../common'
import NGOCreateFoodDonation from './NGOCreateFoodDonation'
import IOrganizationInfo from '../../../models/iOrganizationInfo';
import { defaulOrgData } from './common/validation';
import StoreSelectionComponent from './components/StoreSelectionComponent'
import { IFacilitynfo } from '../../../models/storeModel';
import { contextPath } from '../../../constants'; import { OrganizationTypes } from 'src/components/common/utils';
;

const breadcrumbsData = [
  {
    name: 'oneTimeGoodsDonation.nav.home',
    path: '/',
  },
  {
    name: 'oneTimeFoodDonation.oneTimeFoodDonation',
    path: `${contextPath}/dashboard/one-time-food?type=one-time-food`,
  },
  {
    name: 'oneTimeGoodsDonation.nav.requestNew',
    active: true,
    applyFormat: false
  }
];

interface OwnProps {
  orgData: IOrganizationInfo,
  selectedLang: string
}
const CreateDonationOfFood: FC<OwnProps> = ({
  orgData,
  selectedLang
}) => {
  const [storeInfo, setStoreNumber] = useState<IFacilitynfo | null>();
  const countryCode = orgData.countryCode;
  const storeNumberInfo = storeInfo?.facilityNo;
  const handleFacilityChange = (facility: IFacilitynfo) => {
    setStoreNumber(facility)
  }
  return <>
    <BreadcrumbsComponent breadcrumbsData={breadcrumbsData} />
    <BackWithHeading url='/dashboard/one-time-food?type=one-time-food' heading='oneTimeFoodDonation.oneTimeFoodDonation' />
    <StoreSelectionComponent
      countryCode={countryCode}
      handleFacilityChange={handleFacilityChange}
      storeNumber={storeNumberInfo ?? null}
      selectedLang={selectedLang}
    />


    {orgData && storeNumberInfo &&
      <NGOCreateFoodDonation
        orgType={orgData?.organizationType?.keyText === OrganizationTypes.NGO ? OrganizationTypes.NGO : OrganizationTypes.GE}
        storeNumber={+storeNumberInfo}
        selectedLang={orgData?.selectedLang ?? ''}
        orgData={orgData ?? defaulOrgData as any} countryCode={countryCode} />
    }
  </>
}

export default memo(CreateDonationOfFood)
