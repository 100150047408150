import React from 'react';
import { Alert } from '@material-ui/lab';

interface Props {
  id?: string,
  className?: string,
  colGrid?: string,
  message: string,
  rowStyles?: any,
  severity?: 'error' | 'info' | 'success' | 'warning',
  variant?: 'filled' | 'outlined' | 'standard',
  rowClass?: 'reg-form-error-container' | any
}

// ToDo -- Add style, translation, props etc to generalize
export const AlertBox = React.memo((props: Props) => {
  const { className, message, colGrid, rowStyles, rowClass } = props;
  return (
    <div className={`row ${rowClass}`} style={{...rowStyles}}>
      <div className={colGrid}>
        <Alert
          className={className}
          {...props}
        >
          {message}
        </Alert>
      </div>
    </div>
  )
});