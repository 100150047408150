import LDCard from "../../commonComponents/uiComponents/LDCard";
import "./NpoSpark.css";
const config = require(`src/config.${process.env.NODE_ENV}.json`);
const NpoSpark = () => {
  return (
    <div className="npo-spark-container">
      <LDCard className="npo-spark-card">
        <div className="npo-spark-verify-contents">
          You must verify your email address before you’re able to access Space
          Tool
          <div>
            <a
              className="npo-spark-link"
              rel="noreferrer"
              target={"_blank"}
              href={`${config.walmartProfilePage}`}
            >
              Verify now in settings
            </a>
          </div>
        </div>
      </LDCard>
    </div>
  );
};

export default NpoSpark;
