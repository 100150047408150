import './styles.css';
import React from 'react';
import SideBarComponent from '../sideBar/sideBarParent';

import { contextPath } from 'src/constants';
import { Outlet } from 'react-router-dom';

import TopBar from '../../../commonComponents/appComponents/topBar/topBar';

import LoaderComponent from '../loader/Spinner';

export const hoSearchContext = React.createContext<any>(null);
// tslint:disable-next-line
export interface DrawerProps {
}
// tslint:disable-next-line
export interface DrawerState {
}

const Drawer: React.SFC<DrawerProps> = () => {
    return <div className='drawer-root'>
        <DrawerComponent />
    </div>
}

const ContentsComponent = () => {
    return <div className='content'>
        <NavRouter />
    </div>
}
const DrawerComponent = () => {
    const [searchContextVal , setSearchContextVal] = React.useState([]);
    return  <hoSearchContext.Provider value={{searchContextVal , setSearchContextVal}}><div>
        <TopBar />
        <div className='space-app-container'>
            <div className='wrapper'>
                {/* time attribute is dummy attribute to rerender Sidebar component on prop change */}
                <SideBarComponent time={new Date()} />
                <ContentsComponent />
            </div>
        </div>
    </div>
     </hoSearchContext.Provider>
}

export default Drawer;


const NavRouter = () => {
    return <Outlet></Outlet>
}

export const SpacePrivateRoute: any = ({ element, authed, orgData, isLoading }: any) => {
    return authed ? renderComponent(isLoading, orgData, element) : redirect()
}

const renderComponent = (isLoading: boolean, orgData: any, children: any) =>
    isLoading ? <LoaderComponent /> :
    ((Object.keys(orgData).length > 0 && (orgData.id || (orgData.myOrgs && orgData.myOrgs.length) ))? children : redirect());
const redirect = () => window.location.href = `${contextPath}/space-donation/onboarding`;
