import { createStyles, Grid, IconButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { getEncryptedString } from 'dynamic-form-components/lib/crypto';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormBlockHeading } from '../../components/donations/utils';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { useDownloadFile } from '../../custom-hooks/useDownloadFile';
import { ErrorMessageComponent } from '../common';
import LoaderComponent from '../LoaderComponent';
import { getFormattedDate } from '../common/DateUtils';
import { getEmptyFileList, getShorterFilename, MAX_ALLOWED_FILES_SIZE, SUPPORTED_FORMATS, UPLOAD_FILE_SIZE, validateFileSizeAndFormat } from './utils';

const config = require(`../../config.${process.env.NODE_ENV}.json`);

const useStyles = makeStyles(() => createStyles({
    root: {
        textAlign: 'center',
        cursor: 'pointer',
        color: '#333',
        padding: '10px',
        marginTop: '20px',
        border: '2px solid #c1c1c1',
        borderStyle: 'dotted',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '68px'
    },
    icon: {
        marginTop: '16px',
        color: '#888888',
        fontSize: '42px',
    },
    label: {
        fontSize: '16px',
        fontFamily: 'Bogle',
        fontWeight: 500
    },
    filesType: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        color: '#6d7278',
        marginTop: '8px'
    },
    tableContainer: {
        padding: '10px',
        backgroundColor: '#fbfbfb'

    },
    rowRoot: {
        '&:last-child td': {
            borderBottom: 0,
        },
        '&:last-child th': {
            borderBottom: 0,
        },
    }
}));

interface FileUploadComponentProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    defaultFiles: any,
    readOnly?: boolean,
    disabled?: boolean,
    label?: string,
    name: string,
    allowMultiple: boolean
    showFileUpload?: boolean
    heading: string,
    style?: any,
    required?: boolean
    showEmptyFileMessage?: boolean
    errorMessage?: string
    callBack?: any;
    options?: {
        supportedFileExtMessage: string,
        fileSize: number,
        maxAllowedFilesSize: number,
        supportedFormats: string,
        fileSizeErrorMsg: string,
        fileFormatErrorMsg: string
    }
}

export const FileUploadComponent: FC<FileUploadComponentProps> = (props: any) => {
    const { name, defaultFiles, showEmptyFileMessage = true, callBack } = props
    const [newFileList, setNewFileList] = useState<any>()
    const styles = useStyles();
    const defaultStyle = props?.style ?? {
        marginBottom: '40px'
    }
    const { t } = useTranslation();
    const {
        register,
        unregister,
        setValue,
        getValues,
        watch,
        setError,
        errors
    } = useFormContext()

    const defaultOptions = props?.options ?? {
        supportedFileExtMessage: 'common.labels.supportedFileExtMessage',
        fileSize: UPLOAD_FILE_SIZE,
        maxAllowedFilesSize: MAX_ALLOWED_FILES_SIZE,
        supportedFormats: SUPPORTED_FORMATS,
        fileSizeErrorMsg: t('common.errorMesg.fileSizeErrorMsg'),
        fileFormatErrorMsg: t('common.errorMesg.fileFormatErrorMsg')
    }

    const { mutateAsync, isLoading, isError, error } = useDownloadFile();

    const requiredFieldTranslation = t(props?.errorMessage ?? 'common.validationMsg.requiredField')
    const files: File[] = watch(name, defaultFiles)
    const tableRows: React.ReactElement[] = []
    let mergedFileList: any[] = []
    const formattedFileList: any = []

    /* istanbul ignore next */
    const onDrop = useCallback(
        (droppedFiles: any[]) => {
            let fileList: any[] = [];
            const newFiles = droppedFiles.map((file: any) => {
                file.isDeletable = true
                return file
            })
            const _files = getValues(name);
            if (_files) {
                fileList = [...newFiles, ..._files];
            } else {
                fileList = [...newFiles]
            }
            const res = validateFileSizeAndFormat(fileList, defaultOptions)
            if (res?.isValid) {
                setValue(name, fileList, { shouldValidate: true })
                if (callBack) {
                    props.callBack(fileList);
                }
            }
            if (!res?.isValid && res?.error) {
                setError(name, { message: t(`${res?.error}`), shouldFocus: true })
            }
        },
        [setValue, name],
    )
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: props.accept,
        multiple: props?.allowMultiple
    })

    useEffect(() => {
        register(name,
            {
                required: props?.required ? requiredFieldTranslation : false
            }
        )
        return () => {
            unregister(name)
        }
    }, [register, unregister, name, props?.required, newFileList])

    const handleRemove = (index: number) => {
        // @ts-ignore
        const filteredList = files.filter((value, idx) => {
            return idx !== index;
        });
        // get only newly uploaded files
        const newFiles = filteredList.filter((value) => {
            // @ts-ignore
            return value?.isDeletable === true
        });
        setNewFileList(newFiles)
        if (props?.required && newFiles?.length < 1) {
            setValue(name, getEmptyFileList(), { shouldValidate: true })
            setError(name, { message: requiredFieldTranslation, shouldFocus: true, })
            if (callBack) {
                props.callBack(getEmptyFileList());
            }
        } else {
            setValue(name, filteredList, { shouldValidate: true });
            if (callBack) {
                props.callBack(filteredList);
            }
        }
    }

    const downloadFile = async (file: any) => {
        if (file?.fileName) {
            const fileName = file?.fileName;
            const fileInfo = {
                fileId: fileName,
                fileName,
                directory: file?.uploadDir,
                container: file?.container
            }
            const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
            const dataUri = `files?fileInfo=${encryptedFileInfo}`;
            await mutateAsync({ name: `${fileName}`, type: 'downloadFiles', actionUrl: `${config.appBaseUrl}/${dataUri}` });
        }
        else if (file?.name) {
            const url = URL?.createObjectURL(file?.fileObj);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.target = '_blank'
            a.download = `${file?.name}`;
            document.body.appendChild(a);
            a.click();
            return window.URL?.revokeObjectURL(url);
        }
    }

    files?.forEach((file: any) => {
        if (file?.name) {
            formattedFileList.push({
                name: file?.name,
                size: file?.size,
                type: file?.type,
                isDeletable: file?.isDeletable,
                lastModified: file?.lastModified,
                path: file?.path,
                fileObj: file
            })
        }
        return
    })

    if (defaultFiles && defaultFiles?.length > 0) {
        mergedFileList = [...formattedFileList, ...defaultFiles]
    } else {
        mergedFileList = [...formattedFileList]
    }

    mergedFileList?.forEach((file: any, index: any) => {
        const fileName = file?.name ? file.name : file?.fileName;
        const shortenFileName = getShorterFilename(fileName, 25)
        tableRows.push(
            <TableRow key={`${index}_${shortenFileName}`} classes={{ root: styles.rowRoot }}>
                <TableCell component='th' scope='row'>{shortenFileName}</TableCell>
                <TableCell component='th' scope='row'>{file?.uploadedBy ?? 'NA'}</TableCell>
                <TableCell component='th' scope='row'>
                    {file?.uploadedOn ? getFormattedDate(file.uploadedOn) : 'NA'}
                </TableCell>
                <TableCell align='right'>
                    <IconButton color='default'
                        aria-label='Download File'
                        onClick={() => downloadFile(file)}
                        component='span'>
                        <CloudDownloadIcon />
                    </IconButton>
                    {(file?.isDeletable) &&
                        <IconButton color='default'
                            aria-label='Delete File'
                            onClick={() => handleRemove(index)}
                            component='span'>
                            <DeleteIcon />
                        </IconButton>}
                </TableCell>
            </TableRow>
        )
    })

    return (
        <div style={{ ...defaultStyle }}>
            <Grid container={true} item={true} xs={12} md={12}>
                <FormBlockHeading heading={t(props?.heading)} />
                {props?.showFileUpload && <>
                    <Grid container={true} item={true} xs={12}>
                        <Paper
                            variant='outlined'
                            className={styles.root}{...getRootProps()}>
                            <input
                                id={name}
                                data-testid={name}
                                {...getInputProps()}
                            />
                            <Typography classes={{ root: styles.label }} >{t('common.buttonLabels.label')}</Typography>

                        </Paper>

                    </Grid>
                    <Typography classes={{ root: styles.filesType }}>{t('common.buttonLabels.fileType')}</Typography>
                </>}

            </Grid >
            <ErrorMessageComponent errors={errors} fieldName={name} />
            <Grid container={true} item={true} xs={12} md={8} style={{ marginBottom: '10px', marginTop: '10px' }}>
                {!!mergedFileList?.length ? (
                    <TableContainer component={Paper} elevation={3} variant='elevation' className={styles.tableContainer}>
                        <Table aria-label='attached files table' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} >{t('common.attachmentsTable.columnFileName')}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} >{t('common.attachmentsTable.uploadedBy')}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }} >{t('common.attachmentsTable.uploadedOn')}</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {tableRows}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>

                ) :
                    showEmptyFileMessage ? (<div>{t('appendixBDonationLabels.noAttachments')}</div>) : ''
                }
            </Grid>
            <ErrorSnackbarComponent
                isError={isError}
                // @ts-ignore
                message={error?.errorMessage || t('common.errorMesg.genericError')}
            />
            {isLoading ? <LoaderComponent fullScreen={true} /> : <></>}
        </div>
    )
}