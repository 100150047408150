import * as React from 'react';
import {Typography} from '@material-ui/core';
import {WalmartIcon} from 'dynamic-form-components/lib/components/icon/WalmartLogo';

export default class HeaderComponent extends React.PureComponent<any> {
    public render() {
        const { classes } = this.props;
        return (<>
            <div className={`${classes.toolbar} ${classes.headerLogo}`}>
                <Typography style={{marginLeft: '10%'}} color='inherit' variant='h6'>
                    {'Walmart'}<WalmartIcon /> {'Global Donation Management'}</Typography>
            </div>
        </>);


    }
}