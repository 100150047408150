import { useEffect, useState } from 'react';
import { Typography, TextField, makeStyles, Theme } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { validateEmail, Validation } from './../../components/common/utils';
import { useTranslation } from 'react-i18next';
import GenericConfirmDialog from '../dialogComponents/GenericConfirmDialog';
import { contextPath } from './../../constants';
import tracker from '../../adobe-analytics/tracker';
import analytics from '../../adobe-analytics/analytics';
import { useNavigate } from 'react-router-dom';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';

const useStyles = makeStyles((_theme: Theme) => ({
    goBackBtn:{
        color: '#000',
        fontSize:'16px',
        fontWeight:'normal',
        textDecoration:'underline',
        textTransform:'none',
        outline: 'none !important',
        minWidth: '120px !important',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
            textDecoration: 'underline'
        },
    },
    confirmBtn:{
        color: '#fff !important',
        fontSize: '16px',
        border: 'none',
        borderRadius: 'var(--buttonBorderRadius)!important',
        backgroundColor: 'var(--buttonColor)!important',
        outline: 'none !important',
        minWidth:'120px !important',
        textTransform: 'none',
    }
}));
const OrgPrimaryUserDetailsComponent = (props: any) => {
    const navigateTo = useNavigate()
    const { classes } = props;
    const { t } = useTranslation();
    const customClasses = useStyles()

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [formData, setFormData] = useState({})

    const {
        handleSubmit,
        getValues,
        control,
        errors,
    } = useForm({
        mode: 'all',
        defaultValues: { ...props.defaultFormValues }
    });

    useEffect(() => {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.registerPage.registerOrgDetailsPage2);
}, [])

    const goBack = () => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.ORG_PRIMARY_USER_DETAILS_BACK_BUTTON.name,
            location:ANALYTICS.registerPage.registerOrgDetailsPage2
        });
        const formValues = getValues()
        props.goBack(formValues)
    }
    const onRegister = (data:any) =>{
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.REGISTER_USER_DETAIL.name,
            location:ANALYTICS.registerPage.registerOrgDetailsPage2
        });
        setShowConfirmDialog(true)
        setFormData(data)
    }

    const handleCancelAction = () => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.ORG_PRIMARY_USER_DETAILS_CANCEL_BUTTON.name,
            location:ANALYTICS.registerPage.registerOrgDetailsPage2
        });
        setShowConfirmDialog(false)
    }

    const handleConfirmAction = () => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.CONFIRM_REGISTER.name,
            location:ANALYTICS.registerPage.registerOrgDetailsPage2
        });
        setShowConfirmDialog(false)
        props.onSubmit(formData)
    }
    return (
        <div className={classes.formElement}>
            <Typography className='section-header'>
                {t('orgRegForm.labels.userDetails')}
            </Typography>
            <form onSubmit={
                handleSubmit(onRegister)
                } noValidate={true} autoComplete='off'>
                <div>
                    <Controller
                        control={control}
                        name='primaryOrgUser.userName'
                        id='userName'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='userName'
                                label={t('orgRegForm.labels.primaryuser')}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.primaryOrgUser?.userName?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.primaryOrgUser?.userName?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.primaryOrgUser?.userName?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='primaryOrgUser.userTitle'
                        id='userTitle'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='userTitle'
                                label={t('orgRegForm.labels.primaryuserTitle')}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.primaryOrgUser?.userTitle?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.primaryOrgUser?.userTitle?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.primaryOrgUser?.userTitle?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='primaryOrgUser.userEmail'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='email'
                                label={t('orgRegForm.labels.primaryuserEmail')}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors?.primaryOrgUser?.userEmail?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                            validate: (value: any) => {
                                return validateEmail(value) ? true : t('orgRegForm.validationMsg.email') as string
                            }
                        }}
                    />
                    {errors?.primaryOrgUser?.userEmail?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.primaryOrgUser?.userEmail?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='primaryOrgUser.birthYear'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='birthYear'
                                label={t('orgRegForm.labels.yearOfBirth')}
                                placeholder='e.g., 1999'
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors?.primaryOrgUser?.birthYear?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                            validate: (value:any) => {
                                return Validation.isValidBirthYear(value, t('orgRegForm.validationMsg.yearOfbirth') as string)
                            }
                        }}
                    />
                    {errors?.primaryOrgUser?.birthYear?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.primaryOrgUser?.birthYear?.message}<br /></span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='primaryOrgUser.countryOfResidence'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='countryOfResidence'
                                label={t('orgRegForm.labels.countryOfResidence')}
                                helperText=''
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.primaryOrgUser?.countryOfResidence?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.primaryOrgUser?.countryOfResidence?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.primaryOrgUser?.countryOfResidence?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='primaryOrgUser.userTelephone'
                        defaultValue={''}
                        as={
                            <TextField
                                required={true}
                                id='userTelephone'
                                label={t('orgRegForm.labels.telephone')}
                                helperText=''
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors.primaryOrgUser?.userTelephone?.message}
                            />
                        }
                        rules={{
                            required: t('orgRegForm.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors.primaryOrgUser?.userTelephone?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors.primaryOrgUser?.userTelephone?.message}</span>}
                </div>
                <div>
                    <div className='social-impact-btn-group'>
                        <div className='social-impact-btn-group'>
                            <div className='inline-flex-container'>
                                <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    {t('orgRegForm.labels.registeredTxt')}
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <button className='btn-link'
                                    type='button'
                                    style={{
                                        color: 'black',
                                        textDecoration: 'underline',
                                        fontWeight: 'normal',
                                        textTransform: 'none',
                                        fontSize: '14px'
                                    }}
                                    onClick={() => {
                                        analytics.trackEventInfo({
                                            type: tracker.EVENTS.CLICK,
                                            actionOn: tracker.ACTION_ON.LOGIN_BUTTON.name,
                                            location:ANALYTICS.registerPage.registerOrgDetailsPage2
                                        });
                                        navigateTo(`${contextPath}/Public/login?lang=${localStorage.getItem('country')}`);
                                    }}>
                                    {t('orgRegForm.labels.loginTxt')}
                            </button>
                            </div>
                            <div className='inline-flex-container'
                                style={{marginTop:'-8px', float:'right'}}>
                                <button className={`social-imapct-cancel-btn back-btn-reg`}
                                    style={{marginRight:'12px'}}
                                    type='button'
                                    onClick={goBack}
                                >
                                    {t('common.buttonLabels.backBtn')}
                                </button>
                                <button className={`sip-submit-btn go-btn-next`}
                                    type='submit'>
                                    {t('common.buttonLabels.registerBtn')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <GenericConfirmDialog showDialog={showConfirmDialog}
                title={t('common.popUpMsg.orgReg.actionTitle')}
                message={t('common.popUpMsg.orgReg.actionMsg')}
                confirmButton={t('common.buttonLabels.submitBtn')}
                cancelButton={t('common.buttonLabels.goBackBtn')}
                cancel={handleCancelAction}
                confirm={handleConfirmAction}
                cancelBtnClasses={customClasses.goBackBtn}
                confirmBtnClasses={customClasses.confirmBtn}
                customStyle={{ justifyContent:'center'}}
            />
            {/* {loading && <LoaderComponent fullScreen={true} />} */}
        </div>
    )
}
export default OrgPrimaryUserDetailsComponent;
