import { SubmissionDataActions, SubmissionDataActionTypes } from './submissionDataActions';
import { SubmissionDataState } from '../../models/globalDonationState';

export const defaultSubmissionDataState: SubmissionDataState = {
    submissionData: {},
    submissionLoading: false,
    submissionLoadingSuccess: false,
    submissionLoadingFailure: false,
};

export const globalSubmissionDataReducer = (state: SubmissionDataState = defaultSubmissionDataState, action: SubmissionDataActions):
    SubmissionDataState => {
    switch (action.type) {
        case SubmissionDataActionTypes.SUBMISSION_DATA_LOAD: return {
            ...state,
            submissionLoading: true,
            submissionLoadingSuccess: false,
            submissionLoadingFailure: false,
        };
        case SubmissionDataActionTypes.SUBMISSION_DATA_LOAD_SUCCESS: return {
            ...state,
            submissionData: action.data,
            submissionLoading: false,
            submissionLoadingFailure: false,
            submissionLoadingSuccess: true,
        };
        case SubmissionDataActionTypes.SUBMISSION_DATA_LOAD_FAILURE: return {
            ...state,
            submissionLoading: false,
            submissionLoadingFailure: true,
            submissionLoadingSuccess: false,
        };
        default: return state;
    }
}