/* eslint-disable */

import {
    Alert, Button,
    ButtonGroup,
    Nudge
} from "@walmart-web/livingdesign-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DONATION_TYPE_VALUES } from "../../../constants";
import withRouter from "../../../polyfils/customRouter";
import CommonLDModal from "../../common/CommonLDModal";
import StatusCards from "../emergencyDonation/components/StatusCards";
import {
    acceptModal, cancelModal, createPayloadForAction
} from "./SpaceInternationalDataFunctions";

import { Flag } from "@livingdesign/icons";
//import moment from "moment";
import { getOrganizationObject } from "src/components/common/utils";
import TypicalDonationTemplateForm from "../CommonComponents/TypicalDonationTemplateForm";

export interface OwnProps {
    history: any;
    selectedLang: any;
    donationActionMutate: any;
    donationData: any;
    donationDetailsQuery: any;
    donationCategory: any;
}

const SpaceDonationView: FC<OwnProps> = ({ history, selectedLang, donationActionMutate, donationCategory, donationDetailsQuery }) => {
    const { t } = useTranslation();

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<any>({});

    const [donationData, setDonationData] = useState<any>({});
    const [donationStatus, setDonationStatus] = useState<any>([]);
    const [waasTaskProps, setWaasTaskProps] = useState<any>([]);
    let [organizationForm, setOrganizationForm] = useState<any>([]);
    // Re - run this piece of code for fetcing details again
    useEffect(() => {
        setDonationStatus(donationDetailsQuery?.data?.spaceAndServiceDonation?.currentStatusCode);
        setWaasTaskProps(donationDetailsQuery?.data?.waaSTaskMessage?.formProperties || []);
        setDonationData(donationDetailsQuery?.data);
        let orgProfile = donationData?.spaceAndServiceDonation?.organization ? donationData?.spaceAndServiceDonation?.organization : [];
        setOrganizationForm(getOrganizationObject(orgProfile, "spaceDonations"));
    }, [donationData?.spaceAndServiceDonation]);

    const onAction = (actionAllowed: string) => {
        let acceptPayload = createPayloadForAction(donationData, window.modalBoxComments, actionAllowed);
        donationActionMutate.mutate({ form: acceptPayload, action: actionAllowed });
        window.modalBoxComments = "";
        setIsPopupOpen(false);
    }

    const cancelSubmittedDonation = () => {
        let createModalProps = cancelModal(t, setIsPopupOpen, onAction, 'cancel');
        setModalProps(createModalProps);
        setIsPopupOpen(true);
    }

    const acceptAlternateProposalDonation = () => {
        let createModalProps = acceptModal(t, setIsPopupOpen, onAction, 'accept');
        setModalProps(createModalProps);
        setIsPopupOpen(true);
    }
    
    const isAcceptable = donationStatus === "PENDING_APPROVAL" && (waasTaskProps.indexOf('accept') >= 0);

    return (
        <>
            {donationData?.spaceAndServiceDonation ?
                <>
                    {donationData?.spaceAndServiceDonation.nationalEventDonation && (
                        <Nudge style={{ margin: "5px" }} title={t("spaceDonations.form.nationalHead")} leading={<Flag />}>
                            {t("spaceDonations.form.national")}
                        </Nudge>)}
                    {donationStatus === "PENDING_APPROVAL" &&  (<Alert variant="error">
                        {t("spaceDonations.form.conflictMessage")}
                    </Alert>)}
                    <ButtonGroup className="space-international-top-right-button">
                        {isAcceptable &&
                            (<Button onClick={acceptAlternateProposalDonation} variant="primary"
                                data-testid="view-space-international-acceptannce">
                                {t("spaceDonations.form.accept")}
                            </Button>)}

                        {
                            (donationStatus === "UNDER_RDA_REVIEW" || donationStatus === "PENDING_APPROVAL" || donationStatus === "APPROVED") &&
                            (waasTaskProps.indexOf('cancel') >= 0) &&
                            (
                                <Button onClick={cancelSubmittedDonation} variant="primary" data-testid="cancel-new-space-international-request">
                                    {t("spaceDonations.form.cancel")}
                                </Button>)
                        }
                    </ButtonGroup>


                    <StatusCards
                        status={donationStatus}
                        data={donationData?.spaceAndServiceDonation}
                        donationType={DONATION_TYPE_VALUES.SPACE_INTERNATIONAL}
                    />

                    <TypicalDonationTemplateForm
                        history={history}
                        data={donationData?.spaceAndServiceDonation}
                        orgType={donationData?.spaceAndServiceDonation?.organization?.organizationTypePersisted}
                        editable={false}
                        selectedLang={selectedLang}
                        isLocationMandatory={false}
                        donationCategory={donationCategory}
                        organizationForm={organizationForm}
                    />

                </> : ""}

            <CommonLDModal setOpen={setIsPopupOpen} isOpen={isPopupOpen} modalConfig={modalProps} />

        </>
    );
};

export default withRouter(SpaceDonationView);
