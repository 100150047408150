import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getLangaugeDropDownOptionsByCountryCode } from './../../../constants';
import { PrefLangProps } from './../../../types/formComponentTypes';

export const PrefLangSelectComponent = React.memo((props: PrefLangProps) => {
    const languageDropDown: React.ReactElement[] = []
    const {t} = useTranslation()
    const languageList  = getLangaugeDropDownOptionsByCountryCode(props.countryCode)

    return (
        <div>
            <FormControl
                required={props.required}
                style={{ width: '100%' }}
                classes={{ root: props?.classes?.selectFormControl }}
                size='medium' variant='outlined'
                error={props.formErrors?.[props.fieldName]?.message}
                disabled={props.disabled ? props.disabled : false}
            >
                <InputLabel
                    classes={{ root: props?.classes?.selectLabel }}
                    id='prefLanguage-selecct-input'
                    style={{fontWeight:'bold'}}
                >
                    {t(props.translationKey)}
                </InputLabel>
                <Controller
                    control={props.control}
                    name={props.fieldName}
                    defaultValue={props?.defaultValue}
                    as={
                        <Select
                            classes={{ root: props.classes.selectRoot, icon: props.classes.selectIcon }}
                            labelId='prefLanguage-select-label'
                            id={props.fieldName}
                            name={props.fieldName}
                            autoWidth={false}
                            defaultValue={props?.defaultValue}
                            displayEmpty={true}
                        >
                            {
                                // @ts-ignore
                                languageList?.map((item: any) => {
                                languageDropDown.push(<MenuItem
                                    classes={{
                                        root: props.classes.menuItemRoot,
                                        selected: props.classes.menuItemselected
                                    }}
                                    key={item.value} value={item.value}>{item.label}</MenuItem>)
                            })}
                            {languageDropDown}
                        </Select>
                    }
                    rules={{...props.rules}}
                />
                {props.formErrors?.[props.fieldName]?.message &&
                    <span className='reg-form-error-container' style={{ color: 'red' }}>
                        {props.formErrors?.[props.fieldName]?.message}
                    </span>
                }
            </FormControl>
        </div>
    )
})
