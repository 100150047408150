import { useEffect } from 'react';
import { FormBlockHeading, wordLengthValidation } from '../../utils';
import { DropDown, InputBox } from '../../../common/index';
import { countryCodeTranslations } from '../../../../constants';
import '../../../../styles/formStyles.css';
import { useTranslation } from 'react-i18next';
import { setGEAppendixBOrgFields, stringFormat } from '../../../common/utils';
import InputTextField from '../../../common/formComponents/InputTextField';
import { useFormContext } from 'react-hook-form';
import { useStatesList } from 'src/custom-hooks';
import { Box } from '@material-ui/core';

export const GEAppendixBOrgDetails = (props: any) => {

  const { control, errors, setValue } = useFormContext()
  const { t } = useTranslation();
  const countryCode = props?.data?.organization?.countryCode ? props.data.organization?.countryCode : '';
  const countryName = countryCodeTranslations.find(row => row.code === countryCode)?.label?.[props?.selectedLang];
  const requiredFieldTranslation = t('common.validationMsg.requiredField');
  const wordLengthlimit = t('common.validationMsg.wordLengthlimit')

  const {address} = props?.data?.organization ?? {}
  const {isStateListLoading, stateList} = useStatesList(countryCode)

  useEffect(() => {
    if (props?.data) {
      setValue('organization.address.province', props.data.organization?.address?.province ?? '');
    }
  }, [stateList]);

  useEffect(() => {
    if (props?.data) {
      setGEAppendixBOrgFields(setValue, props?.data)
    }
  }, [props?.data]);

  useEffect(() => {
    setValue('organization.address.country', props?.data?.organization?.countryCode ?? countryName);
  }, [props?.data?.organization?.countryCode]);

  const wordLengthValidate10 = (value: any) => {
    const message = stringFormat(wordLengthlimit, 10);
    return wordLengthValidation(value, message, 10)
  };

  const wordLengthValidate600 = (value: any) => {
    const message = stringFormat(wordLengthlimit, 600);
    return wordLengthValidation(value, message, 600)
  };

  return (
    <div>
      <FormBlockHeading heading={t(props?.heading)} />
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputTextField
            required={true}
            label={t('orgRegForm.labels.gelegalName')}
            fieldName={'organization.orgLegalName'}
            id={`gelegalName`}
            defaultValue={''}
            isUsedForArray={false}
            errorKeyName={'organization'}
            errorFieldName={'orgLegalName'}
            disabled={true}
            rules={{
              required: requiredFieldTranslation,
              validate: wordLengthValidate600
            }}
            InputProps={{
              readOnly: true,
              classes: {
                disabled: props.classes.disabled
              }
            }}
          />
        </div>
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputTextField
            required={true}
            label={t('orgMngmtTbl.cols.country')}
            fieldName={'organization.country'}
            id={`country`}
            defaultValue={''}
            errorKeyName={'organization'}
            errorFieldName={'country'}
            disabled={true}
            rules={{
              required: requiredFieldTranslation
            }}
            InputProps={{
              readOnly: true,
              classes: {
                disabled: props.classes.disabled
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-12 col-md-10 col-xl-8'>
          <InputBox
            id={'org-street-address'}
            className={'org-street-address'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.streetAddr')}
            fieldName={'organization.address.addressLine1'}
            defaultValue={''}
            multiline={true}
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: wordLengthValidate600
            }}
            errorMsg={errors?.organization?.address?.addressLine1?.message}
            disabled={(address?.addressLine1 && props.disabled) ? true : false}
            InputProps={{
              readOnly: (address?.addressLine1 && props.disabled) ? true : false,
              classes: {
                disabled: (address?.addressLine1 && props.disabled) ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>
      <div className='row org_details_row' >
        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'user_details_telephone'}
            className={'user_details_telephone'}
            classes={props?.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.telephone')}
            fieldName={'organization.telephone'}
            defaultValue={''}
            rules={{
              required: requiredFieldTranslation
            }}
            disabled={(props?.data?.organization?.telephone && props.disabled) ? true : false}
            InputProps={{
              readOnly: (props?.data?.organization?.telephone && props.disabled) ? true : false,
              classes: {
                disabled: (props?.data?.organization?.telephone && props.disabled) ? props?.classes.disabled : ''
              }
            }}
          />
        </div>

        <div className='col-sm-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
          <InputBox
            id={'org-postalcode'}
            className={'org-postalcode'}
            classes={props.classes}
            formErrors={errors}
            control={control}
            label={t('orgRegForm.labels.postalCode')}
            fieldName={'organization.address.postalCode'}
            defaultValue={address?.postalCode}
            required={true}
            rules={{
              required: requiredFieldTranslation,
              validate: wordLengthValidate10
            }}
            errorMsg={errors.organization?.address?.postalCode?.message}
            disabled={(address?.postalCode && props?.disabled) ? true : false}
            InputProps={{
              readOnly: (address?.postalCode && props?.disabled) ? true : false,
              classes: {
                disabled: (address?.postalCode && props?.disabled) ? props.classes.disabled : ''
              }
            }}
          />
        </div>
      </div>

      <div className='row org_details_row'>
          <div className='col-sm-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
            {stateList && stateList.length > 0 && !isStateListLoading ?
            <Box mt = '16px'>
              <DropDown
              id={'org-state'}
              className={`org-state ${(address?.province && props?.disabled) ? 'disabled-dropdown' : ''}`}
              formErrors={errors}
              control={control}
              inputLabel={t('orgRegForm.labels.state')}
              fieldName={'organization.address.province'}
              required={true}
              defaultValue={''}
              dropDownData={stateList}
              rules={{
                required: requiredFieldTranslation
              }}
              disabled={(address?.province && props?.disabled) ? true : false}
              classes={{
                ...props.classes,
                disabled: (address?.province && props?.disabled) ? props.classes.disabled : ''
              }}
              errorMsg={errors?.organization?.address?.province?.message}
            />
            </Box>
              :
            <InputBox
              id={'org-state'}
              className={`org-state ${(address?.province && props?.disabled) ? 'disabled-dropdown' : ''}`}
              formErrors={errors}
              control={control}
              label={t('orgRegForm.labels.state')}
              fieldName={'organization.address.province'}
              required={true}
              defaultValue={''}
              rules={{
                required: requiredFieldTranslation
              }}
              disabled={(address?.province && props?.disabled) || isStateListLoading ? true : false}
              classes={{
                ...props.classes,
                disabled: (address?.province && props?.disabled)|| isStateListLoading ? props.classes.disabled : ''
              }}
              errorMsg={errors?.organization?.address?.province?.message}
            />}
          </div>
          <div className='col-sm-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
            <InputBox
              id={'org-city'}
              className={'org-city inputBox'}
              classes={props.classes}
              formErrors={errors}
              control={control}
              label={t('orgRegForm.labels.city')}
              fieldName={'organization.address.city'}
              defaultValue={address?.city}
              required={true}
              rules={{
                required: requiredFieldTranslation,
                validate: wordLengthValidate600
              }}
              errorMsg={errors?.organization?.address?.city?.message}
              disabled={(address?.city && props?.disabled) ? true : false}
              InputProps={{
                readOnly: (address?.city && props?.disabled) ? true : false,
                classes: {
                  disabled: (address?.city && props?.disabled) ? props.classes.disabled : ''
                }
              }}
            />
          </div>

      </div>
    </div>
  )
}
