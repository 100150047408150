import {FC} from 'react';
import {Box, makeStyles} from '@material-ui/core'
import {Control, useFormContext, useWatch} from 'react-hook-form';

interface TextFieldMaxSizeIndicatorProps {
    fieldName: string;
    maxSize: number
    control?: Control
}

const useStyles = makeStyles({
    subDesc: {
        fontSize: '12px',
        color: '#74767c;',
        marginLeft: '16px',
        marginTop: '4px'
    }
});
const TextFieldMaxSizeIndicator: FC<TextFieldMaxSizeIndicatorProps> = ({fieldName, maxSize, ...props}) => {
    const classes =  useStyles();
    const {control: controlRef} = useFormContext();
    const fieldValue = useWatch<string>({
        name: `${fieldName}`,
        control: props?.control ?? controlRef
      });
    return <Box className = {classes.subDesc}>{fieldValue?.length??0}/{maxSize}</Box>
}

export default TextFieldMaxSizeIndicator;