import React, { useContext,useEffect, useState } from 'react';
import CardItem from '../../uiComponents/LDCardItem';
import Divider from '../../uiComponents/LDDivider';
import UserContext from '../../../store/user-context';
import AvatarLogo from '../../appComponents/Avatar/Avatar';
import {Panel} from '@walmart-web/livingdesign-components';
import LoaderComponent from '../../../commonComponents/uiComponents/LDSpinner';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useMutation } from 'react-query';
import { contextPath } from 'src/constants';
import './styles.css';
import LDModal from '../../uiComponents/LDModal';
import LDButton from '../../uiComponents/LDButton';
import { Plus } from '@livingdesign/icons';

const service: Service = SpaceDonationService;

interface Props{
    setShowProfileCard:any,
    showProfileCard:any,
    showConfirmModal:any,
    setShowConfirmModal:any,
}

class ProfileCard extends React.Component<Props>{
    public render() {
        return <ProfileCardContent {...this.props}/>
    }
}

const titleCase = (str: any) =>
    str?.trim()?.split(' ')
        ?.map((w:any) => w[0]?.toUpperCase() + w?.substring(1)?.toLowerCase())
        ?.join(' ');

// @ts-ignore
export default ProfileCard;

const ProfileCardContent = (props:any) => {
    const userContext: any = useContext(UserContext);
    const currentOrgName = userContext?.organization?.orgName;
    const mutation = useMutation((data: any) => service.setMyOrgData(data));
    const [selectedOrgId, setSelectedOrgId] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentSelectedOrg, setCurrentSelectedOrg] = useState<any>(null);
    useEffect(() => {
        if (mutation.isSuccess) {
           window.location.href = `${contextPath}/space-donation/create`
        }
    }, [mutation.isSuccess]);

  useEffect(() => {
    if(currentSelectedOrg) {checkPath() }
  }, [currentSelectedOrg]);


    const onSwitchConfirmExistingOrg = () => {
        const org = currentSelectedOrg;
        if (org.orgId) {
            setSelectedOrgId(org.orgId);
            mutation.mutate({ cid: userContext?.organization?.userContactDetails?.cid, orgId: org.orgId , contactDetails: {
                contactType: null,
                cid: userContext?.organization?.userContactDetails?.cid,
                firstName: userContext?.organization?.userContactDetails?.firstName,
                lastName: userContext?.organization?.userContactDetails?.lastName,
                email: userContext?.organization?.userContactDetails?.email,
                phoneNumber: userContext?.organization?.userContactDetails?.phoneNumber,
                orgOwner: org.orgOwner
            }});
            setCurrentSelectedOrg(null);
        } else {
            props.history.push(
              {   pathname: `${contextPath}/space-donation/verifiedOnboarding`,
                  state: { data: {
                          cid: userContext?.organization?.userContactDetails?.cid,
                          orgOwner: org.orgOwner,
                          cybergrantsOrganizationId: org.cybergrantsOrganizationId,
                          orgPhoneNumber: null,
                          orgName: org.orgName,
                          orgAddressLine1: org.orgAddress.addressLineOne,
                          orgAddressLine2: org.orgAddress.addressLineTwo,
                          orgCity: org.orgAddress.city,
                          orgState: org.orgAddress.state,
                          orgZip: org.orgAddress.zip,
                          primaryContactFname: userContext?.organization?.userContactDetails?.firstName,
                          primaryContactLname: userContext?.organization?.userContactDetails?.lastName,
                          primaryContactEmail: userContext?.organization?.userContactDetails?.email,
                          primaryContactNum: ''
                      }}
              });
            props.setShowProfileCard(false);
        }
    }

  const onSwitchConfirmNewOrg = () => {
    props.history.push(
      {
        pathname: `${contextPath}/space-donation/nonVerifiedOnboarding`,
        state: ''
      })
    props.setShowProfileCard(false);
  }

  const checkPath = () => {
      const currentPath = window.location.pathname;
      const confirmationRequired = currentPath.includes('space-donation/create/new')
        || currentPath.includes('space-donation/nonVerifiedOnboarding')
        || currentPath.includes('space-donation/verifiedOnboarding');
      if(confirmationRequired) {
        setShowConfirmModal(true);
      }else{
        switchConfirmAction();
      }
  }

    const switchConfirmAction = () => {
        currentSelectedOrg?.orgId ?
        onSwitchConfirmExistingOrg() :
            (currentSelectedOrg.cybergrantsOrganizationId ?
                onSwitchConfirmExistingOrg() : onSwitchConfirmNewOrg());
    }

    return <Panel id='profile-card' onClose={() => {console.log()}} position='right'
    isOpen={props.showProfileCard} title=' ' closeButtonProps={{onClick : () => props.setShowProfileCard(false)}}>
        <div className='profile-card'>
            <CardItem className='profile-card-header'>
                <div className='profile-card-header-content'>
                    <AvatarLogo bgColor='#041E42' name={currentOrgName ? currentOrgName.toUpperCase() : 'O'} />
                    <div className='profile-card-header-name'>{titleCase(currentOrgName)}</div>
                </div>
            </CardItem>
            <br />
            <Divider />
            <CardItem className='profile-card-list'>
                {userContext?.organization?.myOrgs && userContext?.organization?.myOrgs &&
                    userContext?.organization?.myOrgs
                        .filter((org: any) => org.orgId !== userContext?.organization.id).map((org: any, index: any) => {
                            return <div key={index}>
                                {mutation.isLoading && selectedOrgId === org.orgId &&
                                    <div className='profile-card-loader'><div /><LoaderComponent /><div /></div>}
                                {mutation.isLoading && selectedOrgId !== org.orgId && <div className='profile-card-org'>
                                    <AvatarLogo bgColor='gray' name={org.orgName ? org.orgName.toUpperCase() : 'O'} />
                                    <div className='profile-card-orgname'>{titleCase(org.orgName)}</div>
                                </div>}
                                {!mutation.isLoading && <div className='profile-card-org' onClick={() =>
                                {
                                  setCurrentSelectedOrg(org);
                                }}>
                                    <AvatarLogo bgColor='gray' name={org.orgName ? org.orgName.toUpperCase() : 'O'} />
                                    <div className='profile-card-orgname'>{titleCase(org.orgName)}</div>
                                </div>}
                                <Divider />
                            </div>
                        })
                }
            </CardItem>
            <SwitchConfirm showConfirmModal={showConfirmModal}
                         selectedOrg = {currentSelectedOrg} setCurrentSelectedOrg = {setCurrentSelectedOrg}
                         setShowConfirmModal={setShowConfirmModal}
                         switchConfirmAction={switchConfirmAction} {...props}/>
            <br />
            <div className='profile-card-addorg' onClick={() => {
              setCurrentSelectedOrg({});
            }
            }>
                <Plus style={{ fill: '#0072ea', fontSize: '2.5rem', margin: 'auto' }} />
                <div className='profile-card-orgname'>Add a new organization</div>
            </div>
        </div>
    </Panel>
}


 const SwitchConfirm: any = (props: any) => {
    return  <LDModal isOpen={props.showConfirmModal} onClose={() => {props.setShowConfirmModal(false);props.setCurrentSelectedOrg(null);}}
           size='small' className='switch-message-modal' title=' '>
     <div className='switch-message'>
         <div className='switch-account-header'>{props.selectedOrg?.orgName? 'Switch account to ' + props.selectedOrg?.orgName
           : null} </div>
         <br />
          <div className='switch-account-body'>Your data will be lost if you switch from one account to another.</div>
         <br />
         <div className='switch-confirmation-buttons'>
         <LDButton className='switch-confirmation-cancel-button-spacing'
             onClick={() => {
                 props.setShowConfirmModal(false);
                 props.setCurrentSelectedOrg(null);
             }} variant='secondary'>
             Cancel
         </LDButton>
         <LDButton
           onClick={() => {
             props.switchConfirmAction();
             props.setShowConfirmModal(false);
           }}
           variant='primary'>
             Switch
         </LDButton>
         </div>
     </div>
 </LDModal>
 }
