import { Action } from 'redux';

export enum NavBarActionTypes {
    NAV_BAR_OPEN = 'NAV_BAR_OPEN',
    NAV_BAR_CLOSE = 'NAV_BAR_CLOSE'
}

export interface NavBarOpen extends Action {
    type: NavBarActionTypes.NAV_BAR_OPEN
}

export const navBarOpen = (): NavBarOpen => ({
    type: NavBarActionTypes.NAV_BAR_OPEN
});

export interface NavBarClose extends Action {
    type: NavBarActionTypes.NAV_BAR_CLOSE
}

export const navBarClose = (): NavBarClose => ({
    type: NavBarActionTypes.NAV_BAR_CLOSE
});

export type NavBarActions = NavBarOpen | NavBarClose;