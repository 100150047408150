import {Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Typography} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RestoreIcon from '@material-ui/icons/Restore';
import React from 'react';
import {useTranslation} from 'react-i18next';


export interface CustomAccordionProps {
    title: string
    text?: string;
    translationKey?: string;
    showStatusIcon?: boolean;
    hasError?: boolean;
    statusText?: string;
    showStatusMessage?: boolean;
    children: any;
    isEditable: boolean | undefined | null,
    defaultExpanded?: boolean,
    id: string
}

const useStyles = makeStyles({
    accr_summary_content: {
        justifyContent: 'space-between',
        padding: '0 4px 0 16px',
        alignItems: 'center'
    },
    successIcon: {
        color: '#6AAB4F'
    },
    errorIcon: {
        color: '#FC934D'
    },
    typography: {
        fontWeight: 'bold'
    },
    message: {
        lineHeight: 2,
        marginLeft: '5px'
    },
    accordionRoot: {
        marginTop: '16px',
        '&:before': {
            height: '0px',
            'background-color': 'transparent'
        },
        '&.Mui-expanded:first-child': {
            marginTop: '16px'
        }

    },
    accordionExpanded: {
        '&:first-child': {
            marginTop: '16px'
        }
    },
    accDetailRoot: {
        borderRadius: '2px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: 0,
        flexDirection: 'column',
    },
    accSummaryRoot: {
        padding: '0 16px 0 0',
        borderBottom: '1px solid #f1f1f2;'
    },
    disabled: {
        pointerEvents: 'none'
    }
})


export const CustomAccordion: React.FC<CustomAccordionProps> = ({ children, showStatusIcon = true, showStatusMessage = true,
    hasError = false, title, defaultExpanded = false, isEditable, id }) => {
    const customStyles = useStyles();

    const { t } = useTranslation();
    const statusMessage = hasError ? t('components.accordion.pending') : t('components.accordion.completed')

    return (<Accordion defaultExpanded={defaultExpanded}
        classes={{ root: customStyles.accordionRoot, expanded: customStyles.accordionExpanded }}
        square={true}
        id = {id} >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
                content: customStyles.accr_summary_content,
                root: customStyles.accSummaryRoot
            }}

            aria-controls='panel1a-content'
            id='panel1a-header'
        >
            <Typography classes={{ root: customStyles.typography }}>{title}</Typography>
            {showStatusIcon && (<Box display='flex' alignItems='center' >
                {!hasError && <CheckCircleOutlineIcon classes={{ colorPrimary: customStyles.successIcon }} color='primary' />}
                {hasError && <RestoreIcon classes={{ colorSecondary: customStyles.errorIcon }} color='error' />}
                {showStatusMessage && <Typography classes={{ root: customStyles.message }}>{statusMessage}</Typography>}
            </Box>)}
        </AccordionSummary>
        <AccordionDetails classes={{ root: customStyles.accDetailRoot }} className={!isEditable ? customStyles.disabled : ''} >
            {children}
        </AccordionDetails>
    </Accordion>

    );
}

CustomAccordion.defaultProps = {
    isEditable: true,
}