import {Box,Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {FC} from 'react';
import {useFieldArray, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormBlockHeading} from 'src/components/donations/utils';
import AddRow from '../common/AddRow';

import {CustomCell, CustomHeaderCell} from '../common/CustomCell';
import {DatePickerField} from '../common/DatePickerField';
import {InputTextField} from '../../../common/Input/InputTextField';
import {DefaultPrincipalProgramFields} from './orgDetailsDefaults';

export interface IPrincipleProgram {
    fieldName: string
}

const columnHeader = [{ key: 'programName', width: '24%' },
{ key: 'programDescription', width: '24%' }, { key: 'startDate', width: '24%' },
{ key: 'endDate', width: '24%' }, { key: 'actions', width: '5%' }];

export const PrincipleProgram: FC<IPrincipleProgram> = ({ fieldName }) => {
    const { control } = useFormContext();
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);
    const principleProgramFields = useFieldArray({
        name: fieldName,
        control
    })
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField');
    const handleRemove = (index: number) => {
        principleProgramFields.remove(index);
    }
    const onRowAdd = () => {
        principleProgramFields.append(DefaultPrincipalProgramFields[0]);
    }

    return (
        <Box style={{
            width: '100%', padding: '10px',
            boxShadow: 'box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1)', borderRadius: '6px'
        }}
            bgcolor='#fbfbfb'>
            <FormBlockHeading heading={getTranslation('ppSummary')} />
            <TableContainer >
                <Table aria-label='Principle Program Table' size='small'>
                    <TableHead>
                        <TableRow>
                            {columnHeader.map((column, index) => {
                                return (<CustomHeaderCell key={`header_${index}`}
                                    style={{ width: column.width }}>
                                    {column.key === 'actions' ? null : getTranslation(column.key)}
                                </CustomHeaderCell>)
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {principleProgramFields.fields.map((field, index) => {
                            const partialErrKey = `${fieldName}.${index}.`;
                            const partialFieldKey = `${fieldName}[${index}]`;
                            return (<TableRow key={field.id} >
                                <CustomCell >
                                    <InputTextField
                                        fieldName={`${partialFieldKey}.programName`}
                                        id={field.id}
                                        defaultValue={field.programName}
                                        errorKeyName={`${partialErrKey}].programName`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell  >
                                    <InputTextField
                                        fieldName={`${partialFieldKey}.programDescription`}
                                        id={`${index}`}
                                        defaultValue={field.programDescription}
                                        multiline={true}
                                        rows={2}
                                        errorKeyName={`${partialErrKey}.programDescription`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell >
                                    <DatePickerField defaultValue={field.startDate} id={field.id}
                                        errorKeyName={`${partialErrKey}.startDate`}
                                        fieldName={`${partialFieldKey}.startDate`} />
                                </CustomCell>
                                <CustomCell >
                                    <DatePickerField defaultValue={field.endDate} id={field.id}
                                        errorKeyName={`${partialErrKey}.endDate`}
                                        fieldName={`${partialFieldKey}.endDate`} />
                                </CustomCell>
                                <CustomCell >
                                    <IconButton color='default' onClick={() => handleRemove(index)} id={field.id}
                                        disabled={principleProgramFields?.fields?.length === 1}
                                        aria-label='delete row' component='span'>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </CustomCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddRow
                handler = {onRowAdd}
                key = 'addNewPrincipleProgram'
                t = {t}
            />
        </Box>

    );
}