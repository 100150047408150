import { Grid, InputLabel, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputTextField } from 'src/components/common/Input/InputTextField';
import { sectionClasses } from '../common/styles';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';
import { BaseSectionProps } from '../organizationDetails/orgDetailsDefaults';

export interface ProjectImpactAndContinuityProps extends BaseSectionProps {
    data: any
}

export const ProjectImpactAndContinuity:
    FC<ProjectImpactAndContinuityProps> = (props: ProjectImpactAndContinuityProps) => {
        const { t } = useTranslation();
        const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
        return (<div className={sectionClasses.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Typography classes={{ root: sectionClasses.title }}>{getTranslation('impactAndContinuityDetails')}</Typography>
                </Grid>
                <Grid item={true} xs={12} md={4}>
                    <InputLabel required = {true} id={'explainProjectFitsLabel'}>
                        {getTranslation('explainProjectFits')}
                    </InputLabel>
                    <InputTextField
                        required={true}
                        fieldName={`${props.fieldName}.projectLongTermVisionFit`}
                        errorKeyName={`${props.fieldName}.projectLongTermVisionFit`}
                        id={'projectLongTermVisionFit'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        rows={4}
                        multiline={true}
                        defaultValue={props?.data?.projectLongTermVisionFit}
                    />
                     <TextFieldMaxSizeIndicator
                        fieldName = {`${props.fieldName}.projectLongTermVisionFit`}
                        maxSize = {1000}/>
                </Grid>
            </Grid>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputLabel required = {true} id={'specifyNumberOfJobsLabel'}>
                        {getTranslation('specifyNumberOfJobs')}
                    </InputLabel>
                    <InputTextField
                        required={true}
                        fieldName={`${props.fieldName}.noOfJobsProjectCreate`}
                        errorKeyName={`${props.fieldName}.noOfJobsProjectCreate`}
                        id={'noOfJobsProjectCreate'}
                        type={'number'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfJobsProjectCreate}
                    />
                </Grid>
            </Grid>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputLabel required = {true} id={'yearsOfImpactLabel'}>
                        {getTranslation('yearsOfImpact')}
                    </InputLabel>
                    <InputTextField
                        required={true}
                        fieldName={`${props.fieldName}.noYearsOfImpact`}
                        errorKeyName={`${props.fieldName}.noYearsOfImpact`}
                        id={'noYearsOfImpact'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        rows={4}
                        multiline={true}
                        defaultValue={props?.data?.noYearsOfImpact}
                    />
                     <TextFieldMaxSizeIndicator
                        fieldName = {`${props.fieldName}.noYearsOfImpact`}
                        maxSize = {1000}/>
                </Grid>
            </Grid>
        </div>
    );
}