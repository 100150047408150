import { Box, makeStyles, Theme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import analytics from '../../adobe-analytics/analytics';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
import tracker from '../../adobe-analytics/tracker';
import { contextPath, countryCodeToCurrenccySymbol, DashBoardTypes } from 'src/constants';
import { getFormattedDate } from '../common/DateUtils';
import { ExtractDialogComponent } from '../common/ExtractDialogComponent';
import { currencyFormat } from '../common/utils';
import DataTableComponent from '../donations/oneTimeGoods/DataTableComponent';

const useStyles = makeStyles((_theme: Theme) => ({
    extractReportBtn: {
        height: '40px',
        borderRadius: '20px',
        backgroundColor: '#0071ce',
        textTransform: 'uppercase',
        marginRight: '10px',
        color: '#fff',
        border: 'solid 1px',
        fontSize: '14px',
        fontWeight: 'bold',
        width: 'auto',
        float: 'right'

    },
    finalizedReport: {
        backgroundColor: '#EAF3E6',
        color: '#7DA46D',
        padding: '5px'
    },
    notInitiatedReport: {
        backgroundColor: '#F1F1F2',
        color: '#A2A2A5',
        padding: '5px'
    },
    inProgressReport: {
        backgroundColor: '#FFF9E9',
        color: '#df982f',
        padding: '5px'
    }
}));

const useCustomStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        margin: '10px',
        borderRadius: '8px'
    },
}));


const getStatusIcon = (status: string) => {

    if (status === 'IN_PROGRESS_REPORT') {
        return (<ScheduleIcon fontSize='small' style={{ paddingRight: '3px' }} />)
    }
    if (status === 'FINALIZED_REPORT') {
        return (<CheckCircleOutlineIcon fontSize='small' style={{ paddingRight: '3px' }} />)
    }

    return <></>;
}
const reportUrl = 'report/social-impact-monetary?type=social-impact-monetary'
const SocialImpactMonetaryDashboard: FC<any> = ({ myDonationTableProps, loggedInUserDetails, ...props }) => {
    useEffect(() => {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.socialImpact.monetaryReportPage);
    }, [])
    const classes = useStyles();
    const { t } = useTranslation()
    const customClass = useCustomStyles();
    const [showExtractDialog, setShowExtractDialog] = useState<boolean>(false);


    const amountConverter = (amount: number) => {
        // @ts-ignore
        const currencySymbol = countryCodeToCurrenccySymbol[props.countryCode]
        return <div>
            {currencyFormat(amount, currencySymbol)}
        </div>
    }

    /* istanbul ignore next */
    const viewDonationRequest = (orgDetail: any) => {
        const payload: any = {
            value: {
                ...orgDetail
            },
            key: DashBoardTypes.SocialImpactMonetaryDashBoard
        }
        props.addDataToStore(payload);
        props.history.push(`${contextPath}/${reportUrl}`);
    }

    const columns: any = [
        {
            field: 'projectId',
            header: t('socialImpactReports.monetaryDashboard.columns.projectId'),
            sortable: false,
            sortField: 'projectId',
            filter: true,
            filterField: 'projectId',
            filterMatchMode: 'equals',
            filterPlaceholder: t('socialImpactReports.monetaryDashboard.columns.projectId'),
            body: (rowData: any) => {
                /* istanbul ignore next */
                return <div>
                    <button type='button' className='p-button p-component p-button-link'
                        // @ts-ignore
                        onClick={() => { viewDonationRequest(rowData) }}>
                        <span className='p-button-label p-c' style={{ fontWeight: 'bold' }}
                            data-testid={`id_${rowData?.projectId}`} >{rowData?.projectId}</span>
                    </button>
                </div>
            },
            style: {
                width: '12em'
            }
        },
        {
            field: 'projectName',
            header: t('socialImpactReports.monetaryDashboard.columns.projectName'),
            sortable: false,
            sortField: 'projectName',
            style: {
                width: '15em',
                'text-align': 'center'
            }
        },
        {
            field: 'amountGranted',
            header: t('socialImpactReports.monetaryDashboard.columns.amountGranted'),
            sortable: false,
            sortField: 'amountGranted',
            body: (rowData: any) => {
                if (rowData?.amountGranted === 0) {
                    return '-'
                }
                /* istanbul ignore next */
                return amountConverter(rowData?.amountGranted)
            },
            style: {
                width: '10em',
                'text-align': 'right'
            }
        },
        {
            field: 'amountReported',
            sortField: 'amountReported',
            header: ('socialImpactReports.monetaryDashboard.columns.amountReported'),
            sortable: false,
            body: (rowData: any) => {
                if (rowData?.amountReported === 0) {
                    return '-'
                }

                return amountConverter(rowData?.amountReported)
            },
            style: { width: '12em', 'text-align': 'right' },
        },
        {
            field: `activitiesInProgress`,
            sortField: `activitiesInProgress`,
            header: t('socialImpactReports.monetaryDashboard.columns.activitiesInProg'),
            sortable: false,
            body: (rowData: any) => {
                if (rowData?.activitiesInProgress === 0) {
                    return '-'
                }

                return rowData?.activitiesInProgress
            },
            style: { width: '14em', 'text-align': 'right' },
        },
        {
            field: 'activitiesCompleted',
            sortField: 'activitiesCompleted',
            header: t('socialImpactReports.monetaryDashboard.columns.activitiesCompleted'),
            sortable: false,
            body: (rowData: any) => {
                if (rowData?.activitiesCompleted === 0) {
                    return '-'
                }

                return rowData?.activitiesCompleted
            },
            style: { width: '14em', 'text-align': 'right' },
        },
        {
            field: 'status',
            header: ('socialImpactReports.monetaryDashboard.columns.status'),
            sortable: false,
            sortField: 'status',
            body: (rowData: any) => {
                let className = classes.notInitiatedReport

                if (rowData?.status === 'FINALIZED_REPORT') {
                    className = classes.finalizedReport
                } else if (rowData?.status === 'IN_PROGRESS_REPORT') {
                    className = classes.inProgressReport
                }

                return (
                    <span className={className}>
                        {getStatusIcon(rowData?.status)}
                        {t(`socialImpactReports.status.${rowData.status}`)}
                    </span>
                );
            },

            style: { width: '14em', 'text-align': 'left' }
        },
        {
            field: `lastUpdateDate`,
            sortField: `lastUpdateDate`,
            header: t('socialImpactReports.monetaryDashboard.columns.lastUpdated'),
            sortable: false,
            body: (rowData: any) => {
                /* istanbul ignore next */
                return getFormattedDate(rowData?.lastUpdateDate)
            },
            style: { width: '12em', 'text-align': 'center' },
        },
    ];

    const handleModalAction = (show: boolean) => {
        setShowExtractDialog(show)
    }

    return (<>
        <div style={{ paddingTop: '5px', marginBottom: '25px', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 'bold', fontSize: '20px', margin: '10px' }}>
                {t('socialImpactReports.header.monetaryHeader')}
            </span>
            <button type='button'
                data-testid='simonetarydashextractrequest'
                onClick={() => {
                    handleModalAction(true)
                    analytics.trackEventInfo({
                        type: tracker.EVENTS.CLICK,
                        actionOn: tracker.ACTION_ON.EXPORT_REPORT_SUMMARY.name,
                        location: ANALYTICS.socialImpact.monetaryReportPage
                    });
                }}
                className={classes.extractReportBtn}
                id='request-extract-report-summary'
            >
                {t('extractDialog.extractReportLabel')}
            </button>
        </div>

        {/* Dashboard section */}
        <div className={customClass.root}>
            <Box
                style={{ borderRadius: '8px' }}
                boxShadow={0.5}
                bgcolor='background.paper'
                m={1}
                p={1}>

                <DataTableComponent
                    {...{ ...myDonationTableProps }}
                    dashboardType={DashBoardTypes.SocialImpactMonetaryDashBoard}
                    socialImpactDashboardColumns={columns}
                    history={props.history}
                    addDataToStore={props.addDataToStore}
                    reviewDonationUrl={props?.reviewDonationUrl}
                />
            </Box>
        </div>
        {showExtractDialog && <ExtractDialogComponent
            handleModalAction={handleModalAction}
            showModal={showExtractDialog} lang={props.selectedLang}
            donationType='DONATION_OF_MONEY'
            heading={t('extractDialog.monetaryHeader')} />}

    </>
    )
}


export default React.memo(SocialImpactMonetaryDashboard);
