
import { getAppendixFormValidations, getOrgValidationSchemas } from './validation'
import * as yup from 'yup';
import { taxIdFormat } from '../../../../constants';
import newInstance from '../../../../i18n';
import { OrganizationTypes } from '../../../../components/common/utils';

const translate = (key: string) => {
  return newInstance.t(key);
}

const validateTaxIdCb = (taxId: any, countryCode: string): boolean => {
  const format = taxIdFormat[countryCode]
  if (taxId && format) {
    return format.test(taxId) ? true : false
  }
  return true
}

export const getAppendixBValidationSchema = (countryCode: string, orgType:OrganizationTypes, isReview = false) => {

  const isNgo = orgType === OrganizationTypes.NGO;

  const {
    required,
    stringRule,
    yesNoSchema,
    validateEmail,
    todayRule,
    dateRule,
    birthYearRule,
    establishmentDateRule,
    beforeOrTodaysDate,
    booleanRule,
    acknowledgeRule
  } = getAppendixFormValidations();

  const {
   address
  } = getOrgValidationSchemas();

  const primaryUser =  yup.object().shape({
    userName: stringRule,
    userTitle: stringRule,
    userEmail: validateEmail(),
    userTelephone: stringRule,
    birthYear: birthYearRule,
    countryOfResidence: stringRule,
  })

  const primaryUserGE =  yup.object().shape({
    userName: stringRule,
    userTitle: stringRule,
    userEmail: validateEmail(),
    userTelephone: stringRule,
  })

  const validateTaxId = yup.string().test('test', translate('orgRegForm.validationMsg.taxIdError'),
    (value) => {
    return validateTaxIdCb(value, countryCode);
  },);

  const reqAmountValidation = yup.number().typeError(translate('monetaryDonation.fieldValidation.integerAllowed')).required(required).min(0,
    translate('monetaryDonation.fieldValidation.positiveNumbers'));

  const signeeCommonSchema = {
    signature: stringRule,
    userTitle: stringRule,
    userName: stringRule,
    birthYear: birthYearRule,
    countryOfResidence: stringRule,
  }

  const signeeCommonSchemaGE = yup.object().shape({
    signature: stringRule,
    userTitle: stringRule,
    userName: stringRule,
    employer: stringRule,
  })

  const certificateSignee = yup.object().shape({
    ...signeeCommonSchema,
    employer: stringRule,
  });
  const consentSignee = yup.object().shape({
    ...signeeCommonSchema,
  })

  const boardMembersRule = yup.array(yup.object().shape({
    userName: stringRule,
    userTitle: stringRule,
    birthYear: birthYearRule,
    cityOfResidence: stringRule,
    stateOfResidence: stringRule,
    countryOfResidence: stringRule
  }));

  const optionalString = (name: string) => (yup.string().test(`${name}-desc`,required,
  (value, context) => {
    return ((!context.parent[name]) || (value && value.length > 0)) ? true: false;
  }))

  const organizationSchemaGE = {
    orgLegalName: stringRule,
    address,
    primaryOrgUser: primaryUserGE,
    certificateSignee: signeeCommonSchemaGE,
    certificateSignedDate:  isReview ? dateRule: todayRule,
    certificationCheckBox1: acknowledgeRule,
    certificationCheckBox2: acknowledgeRule,
    certificationCheckBox3: acknowledgeRule
  }

  const orgSchemaNGO = {
    orgLegalName: stringRule,
    taxId: validateTaxId,
    address,
    website: stringRule,
    establishedDate: establishmentDateRule,
    missionAndPurpose: stringRule,
    geographicCoverage: stringRule,
    primaryOrgUser:primaryUser,
    govtEntityRelation: booleanRule,
    govtEntityRelationDetails: optionalString('govtEntityRelation'),
    govtOfficialRelation:booleanRule,
    govtOfficialRelationDetails: optionalString('govtOfficialRelation'),
    politicalActivity: booleanRule,
    politicalActivityDetails: optionalString('politicalActivity'),
    corruptionOrBribery: booleanRule,
    corruptionOrBriberyDetails: optionalString('corruptionOrBribery'),
    pastDonation: booleanRule,
    pastDonationDetails: optionalString('pastDonation'),
    prevDeniedDonation: booleanRule,
    prevDeniedDonationDetails: optionalString('prevDeniedDonation'),
    govtOrPoliticalOffice: booleanRule,
    govtOrPoliticalOfficeDetails: optionalString('govtOrPoliticalOffice'),
    govtOrPoliticalOfficeRelative: booleanRule,
    govtOrPoliticalOfficeRelativeDetails: optionalString('govtOrPoliticalOfficeRelative'),
    certificateSignee,
    boardMembers: boardMembersRule,
    executiveOfficers:boardMembersRule,
    keyPersons: boardMembersRule,
    certificateSignedDate: isReview ? beforeOrTodaysDate: todayRule,
    certificationCheckBox1: acknowledgeRule,
    certificationCheckBox2: acknowledgeRule,
    certificationCheckBox3: acknowledgeRule,
    consentCheckBox1: acknowledgeRule,
    consentCheckBox2: acknowledgeRule,
    consentSignedDate: isReview ? beforeOrTodaysDate: todayRule,
    consentSignee
  }

  const organizationSchema = yup.object().shape(isNgo ? orgSchemaNGO : organizationSchemaGE)

  const actionRule = yup.object().shape({
    value: stringRule,
    label: yup.object()
  }).typeError(required)

  const schema = {
    donationTitle: stringRule,
    listOfCountries: stringRule,
    organization: organizationSchema,
    description: stringRule,
    amount: reqAmountValidation,
    isRecurring: yesNoSchema('isRecurring'),
    ...(isNgo && {govtSuggestion:yesNoSchema('govtSuggestion')}),
    purpose: stringRule
  }
  if(isReview) {
    // @ts-ignore
    schema.action = actionRule;
  }

  return  yup.object().shape(schema);
}
