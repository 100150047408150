import { Box, Button, Divider, Grid, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface MonetaryDonationFooterProps {
    pending: number,
    totalSections: number,
    onCancel: () => void,
    onGoToAppendix: () => void,
    onSubmit: () => void;
    isLoading: boolean,
    formName: string,
    isSubmitDisabled: boolean,
    submitRef: React.RefObject<HTMLButtonElement>,
    isFormEditable: boolean
    isAdditionDetailsSubmitButtonEnabled: boolean;
    isAppendixBEditable: boolean;
}

const FooterBtn = withStyles({
    root: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    contained: {
        borderRadius: 20,
        backgroundColor: 'var(--buttonColor)',
        '&:hover': {
            backgroundColor: 'var(--buttonColor)',
            color: 'var(--white)'
        }
    },
    outlined: {
        borderRadius: 20, '&:hover': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: '0.50',
    }
})(Button)

const useStyles = makeStyles((theme) => ({

    root: {
        textDecoration: 'underline',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.87)',
            textDecoration: 'underline'
        }
    },
    footerContainer: {
        position: 'fixed',
        bottom: '30px',
        left: '250px',
        right: 0,
        zIndex: 1000,
        backgroundColor: '#fff',
        boxShadow: '0 -1px 6px 0 rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('xs')]: {
            left: 0,
        },
    },
    progressBox: {
        width: '280px',
        display: 'flex',
        alignItems: 'center',
        margin: '8px 8px 8px 39px',
        [theme.breakpoints.down('xs')]: {
            margin: '2px',
        },
    },
    buttonsContainer: {
        flex: 1
    },
    footerWrapper: {
        alignItems: 'center',
        justifyContent: 'end',
        margin: '8px 30px 8px 16px'

    },
    typography: {
        fontWeight: 'bold'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: '0.50',
    }
}));


export const MDFooter: FC<MonetaryDonationFooterProps> = ({ onCancel, onGoToAppendix,
    onSubmit,
    pending, totalSections, formName,
    isAdditionDetailsSubmitButtonEnabled,
    isAppendixBEditable,
    submitRef, isSubmitDisabled, isLoading, isFormEditable }) => {
    const { t } = useTranslation();
    const customStyles = useStyles();

    return (
        <Box className={classNames(customStyles.footerContainer, { [customStyles.disabled]: isLoading })}>
            <Divider />
            <Box m={1} display='flex'>
                {totalSections > 1 &&
                    <Box m={1} className={customStyles.progressBox}>
                        <Typography classes={{ root: customStyles.typography }}>
                            {t('monetaryDonation.footer.progress')}: {`${pending} / ${totalSections}`}
                        </Typography>
                    </Box>
                }
                <Grid container={true} classes={{ container: customStyles.footerWrapper }} >
                    <Grid item={true} >
                        <FooterBtn onClick={onCancel}
                            classes={{ root: customStyles.root }}> {t('monetaryDonation.footer.cancel')}</FooterBtn>
                    </Grid>
                    <Grid item={true} >
                        <Box ml={2}>
                            <FooterBtn onClick={onGoToAppendix} variant='outlined'> {isAppendixBEditable
                                ? t('monetaryDonation.footer.appendix')
                                : t('monetaryDonation.footer.viewappendix')} </FooterBtn>
                        </Box>
                    </Grid>
                    {isFormEditable && (<Grid item={true} >
                        <Box mx={2}>
                            <FooterBtn type='submit' form={formName} variant='outlined' ref={submitRef}  >
                                {t('monetaryDonation.footer.save')}</FooterBtn>
                        </Box>
                    </Grid>)}
                    {isAdditionDetailsSubmitButtonEnabled && (<Grid item={true}  >
                        <Box mr={1}>
                            <FooterBtn onClick={onSubmit} color='primary' variant='contained' disabled={isSubmitDisabled} >
                                {t('monetaryDonation.footer.submit')}</FooterBtn>
                        </Box>
                    </Grid>)}
                </Grid >
            </Box>
        </Box>
    );
}
