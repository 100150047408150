import { DownloadFileDataActions, DownloadFileDataActionTypes } from './downloadFileDataActions';
import { DownloadFileState } from '../../models/globalDonationState';

export const defaultDownloadFileDataState: DownloadFileState = {
    downloadFileData: {
        donationId:'',
        donationType:''
    },
    downloadFileSuccess: false,
    downloadFileFailure: false,
    downloadFileLoading: false
};

export const downloadFileDataReducer = (state: DownloadFileState = defaultDownloadFileDataState, action: DownloadFileDataActions):
    DownloadFileState => {
    switch (action.type) {
        case DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA: return {
            ...state,
            downloadFileLoading:true,
            downloadFileFailure: false,
            downloadFileSuccess: false,
        };
        case DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA_SUCCESS: return {
            ...state,
            downloadFileLoading:false,
            downloadFileSuccess: true,
            downloadFileFailure: false,
        };
        case DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA_FAILURE: return {
            ...state,
            downloadFileLoading:false,
            downloadFileFailure: true,
            downloadFileSuccess: false,
        };
        default: return state;
    }
}