import * as React from "react";
import { Select } from "@walmart-web/livingdesign-components";

export interface ILdSelectProps {
    defaultValue?: any
    options: any[]
    label: string
    placeholderTxt: string
    size: "small" | "large"
    valueKey: string
    labelKey: string
    disabled: boolean
    onChange?: (data: any) => void
    style?: any
    className?: string
    error: string
    leadingIcon?: any
    selectedLang: string
    id: string
    testId: string
}
export const LdSelectComponent: React.FC<ILdSelectProps> = ({
    label,
    disabled,
    placeholderTxt,
    defaultValue,
    options,
    size,
    labelKey,
    valueKey,
    onChange,
    error,
    leadingIcon,
    selectedLang,
    style,
    id,
    testId
}) => {
    const [value, setValue] = React.useState<any>(defaultValue ?? "");
    return (
        <div style={{ ...style, width: '100%', color: '#000' }}>
            <Select
                id={id}
                data-testId={testId}
                disabled={disabled}
                label={label}
                leadingIcon={leadingIcon ? leadingIcon : ''}
                onChange={(event) => {
                    setValue(event.target.value)
                    // @ts-ignore
                    onChange(event.target.value)
                }}
                size={size}
                value={value}
                error={error}
            >
                <option value="">{placeholderTxt}</option>
                {options?.map((itm: any, index) => (
                    <option
                        key={`dropDownChild-${index}`}
                        // @ts-ignore
                        value={valueKey ? itm[valueKey] : itm}
                    >{
                            // @ts-ignore
                            (labelKey && selectedLang) ? itm?.[labelKey]?.[selectedLang] : labelKey ? itm?.[labelKey] : itm
                        }</option>
                ))}
            </Select>
        </div>
    );
}
