import { Box, Fab, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FC } from 'react';

const useStyles = makeStyles(_theme => ({
    buttonText: {
        fontWeight: 'bold',
        marginLeft: '10px',
        marginTop: '10px'
    }
}));

const AddRow: FC<{handler: () => void, key: any, t: (key: string) => string}> = ({
    handler, key, t
}) => {
    const classes = useStyles();
    return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Fab color='primary'
            style={{ marginTop: '14px', marginLeft: '12px', backgroundColor: '#0071dc' }}
            aria-label='add row'
            name={`addRow_${key}`}
            size='small'
            data-testid='add_row'
            id={'add-geographical-fields'}
            onClick={() => handler()}>
            <AddIcon />
        </Fab>
        <Typography classes={{ root: classes.buttonText }} component={'span'}>{t('monetaryDonation.addRow')}</Typography>
    </Box>
    );
}

export default AddRow;
