import { GlobalDonationActionTypes, GlobalDonationDataActions } from './globalDonationActions';
import { GlobalDonationState } from './../models/globalDonationState';
import { defaultConfigState } from './configuration/configReducer';
import { defaultApplicationState } from 'dynamic-form-components/lib/state/ApplicationData';
import { defaultSubmissionDataState } from './submission/submissionDataReducer';
import { defaulNotificationDataState } from './Globalnotification/notificationDataReducer';
import { defaultTranslationState } from './translations/translationReducer';
import { defaultRegisterUser } from './userLogin/registerUserReducer';
import { defaultFacilitySearchState } from './facilitySearch/facilitySearchReducer';
import { defaultProvinceSearchState } from './provinceSearch/provinceSearchReducer';
import { defaultSelectedDataState } from './selectedData/selectedDataReducer';
import { defaultReportDataState } from './report/reportDataReducer';
import { defaultOrgProfile } from './OrgProfile/orgProfileReducer';
import { defaultFoodSurplusConfirmationState } from './foodSurplusConfirmation/foodSurplusConfirmationReducer';
import { defaultDownloadFileDataState } from './downloadFile/downloadFileDataReducer';
import { defaultLoggedInUserState } from './loggedInUser/loggedInUserReducer';
import { defaultDonationOfMoneyState } from './donationOfMoney/donationOfMoneyReducer';
import { defaultDonationApprovalState } from './donationOfApproval/donationOfApprovalReducer';
import { defaultLogoutUserState } from './logout/logoutUserReducer';
import { defaultDeleteFileState } from './downloadFile/deleteFileReducer';
import { defaultDivisionDataState } from './divisionData/divisionDataReducer';
import { defaultOrgUserManagementState } from './orgUserManagement/orgUserManagementReducer';
import { defaultNavBarState } from './spaceDonation/navbar/navBarReducer';
import { defaultAppLogoState } from './spaceDonation/AppLogo/AppLogoReducer';
import { defaultUploadDownloadActionState } from './uploadDownloadFile/uploadDownloadFileReducers';
import { defaultSidebarState } from './sidebar/sidebarReducer';


export const defaultGlobalDonationState: GlobalDonationState = {
    notificationMsg: '',
    selectedMenuOption: '',
    selectedLang: 'en',
    ...defaultApplicationState,
    configState: defaultConfigState,
    translationState: defaultTranslationState,
    notificationDataState: defaulNotificationDataState,
    submissionDataState: defaultSubmissionDataState,
    facilitySearchState: defaultFacilitySearchState,
    provinceSearchState: defaultProvinceSearchState,
    selectedDataState: defaultSelectedDataState,
    downloadReportState: defaultReportDataState,
    downloadFileState: defaultDownloadFileDataState,
    donationApprovalState: defaultDonationApprovalState,
    globalDonationDataLoading: false,
    globalDonationDataLoadingSuccess: false,
    globalDonationDataLoadingFailure: false,
    registerUser: defaultRegisterUser,
    orgProfile: defaultOrgProfile,
    foodSurplusConfirmationState: defaultFoodSurplusConfirmationState,
    loggedInUserState: defaultLoggedInUserState,
    sidebarState: defaultSidebarState,
    donationOfMoney: defaultDonationOfMoneyState,
    logoutState:defaultLogoutUserState,
    deleteFileState: defaultDeleteFileState,
    divisionDataState: defaultDivisionDataState,
    orgUserManagementState : defaultOrgUserManagementState,
    navBarState: defaultNavBarState,
    appLogoState:defaultAppLogoState,
    uploadDownloadActionState: defaultUploadDownloadActionState
};

export const globalDonationDataReducer = (state: GlobalDonationState = defaultGlobalDonationState, action: GlobalDonationDataActions):
    GlobalDonationState => {
    switch (action.type) {
        case GlobalDonationActionTypes.GLOBALDONATION_LOAD_SUCCESS: return {
            ...state,
            notificationMsg: action.data,
            globalDonationDataLoading: false,
            globalDonationDataLoadingSuccess: true,
            globalDonationDataLoadingFailure: false,
        };
        case GlobalDonationActionTypes.GLOBALDONATION_LOAD_FAILURE: return {
            ...state,
            notificationMsg: action.data || '',
            globalDonationDataLoading: false,
            globalDonationDataLoadingSuccess: false,
            globalDonationDataLoadingFailure: true,
        };
        case GlobalDonationActionTypes.SELECTED_MENU: return {
            ...state,
            selectedMenuOption: action.data || '',
            globalDonationDataLoading: false,
            globalDonationDataLoadingSuccess: false,
            globalDonationDataLoadingFailure: true,
        };
        case GlobalDonationActionTypes.SELECTED_LANG: return {
            ...state,
            selectedLang: action.data || '',
            globalDonationDataLoading: false,
            globalDonationDataLoadingSuccess: false,
            globalDonationDataLoadingFailure: true,
        };
        default: return state;
    }
}