import { Box, makeStyles, Paper, Theme } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import analytics from '../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../adobe-analytics/analytics-dataelemets';
import tracker from '../../../adobe-analytics/tracker';

import { dashboardPageBreadcrumbs } from '../../../components/common/utils';
import SuccessAlertDailog from '../../../components/dialogComponents/SuccessAlertDailog';
import { OneTimeGoodsDashboardProps } from '../../../types/oneTimeGoodsProps';
import { BreadcrumbsComponent } from './../../../components/common';
import CustomTab, { a11yProps } from './../../../components/common/formComponents/CustomTab';
import CustomTabPanel from './../../../components/common/formComponents/CustomTabPanel';
import CustomTabs from './../../../components/common/formComponents/CustomTabs';
import { appFontFamily } from './../../../styles/styleUtil';
import DataTableComponent from './DataTableComponent';

const useStyles = makeStyles((_theme: Theme) => ({
    budgetInfoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        top: '10px',
    },
    budgetInfoBox: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: 'max-content',
        fontFamily: appFontFamily,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        flexGrow: 1,
        flexBasis: 0,
    },
    createDonationBtn: {
        height: '40px',
        borderRadius: '20px',
        backgroundColor: '#0071ce',
        textTransform: 'uppercase',
        color: '#fff',
        border: 'solid 1px',
        fontSize: '14px',
        fontWeight: 'bold',
        width: 'auto'
    },
    backdrop: {
        position: 'absolute',
        zIndex: 1200,
        opacity: 0.4
    }
}));

const useCustomStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        margin: '10px',
        borderRadius: '8px'
    },
}));


const OneTimeGoodsDashboardComponent: FC<OneTimeGoodsDashboardProps> = (props) => {
    useEffect(() => {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.oneTimeGoodsDonation.dashboardPage);
    }, []);
    const classes = useStyles();
    const { t } = useTranslation()
    const customClass = useCustomStyles()
    const [value, setValue] = useState(0);
    const [warningDialog, setWarningDialog] = useState<boolean>(false)

    const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
        /* istanbul ignore next */
        setValue(newValue);
    };
    const createNewDonation = () => {
        /* istanbul ignore next */
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.REQUEST_NEW_DONATION_FOR_ONE_TIME_GOODS.name,
            location:ANALYTICS.oneTimeGoodsDonation.dashboardPage
        });
        props.history.push(props?.createNewDonationUrl);
    }

    return (<>
        <div style={{ fontWeight: 'bold', fontSize: '20px', margin: '10px' }}>
            <BreadcrumbsComponent breadcrumbsData={dashboardPageBreadcrumbs} history={props.history} />
        </div>
        <div style={{ paddingTop: '5px', fontWeight: 'bold', fontSize: '20px', margin: '10px' }}>
            {t('oneTimeGoodsDonationDashboard.header')}
        </div>

        {/* Budget details */}
        {props?.budgetInfoComponent}

        {!props?.disableRequestNewDonation &&
                <div style={{ marginLeft: '10px', display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
                <button type='button'
                  data-testid='request-onetimeFood'
                  onClick={createNewDonation}
                  className={classes.createDonationBtn}
                  id='request-onetimeFood'
                  style={{ minWidth: '0px', width: 'auto', textTransform: 'none', padding: '0px 20px 0px 20px' }}>
                  {t('oneTimeGoodsDonationDashboard.requestNewBtnTxt')}
                </button>
              </div>}

        {/* Dashboard section */}
        <div className={customClass.root}>
            <Box
                style={{ borderRadius: '8px' }}
                boxShadow={0.5}
                bgcolor='background.paper'
                m={1}
                p={1}>
                <Paper square={true}>
                    <CustomTabs
                        value={value}
                        indicatorColor='primary'
                        textColor='primary'
                        onChange={handleChange}
                        aria-label='one time goods tabs'
                    >
                        <CustomTab
                            label={t('oneTimeGoodsDonationDashboard.tabs.myDonationTitle')}
                            {...a11yProps(0, 'one-time-goods', 'one time goods')}
                        />
                        <CustomTab
                            label={t('oneTimeGoodsDonationDashboard.tabs.allDonationTitle')}
                            {...a11yProps(1, 'one-time-goods', 'one time goods')}
                        />
                    </CustomTabs>
                </Paper>
                <CustomTabPanel value={value} index={0}>
                    <DataTableComponent
                        {...{ ...props?.myDonationTableProps }}
                        history={props.history}
                        addDataToStore={props.addDataToStore}
                        reviewDonationUrl={props?.reviewDonationUrl}
                        setWarningDialog={setWarningDialog}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <DataTableComponent
                        {...{ ...props?.allDonationTableProps }}
                        history={props.history}
                        addDataToStore={props.addDataToStore}
                        reviewDonationUrl={props?.reviewDonationUrl}
                        setWarningDialog={setWarningDialog}
                    />
                </CustomTabPanel>
            </Box>
        </div>
        <SuccessAlertDailog
            showDialog={warningDialog}
            maxWidth={'lg'}
            ok={() => { setWarningDialog(false) }}
            title={t('common.popUpMsg.donationStatusInfo.title')}
            message={<Trans i18nKey='common.popUpMsg.donationStatusInfo.message'>
                {`The selected donation is still in <strong>under process.</strong>
                    You will be able to view the details only when the donation move to <strong>acknowledgment</strong> status}`
                }
            </Trans>} />
    </>
    )
}

export default React.memo(OneTimeGoodsDashboardComponent);
