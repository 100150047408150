import React, { useContext } from 'react';
import Card from '../../uiComponents/LDCard';
import CardItem from '../../uiComponents/LDCardItem';
import './EventCard.css';
import moment from 'moment';
import { Box, Card as MCard, CardContent, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Spinner from '../../uiComponents/LDSpinner';
import { contextPath } from 'src/constants';
import UserContext from '../../../store/user-context';
import { useNavigate } from 'react-router';

export interface EventCardProps {
    ncDataSource?: any,
    history?: any,
    status?: string
}

/* istanbul ignore next */
export const dateFormats = (date: any, type: any) => {
    let message = '';
    date = new Date(date);
    if (date) {
        switch (type) {
            case 'month': message = moment(date).format('MMM, DD');
                break;
            case 'full': message = moment(date).format('MMM DD, YYYY , HH:mm:ss');
                break;
            case 'ncDuration': message = moment(date).format('MMM DD, YYYY');
                break;
        }
    }
    return message;
}

const handleSpaceEvent = (navigateTo: any, ncDataSource: any) => {
  navigateTo(`${contextPath}/space-donation/events/detail`, {state: {
    spaceDonation: ncDataSource.spaceDonation,
    waaSTaskMessage: ncDataSource.waaSTaskMessage,
  }
})
}
/* istanbul ignore next */
const handleNcEvent = (navigateTo: any, ncDataSource: any, myOrgId:any , status:any) => {
  navigateTo(`${contextPath}/space-donation/events/ngo-detail`, {state: {
    orgId: myOrgId,
    ncId: ncDataSource.id,
    status: { status }
    }
  })
}

const EventCard: React.SFC<EventCardProps> = ({ ncDataSource, status }) => {
    const navigateTo = useNavigate();
    const userContext = useContext(UserContext);
    const eventSlots = ncDataSource?.spaceDonation?.eventSlots
    const singleSlot = eventSlots?.length === 1 ? eventSlots[0]?.label : undefined
    const multiSlot = eventSlots?.length > 1 ? eventSlots[0]?.label?.split('-')[0] +
         '-' + eventSlots[eventSlots?.length - 1]?.label?.split('-')[1] : []
      const displaylabel = singleSlot ? singleSlot : multiSlot
    // @ts-ignore
    const myOrgId = userContext?.organization?.id;
    return (
        <MCard className={`event-info-card-wrapper`}>
            <Box>
                {ncDataSource?.donationType !== 'SPACE_DONATION' ?
                    <CardContent className={`nc-event-info-card`}
                        style={{ color: '#fff' }}>
                        <img className='nc-walmart-spark-icon' alt=''
                            src={require('../../../commonComponents/assets/logos/Walmart_Spark.png')} />
                        <div className={`nc-event-left-header`}>
                            Home<br />Office
                        </div>
                    </CardContent> : <CardContent className={`space-event-info-card`}
                        style={{ color: '#fff' }}>
                        <div className={`space-event-left-header`}>
                            {dateFormats(ncDataSource.eventStartDateString, 'month')}
                        </div>
                    </CardContent>}
            </Box>
            <CardContent className={`nc-event-right-content`}>
                <div className='ngo-nc-header-card'>
                    <div className='ngo-nc-header-content'>
                        {ncDataSource?.donationType !== 'SPACE_DONATION' ?
                            <Typography variant='subtitle1' color='textPrimary' component='div'
                                className={`event-card-title`}>
                                {ncDataSource?.title}
                            </Typography> :
                            <Typography variant='subtitle1' color='textPrimary' component='div'
                                className={`event-card-title`}>
                                {ncDataSource.locationName}
                            </Typography>
                        }
                    </div>
                    <div>
                        {ncDataSource?.donationType === 'SPACE_DONATION' ?
                            <div id={`btn-space`}>
                                <ChevronRightIcon
                                    onClick={() =>
                                        /* istanbul ignore next */
                                    handleSpaceEvent(navigateTo, ncDataSource)
                                    } />
                            </div>
                            :
                            <div id={`btn-nc`}>
                                <ChevronRightIcon
                                    onClick={() =>
                                        /* istanbul ignore next */
                                    handleNcEvent(navigateTo, ncDataSource , myOrgId , status)
                                    } />
                            </div>
                        }
                    </div>
                        </div>
                {ncDataSource?.donationType === 'SPACE_DONATION' ?
                    <Typography variant='subtitle2' color='textPrimary' component='div'
                        className='space-event-subtitle'>
                        {ncDataSource.hostName}
                    </Typography> :
                    <Typography variant='subtitle2' color='textPrimary' component='div'>
                        {''}
                    </Typography>
                }
                {ncDataSource?.donationType !== 'SPACE_DONATION' ?
                    <Typography variant='subtitle2' color='textSecondary' component='div'
                        className='ngo-nc-event-duration'>
                        {dateFormats(ncDataSource?.eventStartDateString, 'ncDuration')} -
                        {dateFormats(ncDataSource?.eventEndDateString, 'ncDuration')}
                    </Typography>
                    : <>
                        <Typography variant='subtitle2' color='textSecondary' component='div'
                            className='space-event-duration'>
                            {dateFormats(ncDataSource.eventStartDateString, 'ncDuration')}
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' component='div'
                            className='space-event-duration'>
                            {displaylabel}
                        </Typography>
                    </>
                }
                {ncDataSource?.donationType !== 'SPACE_DONATION' ?
                    <Typography variant='subtitle2' color='textPrimary' component='div'
                        className='ngo-nc-event-location-info'>
                        {ncDataSource?.location} | {ncDataSource?.locationName}
                    </Typography> : <Typography variant='subtitle2' color='textPrimary' component='div'
                        className='space-event-location-info'>
                        {ncDataSource.addressLine1} | {ncDataSource.title}
                    </Typography>}
            </CardContent>
        </MCard>
    )
}


export interface EmptyEventCardProps {
    message: any
}

const EmptyEventCard: React.SFC<EmptyEventCardProps> = ({ message }) => {
    /* istanbul ignore next */
    return <div className='event-info-card'><Card className='event-info-card-wrap'>
        <CardItem className='event-info-card-item'><div className='event-info-card-header' /></CardItem>
        <div className='empty-event-info-card-content'>
            <CardItem className='event-info-card-item'>
                <div>{message}</div>
            </CardItem>
        </div>
    </Card></div>
}


// tslint:disable-next-line
export interface LoadingEventCardProps {
}

const LoadingEventCard: React.SFC<LoadingEventCardProps> = () => {
    /* istanbul ignore next */
    return <div className='event-info-card'><Card className='event-info-card-wrap'>
        <CardItem className='event-info-card-item'><div className='event-info-card-header' /></CardItem>
        <div className='empty-event-info-card-content'>
            <CardItem className='event-info-card-item'>
                <div style={{ 'textAlign': 'center' }}><Spinner /></div>
            </CardItem>
        </div>
    </Card></div>
}

export { EmptyEventCard, LoadingEventCard, EventCard };
