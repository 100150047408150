import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormBlockHeading } from '../../utils';
import { ApprovalActionDropDownComponent } from './ApprovalActionDropDownComponent';

export const ReviewSectionComponent = React.memo((props:any) => {
    const { t } = useTranslation()
    return (<>
        <div className='row'>
            <div className='col-md-5'>
            <FormBlockHeading heading={t('oneTimeGoodsDonation.reviewSection.heading')} />
            <ApprovalActionDropDownComponent
                {...props }
                id={'approval-action'}
                translationKey={'oneTimeGoodsDonation.reviewSection.actionDropDownLabel'}
            />
            </div>
        </div>
    </>)
})
