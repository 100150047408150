import * as React from 'react';
import {getEncryptedString} from 'dynamic-form-components/lib/crypto';
import {viewOrDownloadFile} from '../state/ManageWorkflow/fileActions';
import {GlobalDonationState} from '../models/globalDonationState';
import {Link, withStyles} from '@material-ui/core';
import styles from './menu/ThemeStyle';
import withRouter from 'src/polyfils/customRouter';
import {connect} from 'react-redux';
const config = require(`./../config.${process.env.NODE_ENV}.json`);

export interface DispatchProps {
    downloadFile: (data: any) => void;
}
export interface StateProps {
    selectedLang: any;
}
const appi18n: any = {
    privacyPolicy: {
        en:'Privacy Policy',
        es:'Política de privacidad',
        fr:'Politique de confidentialité',
        ja:'個人情報保護方針',
        zh:'隱私政策'
    }
}
class FooterComponent extends React.PureComponent<DispatchProps & StateProps> {
    public handleDownload = () => {
        const fileInfo ={
            templateKey: 'PRIVACY_POLICY',
            downloadDir: 'WW'
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `public/files/privacy-policy?fileInfo=${encryptedFileInfo}`;
        this.props.downloadFile({name:'privacy-policy.docx', type: 'downloadFiles', actionUrl:`${config.appBaseUrl}/${dataUri}`});
    }
    public render() {
        return (<>
            <div className='app-footer'>
                <Link style={{ marginLeft: '100px', cursor:'pointer'}}
                    title='Privacy Policy'
                    onClick={this.handleDownload}>
                    {appi18n.privacyPolicy[this.props.selectedLang]}
                </Link>
                <p>© Walmart Stores, Inc.</p>
            </div>
        </>);
    }
}
const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    downloadFile: (data) => dispatch(viewOrDownloadFile(data))
});


export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterComponent)));