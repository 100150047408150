import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import ThankyouComponent from "../../../components/common/ThankyouComponent";
import withRouter from "../../../polyfils/customRouter";
export interface OwnProps {
  history: any;
  donationId: any;
}
const EmergencyDonations: FC<OwnProps> = ({ history }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const breadcrumbsData = [
    {
      name: "emergencyDonations.nav.home",
      path: "/",
    },
    {
      name: "emergencyDonations.nav.emergencyDonations",
      path: "/dashboard/emergency-donation",
    },
    {
      name: `${t("emergencyDonations.nav.emergencyDonationId")}${state.donationId ?? ""}`,
      active: true,
      applyFormat: false,
    },
  ];

  return (
    <div style={{ paddingTop: "2px" }}>
      <ThankyouComponent breadcrumbsData={breadcrumbsData}
        history={history}
        thankyouSubject={t("emergencyDonations.thankYou.sub")}
        thankyouHeading={t("emergencyDonations.thankYou.heading")}
        thankyouButton={t("emergencyDonations.thankYou.button")}
        contextUrl={"emergency-donation"}
      ></ThankyouComponent>
    </div>
  );
};
export default withRouter(EmergencyDonations);
