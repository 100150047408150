import { Car } from "@livingdesign/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CardOutline } from "../../common/CardOutline";
import { LocationTypes } from "../commonFunctions";

export interface LocProps {
    data: any;
    langObjectPath: string;
    facilityLabel: string;
}

export const GetLocation: FC<LocProps> = ({ data,  langObjectPath, facilityLabel}) => {
    const { t } = useTranslation();
    
   
    return (
        <CardOutline
            HeaderIcon={<Car size='medium' style={{ marginRight: "10px" }} />}
            headerContent={`${langObjectPath}.cards.pickup`}
            bodyContent={
                <>
                    <b>{`${t(`${langObjectPath}.cards.location`)}: ${facilityLabel}`}</b>
                    <br />
                    <div style={{ paddingLeft: "8px" }}>
                        {data.donationDetails?.locationType === LocationTypes.other && (
                            <>
                            <b>{t("orgRegForm.labels.streetAddr")}</b>:{" "}
                                {data.donationDetails?.otherLocationDetails?.addressLine1}
                                <br />
                                <b>{t(`${langObjectPath}.cards.country`)}</b>:{" "}
                                {data.donationDetails?.otherLocationDetails?.county}
                                <br />
                                <b>{t(`${langObjectPath}.cards.postalCode`)}</b>:{" "}
                                {data.donationDetails?.otherLocationDetails?.postalCode}
                                <br />
                                </>
                        )}
                        {data.donationDetails?.locationType !== LocationTypes.na && data.donationDetails?.locationType !== LocationTypes.other
                        && data.donationDetails?.facilityDetails?.facilityNo && (
                            <>
                                <b>{t(`${langObjectPath}.cards.facilityNo`)}</b>:{" "}
                                {data.donationDetails?.facilityDetails?.facilityNo}
                                <br />
                                <b>{t(`${langObjectPath}.cards.facilityType`)}</b>:{" "}
                                {data.donationDetails?.facilityDetails?.facilityType}
                                <br />
                                <b>{t("orgRegForm.labels.streetAddr")}</b>:{" "}
                                {data.donationDetails?.facilityDetails?.streetAddress}
                                <br />
                                <b>{t(`${langObjectPath}.cards.city`)}
                                </b>: {data.donationDetails?.facilityDetails?.city}
                                <br />
                                <b>{t(`${langObjectPath}.cards.country`)}</b>:{" "}
                                {data.donationDetails?.facilityDetails?.county}
                                <br />
                                <b>{t(`${langObjectPath}.cards.postalCode`)}</b>:{" "}
                                {data.donationDetails?.facilityDetails?.postalCode}
                                <br />
                                <b>{t(`${langObjectPath}.cards.primaryEmail`)}</b>:{" "}
                                {data?.donationDetails?.primaryEmail}
                                <br />
                                <b>{t(`${langObjectPath}.cards.secondaryEmail`)}</b>:{" "}
                                {data?.donationDetails?.secondaryEmail}
                                <br />
                            </>
                        )}
                    </div>
                    <br />
                </>
            }
        />
    );
};