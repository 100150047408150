import { useMutation, useQuery } from 'react-query';
import { reactQueryConfigOptions } from "../../constants";
import { saveOrgProfile,getOrgProfileData } from '../../service/apiService'

export const UseSaveOrgProfileApi = (saveOrgProfileFn:any,onSuccess:any) => {
    const { data, mutateAsync, isError, isLoading, isSuccess, error } = useMutation({

        mutationFn: saveOrgProfile,
        onSuccess : (data:any)=>{
            const email: string = localStorage.getItem("userEmail") || "";
            saveOrgProfileFn({email});
            onSuccess(data);
        },
    })
    return { resopnse:data, mutateAsync, isError,isLoading,isSuccess, error }
}


export const useGetOrgProfileApi = (onSuccess:any) => {
    return  useQuery(
        ["orgProfile"],
        getOrgProfileData,
        { ...reactQueryConfigOptions, cacheTime: 0,onSuccess }
    );
}
