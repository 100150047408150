import { FormControl, TextField, FormLabel } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorMessageComponent } from './ErrorMessage'
import { resolvePath } from '../utils'
import { useStyles } from '../Input/InputStyles'

export interface InputTextFieldProps {
    required?: boolean
    multiline?: boolean
    disabled?: boolean
    className?: string
    classes?: any
    fieldName: string
    id: string | undefined
    label: string
    helperText?: string
    defaultValue?: string
    rules?: any
    InputProps?: any
    value?: any
    errorMsgTranslationKey?: string
    placeholder?: string
    rows?: number
    isUsedForArray?: boolean
    errorKeyName?: string
    errorFieldName?: string
    errorIndex?: number
    customStyle?: any
    readOnly?: boolean
    errorClassName?:string
}

const InputTextField = ((props: InputTextFieldProps) => {
    const { t } = useTranslation()
    const { control, errors } = useFormContext()
    const defaultClasses = useStyles();
    const errorKey = props?.errorKeyName || '';
    const fieldError = resolvePath(errorKey, errors, '')?.message;

    const defaultCustomStyle = {
        containerMarginTop: '0px',
        containerMarginBottom: '0px',
        fieldWidth: '100%',
        errorTextColor: 'red'
    }
    const style = {
        ...defaultCustomStyle,
        ...props?.customStyle
    }

    return (
        <div
            className={props?.className}
            id={props.id}
            style={{ marginTop: style.containerMarginTop, marginBottom: style.containerMarginBottom }}
        >
            <FormControl
                required={props.required}
                style={{ width: style.fieldWidth }}
                classes={{ root: props?.classes?.inputFormControl }}
                className={defaultClasses.formControl}
                variant='outlined'
                error={fieldError ? true : false}
            >
                <FormLabel
                    style={{ marginTop: '0px', marginBottom:'0px' }}>
                    {t(props.label)}
                </FormLabel>
                <Controller
                    id={props.fieldName}
                    name={props.fieldName}
                    control={control}
                    defaultValue={props.defaultValue}
                    as={
                        <TextField
                            id={props.fieldName}
                            rows={props?.rows}
                            required={props.required}
                            helperText={props?.helperText}
                            fullWidth={true}
                            margin='normal'
                            classes={{ root: props?.classes?.selectFormControl }}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            InputProps={props?.InputProps}
                            defaultValue={props.defaultValue}
                            disabled={props.disabled}
                            multiline={props.multiline}
                            placeholder={props?.placeholder ? props.placeholder : ''}
                            error={fieldError ? true : false}
                        />
                    }
                    rules={{ ...props?.rules }}
                />
                <ErrorMessageComponent errors={errors} fieldName={errorKey} errorClassName={props.errorClassName}/>
            </FormControl>
        </div>
    )
})
export default InputTextField
