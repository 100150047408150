import * as React from 'react';
import { Backdrop, withStyles } from '@material-ui/core';
import Spinner from '../../../commonComponents/uiComponents/LDSpinner';


export interface OwnProps {
    backgroundColor: string;
    classes?: any;
}

const styles = (theme: any) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class LoaderComponent extends React.PureComponent<any> {
    public render() {
        const {classes} = this.props;
        return <Backdrop className={classes.backdrop} open={true} >
                    <Spinner color='white' />
                </Backdrop>
        ;
    }
}

export default withStyles(styles) (LoaderComponent);
