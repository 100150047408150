import {FC} from 'react';
import {BaseSectionProps} from '../organizationDetails/orgDetailsDefaults';
import {useTranslation} from 'react-i18next';
import {InputLabel, makeStyles, Typography} from '@material-ui/core';
import InformationBar from '../common/informationBar';
import {ILogisticsAdminQuestions} from './projectDetailsDefaults';
import {InputTextField} from '../../../common/Input/InputTextField';
import {useFormContext} from 'react-hook-form';
import GridItem from '../../../common/Grid/GridItem';
import GridContainer from '../../../common/Grid/GridContainer';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';

interface LogisticsAdminQuestionsrops extends BaseSectionProps {
    data: ILogisticsAdminQuestions
}

const useStyles = makeStyles({
    container: {
        margin: '12px 14px 6px'
    },
    tittle: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    subDesc: {
        fontSize: '12px',
        color: '#74767c;',
        marginLeft: '16px',
        marginTop: '4px'
    },
    titleGridCell: {
        marginTop: '15px',
        marginBottom: '1px'
    },
    inputLabel: {
        color: '#2e2f32',
        marginBottom: 0
    }
});

const LogisticsAdminQuestions: FC<LogisticsAdminQuestionsrops> = ({fieldName, data}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { control } = useFormContext();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);

    return  <GridContainer className = {classes.container}>
                <GridItem xs={12}>
                    <Typography classes={{ root: classes.tittle }}>{getTranslation('logisticsAdminTtl')}</Typography>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12} container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            id={`${fieldName}.methodLbl`}
                            classes = {{root: classes. inputLabel}}
                            required = {true}>
                            {getTranslation('methodLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12} container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.methodsToCollectDonations`}
                            id={`${fieldName}.methodsToCollectDonations`}
                            errorKeyName = {`${fieldName}.methodsToCollectDonations`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.methodsToCollectDonations}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.methodsToCollectDonations`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                    <GridItem xs = {6}>
                        <InformationBar>
                            {getTranslation('donationCollectionQn')}
                        </InformationBar>
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.capacityLbl`}
                            required = {true}>
                            {getTranslation('capacityLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.recurringProdAgreement`}
                            id={`${fieldName}.recurringProdAgreement`}
                            errorKeyName = {`${fieldName}.recurringProdAgreement`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.recurringProdAgreement}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.recurringProdAgreement`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                    <GridItem xs = {6}>
                        <InformationBar>
                            {getTranslation('vehicleQn')}
                        </InformationBar>
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.frequencyLbl`}
                            required = {true}>
                            {getTranslation('frequencyLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.howOffenAgreement`}
                            id={`${fieldName}.howOffenAgreement`}
                            errorKeyName = {`${fieldName}.howOffenAgreement`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            defaultValue={data.howOffenAgreement}
                        />
                    </GridItem>
                    <GridItem xs = {6}>
                        <InformationBar>
                            {getTranslation('frequencyQn')}
                        </InformationBar>
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.procedureLbl`}
                            required = {true}>
                            {getTranslation('procedureLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.describeDistributionOfGoods`}
                            id={`${fieldName}.describeDistributionOfGoods`}
                            errorKeyName = {`${fieldName}.describeDistributionOfGoods`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.describeDistributionOfGoods}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.describeDistributionOfGoods`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                    <GridItem xs = {6}>
                        <InformationBar>
                            {getTranslation('collectionQn')}
                        </InformationBar>
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.deliveryExperienceLbl`}
                            required = {true}>
                            {getTranslation('deliveryExperienceLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.experienceDeliveryFood`}
                            id={`${fieldName}.experienceDeliveryFood`}
                            errorKeyName = {`${fieldName}.experienceDeliveryFood`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.experienceDeliveryFood}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.experienceDeliveryFood`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                    <GridItem xs = {6}>
                        <InformationBar>
                            {getTranslation('processQn')}
                        </InformationBar>
                    </GridItem>
                </GridItem>
                <GridItem item={true} xs={12} className = {classes.titleGridCell}>
                    <Typography classes={{ root: classes.tittle }}>{getTranslation('adminQnTtl')}</Typography>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.otherDonors`}
                            required = {true}>
                            {getTranslation('otherDonors')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.donorsLegalNames`}
                            id={`${fieldName}.donorsLegalNames`}
                            errorKeyName = {`${fieldName}.donorsLegalNames`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            defaultValue={data.donorsLegalNames}
                        />
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.longtermUseLbl`}
                            required = {true}>
                            {getTranslation('longtermUseLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.explainUseOfDonations`}
                            id={`${fieldName}.explainUseOfDonations`}
                            errorKeyName = {`${fieldName}.explainUseOfDonations`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.explainUseOfDonations}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.explainUseOfDonations`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.transparencyLbl`}
                            required = {true}>
                            {getTranslation('transparencyLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            required={true}
                            fieldName={`${fieldName}.howOrgTransparency`}
                            id={`${fieldName}.howOrgTransparency`}
                            errorKeyName = {`${fieldName}.howOrgTransparency`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.howOrgTransparency}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.howOrgTransparency`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                </GridItem>
                <GridItem classes={{root: classes.titleGridCell}}
                    spacing = {3}
                    xs = {12}  container = {true}>
                    <GridItem xs = {6}>
                        <InputLabel
                            classes = {{root: classes. inputLabel}}
                            id={`${fieldName}.whatCorporatePurposeLbl`}>
                            {getTranslation('whatCorporatePurposeLbl')}
                        </InputLabel>
                    </GridItem>
                </GridItem>
                <GridItem xs = {12}  container = {true} spacing = {4}>
                    <GridItem xs = {6}>
                        <InputTextField
                            fieldName={`${fieldName}.whatCorporatePurpose`}
                            id={`${fieldName}.whatCorporatePurpose`}
                            errorKeyName = {`${fieldName}.whatCorporatePurpose`}
                            InputProps={{readOnly: false}}
                            fullWidth={true}
                            rows={5}
                            multiline={true}
                            defaultValue={data.whatCorporatePurpose}
                        />
                        <TextFieldMaxSizeIndicator
                            fieldName = {`${fieldName}.whatCorporatePurpose`}
                            control = {control}
                            maxSize = {1000}/>
                    </GridItem>
                </GridItem>
            </GridContainer>

}

export default LogisticsAdminQuestions;