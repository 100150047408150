
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";


export default function RadioButtonComponent(props: any) {
    const { 
        setSelectedItem,
        selectedItem,
        radioTypeInputs,
        facilityNumber = "",
        title = "",
        callBack } = props;
    let str = facilityNumber ? " # " + facilityNumber : "";
    const [value, setValue] = useState(selectedItem);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setSelectedItem(event.target.value);
        if (callBack) {
            callBack();
        }
    }

    return (
        <FormControl className={`formRadio`}>
             { title ? <FormLabel id="demo-row-radio-buttons-group-label" className="selectionButtonHeader">
                {title}
            </FormLabel> : "" }
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name={"row-radio-buttons-group"}
                onChange={handleChange}
                className={"formRadiogroup"}>
                {radioTypeInputs.map((formValue: any, index: any) => {
                    let labelString = formValue.label + (formValue.value === value ? str : "");
                    return <FormControlLabel className={`formLabelControl ${formValue.value === value ? "labelBold" : ""}`}
                        key={formValue.value + "- " + index} value={formValue.value}
                        control={<Radio />} label={labelString}
                        checked={formValue.value === value} data-testid={formValue.value + "- " + index} />
                })}
            </RadioGroup>

        </FormControl>
    );
}