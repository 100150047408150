import { QueryFunctionContext, useQuery } from 'react-query';
import { reactQueryConfigOptions } from '../constants';
import GlobalDonationDataService from '../service';

export const useStatesList = (countryCode: string) => {

  const {
    isLoading,
    isSuccess,
    error,
    isError,
    data
  } = useQuery(['stateList', countryCode],
  (context: QueryFunctionContext) => {
    return GlobalDonationDataService.getStateList({countryCode: context.queryKey[1]})
  }, { ...reactQueryConfigOptions, staleTime: 60000, cacheTime: 60000 });
  return {
      stateList: data ?? [],
      isStateListLoading: isLoading,
      isStateListSucess: isSuccess,
      isStateListError: isError,
      stateListError: error
  };

}
