import { DateType, NumberType, StringType, TabsCommonProps } from '../organizationDetails/orgDetailsDefaults';

export interface ProjectDetailsProps extends Omit<TabsCommonProps, 'setFormStatus'> {
    onProjectDetailsSubmit: any,
    projectDetailsData: any,
    setFormStatus: (errors: any) => void
}

export interface ProjectDetailsTabProps {
    projectDetailsData: any,
    isFormEditable: boolean,
    errors: any
}

export enum Sections {
    ProjectJustification = 'justificationDetails',
    ProjectScheduleOfActivities = 'projectScheduleOfActivities',
    ProjectScheduleOfActivitiesCategory = 'projectScheduleOfActivitiesCategory',
    ProjectScheduleOfActivitiesSubCategory = 'projectScheduleOfActivitiesSubCategory',
    ProjectExecution = 'executionDetails',
    ProjectBeneficiaries = 'beneficiaries',
    ProjectBeneficiariesParticipation = 'projectBeneficiariesParticipant',
    ProjectImpactAndContinuity = 'impactContinuityDetails',
    ProjectCoInvestors = 'coInvestors',
    ProjectLocation = 'locations',
    ProjectObjectives = `observationSummary`,
    BeneficiaryInformation = 'beneficiaryInformation',
    LogisticsAdminQuestions = 'logisticsAdminQuestions',
    ProjectJustificationTotals = 'justificationTotal',
}



const _projTabErrorsMap = new Map();

_projTabErrorsMap.set(Sections.BeneficiaryInformation, Sections.BeneficiaryInformation);
_projTabErrorsMap.set(Sections.LogisticsAdminQuestions, Sections.LogisticsAdminQuestions);
_projTabErrorsMap.set(Sections.ProjectBeneficiaries, Sections.ProjectBeneficiaries);
_projTabErrorsMap.set(Sections.ProjectBeneficiariesParticipation, Sections.ProjectBeneficiariesParticipation);
_projTabErrorsMap.set(Sections.ProjectCoInvestors, Sections.ProjectCoInvestors);
_projTabErrorsMap.set(Sections.ProjectExecution, Sections.ProjectExecution);
_projTabErrorsMap.set(Sections.ProjectImpactAndContinuity, Sections.ProjectImpactAndContinuity);
_projTabErrorsMap.set(Sections.ProjectJustification, Sections.ProjectJustification);
_projTabErrorsMap.set(Sections.ProjectLocation, Sections.ProjectLocation);
_projTabErrorsMap.set(Sections.ProjectObjectives, Sections.ProjectObjectives);

_projTabErrorsMap.set(Sections.ProjectScheduleOfActivities, Sections.ProjectScheduleOfActivities);
_projTabErrorsMap.set(Sections.ProjectScheduleOfActivitiesCategory, Sections.ProjectScheduleOfActivities);
_projTabErrorsMap.set(Sections.ProjectScheduleOfActivitiesSubCategory, Sections.ProjectScheduleOfActivities);
_projTabErrorsMap.set(Sections.ProjectJustificationTotals, Sections.ProjectScheduleOfActivities);

export const ProjTabErrorsMap = _projTabErrorsMap;

export interface IProjectObjectiveMeasures {
    measure: StringType,
    result: StringType
}
export interface IProjectObjectives {
    projectObservation: StringType,
    measureResults: IProjectObjectiveMeasures[]
}


export const DefaultProjectObjectives: IProjectObjectives[] = [{
    projectObservation: '',
    measureResults: [{
        measure: '',
        result: ''
    }]
}];


export interface BaseSectionProps {
    fieldName: string
}

export interface IProjectJustification {
    projectNeed: StringType,
    economicStatusDirectBeneficiaries: StringType,
    requestAmount: NumberType,
    expertiseInArea: StringType,
    projectCarriedOut: StringType,
    projectStartDate: DateType,
    projectEndDate: DateType
}

export interface IScheduleOfActivitiesFields {
    category: StringType,
    subCategory: StringType,
    activities: IActivitiesFields[]
}

export interface IActivitiesFields {
    number: StringType,
    activityDescription: StringType,
    amount: NumberType,
    startDate: DateType,
    endDate: DateType
}

export interface IProjectExecution {
    transparencyDetails: StringType,
    methodsAndPurpose: StringType,
    riskAndLimitations: StringType
}


export interface IProjectLocation {
    geographicalLocation: StringType,
    others: StringType,
    state: StringType,
    city: StringType,
}

export interface IProjectBeneficiaries {
    noOfChild: NumberType,
    noOfYoungAdults: NumberType,
    noOfAdults: NumberType,
    noOfSenior: NumberType,
    noOfFamilies: NumberType,
    noOfWomen: NumberType,
    totalIndirect: NumberType,
    calculationIndirectBeneficiaries: StringType
}

export interface IProjectBeneficiariesParticipation {
    provideFinancialResources: StringType,
    provideMaterialResources: StringType,
    functionsOrActivities: StringType,
    participationMechanism: StringType
}

export interface ICoInvestorsFields {
    coInvestorDonor: StringType,
    supportingActivities: StringType,
    donationAmount: NumberType,
    percentage: NumberType,
}

export interface IProjectImpactAndContinuity {
    projectLongTermVisionFit: StringType,
    noOfJobsProjectCreate: NumberType,
    noYearsOfImpact: StringType
}

export interface IBenificiariesInformation {
    listOfBeneficiaries: boolean | null,
    grantAgreement: string,
    averageIncomeLevel: string
}

export interface ILogisticsAdminQuestions {
    methodsToCollectDonations: string,
    recurringProdAgreement: string,
    howOffenAgreement: string,
    describeDistributionOfGoods: string,
    experienceDeliveryFood: string,
    donorsLegalNames: string,
    explainUseOfDonations: string,
    howOrgTransparency: string,
    whatCorporatePurpose: string
}



export const DefaultProjectJustification: IProjectJustification = {
    projectNeed: null,
    economicStatusDirectBeneficiaries: null,
    requestAmount: null,
    expertiseInArea: null,
    projectCarriedOut: null,
    projectStartDate: null,
    projectEndDate: null
}
export const DefaultActivities: IActivitiesFields = {
    number: null,
    activityDescription: null,
    amount: null,
    startDate: null,
    endDate: null
}

export const DefaultScheduleOfActivities: IScheduleOfActivitiesFields = {
    category: null,
    subCategory: null,
    activities: [DefaultActivities]
}

export const DefaultProjectExecution: IProjectExecution = {
    transparencyDetails: null,
    methodsAndPurpose: null,
    riskAndLimitations: null
}

export const DefaultProjectLocation: IProjectLocation[] = [{
    geographicalLocation: null,
    others: null,
    state: null,
    city: null
}]

export const DefaultProjectBeneficiaries: IProjectBeneficiaries = {
    noOfChild: null,
    noOfYoungAdults: null,
    noOfAdults: null,
    noOfSenior: null,
    noOfFamilies: null,
    noOfWomen: null,
    totalIndirect: null,
    calculationIndirectBeneficiaries: null
}

export const DefaultProjectBeneficiariesParticipation: IProjectBeneficiariesParticipation = {
    provideFinancialResources: '',
    provideMaterialResources: '',
    functionsOrActivities: '',
    participationMechanism: ''
}
export const DefaultCoInvestorsFields: ICoInvestorsFields[] = [{
    coInvestorDonor: null,
    supportingActivities: null,
    donationAmount: null,
    percentage: null
}]

export const DefaultProjectImpactAndContinuity: IProjectImpactAndContinuity = {
    projectLongTermVisionFit: null,
    noOfJobsProjectCreate: null,
    noYearsOfImpact: null
}

export const DefaultBenificiariesInformation: IBenificiariesInformation = {
    listOfBeneficiaries: null,
    grantAgreement: '',
    averageIncomeLevel: ''
}

export const DefaultLogisticsAdminQuestions: ILogisticsAdminQuestions = {
    methodsToCollectDonations: '',
    recurringProdAgreement: '',
    howOffenAgreement: '',
    describeDistributionOfGoods: '',
    experienceDeliveryFood: '',
    donorsLegalNames: '',
    explainUseOfDonations: '',
    howOrgTransparency: '',
    whatCorporatePurpose: ''
}