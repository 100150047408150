import { useMutation } from 'react-query'
import { updateNGOAcknowledgement } from './../service/apiService'

export const useUpdateNGOAcknowledgement = () => {
  const { data, mutateAsync, isError, isLoading, isSuccess, error } = useMutation(updateNGOAcknowledgement);
  return {
    updateRes: data,
    mutateAsync,
    isUpdateError: isError,
    isUpdateLoading: isLoading,
    isUpdateSuccess: isSuccess,
    updateError: error
  }
};
