import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormRadioButton } from '../../common'
import { FormBlockHeading } from '../../donations/utils'

export interface AppendixBQuestionnaireProps {
    heading: string
    classes: any
    data?: any
    readOnly?: boolean
    disabled?: boolean
}
export const AppendixBQuestionnaire: FC<AppendixBQuestionnaireProps> = (props: any) => {
    const { t } = useTranslation()
    const { control, setValue, errors } = useFormContext()
    const requiredMsg = t('common.validationMsg.requiredField')
    return (<div>
        <FormBlockHeading heading={t(props?.heading)} />
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'authorizedQuestion-radio-btn'}
                    className={'authorizedQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.authorizedQuestion')}
                    inputBoxHeading={''}
                    fieldName={'authorized'}
                    fieldNameInput={''}
                    defaultValue={props?.data?.authorized}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={true}
                    radioButtonValue={props?.data?.authorized}
                    inputBoxValue={''}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'issueReceiptsQuestion-radio-btn'}
                    className={'issueReceiptsQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.issueReceiptsQuestion')}
                    inputBoxHeading={''}
                    fieldName={'issueReceipts'}
                    fieldNameInput={''}
                    defaultValue={props?.data?.issueReceipts}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={true}
                    radioButtonValue={props?.data?.issueReceipts}
                    inputBoxValue={''}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
        <div className='row' style={{ marginTop: '20px', marginBottom: '10px' }}>
            <div className='col-md-8'>
                <p style={{ fontSize: '14px', fontFamily: 'Bogle,Roboto', color: 'rgba(0, 0, 0, 0.54)' }}>
                    {t('appendixBQuestionnaires.issueReceiptText')}
                </p>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'govtEntityRelationshipQuestion-radio-btn'}
                    className={'govtEntityRelationshipQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.govtEntityRelationshipQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.govtEntityRelationshipDescription')}
                    fieldName={'govtEntityRelation'}
                    fieldNameInput={'govtEntityRelationDetails'}
                    defaultValue={props?.data?.govtEntityRelation}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={props?.data?.govtEntityRelation}
                    inputBoxValue={props?.data?.govtEntityRelationDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-10'>
                <FormRadioButton
                    id={'govtOfficialRelationshipQuestion-radio-btn'}
                    className={'govtOfficialRelationshipQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.govtOfficialRelationshipQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.govtOfficialRelationshipDescription')}
                    fieldName={'govtOfficialRelation'}
                    fieldNameInput={'govtOfficialRelationDetails'}
                    defaultValue={props?.data?.govtOfficialRelation}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={props?.data?.govtOfficialRelation}
                    inputBoxValue={props?.data?.govtOfficialRelationDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'politicalActivityQuestion-radio-btn'}
                    className={'politicalActivityQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.politicalActivityQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.politicalActivityDescription')}
                    fieldName={'politicalActivity'}
                    fieldNameInput={'politicalActivityDetails'}
                    defaultValue={props?.data?.politicalActivity}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={props?.data?.politicalActivity}
                    inputBoxValue={props?.data?.politicalActivityDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'briberyQuestion-radio-btn'}
                    className={'briberyQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.briberyQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.briberyDescription')}
                    fieldName={'corruptionOrBribery'}
                    fieldNameInput={'corruptionOrBriberyDetails'}
                    defaultValue={props?.data?.corruptionOrBribery}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={props?.data?.corruptionOrBribery}
                    inputBoxValue={props?.data?.corruptionOrBriberyDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'donationReceivedQuestion-radio-btn'}
                    className={'donationReceivedQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.donationReceivedQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.donationReceivedDescription')}
                    fieldName={'pastDonation'}
                    fieldNameInput={'pastDonationDetails'}
                    defaultValue={props?.data?.pastDonation}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={props?.data?.pastDonation}
                    inputBoxValue={props?.data?.pastDonationDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>

        <div className='row'>
            <div className='col-md-8'>
                <FormRadioButton
                    id={'donationDeniedQuestion-radio-btn'}
                    className={'donationDeniedQuestion-radio-btn'}
                    classes={props.classes}
                    formErrors={errors}
                    control={control}
                    radioButtonHeading={t('appendixBQuestionnaires.donationDeniedQuestion')}
                    inputBoxHeading={t('appendixBQuestionnaires.donationDeniedDescription')}
                    fieldName={'prevDeniedDonation'}
                    fieldNameInput={'prevDeniedDonationDetails'}
                    defaultValue={props?.data?.prevDeniedDonation}
                    required={true}
                    disabled={props?.disabled}
                    setValue={setValue}
                    noInputBox={false}
                    radioButtonValue={props?.data?.prevDeniedDonation}
                    inputBoxValue={props?.data?.prevDeniedDonationDetails}
                    rules={{
                        required: requiredMsg
                    }}
                />
            </div>
        </div>
    </div>)
}