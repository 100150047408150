import * as React from 'react';
import {Dialog, DialogContent, DialogContentText, DialogTitle, withStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {GlobalDonationState} from '../../models/globalDonationState';
import {orgUserManagementTranslations} from '../../constants';
import OrgUserRestPasswordForm from './OrgUserRestPasswordForm';
import LoaderComponent from '../LoaderComponent';

const styles: any = (_theme: any) => ({
    selectRoot: {
        color: 'var(--inputTextColor) !important',
        fontFamily: ' var(--appFont)',
    },
    outlined: {
        color: 'red'
    },
    selectIcon: {
        color: 'var(--inputTextColor) !important'
    },
    selectFormControl: {
        color: '#000 !important',
        minWidth: '200px',
        fontFamily: ' var(--appFont)',
        backgroundColor: '#fff',
        marginRight: '1rem',
        marginBottom: '1rem',
        fontWeight: 'bold'
    },
    selectLabel: {
        color: '#495057 !important',
        fontFamily: ' var(--appFont)',
        marginLeft: '10px',
        backgroundColor: '#fff'
    },
    menuItemRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)'
    },
    menuItemselected: {
        color: 'var(--inputTextColor);'
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonGroup: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonStyles: {
        marginBottom: 10,
        color: '#fff !important',
        background: '#007bff !important',
        borderRadius: '20px',
        textAlign: 'center',
        textTransform: 'none',
        minWidth: '80px'
    },
    buttonContainer: {
        justifyContent: 'center !important',
    },
    closeBtn: {
        fontSize: '18px',
        backgroundColor: '#F4F6F9 !important',
        '&:hover': {
            backgroundColor: '#F4F6F9 !important',
            color: 'red'
        }
    }
})

interface OwnProps {
    showDialog: boolean;
    saveAndLogout: () => void;
    message: string;
    isAsdaTheme?: boolean;
}

export interface StateProps {
    selectedLang: string;
}



class OrgUserResetTempPasswordDialog extends React.PureComponent<OwnProps & StateProps & any> {

    public render() {
        const {classes} = this.props;
        // alert(this.props.showDialog)
        return <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            disableEnforceFocus={true}
            disableBackdropClick={true}
            open={this.props.showDialog}
            aria-labelledby='org-user-temp-password-reset-confirm-dialog'
            aria-describedby='org-user-temp-password-reset-confirm-dialog'>
            <DialogTitle id='org-user-temp-password-reset-confirm-dialog'>
                <div>
                    <b>{orgUserManagementTranslations.resetPasswordModal.title[this.props.selectedLang]}</b>
                </div>
            </DialogTitle>

            <DialogContent>
                <div>
                    <DialogContentText id='org-user-action-confirm-dialog-description'
                                       style={{paddingBottom: '5%'}}>
                        <b>{orgUserManagementTranslations.resetPasswordModal.content[this.props.selectedLang]}</b>
                    </DialogContentText>
                </div>
                <OrgUserRestPasswordForm
                    classes={classes}
                    selectedLang={this.props.selectedLang}
                    resetPassword={this.props.saveNewPassword}
                />
                {(this.props.loading) ? <LoaderComponent fullScreen={true} /> : <></>}
            </DialogContent>
        </Dialog>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang
});
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, null)((OrgUserResetTempPasswordDialog)));
