import '../../../styles/donationofMoney_mx.css';

import { Box, withStyles } from '@material-ui/core';
import Axios from 'axios';
import { saveFormData } from 'dynamic-form-components/lib/state/form/formDataActions';
import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { getFormattedDate } from 'src/components/common/DateUtils';
import { getMonetaryDonationBreadCrumbs, scrollToTop } from 'src/components/common/utils';

import SuccessAlertDailog from '../../../components/dialogComponents/SuccessAlertDailog';
import LoaderComponent from '../../../components/LoaderComponent';
import { contextPath, DONATION_TYPE } from '../../../constants';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { service } from '../../../sagas';
import { viewOrDownloadFile } from '../../../state/ManageWorkflow/fileActions';
import styles from '../../menu/ThemeStyle';
import AttachmentsTabComponent from '../moreDetailsSections/attachments/attachmentsTab';
import { BiLingualDropdownContext, IMDDropdownOption } from '../moreDetailsSections/common/biLingualDropdown';
import { OrganizationDetails } from '../moreDetailsSections/organizationDetails/organizationdetails';
import { ProjectDetails } from '../moreDetailsSections/projectDetails/projectDetails';
import { BreadcrumbsComponent } from './../../../components/common';
import CustomTab, { a11yProps } from './../../../components/common/formComponents/CustomTab';
import CustomTabPanel from './../../../components/common/formComponents/CustomTabPanel';
import CustomTabs from './../../../components/common/formComponents/CustomTabs';
import { IFileDataResponse, IFileSnapshot } from './attachments/attachmentsTable';
import { LoadingSkeleton } from './common/LoadingSkeleton';
import { MDFooter } from './moreDetailsFooter';
import { OrgTabErrorsMap, RadioOption, Sections } from './organizationDetails/orgDetailsDefaults';
import { ProjTabErrorsMap, Sections as ProjectTabSections } from './projectDetails/projectDetailsDefaults';


declare global {
    interface Window {
        donationAttachments: any,
        organizationAttachments: any,
        divisions: any,
        provinceList: any
    }
}

export interface OwnProps {
    formAlias: string;
    selectedTab: number;
    classes: any;
    appi18n: any;
    orgProfile: any;
    formState: any;
    isNewRequest?: any;
    t: any;
    formName: string;

}

export interface State {
    completedSections: number;
    totalSections: number;
    isLoading: boolean,
    showLoader: boolean,
    showDialog: boolean,
    successMessage: string,
    dialogTitle: string,
    donationId: string,
    isSubmitDisabled: boolean,
    isFormEditable: boolean,
    additionalDetails: any,
    projectDetailsData: any,
    lastModified: string,
    isSubmitted: boolean,
    isAdditionDetailsSubmitButtonEnabled: boolean;
    isAppendixBEditable: boolean;
}

export interface StateToProps {
    submissionData?: any;
    selectedData: any
    language: string;
}

export interface DispatchProps {
    submitDonation: (data: any) => void;
    downloadFile: (data: any) => void;
}

export enum Tabs {
    OrgTab = 0,
    ProjDetail = 1,
    Attachment = 2,
}

const formNameMapping = [
    'organization_details',
    'project_details',
    'attachments'
];

const SectionsCount: { [key: string]: number } = { organization_details: 7, attachments: 1, project_details: 9 }

class DonationOfMoneyMoreDetails extends React.PureComponent<OwnProps & RouteComponentProps & State & StateToProps & DispatchProps> {
    public state = {
        formAlias: 'donationOfMoneyMoreDetails',
        selectedTab: Tabs.OrgTab,
        completedSections: 0,
        totalSections: SectionsCount.organization_details,
        selectedForm: 'organization_details',
        projectDetailsData: {},
        additionDetails: {},
        isLoading: true,
        showLoader: true,
        dialogTitle: '',
        successMessage: '',
        isSubmitDisabled: true,
        isFormEditable: true,
        showDialog: false,
        donationId: '',
        dropdownOptionMap: new Map<string, IMDDropdownOption[]>(),
        stateList: [],
        attachmentData: new Map<string, IFileSnapshot>(),
        isAttachmentFulfilled: false,
        isOrgDetailsSaved: false,
        isProjectDetailsSaved: false,
        donationType: new URLSearchParams(this.props.location.search).get('type') ?? '',
        isSubmitted: false,
        isAdditionDetailsSubmitButtonEnabled: false,
        isAppendixBEditable: false,
        lastModified: '',
    };
    private submitRef = createRef<HTMLButtonElement>();
    private getTranslation = (key: string) => {
        return this.props.t(key);
    };

    private handleOnTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {

        this.setState({
            ...this.state, selectedTab: newValue,
            selectedForm: formNameMapping[newValue],
            isLoading: true,
            totalSections: SectionsCount[formNameMapping[newValue]]
        }, () => {
            this.getSelectedTabData(this.state.donationId, this.state.selectedTab);
        });
    }



    private reDirectToDashboard() {
        this.props.history.push(`${contextPath}/dashboard/${this.state.donationType}?type=${this.state.donationType}`);
    }

    private getSelectedTabData = (donationId: string, tab: Tabs) => {
        let svc: any = null;
        let callBack: any = null;

        if (!donationId) {
            this.reDirectToDashboard();
        }

        if (tab === Tabs.ProjDetail) {
            svc = service.getAdditionProjectDetailsMonetaryDonation;
            callBack = (projectDetailsData: any) => {
                this.setState({
                    ...this.state, projectDetailsData,
                    isLoading: false,
                    lastModified: projectDetailsData?.lastModifiedOn,
                    isProjectDetailsSaved: projectDetailsData.saved
                })
            }
        } else if (tab === Tabs.OrgTab) {
            svc = service.getOrganizationProfileData;
            callBack = (additionDetails: any) => {
                this.setState({
                    ...this.state, additionDetails,
                    isLoading: false,
                    lastModified: additionDetails?.lastModifiedOn,
                    isOrgDetailsSaved: additionDetails.saved
                })
            }
        } else {
            svc = service.getAdditionalAttachmentsDetails;
            callBack = (attachmentData: IFileDataResponse) => {
                this.setState({
                    ...this.state,
                    attachmentData: attachmentData.uploadedFiles,
                    isLoading: false,
                    isAttachmentFulfilled: !(Array.from(attachmentData.uploadedFiles.values()).some((item: any) =>
                        (item.mandatory && !item.fileDetails?.fileName))),
                    lastModified: attachmentData.lastModifiedOn
                });
            }
        }

        svc(donationId).then(callBack);
    }

    private onCancel = () => {
        this.props.history.push(`${contextPath}/dashboard/${this.state.donationType}?type=${this.state.donationType}`);
    }
    private onGoToAppendix = () => {
        this.props.history.push(`${contextPath}/donation/view/${this.state.donationType}?type=${this.state.donationType}`);
    }

    private setAttachmentFulfilled = (status: boolean) => {

        this.setState(
            { ...this.state, isAttachmentFulfilled: status }
        );
    }

    private setLasModified = (lastModified: Date) => {

        this.setState(
            { ...this.state, lastModified }
        );
    }

    private onSubmit = () => {

        this.setState({ ...this.state, showLoader: true })
        // @ts-ignore
        service.submitAdditionalDetails(this.state.donationId).then((response: any) => {

            this.setState({
                ...this.setState, showLoader: false, showDialog: true,
                isSubmitted: true,
                dialogTitle: this.getTranslation('monetaryDonation.dialogMsgs.success'),
                successMessage: this.props.t('monetaryDonation.dialogMsgs.donationSubmit', { donationId: this.state.donationId })
            });
        })

    }

    private setCurrentFormStatus = (errors:any) => {

        const totalErrors = new Set(Object.keys(errors).map((section) => {
            if (section === ProjectTabSections.ProjectScheduleOfActivitiesCategory
                || section === ProjectTabSections.ProjectScheduleOfActivitiesSubCategory
                || section === ProjectTabSections.ProjectJustificationTotals ) {
                 return ProjectTabSections.ProjectScheduleOfActivities;
            }
            return ProjTabErrorsMap.get(section);
        }));

        this.setErrorState(totalErrors);

    }

    private setErrorState = (totalErrors:Set<any>)=>{

        let completedSectionCount = 0
        if (totalErrors.size > 0) {
            completedSectionCount = this.state.totalSections - totalErrors.size;
        } else {
            completedSectionCount = this.state.totalSections;
        }

        const newState = {
            ...this.state,
            completedSections: completedSectionCount,
        }

        this.setState(newState);

    }


    private setCurrentFormStatusOrgDetails = (errors: {}) => {
        const totalErrors = new Set(Object.keys(errors).map((section) => {
            if (section === Sections.percentTotals) {
                // @ts-ignore
                if (errors[section].hasOwnProperty('incomeCurrentFinancialYear') || errors[section].hasOwnProperty('incomeCurrentFinancialYear')) {
                    return 'income'
                    // @ts-ignore
                } else if (errors[section].hasOwnProperty('expenseCurrentFinancialYear') || errors[section].hasOwnProperty('expensePreviousFinancialYear')) {
                    return 'expense'
                }
            }
            return OrgTabErrorsMap.get(section);
        }));

        this.setErrorState(totalErrors);
    }

    private getOrgFormData = (data: any) => {
        const formData = new FormData();
        const dataWithoutAttachments = {
            ...data, income: {
                currentFinancialYear: data?.currentIncome,
                previousFinancialYear: data?.previousIncome
            },
            expenditure: {
                currentFinancialYear: data?.currentExpense,
                previousFinancialYear: data?.previousExpense
            },
            mission: {
              ...data.mission,
              personsOnPayroll: data.boardMemberSalaryInfo.personsOnPayroll
            },
            boardMemberSalaryInfo: data.boardMemberSalaryInfo.boardMembers
        };

        if(data.boardMemberSalaryInfo.isReceivingSalary === RadioOption.No) {
          dataWithoutAttachments.boardMemberSalaryInfo = (this.state.additionDetails as any).boardMemberSalaryInfo.map((bm: any) => {
            bm.additionalUserDetails.isReceivingSalary = false
            return bm;
          })
          dataWithoutAttachments.mission.personsOnPayroll = null;
        }
        delete dataWithoutAttachments.totals;

        data?.incomeAttachments.forEach((file: File) => {
            formData.append('orgIncomeAttachments', file)
        })
        data?.expenseAttachments.forEach((file: File) => {
            formData.append('orgExpenditureAttachments', file)
        })

        formData.append('additionalOrgDetails', JSON.stringify(dataWithoutAttachments))
        formData.append('saved', 'true');

        return formData;
    }

    private onOrgDataSave = (data: {}) => {

        this.setState({ ...this.state, showLoader: true }, () => {
            // @ts-ignore
            service.saveOrganizationProfileData(this.state.donationId, this.getOrgFormData(data)).then((response: any) => {
                // @ts-ignore
                scrollToTop();
                this.setState({
                    ...this.setState, showLoader: false, showDialog: true,
                    isOrgDetailsSaved: true,
                    additionDetails: response.data,
                    dialogTitle: this.getTranslation('monetaryDonation.dialogMsgs.orgDetails'),
                    successMessage: this.getTranslation('monetaryDonation.dialogMsgs.orgDetailsSave')
                })
            })
        });
    }

    public async componentDidMount() {
        let donationId = '';
        if (this.state.donationType === DONATION_TYPE.GOODSANDFOOD) {
            SectionsCount.project_details = 4;
            donationId = this.props?.selectedData?.donationOfGoods?.id;
        } else {
            SectionsCount.project_details = 9;
            donationId = this.props?.selectedData?.donationOfMoney?.donation?.id;
        }
        if (!donationId) {
            this.reDirectToDashboard();
        } else {
            this.getTabsData(donationId)
        }
    }

    private getTabsData(donationId: string) {

        this.setState({ ...this.state, donationId, showLoader: false }, () => {
            if (donationId) {
                Axios.all([service.getOrganizationProfileData(donationId),
                service.getAdditionProjectDetailsMonetaryDonation(donationId),
                service.getAdditionalAttachmentsDetails(donationId),
                service.getDropdownDetails(),
                service.getStateList({ countryCode: localStorage.getItem('countryCode') ?? '' })])
                    .then(Axios.spread((orgData, projectData, attachmentData, dropdownOptionMap, stateList) => {
                        this.setState({
                            ...this.state, additionDetails: orgData,
                            projectDetailsData: projectData,
                            lastModified: orgData?.lastModifiedOn,
                            dropdownOptionMap,
                            donationId,
                            attachmentData: attachmentData.uploadedFiles,
                            stateList,
                            isLoading: false,
                            isOrgDetailsSaved: orgData?.saved,
                            isProjectDetailsSaved: projectData?.saved,
                            isAttachmentFulfilled: !(Array.from(attachmentData.uploadedFiles.values()).some((item: any) =>
                                (item.mandatory && !item.fileDetails?.fileName))),
                            isFormEditable: orgData?.formControl?.formEditable && projectData?.formControl?.formEditable,
                            isAppendixBEditable: orgData?.formControl?.appendixBEditable && projectData?.formControl?.appendixBEditable,
                            isAdditionDetailsSubmitButtonEnabled: orgData?.formControl?.additionDetailsSubmitButtonEnabled
                                && projectData?.formControl?.additionDetailsSubmitButtonEnabled,
                        })
                    })).catch((error) => {
                        console.log(error);
                    })
            }
        });
    }

    private onProjectDetailsSave = (data: any) => {
        this.setState({ ...this.state, showLoader: true }, () => {
            const dataWithAdditionalValues = {
                ...data,
                saved: true,
                projectScheduleOfActivities: {
                    activities: data.projectScheduleOfActivities,
                    category: data.projectScheduleOfActivitiesCategory,
                    subCategory: data.projectScheduleOfActivitiesSubCategory
                },
            };

            delete dataWithAdditionalValues.projectScheduleOfActivitiesCategory;
            delete dataWithAdditionalValues.projectScheduleOfActivitiesSubCategory;

            // @ts-ignore
            service.saveAdditionProjectDetailsMonetaryDonation(this.state.donationId, dataWithAdditionalValues).then((response) => {
                scrollToTop();
                this.setState({
                    ...this.state, showLoader: false, showDialog: true,
                    isProjectDetailsSaved: true,
                    dialogTitle: this.getTranslation('monetaryDonation.dialogMsgs.projDetails'),
                    successMessage: this.getTranslation('monetaryDonation.dialogMsgs.projDetailsSave')
                });
            })
        })

    }

    private onDialogOk = () => {
        this.setState({ ...this.state, showDialog: false });
        if (this.state.isSubmitted) {
            this.reDirectToDashboard();
        }
    }

    public render() {

        return (
            <div>
                <BiLingualDropdownContext.Provider value={{
                    optionsMap: this.state.dropdownOptionMap,
                    languageKey: this.props.language,
                    stateList: this.state.stateList
                }} >

                    <div style={{ fontWeight: 'bold', fontSize: '20px', margin: '10px' }}>
                        <BreadcrumbsComponent
                            breadcrumbsData={
                                getMonetaryDonationBreadCrumbs(this.state.donationId, this.state.donationType)
                            }
                            history={this.props.history} />
                    </div>
                    <div style={{
                        paddingTop: '5px', margin: '10px 30px', display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <span style={{ fontWeight: 'bold', fontSize: '20px', }}>{this.getTranslation(`monetaryDonation.nav.${this.state.donationType === DONATION_TYPE.MONETARY ? 'moreDetails' : 'goodsAndFood'}`)}</span>
                        {this.state.lastModified && <span>{`${this.getTranslation('monetaryDonation.lastModified')} ${getFormattedDate(this.state.lastModified)}`}</span>}
                    </div>
                    <div className='monetary_donation_container'>
                        <Box
                            boxShadow={0.5}
                            m={1}
                            p={1}>
                            <CustomTabs
                                value={this.state.selectedTab}
                                indicatorColor='primary'
                                textColor='primary'
                                onChange={this.handleOnTabChange}
                                aria-label={this.state.donationType === DONATION_TYPE.MONETARY ?
                                    this.getTranslation('monetaryDonation.tabs.ariaLabel') :
                                    this.getTranslation('goodsAndFoodDonation.tabs.ariaLabel')}
                            >
                                <CustomTab showBadge={true} isSaved={this.state.isOrgDetailsSaved} translationKey={'monetaryDonation.tabs.organizationDetails'}
                                    {...a11yProps(0, 'org-details', 'monetary donations')}
                                />
                                <CustomTab showBadge={true} isSaved={this.state.isProjectDetailsSaved} translationKey={'monetaryDonation.tabs.projectDetails'}
                                    {...a11yProps(1, 'project-details', 'monetary donations')}
                                />
                                <CustomTab showBadge={true} isSaved={this.state.isAttachmentFulfilled} translationKey={'monetaryDonation.tabs.attachment'}
                                    {...a11yProps(1, 'attachments', 'monetary donations')}
                                />
                            </CustomTabs>
                            <CustomTabPanel id='md_org_tab' value={this.state.selectedTab} index={Tabs.OrgTab}
                                ariaLabelledby='Organization Tab'>
                                {this.state.isLoading ? <LoadingSkeleton /> : <OrganizationDetails orgData={this.state.additionDetails}
                                    isFormEditable={this.state.isFormEditable}
                                    setFormStatus={this.setCurrentFormStatusOrgDetails}
                                    onOrgSubmit={this.onOrgDataSave}
                                    fileDownloader={this.props.downloadFile}
                                />}

                            </CustomTabPanel>
                            <CustomTabPanel id='md_projDetail_tab' value={this.state.selectedTab} index={Tabs.ProjDetail}
                                ariaLabelledby='Project Details Tab'>
                                {this.state.isLoading ? <LoadingSkeleton /> : <ProjectDetails
                                    onProjectDetailsSubmit={this.onProjectDetailsSave}
                                    projectDetailsData={this.state.projectDetailsData}
                                    isFormEditable={this.state.isFormEditable}
                                    isLoading={this.state.isLoading}
                                    setFormStatus={this.setCurrentFormStatus}
                                />}
                            </CustomTabPanel>
                            <CustomTabPanel id='md_attachment_tab' value={this.state.selectedTab} index={Tabs.Attachment}
                                ariaLabelledby='Attachment Tab'>
                                <AttachmentsTabComponent
                                    donationId={this.state.donationId}
                                    files={this.state.attachmentData}
                                    isFormEditable={this.state.isFormEditable}
                                    isLoading={this.state.isLoading}
                                    setAttachmentFullfill={this.setAttachmentFulfilled}
                                    lang={this.props.language}
                                    setLasModified={this.setLasModified} />
                            </CustomTabPanel>
                            <SuccessAlertDailog
                                showDialog={this.state.showDialog}
                                ok={this.onDialogOk}
                                title={this.state.dialogTitle}
                                message={this.state.successMessage}
                            />
                        </Box>
                        <MDFooter onCancel={this.onCancel} onGoToAppendix={this.onGoToAppendix}
                            isLoading={this.state.isLoading}
                            formName={this.state.selectedForm}
                            onSubmit={this.onSubmit} submitRef={this.submitRef}
                            isFormEditable={this.state.isFormEditable}
                            isAppendixBEditable={this.state.isAppendixBEditable}
                            isAdditionDetailsSubmitButtonEnabled={this.state.isAdditionDetailsSubmitButtonEnabled}
                            isSubmitDisabled={!(this.state.isOrgDetailsSaved &&
                                this.state.isProjectDetailsSaved && this.state.isAttachmentFulfilled)}
                            pending={this.state.completedSections} totalSections={this.state.totalSections} />
                    </div>
                    {this.state.showLoader && <LoaderComponent fullScreen={true} />}
                </BiLingualDropdownContext.Provider>
            </div>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateToProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedData: state.selectedDataState.addDataToStore,
    language: state.selectedLang
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    submitDonation: (data: any) => dispatch(saveFormData(data)),
    downloadFile: (data) => dispatch(viewOrDownloadFile(data))
});

export default withStyles(styles)(withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(DonationOfMoneyMoreDetails))));