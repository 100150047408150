import { validateWalmartEmail } from "../../common/utils";
import { formatDateToYYYYMMDD, getTomorrowDate } from "../commonFunctions";

export const errorAttributes = {
    eventStartDate: '',
    eventEndDate: '',
    donationAmount: "",
    serviceType: "",
    donationTitle: "",
    description: "",
    supportDocument: "",
    locationKnown: '',
    //donationAttachments: [],
    addressLine1: "",
    county: "",
    postalCode: ""

}

export const serviceDonationFormAttributes = () => {
    const obj = {
        eventStartDate: getTomorrowDate().toString(),
        eventEndDate: getTomorrowDate().toString(),
        locationType: "Store",
        locationKnown: "No",
        primaryEmail: "",
        secondaryEmail: "",
        donationAmount: "",
        serviceType: "",
        donationTitle: "",
        description: "",
        supportDocument: "",
        primaryEmailError: "",
        secondaryEmailError: "",
        donationAttachments: [],
        addressLine1: "",
        county: "",
        postalCode: "",
        errorMessage: errorAttributes
    };
    return obj;
}


export const checkCalendarTimeValidation = (eventStartTime: string, eventEndTime: string) => {
    const firstTime = new Date(eventStartTime);
    const secondTime = new Date(eventEndTime);
    return secondTime.getTime() === firstTime.getTime();
}

export const serviceDonationFormUpdate = (formAttributes: any, t: any, facilityName: string,
    isLocationMandatory?: any, locationNumber?: any) => {

    formAttributes.errorMessage.donationTitle = formAttributes.donationTitle ? "" : t('serviceDonations.form.error.donationTitle');
    formAttributes.errorMessage.donationAmount = formAttributes.donationAmount ? "" : t('serviceDonations.form.error.donationAmount');
    formAttributes.errorMessage.serviceType = formAttributes.serviceType ? "" : t('serviceDonations.form.error.serviceType');
    formAttributes.errorMessage.description = formAttributes.description ? "" : t('serviceDonations.form.error.description');

    let locationErrorMessage =
        isRDALocationAndStoreMandatory(isLocationMandatory, formAttributes.locationKnown,
            facilityName, locationNumber)
            ? t("serviceDonations.location.error").replace(/FACILITY/g, formAttributes.locationType) : "";
    formAttributes.errorMessage.locationKnown = locationErrorMessage;

    if (facilityName.toLowerCase() === "other") {
        formAttributes.errorMessage.addressLine1 =
            formAttributes.addressLine1 ? "" : t('serviceDonations.form.error.addressLine1');
        formAttributes.errorMessage.county =
            formAttributes.county ? "" : t('serviceDonations.form.error.county');
        formAttributes.errorMessage.postalCode =
            formAttributes.postalCode ? "" : t('serviceDonations.form.error.otherPostalCode');
    }

    if (formAttributes.locationKnown === "Yes" && locationNumber) {
        // for Store Primary is mandatory, Secondary is optional
        if (facilityName.toLowerCase() === "store") {
            formAttributes.primaryEmailError = validateWalmartEmail(formAttributes.primaryEmail) ? "" : t('serviceDonations.location.primaryEmailError');
            if (formAttributes.secondaryEmail.length) {
                formAttributes.secondaryEmailError =
                    validateWalmartEmail(formAttributes.secondaryEmail) ? "" : t('serviceDonations.location.emailError');
            }
        }
        else {
            //if not store(May be DC & HomeOffice)
            if (formAttributes.primaryEmail.length) {
                formAttributes.primaryEmailError =
                    validateWalmartEmail(formAttributes.primaryEmail) ? "" : t('serviceDonations.location.emailError');
            }
            if (formAttributes.secondaryEmail.length) {
                formAttributes.secondaryEmailError =
                    validateWalmartEmail(formAttributes.secondaryEmail) ? "" : t('serviceDonations.location.emailError');
            }
        }

    }

    return formAttributes;
}

export const isRDALocationAndStoreMandatory = (isRDALocationMandatory: any, locationSelected: string,
    locationPicked: string, locationNumber: string) => {
    return isRDALocationMandatory && locationSelected === "Yes" &&
        locationPicked.toLowerCase() === "store" &&
        (locationNumber === undefined || locationNumber === "")
}

export const checkServiceDonationValidation = (formAttributes: any) => {
    let isAnyPropError = false;
    let errorItems = formAttributes.errorMessage;
    for (var formProp in errorItems) {
        if (errorItems[formProp]) {
            isAnyPropError = true;
            break;
        }
    }

    // Add code for Primary Contact Details Validation
    if (formAttributes.primaryEmailError) { isAnyPropError = true; }
    if (formAttributes.secondaryEmailError) { isAnyPropError = true; }
    return isAnyPropError;
}

export const createServiceDonationPayload = (donationFormAttributes: any,
    selectedFacility: any, facilityType: string, fileAttachments: any) => {
    let otherLocationDetails = {};
    if (donationFormAttributes.locationKnown === "Yes" && facilityType === 'OTHER') {
        otherLocationDetails = {
            addressLine1: donationFormAttributes.addressLine1,
            county: donationFormAttributes.county,
            postalCode: donationFormAttributes.postalCode
        };
    }
    const payload: any = {
        donationDetails: {
            "donationTitle": donationFormAttributes.donationTitle,
            "donationAmount": donationFormAttributes.donationAmount,
            "locationType": donationFormAttributes.locationKnown === "Yes" ? facilityType : "NA",
            "facilityDetails": {
                ...selectedFacility
            },
            "otherLocationDetails": otherLocationDetails
        },
        "eventStartDate": formatDateToYYYYMMDD(donationFormAttributes.eventStartDate),
        "eventEndDate": formatDateToYYYYMMDD(donationFormAttributes.eventEndDate),
        "serviceType": donationFormAttributes.serviceType,
        "serviceDescription": donationFormAttributes.description,
        "isLocationTypeSelected": false,
        "requestSourceApp": "EXTERNAL",
        "locationType": donationFormAttributes.locationKnown === "Yes" ? facilityType : "NA"
    }

    if (donationFormAttributes.locationKnown === "Yes") {
        payload["isLocationTypeSelected"] = true;
        let facilityPropType = facilityType === "STORE" ? "storeNumber" :
            (facilityType === "DC" ? "dcNumber" : "homeofficeNumber");

        payload.donationDetails[facilityPropType] = selectedFacility.facilityNo;

        if (donationFormAttributes.primaryEmail) {
            payload.donationDetails["primaryEmail"] = donationFormAttributes.primaryEmail;
        }
        if (donationFormAttributes.secondaryEmail) {
            payload.donationDetails["secondaryEmail"] = donationFormAttributes.secondaryEmail;
        }
    }
    const form = new FormData();
    if (fileAttachments && fileAttachments.length > 0) {
        fileAttachments.forEach((attachment: any) => {
          form.append("donationAttachments", attachment);
        });
      }
    //form.append("donationAttachments", result);
    form.append("baseSpaceAndServiceDonationRequest", JSON.stringify(payload));
    return form;
}