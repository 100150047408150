import "../styles.css";
import {
  getDateOnlyForLocale,
  getFormattedDate,
} from "../../../../components/common/DateUtils";
import ErrorSnackbarComponent from "dynamic-form-components/lib/components/menu/ErrorSnackbarComponent";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { paginator } from "../../../../components/common/paginatorConfig";
import { makeStyles, Theme } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar } from "primereact/calendar";
import { contextPath } from "src/constants";
import { useFetchEmergencyDonationDashboard } from "../common/emergencyDonationsApi";
import { Dropdown } from "primereact/dropdown";
import { donationsStatusText } from "src/constants";

import {
  Spinner,
} from "@walmart-web/livingdesign-components";
import { getLocationNumber } from "../../../common/utils";
//import { t } from "i18next";
export interface FormProps {
  results: any;
}



const getStatusCode = (inputStatus: any, selectedLang: any) => {
  return donationsStatusText["ALL"][selectedLang??"en"][inputStatus] ?? "";
};

const projectStatusOptions: any = (inputs: any[], selectedLang: any) => {
  let output: any = [];
  inputs.forEach((inp) =>
    output.push({ name: getStatusCode(inp, selectedLang), code: inp })
  );
  return output;
};

const EmergencyDonationDataTable = (props: any) => {
  const useStyles = makeStyles((_theme: Theme) => ({
    orgResultTbl: {
      fontFamily: "var(--appFont) !important",
    },
  }));
 
  const classes = useStyles();
  const [dateFilter, setDateFilter] = useState();
  const { t } = useTranslation();
  const dt: any = useRef();
  const [selectedDonationFrom, setSelectedDonationFrom] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>();

  /* istanbul ignore next */
  const formatDate = (date: Date) => {
    return getFormattedDate(date);
  };

  const donationFromOptions = [
    { name: t('emergencyDonations.location.store'), code: "store" },
    { name: t('emergencyDonations.location.DC'), code: "dc" },
  ];

  
  /* istanbul ignore next */
  
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    pageSize: 10,
    pageNumber: 0,
    page: 0,
    countryCode: props?.countryCode,
    sortBy: "id",
    sortOrder: -1,
    searchText: "",
    globalSearch: false,
    filters: {
      currentStatusCode: {
        value: [
          // "UNDER_CA_REVIEW",
          "UNDER_RDA_REVIEW",
          "WAITING_FOR_APPENDIX_H",
          "WAITING_FOR_COLLECTION_FROM_STORE",
          "REJECTED",
          "COMPLETED",
          "CANCELLED",
        ],
        matchMode: "equals",
      },
    },
  });

  const {
    data: results,
    isLoading,
    isFetching,
    isRefetching,
    isError,
  } = useFetchEmergencyDonationDashboard({
    ...lazyParams,
    sortOrder: lazyParams.sortOrder === -1 ? "DESC" : "ASC",
  });


  /* istanbul ignore next */
  const filterDate = (value: any, filter: any) => {
    if (
      filter === undefined ||
      filter === null ||
      (typeof filter === "string" && filter.trim() === "")
    ) {
      return true;
    }
    if (value === undefined || value === null) {
      return false;
    }
    return value === formatDate(filter);
  };
  /* istanbul ignore next */
  const onDateFilterChange = (event: any) => {
    if (event.value !== null) {
      // @ts-ignore
      dt.current.filter(formatDate(event.value), "submittedDate", "equals");
    } else {
      // @ts-ignore
      dt.current.filter(null, "submittedDate", "equals");
    }
    // @ts-ignore
    setDateFilter(event.value);
  };
  /* istanbul ignore next */
  const onPage = (event: any) => {
    const _lazyParams = { ...lazyParams, ...event, pageSize: event.rows };
    if (event.page !== lazyParams.page || event.rows !== lazyParams.rows) {
      setLazyParams(_lazyParams);
    }
  };

  /* istanbul ignore next */
  const onSort = (event: any) => {
    const _lazyParams = { ...lazyParams, ...event };
    setLazyParams({
      ..._lazyParams,
      sortBy: event?.sortField,
    });
  };
  /* istanbul ignore next */
  const onFilter = (event: any) => {
    const _lazyParams = { ...lazyParams, ...event };
    _lazyParams.first = 0;
    setLazyParams(_lazyParams);
  };

  const renderDateFilter = () => {
    return (
      <Calendar
        maxDate={new Date()}
        showIcon={false}
        value={dateFilter}
        onChange={onDateFilterChange}
        placeholder={t(
          "oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.requestDate"
        )}
        dateFormat="dd-mm-yy"
        className="p-column-filter"
      />
    );
  };


  useEffect(() => {
    props.setParentParams({ ...lazyParams });
  }, [lazyParams]);

  const onProjectStatusFilterChange = (event: any) => {
    dt.current.filter([event.value], "currentStatusCode", "equals");
    setSelectedStatus(event.value);
  };

  const renderProjectStatusFilter = () => {
    return (
      <Dropdown
        value={selectedStatus}
        optionLabel="name"
        optionValue="code"
        options={projectStatusOptions(props.filters, props.selectedLang)}
        onChange={onProjectStatusFilterChange}
        // itemTemplate={statusItemTemplate}
        showClear={true}
        placeholder={t(
          "oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectStatus"
        )}
        className="p-column-filter"
      />
    );
  };
  const onDonationFromFilterChange = (event: any) => {
    dt.current.filter(event.value, "locationType", "equals");
    setSelectedDonationFrom(event.value);
  };

  const renderDonationFromFilter = () => {
    return (
      <Dropdown
        value={selectedDonationFrom}
        optionLabel="name"
        optionValue="code"
        options={donationFromOptions}
        onChange={onDonationFromFilterChange}
        // itemTemplate={statusItemTemplate}
        showClear={true}
        placeholder={t(
          "emergencyDonations.table.searchByDonation"
        )}
        className="p-column-filter"
      />
    );
  };
 
  const dateFilterElement = renderDateFilter();
  const donationFromFilter = renderDonationFromFilter();
  const projectStatusFilter = renderProjectStatusFilter();

  const columns = [
    {
      field: "id",
      header: t("emergencyDonations.table.id"),
      sortable: true,
      sortField: "id",
      filter: true,
      filterPlaceholder: t("emergencyDonations.table.searchByID"),
      body: (rowData: any) => {
        /* istanbul ignore next */
        return (
          <div>
            <button
              type="button"
              className="p-button p-component p-button-link"
              // @ts-ignore
              onClick={() => {
                props.history.push({
                  pathname: `${contextPath}/dashboard/view/emergency-donation?type=emergency-donation`,
                  state: { donationId: rowData.id },
                });
              }}
            >
              <span
                className="p-button-label"
                style={{ fontWeight: "bold", textAlign: "left" }}
              >
                {rowData?.id}
              </span>
            </button>
          </div>
        );
      },
      style: {
        width: "14em",
      },
    },
    {
      field: "submittedDate",
      header: t("emergencyDonations.table.date"),

      sortable: true,
      sortField: "submittedDate",
      filter: true,
      body: (rowData: any) => {
        /* istanbul ignore next */
        return (
          <>
            <span>
              {rowData?.submittedDate
                ? getDateOnlyForLocale(rowData?.audit?.createdDtm)
                : "NA"}
            </span>
          </>
        );
      },
      filterField: "submittedDate",
      // filterMatchMode: "custom",
      filterFunction: filterDate,
      filterElement: dateFilterElement,
      style: {
        width: "15em",
      },
    },
    {
      field: "currentStatusCode",
      header: t("emergencyDonations.table.status"),
      sortField: "donationFrom",
      filter: true,
      filterElement: projectStatusFilter,
      body: (rowData: any) => (
        <span>
          {getStatusCode(rowData.currentStatusCode, props.selectedLang)}
        </span>
      ),
    },
    {
      field: "donationDetails.locationType",
      header: t("emergencyDonations.table.donationFrom"),
      filterPlaceholder: t("emergencyDonations.table.searchByDonation"),
      sortField: "locationType",
      filter: true,
      filterElement: donationFromFilter,

      body: (rowData: any) => {
        // let donationFrom = rowData?.?.donationFrom;

        /* istanbul ignore next */
        return (
           <>
            <span>
            { getLocationNumber(rowData) }

            </span>
          </>
        );
      },
    }
  ];

  const dynamicColumns = columns.map((column) => {
    return (
      <Column key={column.field} {...{ ...column, header: t(column.header) }} />
    );
  });

  const DataTableItem = DataTable as any;
  // Add loader while any data is loading
  if (isLoading) {
    return (
      <div className="flex-container" style={{paddingBottom:"25px"}}>
        <div className="row">
          <div className="flex-item">
            <Spinner
              color="gray"
              size="large"
              className="emergency-donation-loader"
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={`one-time-goods ${classes.orgResultTbl}`}>
        <div className="card">
          <DataTableItem
            ref={dt}
            className="p-datatable-sm"
            //  header={t('rdaManagement.label.rdaListing')}
            lazy={true}
            // @ts-ignore
            value={results?.data}
            dataKey="id"
            paginator={true}
            paginatorTemplate={paginator.paginatorTemplate}
            //  currentPageReportTemplate={t('orgMngmtTbl.labels.paginationText')}
            rows={lazyParams.pageSize}
            rowsPerPageOptions={paginator.rowsPerPageOptions}
            first={lazyParams.first}
            // @ts-ignore
            totalRecords={results?.totalCount}
            onPage={onPage}
            onSort={onSort}
            sortField={lazyParams.sortBy}
            // @ts-ignore
            sortOrder={lazyParams.sortOrder}
            onFilter={onFilter}
            filters={lazyParams.filters}
            loading={isLoading || isFetching || isRefetching}
            scrollable={true}
            scrollHeight="620px"
            emptyMessage={t("common.errorMesg.noRecordFound")}
            autoLayout={true}
            style={
              {
                minBodyHeight: "450px",
                maxBodyHeight: "600px",
                width: "100%",
              } as any
            }
            filterDelay={600}
          >
            {dynamicColumns}
          </DataTableItem>
        </div>
      </div>
      <ErrorSnackbarComponent
        isError={isError}
        message={t("emergencyDonations.form.errorResponse")}
      />


       
    </>
  );
};

export default EmergencyDonationDataTable;
