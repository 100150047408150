//@ts-nocheck
import { FC } from 'react';
import { Route } from 'react-router';
import { Navigate, Routes } from 'react-router-dom';
import LoginComponent from '../../components/login/LoginComponent';
import ResponsiveMenuComponent from '../../components/menu/ResponsiveMenuComponent';
import RegistrationComponent from '../../components/register/RegistartionComponent';
import ResetPassword from '../../components/register/ResetPassword';
import { contextPath, DONATION_TYPE_VALUES, routePathsRelative } from '../../constants';

import { getCookieValue } from 'dynamic-form-components/lib/service';
import { ObjectUtils } from 'primereact/utils';
import DashboardComponent from '../../components/dashboard/DashboardComponent';
import FoodSurplusConfirmationScreen from '../../components/FoodsurplusConfirmationScreen/FoodSurplusConfirmationScreen';
import Landing from '../../components/register/Landing';
import { useHistory } from '../../polyfils/customRouter';
import { OneTimeGoodsDashboardProps, SocialImpactMonetaryDashboardProps } from '../../types/oneTimeGoodsProps';
import CommunityGrant from '../donations/communityGrant/CommunityGrant';
import CommunityGrantGrid from '../donations/communityGrant/CommunityGrantGrid';
import DonationDashboardComponent from '../donations/donationDashboard/DonationDashboardComponent';
import CreateDonationOfFood from '../donations/donationOfFood/CreateDonationOfFood';
import ReviewDonationOfFood from '../donations/donationOfFood/ReviewDonationOfFood';
import AppendixcContainer from '../donations/donationOfGoods/AppendixcContainer';
import DonationOfGoodsComponent from '../donations/donationOfGoods/DonationOfGoods';
import DonationOfMoneyComponent from '../donations/donationOfMoney/DonationOfMoney';
import DonationOfMoneyMoreDetails from '../donations/moreDetailsSections/moreDetails';
import OneTimeGoodsReviewComponent from '../donations/oneTimeGoods/components/OneTimeGoodsReviewComponent';
import NGOAcknowledgeComponent from '../donations/oneTimeGoods/NGOAcknowledgeComponent';
import OneTimeGoodsCreateComponent from '../donations/oneTimeGoods/OneTimeGoodsCreateComponent';
import OneTimeGoodsDashboardComponent from '../donations/oneTimeGoods/OneTimeGoodsDashboardComponent';
import UnmannedCollection from '../donations/UnmannedCollection/UnmannedCollection';
import UnmannedCollectionGrid from '../donations/UnmannedCollection/UnmannedCollectionGrid';
import { getMyPendingDonationStatuses } from '../donations/utils';
import OrganizationProfile from '../OrganizationProfile/OrganizationProfile';
import OrgUserManagementGrid from '../orgUserManagement/OrgUserManagementGrid';
import PrivateRoute from '../PrivateRoute';
import EditInkindSocialImpactReport from '../socialImpact/EditInkindSocialImpactReport';
import InkindSocialImpactReport from '../socialImpact/InkindSocialImpactReport';
import MonetarySocialImpactReport from '../socialImpact/monetary/MonetarySocialImpactReport';
import SocialImpactDashBoard from '../socialImpact/SocialImpactDashboard';
import socialImpactMonetaryDashboard from '../socialImpact/socialImpactMonetaryDashboard';
import getDonationRoutes from './Routes/DonationConfigRoutes';
import getEmergencyDonationRoutes from './Routes/EmergencyDonationRoutes';
import SpaceRoutesContainer from './SpaceRoutes';
import OrganizationProfileNGO from '../OrganizationProfile/OrganizationProfileNGO';

interface OwnProps {
    userDetail: any,
    addSelectedDataToStore: any,
    selectedLang: string,
    refreshing: boolean,
    closeApprovalDialog: any,
    sidebarDetail:any
}
const GDMSRoutes: FC<OwnProps> = ({
    userDetail,
    addSelectedDataToStore,
    selectedLang,
    refreshing,
    closeApprovalDialog,
    sidebarDetail
}) => {

    const history = useHistory();
    const loggedInUserloading = refreshing || ObjectUtils.isEmpty(userDetail);
    const userName = userDetail && userDetail.orgLegalName;
    const orgType = userDetail?.organizationType?.keyText;
    const authed = userName ? true : false;

    const socialImpactMonetaryDonationsDashboardProps: SocialImpactMonetaryDashboardProps = {
        countryCode: getCookieValue('countryCodes'),
        myDonationTableProps: {
            selectedLang,
            defaultFilters: {
                currentStatusCode: {
                    value: getMyPendingDonationStatuses(),
                    matchMode: 'equals'
                }
            },
            showStatusFilter: false,
            showExportExcel: false
        },
        allDonationTableProps: {
            selectedLang,
            showStatusFilter: true,
            showExportExcel: true
        },
        history,
        addDataToStore: addSelectedDataToStore
    }

    const donationDashboardProps: OneTimeGoodsDashboardProps = {
        storeNumber: getCookieValue('storeNumber'),
        countryCode: getCookieValue('countryCodes'),
        myDonationTableProps: {
            selectedLang,
            defaultFilters: {
                currentStatusCode: {
                    value: getMyPendingDonationStatuses(),
                    matchMode: 'equals'
                }
            },
            showStatusFilter: false,
            showExportExcel: true
        },
        allDonationTableProps: {
            selectedLang,
            showStatusFilter: true,
            showExportExcel: true
        },
        history,
        addDataToStore: addSelectedDataToStore,
        closeApprovalDialog
    }

    return <Routes>
        <Route path={'/health'}
            // @ts-ignore
            element={<div>Success!!</div>} />
        {/* <Route path={`${contextPath}/`}
  element={<Navigate to={`${contextPath}/Public/landingPage`} />} /> */}
        <Route path={`${contextPath}/Public/landingPage`}
            element={<Landing />}
        />
        <Route path={`${contextPath}/Public/login`}
            element={<LoginComponent
                lang={localStorage.getItem('lang')}
                countryName={localStorage.getItem('countryName')}
            />}
        />
        <Route path={`${contextPath}/Public/register/eligibilityQuiz`}
            element={<RegistrationComponent />}
        />
        <Route path={`${contextPath}/Public/forgotPassword`}
            element={<ResetPassword />}
        />
        <Route path={`${contextPath}/Public/foodsurplus/response/:id`}
            // @ts-ignore
            element={<FoodSurplusConfirmationScreen />}
        />

        <Route path={`${contextPath}/space-donation/*`} element={
            <SpaceRoutesContainer />
        } />

        <Route path={`${contextPath}`}
            element={<ResponsiveMenuComponent />} >
            <Route path={`dashboard`} element={<DashboardComponent />} />
            {/* <Route path={'/'} element={<DashboardComponent />} /> */}
            <Route path={`dashboard/community-grant`} element={<PrivateRoute
                authed={authed}
                loggedInUserloading={loggedInUserloading}
                component={CommunityGrantGrid}
                content='Comunity Grant'
                loggedInUserData={userDetail}
            />} />
            <Route path={`donation/community-grants`} element={<PrivateRoute
                authed={authed}
                loggedInUserloading={loggedInUserloading}
                component={CommunityGrant}
                content='newRequest'
                isNewRequest={true}
                loggedInUserData={userDetail}
            />} />
            <Route path={`donation/view/community-grants`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={CommunityGrant}
                    content='viewSubmittedRequest'
                    isNewRequest={false}
                />
            } />


            <Route path={`dashboard/organization-profile`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={orgType === "org_type_governmental" ? OrganizationProfile : OrganizationProfileNGO}
                    content='viewSubmittedRequest'
                    isNewRequest={false}
                />
            } />
            <Route path={`dashboard/organization-users`} element={
                <PrivateRoute
                    authed={authed}
                    component={OrgUserManagementGrid}
                    loggedInUserloading={loggedInUserloading}
                    content='Organization Users'
                    isNewRequest={false}
                />
            } />
            <Route path={`dashboard/donation-money`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationDashboardComponent}
                    content='Donation Of Money'
                    loggedInUserData={userDetail}
                    {
                    ...{
                        ...donationDashboardProps,
                        isNewRequest: false,
                        createNewDonationUrl: `${contextPath}/donation/donation-money?type=donation-money`,
                        reviewDonationUrl: `${contextPath}/donation/view/donation-money?type=donation-money`,
                        disableRequestNewDonation: false,
                        showCreateNewBtn: true,
                        type: DONATION_TYPE_VALUES.MONETARY,
                        closeApprovalDialog
                    }
                    }
                />
            } />
            <Route path={`donation/donation-money`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationOfMoneyComponent}
                    content='newRequest'
                    isNewRequest={true}
                />
            } />
            <Route path={`${routePathsRelative.mx_fill_more_details}`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationOfMoneyMoreDetails}
                    content='Donation of Money - More Details'
                    isNewRequest={false}
                />
            } />
            <Route path={`${routePathsRelative.mx_fill_more_details_goods}`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationOfMoneyMoreDetails}
                    content='Donation of Goods - More Details'
                    isNewRequest={false}
                />
            } />
            <Route path={`${routePathsRelative.recurring_goods_appendix_c}`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={AppendixcContainer}
                    content='Donation of Goods - Appendix-c'
                    isNewRequest={false}
                />
            } />
            <Route path={`donation/view/donation-money`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationOfMoneyComponent}
                    content='viewSubmittedRequest'
                    isNewRequest={false}
                />
            } />
            <Route path={`dashboard/one-time-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={OneTimeGoodsDashboardComponent}
                    content='One Time Goods Donation'
                    {
                    ...{
                        ...donationDashboardProps,
                        history,
                        addDataToStore: addSelectedDataToStore,
                        isNewRequest: false,
                        createNewDonationUrl: `${contextPath}/donation/one-time-goods?type=one-time-goods`,
                        reviewDonationUrl: `${contextPath}/donation/review/one-time-goods?type=one-time-goods`,
                        disableRequestNewDonation: false
                    }
                    }
                />
            } />
            <Route path={`dashboard/one-time-food`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationDashboardComponent}
                    content='One Time Food Donation'
                    {
                    ...{
                        ...donationDashboardProps,
                        isNewRequest: false,
                        createNewDonationUrl: `${contextPath}/donation/one-time-food?type=one-time-food`,
                        reviewDonationUrl: `${contextPath}/donation/review/one-time-food?type=one-time-food`,
                        disableRequestNewDonation: false,
                        showCreateNewBtn: true,
                        type: DONATION_TYPE_VALUES.FOOD_ONE_TIME
                    }
                    }
                />
            } />
            <Route path={`donation/one-time-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={OneTimeGoodsCreateComponent}
                    content='Create New Donation'
                    isNewRequest={true}
                />
            } />
            <Route path={`donation/one-time-food`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={CreateDonationOfFood}
                    content='Create New Donation'
                    isNewRequest={true}
                    selectedLang={selectedLang}
                    orgData={userDetail}
                />
            } />
            <Route path={`donation/review/one-time-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={NGOAcknowledgeComponent}
                    content='Donation Goods'
                    loggedInUserData={userDetail}
                />
            } />
            <Route path={`donation/review/one-time-food`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={ReviewDonationOfFood}
                    content='Donation Goods'
                    orgData={userDetail}
                />
            } />
            <Route path={`donation/view/one-time-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={OneTimeGoodsReviewComponent}
                    content='Donation Goods'
                    loggedInUserData={userDetail}
                />
            } />
            <Route path={`dashboard/donation-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationDashboardComponent}
                    content='Donation Goods'
                    loggedInUserData={userDetail}
                    {
                    ...{
                        ...donationDashboardProps,
                        isNewRequest: false,
                        createNewDonationUrl: `${contextPath}/donation/donation-goods?type=donation-goods`,
                        reviewDonationUrl: `${contextPath}/donation/view/donation-goods?type=donation-goods`,
                        disableRequestNewDonation: false,
                        showCreateNewBtn: true,
                        type: DONATION_TYPE_VALUES.GOODSANDFOOD,
                        closeApprovalDialog
                    }
                    }
                />
            } />
            <Route path={`donation/donation-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationOfGoodsComponent}
                    content='newRequest'
                    isNewRequest={true}
                />
            } />
            <Route path={`donation/view/donation-goods`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={DonationOfGoodsComponent}
                    content='viewSubmittedRequest'
                    isNewRequest={false}
                />
            } />
            <Route path={`dashboard/unmanned-collection`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={UnmannedCollectionGrid}
                    content='viewSubmittedRequest'
                    isNewRequest={false}
                />
            } />
            <Route path={`donation/unmanned-collection`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={UnmannedCollection}
                    content='newRequest'
                    isNewRequest={true}
                />
            } />
            <Route path={`donation/view/unmanned-collection`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={UnmannedCollection}
                    content='newRequest'
                    isNewRequest={false}
                />
            } />

            {/* Emergency Donation Routes */}
            {getEmergencyDonationRoutes().map((componentProps: any) => {
               return <Route path={componentProps.routePath} element={
                    <PrivateRoute
                        authed={authed}
                        loggedInUserloading={loggedInUserloading}
                        component={componentProps.routeComponent}
                        content={componentProps.content}
                        isNewRequest={false}
                        selectedLang={selectedLang}
                    />
                } />
            }
            )}
            {/* Space Donation Routes */}
            {getDonationRoutes().map((componentProps: any) => {
                return <Route path={componentProps.routePath} element={
                    <PrivateRoute
                        authed={authed}
                        loggedInUserloading={loggedInUserloading}
                        component={componentProps.routeComponent}
                        isNewRequest={false}
                        selectedLang={selectedLang}
                        donationCategory={componentProps.donationCategory}
                    />
                } />
            })
            }


            <Route path={`dashboard/social-impact`} element={
                <PrivateRoute
                    authed={authed}
                    component={SocialImpactDashBoard}
                    loggedInUserloading={loggedInUserloading}
                    content='Social Impact Dashboard'
                    isNewRequest={false}
                />
            } />
            {/* TODO:  add New Donation and review donations path */}
            <Route path={`dashboard/social-impact-monetary`} element={
                <PrivateRoute
                    authed={authed}
                    component={socialImpactMonetaryDashboard}
                    loggedInUserloading={loggedInUserloading}
                    loggedInUserDetails={userDetail}
                    content='Social Impact Monetary Dashboard'
                    {
                    ...{
                        ...socialImpactMonetaryDonationsDashboardProps,
                        history,
                        addDataToStore: addSelectedDataToStore,
                        isNewRequest: false,
                        createNewDonationUrl: ``,
                        reviewDonationUrl: ``,
                        disableRequestNewDonation: false,
                        selectedLang,
                    }
                    }
                />
            } />
            <Route path={`report/inkind-social-impact`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={InkindSocialImpactReport}
                    content='Inkind Social Impact Report'
                    isNewRequest={false}
                />
            } />
            <Route path={`report/view/inkind-social-impact`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={EditInkindSocialImpactReport}
                    content='Edit Inkind Social Impact Report'
                    isNewRequest={false}
                />
            } />
            <Route path={`report/social-impact-monetary`} element={
                <PrivateRoute
                    authed={authed}
                    component={MonetarySocialImpactReport}
                    loggedInUserloading={loggedInUserloading}
                    content='Monetary Donation Social Impact Dashboard'
                    isNewRequest={false}
                />
            } />
            <Route path={`index`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={OrganizationProfile}
                    content='viewSubmittedRequest'
                    isNewRequest={false}
                />
            } />
            <Route path={`Public/landingPage`} element={
                <PrivateRoute
                    authed={authed}
                    loggedInUserloading={loggedInUserloading}
                    component={Landing}
                    content='Dashboard contents'
                />
            } />
        </Route>
        <Route path='*'
            element={<Navigate to={`${contextPath}/dashboard`} />} />
    </Routes>
}

export default GDMSRoutes;;