

// import {DatePicker, DatePickerProps, MuiPickersUtilsProvider} from '@material-ui/pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, {FC} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {resolvePath} from 'src/components/common/utils';
import {ErrorMessageComponent} from '../../../common/formComponents/ErrorMessage';
import {FormControl, TextField, FormLabel} from '@mui/material';

// excluded onChange and value props from Base Interface as these are now controlled by react hook form controller
export interface DatePickerFieldProps extends Omit<any, 'onChange' | 'value'> {
    required?: boolean,
    customOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChange?: (date: any) => void,
    errorKeyName: string
    classes?: any,
    customStyle?: any,
    rules?: any,
    fieldName: string,
    defaultValue: string
}

export const DatePickerField: FC<DatePickerFieldProps> = ((props: DatePickerFieldProps) => {

    const { control, errors } = useFormContext();
    const errorKey = props?.errorKeyName || '';
    const fieldError = resolvePath(errorKey, errors, '')?.message;

    const defaultCustomStyle = {
        containerMarginTop: '0px',
        containerMarginBottom: '0px',
        fieldWidth: '100%',
        errorTextColor: 'red'
    }
    const style = {
        ...defaultCustomStyle,
        ...props?.customStyle
    }

    return (
        <div
            className={props?.className}
            id={props.id}
            style={{ marginTop: style.containerMarginTop, marginBottom: style.containerMarginBottom }}
        >
            <FormControl
                required={props.required}
                style={{ width: style.fieldWidth }}
                className = 'MuiCustomDatePicker'
                classes={{ root: props?.classes?.inputFormControl }}
                variant='outlined'
            >
              {props?.label && <FormLabel required={props.required ?? false}>
                  {props?.label}
              </FormLabel>}
                <Controller
                    id={props.fieldName}
                    name={props.fieldName}
                    label={props.label}
                    control={control}
                    defaultValue={props.defaultValue}
                    render={({ onChange, value, ...rest }) => {
                      return<LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                  {...rest}
                                  label = ''
                                  inputFormat='dd-MM-yyyy'
                                  value={value}
                                  onChange={onChange}
                                  renderInput={(params) =>
                                  <TextField {...params} error = {fieldError ? true: false} />}
                                />
                       </LocalizationProvider>

                    }}
                    rules={{ ...props?.rules }}
                />
            </FormControl>
            <ErrorMessageComponent errors={errors} fieldName={errorKey} />
        </div>
    )
})

DatePickerField.defaultProps = {
    variant: 'inline',
    autoOk: true,
    format: 'dd-MM-yyyy',
    clearable: true,
    disableToolbar: true,
    inputVariant: 'outlined'
}
