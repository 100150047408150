import { Fab, IconButton, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormBlockHeading } from '../../donations/utils';
import InputTextField from './InputTextField';
import { useTranslation } from 'react-i18next';
import { isValidYearOfBirth } from '../../common/utils'
import { countryList, statesMap, citiesMap } from '../../../static/countryList';
import KeyValueDropdown from '../Dropdown/keyValueDropdown';


const useStyles = makeStyles((_theme: Theme) => ({
    closeBtn: {
        fontSize: '18px',
        backgroundColor: '#fff !important',
        '&:hover': {
            backgroundColor: '#fff !important',
            color: 'red'
        }
    }
}));
export interface OrgMemberProps {
    title: string
    parentFieldName: string
    disabled?: boolean
    classes?: any
    data?: any
    countryList?: {
        label: string
        value: string
    }
    errorClassName?: string
}
interface OwnProps {
    sectionName: string,
    field: any,
    index: number,
    disabled?: boolean,
    control: any,
    watch: any,
    requiredFieldMsg: string
    classes: any
    t: any
    errorClassName?: string
    errors: any
    orgMembers: any
}

const Member: FC<OwnProps> = ({ sectionName, field, index,
    disabled, control, watch, classes, t, requiredFieldMsg, errorClassName,
    errors, orgMembers }) => {
    const baseSectionName = `${sectionName}[${index}]`


    const country = watch(`${baseSectionName}.countryOfResidence`);
    const state = watch(`${baseSectionName}.stateOfResidence`);
    const stateList = statesMap[country as keyof typeof statesMap]?.length > 0 ?
        statesMap[country as keyof typeof statesMap] : [];

    const cityList = citiesMap[state as keyof typeof citiesMap]?.length > 0 ?
        citiesMap[state as keyof typeof citiesMap] : [];

    return (<div id={field.id} key={field?.id}>
        <Box>
            <div className='row'>
                <div className='col-sm-12 col-md-6 col-lg-4'>

                    <Controller
                        id={field._id ? field._id : 0}
                        name={`${baseSectionName}._id`}
                        control={control}
                        defaultValue={field._id ? field._id : 0}
                        as={
                            <input type='hidden'
                                name={`${baseSectionName}._id`}
                                id='_id'
                                defaultValue={field._id ? field._id : 0
                                } />
                        }
                    />
                    <InputTextField
                        required={true}
                        disabled={disabled}
                        label={t('common.orgMembers.name')}
                        fieldName={`${baseSectionName}.userName`}
                        id={`${index}`}
                        defaultValue={field.userName}
                        isUsedForArray={true}
                        errorKeyName={`${baseSectionName}.userName`}
                        errorFieldName={'userName'}
                        errorIndex={index}
                        rules={{
                            required: requiredFieldMsg
                        }}
                        InputProps={{
                            readOnly: disabled,
                            classes: {
                                disabled: disabled ? classes?.disabled : ''
                            }
                        }}
                        errorClassName={errorClassName}
                    />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                    <InputTextField
                        required={true}
                        disabled={disabled}
                        label={t('common.orgMembers.title')}
                        fieldName={`${baseSectionName}.userTitle`}
                        id={`${index}`}
                        defaultValue={field.userTitle}
                        isUsedForArray={true}
                        errorKeyName={`${baseSectionName}.userTitle`}
                        errorFieldName={'userTitle'}
                        errorIndex={index}
                        rules={{
                            required: requiredFieldMsg
                        }}
                        InputProps={{
                            readOnly: disabled,
                            classes: {
                                disabled: disabled ? classes?.disabled : ''
                            }
                        }}
                        errorClassName={errorClassName}
                    />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
                    <InputTextField
                        required={true}
                        disabled={disabled}
                        label={t('common.orgMembers.yearOfBirth')}
                        fieldName={`${baseSectionName}.birthYear`}
                        id={`${index}`}
                        defaultValue={field.birthYear}
                        isUsedForArray={true}
                        errorKeyName={`${baseSectionName}.birthYear`}
                        errorFieldName={'birthYear'}
                        errorIndex={index}
                        rules={{
                            required: requiredFieldMsg,
                            validate: (value: any) => isValidYearOfBirth(value, t)
                        }}
                        InputProps={{
                            readOnly: disabled,
                            classes: {
                                disabled: disabled ? classes?.disabled : ''
                            }
                        }}
                        errorClassName={errorClassName}
                    />
                </div>
                {
                    (orgMembers.fields.length !== 1 && (!disabled)) ?
                        <IconButton onClick={() => {
                            orgMembers?.remove(index);
                        }}
                            classes={{ root: classes.closeBtn }}>
                            <CloseIcon classes={{ root: classes.closeBtn }}
                                style={{ marginTop: index === 0 ? '1rem' : 0 }} />
                        </IconButton> : <></>
                }
            </div>
        </Box>
        <Box>
            <div className='row' style={{ marginTop: '16px' }}>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                    <KeyValueDropdown
                        id={`${index}`}
                        inputLabel={t('common.orgMembers.countryOfResidence')}
                        fieldName={`${baseSectionName}.countryOfResidence`}
                        defaultValue={field.countryOfResidence}
                        options={countryList}
                        errors={errors}
                        control={control}
                        disabled={disabled}
                        required={true}
                        valueKey='label'
                        labelKey='label'
                        rules={{
                            required: requiredFieldMsg
                        }}
                        errorKeyName={`${baseSectionName}.countryOfResidence`}
                        errorClassName={errorClassName}
                    />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                    <KeyValueDropdown
                        id={`${index}`}
                        inputLabel={t('common.orgMembers.stateOfResidence')}
                        fieldName={`${baseSectionName}.stateOfResidence`}
                        defaultValue={field.stateOfResidence}
                        // @ts-ignore
                        options={stateList}
                        errors={errors}
                        control={control}
                        disabled={disabled}
                        required={true}
                        valueKey='label'
                        labelKey='label'
                        rules={{
                            required: requiredFieldMsg
                        }}
                        errorKeyName={`${baseSectionName}.stateOfResidence`}
                        errorClassName={errorClassName}
                    />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-4 col-xl-3'>
                    <KeyValueDropdown
                        id={`${index}`}
                        inputLabel={t('common.orgMembers.cityOfResidence')}
                        fieldName={`${baseSectionName}.cityOfResidence`}
                        defaultValue={field.cityOfResidence}
                        // @ts-ignore
                        options={cityList}
                        errors={errors}
                        control={control}
                        disabled={disabled}
                        required={true}
                        valueKey='label'
                        labelKey='label'
                        rules={{
                            required: requiredFieldMsg
                        }}
                        errorKeyName={`${baseSectionName}.cityOfResidence`}
                        errorClassName={errorClassName}
                    />
                </div>
            </div>
        </Box>
    </div>)
}
export const OrgMemberComponent: FC<OrgMemberProps> = ({ title, parentFieldName, ...props }) => {
    const classes = useStyles()
    const { control, watch, errors } = useFormContext();
    const { t } = useTranslation()
    const orgMembers = useFieldArray({
        name: parentFieldName,
        control,
    });
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField')
    const { disabled } = props;
    return (<>
        <div>
            <FormBlockHeading heading={t(title)} />
        </div>
        <div>
            {orgMembers.fields.map((field, index) => <Member
                orgMembers={orgMembers}
                field={field}
                index={index}
                sectionName={parentFieldName}
                requiredFieldMsg={requiredFieldMsg}
                disabled={disabled}
                watch={watch}
                control={control}
                classes={classes}
                errorClassName={props.errorClassName}
                t={t}
                errors={errors}
            />
            )}
            <div className='row' style={{ marginTop: '20px' }}>
                <div className='col-md-12'>
                    {
                        (!props.disabled)
                            ? <Fab style={{ backgroundColor: '#0071ce', color: 'white', fontSize: '5px' }}
                                aria-label='add' name='addBoardOfDirectors' size='small'
                                id={'add-org-member'}
                                onClick={() => {
                                    orgMembers.append({
                                        _id: 0,
                                        userName: '',
                                        userTitle: '',
                                        birthYear: null,
                                        countryOfResidence: '',
                                        stateOfResidence: '',
                                        cityOfResidence: ''
                                    })
                                }}>
                                <AddIcon />
                            </Fab> : <></>
                    }
                </div>
            </div>
        </div>
    </>)
}



