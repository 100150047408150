import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles({
  selectRoot: {
    color: '#495057 !important',
  },
  selectDisabled: {
    backgroundColor: '#e9ecef',
  },
  selectIcon: {
    color: '#495057 !important'
  },
  selectRootLabel: {
    top: '-8px',
    left: '15px',
    zIndex: 5
  }
});
