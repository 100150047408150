
import * as React from 'react';
import Button from '../../../commonComponents/uiComponents/LDButton';
import withRouter from 'src/polyfils/customRouter';
import { contextPath } from 'src/constants';

export interface Props {
    history: any[]
}

class ThankYou extends React.Component<Props> {
    public render() {
        return <div className='thank-you-container'>
            <div><img height='150px' width='150px'
                src={require('src/components/spacedonation/commonComponents/assets/logos/Walmart_Spark.png')} /></div>
            <div className='checkout-text-header'>Thank you for hosting your event with space for good</div>
            <div className='go-back-button'><Button onClick={() => {
                this.props.history
                    .push(
                        { pathname: `${contextPath}/space-donation/events` })
            }}>Back to home</Button></div>
        </div>
    }
}

// @ts-ignore
export default withRouter(ThankYou);