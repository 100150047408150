import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { languageTranslations, orgUserManagementTranslations, availableLanguagesForCountries } from '../../constants';
import 'src/styles/orgUser.css'

const OrgUserForm = (props: any) => {
    const { classes } = props;
    const intitialState:any = {

    };
    const languageDropDown: React.ReactElement[] = [];
    const languageList:any = [];
    const availableLanguages:any = (props.countryCode) ? availableLanguagesForCountries[props.countryCode] : [];
    Object.keys(languageTranslations).map((language:string)=>{
        if(availableLanguages.includes(language)){
            languageList.push({
                label: languageTranslations[language][props.selectedLang],
                value: language
            })
        }
    })

    useEffect(() => {
        setValue('name',(props.selectedOrgUser && props.selectedOrgUser.name ? props.selectedOrgUser.name : ''))
        setValue('title',(props.selectedOrgUser && props.selectedOrgUser.title ? props.selectedOrgUser.title : ''))
        setValue('emailId',(props.selectedOrgUser && props.selectedOrgUser.emailId ? props.selectedOrgUser.emailId : ''))
        setValue('birthYear',(props.selectedOrgUser && props.selectedOrgUser.birthYear ? parseInt(props.selectedOrgUser.birthYear,10) : 0))
        setValue('countryOfResidence',(props.selectedOrgUser && props.selectedOrgUser.countryOfResidence
            ? props.selectedOrgUser.countryOfResidence : ''))
        setValue('userTelephone',(props.selectedOrgUser && props.selectedOrgUser.userTelephone ? props.selectedOrgUser.userTelephone : ''))
        setValue('prefLanguage',(props.selectedOrgUser && props.selectedOrgUser.prefLanguage ? props.selectedOrgUser.prefLanguage : ''))
    }, [props.selectedOrgUser])

    const {
        handleSubmit,
        control,
        setValue,
        errors,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            ...intitialState,
            ...props.selectedOrgUser
        }
    });

    const validateEmail = (email:string) => {
        const format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return format.test(email) ? true : orgUserManagementTranslations.form.validationMsg.invalidEmail[props.selectedLang]
    }
    const validateYearOfBirth = (value:any) => {
        const format = /^\d{4}$/;
        const currentYear = new Date().getFullYear();
        const pastYear = currentYear - 130;
        if((!format.test(value)) || (value>currentYear) || (value<pastYear)){
            return orgUserManagementTranslations.form.validationMsg.invalidBirthYear[props.selectedLang]
        }
        return true
    }
    const onSubmit = (data: any) => {
        props.submitOrgUserData({
            ...data,
            birthYear: parseInt(data.birthYear,10),
            orgId: props.orgId,
            editOfOrgUser: props.editOfOrgUser
        });
    }

    return (
        <div className={`org-user-form-container`}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off' style={{width: '60%'}}>
                <div>
                    <Controller
                        control={control}
                        name='name'
                        id='name'
                        defaultValue={''}
                        style={{ marginTop: '0px', marginBottom: '3px' }}
                        as={
                            <TextField
                                required={true}
                                id='name'
                                label={orgUserManagementTranslations.form.labels.name[props.selectedLang]}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight:'bold', fontSize:'1rem', backgroundColor: 'white'},
                                }}
                                variant='outlined'
                                error={errors.name?.message}
                            />
                        }
                        rules={{
                            required: orgUserManagementTranslations.form.validationMsg.nameRequired[props.selectedLang],
                        }}
                    />
                    {errors.name?.message && <span className='form-error-container'
                        style={{ color: 'red' }}>{errors.name?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='title'
                        id='title'
                        defaultValue={''}
                        style={{ marginTop: '25px', marginBottom: '3px' }}
                        as={
                            <TextField
                                required={true}
                                id='title'
                                label={orgUserManagementTranslations.form.labels.title[props.selectedLang]}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { fontWeight:'bold', fontSize:'1rem', backgroundColor: 'white'},
                                }}
                                variant='outlined'
                                error={errors.title?.message}
                            />
                        }
                        rules={{
                            required: orgUserManagementTranslations.form.validationMsg.titleRequired[props.selectedLang],
                        }}
                    />
                    {errors.title?.message && <span className='form-error-container'
                        style={{ color: 'red' }}>{errors.title?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='emailId'
                        defaultValue={''}
                        style={{ marginTop: '25px', marginBottom: '3px' }}
                        as={
                            <TextField
                                required={true}
                                disabled={props.isPrimaryOrgUserEdit}
                                id='emailId'
                                label={orgUserManagementTranslations.form.labels.emailId[props.selectedLang]}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {fontWeight:'bold', fontSize:'1rem', backgroundColor: 'white'},
                                }}
                                variant='outlined'
                                error={errors?.emailId?.message}
                            />
                        }
                        rules={{
                            required: orgUserManagementTranslations.form.validationMsg.emailRequired[props.selectedLang],
                            validate: validateEmail
                        }}
                    />
                    {errors?.emailId?.message && <span className='form-error-container'
                        style={{ color: 'red' }}>{errors?.emailId?.message}<br /></span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='birthYear'
                        id='birthYear'
                        defaultValue={''}
                        style={{ marginTop: '25px', marginBottom: '3px' }}
                        as={
                            <TextField
                                required={true}
                                id='birthYear'
                                label={orgUserManagementTranslations.form.labels.birthYear[props.selectedLang]}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                type='number'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {fontWeight:'bold', fontSize:'1rem', backgroundColor: 'white'},
                                }}
                                variant='outlined'
                                error={errors.birthYear?.message}
                            />
                        }
                        rules={{
                            required: orgUserManagementTranslations.form.validationMsg.birthYearRequired[props.selectedLang],
                            validate: validateYearOfBirth
                        }}
                    />
                    {errors.birthYear?.message && <span className='form-error-container'
                        style={{ color: 'red' }}>{errors.birthYear?.message}<br/></span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='countryOfResidence'
                        id='countryOfResidence'
                        defaultValue={''}
                        style={{ marginTop: '25px', marginBottom: '3px' }}
                        as={
                            <TextField
                                required={true}
                                id='countryOfResidence'
                                label={orgUserManagementTranslations.form.labels.countryOfResidence[props.selectedLang]}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {fontWeight:'bold', fontSize:'1rem', backgroundColor: 'white'},
                                }}
                                variant='outlined'
                                error={errors.countryOfResidence?.message}
                            />
                        }
                        rules={{
                            required: orgUserManagementTranslations.form.validationMsg.countryOfResidenceRequired[props.selectedLang],
                        }}
                    />
                    {errors.countryOfResidence?.message && <span className='form-error-container'
                        style={{ color: 'red' }}>{errors.countryOfResidence?.message}<br/></span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='userTelephone'
                        id='userTelephone'
                        defaultValue={''}
                        style={{ marginTop: '25px', marginBottom: '3px' }}
                        as={
                            <TextField
                                required={true}
                                id='userTelephone'
                                label={orgUserManagementTranslations.form.labels.telephone[props.selectedLang]}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {fontWeight:'bold', fontSize:'1rem', backgroundColor: 'white'},
                                }}
                                variant='outlined'
                                error={errors.userTelephone?.message}
                            />
                        }
                        rules={{
                            required: orgUserManagementTranslations.form.validationMsg.telephoneRequired[props.selectedLang],
                        }}
                    />
                    {errors.userTelephone?.message && <span className='form-error-container'
                        style={{ color: 'red' }}>{errors.userTelephone?.message}<br/></span>}
                </div>
                <div>
                    <FormControl
                        required={true}
                        style={{ width: '100%', color: 'black', backgroundColor: 'white', marginTop: '25px' }}
                        classes={{ root: classes.selectFormControl }}
                        size='medium' variant='outlined'
                        error={errors.prefLanguage?.message}>
                        <InputLabel classes={{ root: classes.selectLabel }} id='prefLanguage-selecct-input'
                        style={{fontWeight:'bold'}}>
                            {orgUserManagementTranslations.form.labels.prefLanguage[props.selectedLang]}
                        </InputLabel>
                        <Controller
                            control={control}
                            name='prefLanguage'
                            defaultValue={''}
                            as={
                                <Select
                                    style={{ color: 'black', backgroundColor: 'white'}}
                                    classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                    labelId='prefLanguage-select-label'
                                    id='prefLanguage'
                                    name='prefLanguage'
                                    autoWidth={false}
                                    defaultValue={''}
                                    displayEmpty={true}>
                                    {languageList?.map((item: any) => {
                                        languageDropDown.push(<MenuItem
                                            classes={{
                                                root: classes.menuItemRoot,
                                                selected: classes.menuItemselected
                                            }}
                                            key={item.value} value={item.value}>{item.label}</MenuItem>)
                                    })}
                                    {languageDropDown}
                                </Select>
                            }
                            rules={{
                                required: orgUserManagementTranslations.form.validationMsg.prefLanguageRequired[props.selectedLang]
                            }}
                        />
                        {errors.prefLanguage?.message && <span className='form-error-container'
                            style={{ color: 'red', fontWeight:'normal' }}>{errors.prefLanguage?.message}</span>}
                    </FormControl>
                </div>
                <div>
                    <div className='org-user-btn-group'>
                        <div className='org-user-btn-group'>
                            <div className='inline-flex-container'
                                style={{ marginTop: '-8px', alignItems: 'center' }}>
                                <button className={`sip-submit-btn`}
                                    type='submit'>
                                    {orgUserManagementTranslations.submit[props.selectedLang]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default OrgUserForm;