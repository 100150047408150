import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export interface CustomCheckBoxProps {
    fieldName: string
    id: string
    label: any
    required?: boolean
    value?: any
    defaultValue?: any
    disabled?: boolean
    style?: any
    rules?: any
    errorMsg?: string
}
export const CustomCheckBox: FC<CustomCheckBoxProps> = ({ fieldName, id, label, value = null,
  disabled = false, defaultValue = null, ...props }) => {

    const { control, register } = useFormContext()
    const customStyle = {
        color: 'primary',
        errorContainerDisplay: 'block',
        ...props?.style
    }

    return (<div>
        <FormControlLabel
            id={id}
            value={value}
            disabled={disabled}
            inputRef={register}
            control={
                <Controller
                    name={fieldName}
                    control={control}
                    defaultValue={defaultValue}
                    render={(_props) => (
                        <Checkbox
                            {..._props}
                            disabled={disabled}
                            required={props?.required}
                            color={customStyle.color}
                            checked={_props.value}
                            onChange={(e) => _props.onChange(e?.target?.checked)}
                        />
                    )}
                    rules={{
                        ...props?.rules
                    }}
                />
            }
            label={
                <Typography
                    style={{ color: customStyle?.labelColor, fontWeight: customStyle?.labelFontWeight }}
                    variant='body2' color='initial'>
                    {label}
                </Typography>
            }
        />
        {props?.errorMsg && <span className='reg-form-error-container'
            style={{ color: 'red', display: customStyle?.errorContainerDisplay }}>{props?.errorMsg}</span>}
    </div>)
}
