
import {makeStyles, TextField, Theme} from '@material-ui/core';
import {Controller, useForm} from 'react-hook-form';
import {validateEmail} from './../../components/common/utils';
import {Trans, useTranslation} from 'react-i18next';
import {contextPath} from './../../constants';
import withRouter from 'src/polyfils/customRouter';
import {NavLink} from 'react-router-dom';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';


const useStyles = makeStyles((_theme: Theme) => ({
    goBackBtn: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 'normal',
        textDecoration: 'underline',
        textTransform: 'none',
        outline: 'none !important',
        minWidth: '120px !important',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#000',
            textDecoration: 'underline'
        },
    },
    confirmBtn: {
        color: '#fff !important',
        fontSize: '16px',
        border: 'none',
        borderRadius: 'var(--buttonBorderRadius)!important',
        backgroundColor: 'var(--buttonColor)!important',
        outline: 'none !important',
        minWidth: '120px !important',
        textTransform: 'none',
    }
}));
const LoginForm = (props:any) => {

    const { t } = useTranslation();
    const classes = { ...useStyles(), ...props.classes}

    const {
        handleSubmit,
        control,
        errors,
    } = useForm({
        mode: 'onChange',
        defaultValues: { ...props.defaultFormValues }
    });

    const onSubmit = (data: any) => {
        props.onSubmit(data)
    }
    return (
        <div className={`login-form ${classes?.formElement}`}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off'>
                <div>
                    <Controller
                        control={control}
                        name='userName'
                        defaultValue={''}
                        data-testid='email'
                        as={
                            <TextField
                                required={true}
                                id='userName'
                                label={t('loginForm.labels.email')}
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes?.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors?.userName?.message}
                            />
                        }
                        rules={{
                            required: t('common.validationMsg.requiredField') as string,
                            validate: (value: any) => {
                                return validateEmail(value) ? true : t('common.validationMsg.email') as string
                            }
                        }}
                    />
                    {errors?.userName?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.userName?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='codeWord'
                        defaultValue={''}
                        data-testid='codeWord'
                        as={
                            <TextField
                                type='password'
                                name='codeWord'
                                required={true}
                                id='password'
                                label={t('loginForm.labels.password')}
                                placeholder=''
                                helperText=''
                                defaultValue={''}
                                fullWidth={true}
                                margin='normal'
                                classes={{ root: classes.selectFormControl }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant='outlined'
                                error={errors?.codeWord?.message}
                            />
                        }
                        rules={{
                            required: t('common.validationMsg.requiredField') as string,
                        }}
                    />
                    {errors?.codeWord?.message && <span className='reg-form-error-container'
                        style={{ color: 'red' }}>{errors?.codeWord?.message}<br /></span>}
                </div>
                <div>
                    <div className='social-impact-btn-group'>
                        <div className='social-impact-btn-group'>
                            <div className=''>
                                <button className='btn-link'
                                    type='button'
                                    style={{
                                        textDecoration: 'underline',
                                        fontWeight: 'bold',
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily:'var(--appFont)'
                                    }}
                                    onClick={() => {
                                    analytics.trackEventInfo({
                                            type: tracker.EVENTS.CLICK,
                                            actionOn: tracker.ACTION_ON.RESET__BUTTON.name,
                                            location:ANALYTICS.login.loginPage
                                    });

                                      props.history.push(`${contextPath}/Public/forgotPassword`)
                                    }}>
                                    {t('loginForm.labels.resetPwdLinkTxt')}
                                </button>
                            </div>
                            <button className={`sip-submit-btn go-btn-next`}
                                data-testid='login'
                                style={{
                                    minWidth: '120px', width: 'auto', marginTop: '24px',
                                    textTransform: 'none', padding: '0px 20px 0px 20px',
                                    fontFamily: 'var(--appFont)'
                                }}
                                type='submit'>
                                {t('loginForm.singnBtn')}
                            </button>
                        </div>
                        <div className=''
                            style={{ marginTop: '20px', fontFamily: 'var(--appFont)', fontSize: '14px',}}>
                            <Trans i18nKey='loginForm.labels.registerLinkTxt'>
                                New here? <NavLink to={`${contextPath}/Public/register/eligibilityQuiz`}
                                    className='link-subheader'>Register</NavLink>
                            </Trans>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default withRouter(LoginForm);
