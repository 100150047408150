import  {useEffect, useState} from 'react';
import _debounce from 'lodash/debounce';
import { useNavigate, useLocation } from 'react-router';
import {
    StoreSelector,
    WizardFooter,
    WizardHeader
} from '../ngoNcCreateEvents/ngoNcSelectStoreComp';
import { contextPath } from 'src/constants';
interface NcIDProps {
    pathname: string;
    state: {
        ncid: any;
        orgid: any;
    }
}

const NgoNcEditSelectStoreComp = () => {
    const [continueEnabler, setContinueEnabler] = useState(true);
    const [selectedStore, getSelectedStore] = useState([]);
    const history = useNavigate();
    const {state} = useLocation() as NcIDProps;
    const ncid = state?.ncid;
    const spaceOrganizationid = state?.orgid;
    const headerSection = {
        title: 'Add Store',
        progressTrackerItem: ['Select Store', 'Date and Time', 'Summary'],
        activeIndex: 0,
        prevPath: `${contextPath}/space-donation/events/ngo-detail`,
        ncId: ncid,
        orgid: spaceOrganizationid,
    }
    useEffect(() =>{
        if(ncid === undefined || spaceOrganizationid === undefined){
            history(`${contextPath}/space-donation/event/`
            )
        }
    },[ncid, spaceOrganizationid])

    return (
        <div>
            <WizardHeader {...headerSection} />
            <StoreSelector
                getSelectedStore={getSelectedStore}
                setContinueEnabler={setContinueEnabler}
                ncId={ncid}
                spaceOrganizationId={spaceOrganizationid}
            />
            <WizardFooter
                selectedStore={selectedStore}
                isContinueDisabled={continueEnabler}
                ncId={ncid}
                spaceOrganizationId={spaceOrganizationid}
                prevPath={`${contextPath}/space-donation/events/ngo-detail`}
                continuePath={`${contextPath}/space-donation/edit/store-list`}
            />
        </div>
    )
}

export default NgoNcEditSelectStoreComp;
