import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    title: {
        fontWeight: 'bold'
    },
}))

export interface SectionContainerProps {
    title: string,
    children: React.ReactNode
}


export const SectionContainer: FC<SectionContainerProps> = (props) => {
    const { title, children } = props;
    const classes = useStyles();


    return (<div className={classes.root}>
        <Grid container={true} spacing={3} xs={12}>
            <Grid item={true} xs={12}>
                <Typography classes={{ root: classes.title }}>{title}</Typography>
            </Grid>
            <Grid item={true} xs={12}>
                {
                    children
                }
            </Grid>

        </Grid>
    </div>

    );
}