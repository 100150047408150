
import {
    GridColumn, TextField
} from "@walmart-web/livingdesign-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateWalmartEmail } from '../../common/utils';

const StoreContactDetails = (props: any) => {
    const { t } = useTranslation();
    const { disabled, readOnly, contactDetails, setContactDetails,isLocationMandatory, 
            isStoreSelected = false, languageObj = "emergencyDonations" } = props;
    const [primaryContact, setPrimaryContact] = useState<string>(contactDetails?.primaryEmail);
    const [primaryContactErrorMessage, setPrimaryContactErrorMessage] = useState<string>("");
    useEffect(() => {
        if (contactDetails.primaryEmailError) {
            setPrimaryContactErrorMessage(contactDetails.primaryEmailError);
        }
    },[contactDetails.primaryEmailError]);
   
    const [secondaryContact, setSecondaryContact] = useState<string>(contactDetails?.secondaryEmail);
    const [secondaryContactErrorMessage, setSecondaryContactErrorMessage] = useState<string>(contactDetails?.secondaryEmailError);
    const onPrimaryContactEntered = (event: any) => {
        let value = event.target.value;
        if (value.length === 0) {
            setPrimaryContactErrorMessage(isStoreSelected ? t(`${languageObj}.location.primaryEmailError`) : "");
        } else {
            setPrimaryContactErrorMessage(validateWalmartEmail(value) ? "" : t(`${languageObj}.location.emailError`));
        }
        setPrimaryContact(value);
        setContactDetails({ ...contactDetails, primaryEmail: value, primaryEmailError: "" });
    }

    const onSecondaryContactEntered = (event: any) => {
        let value = event.target.value;
        if (value.length > 0) {
            setSecondaryContactErrorMessage(validateWalmartEmail(value) ? "" : t(`${languageObj}.location.emailError`));
        } else {
            setSecondaryContactErrorMessage("");
        }
        setSecondaryContact(value);
        setContactDetails({ ...contactDetails, secondaryEmail: value, secondaryEmailError: "" });
    }

    return (
        <>
            <GridColumn
                sm={6}
                style={{ margin: "15px 0px", padding: "0px 9px 0px 0px" }}>
                <TextField
                    label={`${t(`${languageObj}.location.primaryEmail`)} ${isStoreSelected || isLocationMandatory ? "*" : ""}`}
                    error={primaryContactErrorMessage}
                    size='large'
                    readOnly={readOnly}
                    disabled={disabled}
                    data-testid='primary-email-id'
                    value={primaryContact}
                    onChange={onPrimaryContactEntered}
                />
            </GridColumn>
            <GridColumn
                sm={6}
                style={{ margin: "15px 0px", padding: "0px 10px" }}>
                <TextField
                    label={`${t(`${languageObj}.location.secondaryEmail`)}`}
                    error={secondaryContactErrorMessage}
                    size='large'
                    readOnly={readOnly}
                    disabled={disabled}
                    data-testid='secondary-email-id'
                    value={secondaryContact}
                    onChange={onSecondaryContactEntered}
                />
            </GridColumn>
        </>

    )
}

export default StoreContactDetails;