import * as React from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { GlobalDonationState } from '../../models/globalDonationState';

const styles: any = (_theme: Theme) => ({
    buttonStyles: {
        marginBottom: 10,
        color: '#fff !important',
        background: '#007bff !important',
        borderRadius: '20px',
        textAlign: 'center',
        textTransform: 'none',
        minWidth:'80px'
    },
    buttonContainer:{
        justifyContent: 'center !important',
    }
})

interface OwnProps {
    showDialog: boolean;
    ok: () => void;
    title: string;
    message: string;
    isAsdaTheme?: boolean;
}

export interface StateProps {
    selectedLang: string;
    translationData: any;
}

const appi18n: any = {
    buttonText:{
        en:'Ok',
        es:'Okay',
        fr: 'D\'accord',
        ja:'OK',
        zh:'好'
    }
}

class SuccessAlertDailog extends React.PureComponent<OwnProps & StateProps & any>{

    public translationData: any;

    public render() {
        const { classes } = this.props;
        return <Dialog
                disableEnforceFocus={true}
                disableBackdropClick={true}
                open={this.props.showDialog}
                onClose={this.props.ok}
                aria-labelledby='confirm-dialog'
                aria-describedby='confirm-dialog'
            >
                <DialogTitle id='confirm-dialog'>{this.props.title
                || 'Successfully! Submitted'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='confirm-dialog-description'>
                        {this.props.message ||
                            'Donation has been submitted successfully!'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                className={classes.buttonContainer}>
                    <Button
                        className={`${this.props.isAsdaTheme ? 'confirm-btn-asda' : classes.buttonStyles}`}
                        onClick={this.props.ok} color='primary' autoFocus={true}>
                        {appi18n.buttonText[this.props.selectedLang]}
                    </Button>
                </DialogActions>
            </Dialog>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    translationData: state.translationState,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)((SuccessAlertDailog)));