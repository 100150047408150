import { LoggedInUserActions, LoggedInUserActionTypes } from './loggedInUserActions';
import { LoggedInUserState } from '../../models/loggedInUserState';

export const defaultLoggedInUserState: LoggedInUserState = {
    userDetail: {},
    loggedInUserloading: false,
    loggedInUserLoadingSuccess: false,
    loggedInUserLoadingFailure: false
};

export const loggedInUserReducer = (state: LoggedInUserState = defaultLoggedInUserState, action: LoggedInUserActions):
    LoggedInUserState => {
    switch (action.type) {
        case LoggedInUserActionTypes.LOGGED_IN_USER_DATA_LOAD: return {
            ...state,
            loggedInUserloading: true,
            loggedInUserLoadingSuccess: false,
            loggedInUserLoadingFailure: false,
        };
        case LoggedInUserActionTypes.LOGGED_IN_USER_LOAD_SUCCESS: return {
            ...state,
            userDetail: action.data,
            loggedInUserloading: false,
            loggedInUserLoadingFailure: false,
            loggedInUserLoadingSuccess: true,
        };
        case LoggedInUserActionTypes.LOGGED_IN_USER_LOAD_FAILURE: return {
            ...state,
            loggedInUserloading: false,
            loggedInUserLoadingSuccess: false,
            loggedInUserLoadingFailure: true,
        };
        default: return state;
    }
}