

import { Calendar } from 'primereact/calendar';
import FormHelperText from '@mui/material/FormHelperText';
const TimePickerComponent = (props: any) => {
    const { attributeName = "", errorMessage = "", eventTime, updateTime,
        hourFormat = "12", stepMinute = 30, minDate = null,
        isDisabled = false } = props;

    const onTimeChange = (event: any) => {
        updateTime(event, attributeName);
    };

    return (
        <>
            <Calendar id={`calendar-${attributeName}-timeonly`}
                value={eventTime}
                onChange={onTimeChange}
                timeOnly
                hourFormat={hourFormat}
                readOnlyInput
                stepMinute={stepMinute}
                minDate={minDate} 
                disabled={isDisabled}
                className={`custom-calendar p-column-filter ${errorMessage.length > 0 ? 'higlightCalendar' : ''}`}/>
            {errorMessage ? <FormHelperText className="helperText">{errorMessage}</FormHelperText> : ""}
        </>
    );
}
export default TimePickerComponent;