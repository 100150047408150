import * as React from 'react';
import SubmissionGridComponent from 'dynamic-form-components/lib/components/SubmissionGridComponent';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { Button, createMuiTheme, MuiThemeProvider, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { GlobalDonationState, UiHubProps, Privileges } from './../../models/globalDonationState';
import { GetColumnConfigPayload } from 'dynamic-form-components/lib/models/SubmissionModel';
import { getColumnConfig } from 'dynamic-form-components/lib/state/submission/submissionDataActions';
import { landigPageUrl, languageTranslations } from '../../constants';
import { loggedInUserData } from 'src/state/loggedInUser/loggedInUserActions';
import { changeSelectedUser, addOrgUser, editOrgUser, removeOrgUser, sendTemporaryPassword, setAsPrimaryOrgUser } from 'src/state/orgUserManagement/orgUserManagementActions';
import GlobalDonationService, { Service } from '../../service';
import { gridTranslations } from 'src/static/GridTranslations';
import LoaderComponent from 'src/components/LoaderComponent';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OrgUserAddEditDialog from './OrgUserAddEditDialog';
import OrgUserOtherActionsConfirmDialog from './OrgUserOtherActionsConfirmDialog';
import { orgUserManagementTranslations } from '../../constants';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { loggedInUser } from '../../components/donations/utils'
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
export let service: Service = GlobalDonationService;
const { getLoggedInUserDetail } = service;

export interface OwnProps {
    classes?: any;
    appi18n: any;
    formLink: string;
    remoteDataUri?: string;
    privileges: Privileges;
    uiHubProps: UiHubProps;
    showViewDiagramDialog?: boolean;
}

export interface DispatchProps {
    getColumnConfig: (data: any) => void;
    getLoggedInUserDetail: () => void;
    setSelectedUserInGrid: (data: any) => void;
    addUserForOrg: (data: any) => void;
    editUserForOrg: (data: any) => void;
    removeUserForOrg: (data: any) => void;
    setAsPrimaryUserForOrg: (data: any) => void;
    sendTemporaryPasswordForOrgUser: (data: any) => void;
}

export interface StateProps {
    columns: any[];
    selectedLang: string;
    loggedInUserData: any;
    orgUserManagementState: any;
}

const styles = {
    buttonStyles: {
        marginBottom: 20
    }
}

const appi18n: any = {
    ...gridTranslations,
    ...orgUserManagementTranslations,
    gridTitle: {
        en: 'User Management',
        es: 'Gestión de usuarios',
        fr: 'Gestion des utilisateurs',
        ja: 'ユーザー管理',
        zh: '用戶管理'
    }
}
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#4caf50',
        },
        secondary: {
            main: '#ff9100',
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                width: 'auto !important'
            }
        },
        MuiSelect: {
            selectMenu: {
                minWidth: '150px',
                whiteSpace: 'pre-wrap'
            }
        },
        MuiInputBase: {
            input: {
                minWidth: '80px'
            }
        }
    }
});

const rowMenuOptionsStyle = {
    fontFamily: 'Bogle',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.09px'
};

class OrgUserManagementGrid extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state = {
        listAlias: 'orgUserManagementExternal',
        columns: [],
        options: {},
        addOrgUserClicked: false,
        editOrgUserClicked: false,
        isAsdaTheme: false,
        editOrgUserData: {},
        removeOrgUserClicked: false,
        removeOrgUserData: {},
        setAsPrimaryOrgUserClicked: false,
        setAsPrimaryOrgUserData: {},
        sendTemporaryPasswordClicked: false,
        sendTemporaryPasswordOrgUserData: {},
        anchorEl: null,
        editOrgOldEmailId: '',
    }
    public componentDidMount() {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.organization.organizationUserManagementPage);
    }
    private formOptions: any;

    public UNSAFE_componentWillMount() {
        this.props.getColumnConfig({
            listAlias: this.state.listAlias,
        });
        this.props.setSelectedUserInGrid({});
        this.setState({
            options: {
                exportButton: false,
                debounceInterval: 400,
                minBodyHeight: '300px',
                maxBodyHeight: '721px',
                headerStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                    color: '#041f41',
                },
                rowStyle: {
                    backgroundColor: '#ffffff',
                    fontFamily: 'Bogle',
                    fontSize: '14px',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: '0.09px',
                },
                filtering: false,
                search: false
            }
        })
    }
    public UNSAFE_componentWillReceiveProps(newProps: StateProps) {
        this.formOptions = {
            noAlerts: true,
            language: newProps.selectedLang
        }
        const columns: any = [];
        if ((newProps.columns !== this.props.columns && newProps.columns.length > 0) ||
            (newProps.selectedLang !== this.props.selectedLang) ||
            (newProps.orgUserManagementState.selectedOrgUserData !== this.props.orgUserManagementState.selectedOrgUserData)
            || (newProps.loggedInUserData !== this.props.loggedInUserData)) {
            newProps.columns.map((column: any) => {
                let customCoulmns: any = {};
                if (column.field === 'prefLanguage') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            if (rowData.prefLanguage) {
                                return languageTranslations[rowData.prefLanguage][this.props.selectedLang];
                            }
                            else {
                                return '';
                            }
                        }
                    }
                }
                if (column.field === 'orgUserType') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            if (rowData.orgUserType && rowData.orgUserType === 'PRIMARY') {
                                return <div style={{ color: 'green' }}>
                                    <b>{appi18n.primaryOrgUserLabel[this.props.selectedLang]}</b>
                                </div>
                            }
                            else if (rowData.orgUserType && rowData.orgUserType === 'ADDITIONAL') {
                                return <div style={{ color: '0071ce' }}>
                                    <b>{appi18n.additionalOrgUserLabel[this.props.selectedLang]}</b>
                                </div>
                            }
                            else {
                                return '';
                            }
                        }
                    }
                }
                if (column.field === 'action') {
                    customCoulmns = {
                        ...column,
                        filtering: false,
                        render: (rowData: any) => {
                            if (rowData.orgUserType === 'ADDITIONAL' ||
                                (this.props.loggedInUserData && this.props.loggedInUserData.primary)) {
                                return <div>
                                    <Tooltip title={appi18n.seeActions[this.props.selectedLang]} arrow={true}>
                                        <IconButton
                                            aria-label='more'
                                            aria-controls='long-menu'
                                            aria-haspopup='true'
                                            style={{ color: 'black' }}
                                            id={`moreActions-${rowData.emailId}`}
                                            key={`moreActions-${rowData.emailId}`}
                                            onClick={(event: any) => { this.handleMoreActionMenuClick(rowData, event) }}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        id={`simple-menu-${rowData.emailId}`}
                                        key={`simple-menu-${rowData.emailId}`}
                                        anchorEl={(Boolean(this.state.anchorEl))
                                            ? this.getAnchorEl(`moreActions-${rowData.emailId}`) : null}
                                        keepMounted={true}
                                        open={Boolean(this.state.anchorEl)
                                            && newProps.orgUserManagementState.selectedOrgUserData
                                            && newProps.orgUserManagementState.selectedOrgUserData.emailId
                                            && newProps.orgUserManagementState.selectedOrgUserData.emailId === rowData.emailId
                                            && !(this.props.orgUserManagementState.addOrgUserLoading
                                                || this.props.orgUserManagementState.editOrgUserLoading
                                                || this.props.orgUserManagementState.removeOrgUserLoading
                                                || this.props.orgUserManagementState.sendTemporaryPasswordLoading
                                                || this.props.orgUserManagementState.setAsPrimaryOrgUserLoading
                                                || this.props.orgUserManagementState.addOrgUserSuccess
                                                || this.props.orgUserManagementState.addOrgUserFailure
                                                || this.props.orgUserManagementState.editOrgUserSuccess
                                                || this.props.orgUserManagementState.editOrgUserFailure
                                                || this.props.orgUserManagementState.removeOrgUserSuccess
                                                || this.props.orgUserManagementState.removeOrgUserFailure
                                                || this.props.orgUserManagementState.sendTemporaryPasswordSuccess
                                                || this.props.orgUserManagementState.sendTemporaryPasswordFailure
                                                || this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess
                                                || this.props.orgUserManagementState.setAsPrimaryOrgUserFailure)
                                        }
                                        onClose={this.setInitialStateValuesForActions}>
                                        <MenuItem
                                            id={`edit-${rowData.emailId}`}
                                            key={`edit-${rowData.emailId}`}
                                            onClick={() => this.handleEditOrgUserClick(rowData)}>
                                            <IconButton aria-label='edit' style={{ color: 'black' }}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                            <span style={rowMenuOptionsStyle}>
                                                {appi18n.edit[this.props.selectedLang]}
                                            </span>
                                        </MenuItem>
                                        {this.isPrimaryOrSelfAdditionalUser(rowData)
                                            ? <MenuItem
                                                id={`remove-${rowData.emailId}`}
                                                key={`remove-${rowData.emailId}`}
                                                onClick={() => this.handleRemoveOrgUserClick(rowData)}>
                                                <IconButton aria-label='remove' style={{ color: 'red' }}>
                                                    <RemoveCircleIcon fontSize='small' />
                                                </IconButton>
                                                <span style={rowMenuOptionsStyle}>
                                                    {appi18n.remove[this.props.selectedLang]}
                                                </span>
                                            </MenuItem> : <></>}
                                        {this.isPrimaryOrSelfAdditionalUser(rowData)
                                            ? <MenuItem
                                                id={`sendTemporaryPassword-${rowData.emailId}`}
                                                key={`sendTemporaryPassword-${rowData.emailId}`}
                                                onClick={() => this.handleSendTemporaryPasswordClick(rowData)}>
                                                <IconButton aria-label='sendTemporaryPassword' style={{ color: 'black' }}>
                                                    <EmailIcon fontSize='small' />
                                                </IconButton>
                                                <span style={rowMenuOptionsStyle}>
                                                    {appi18n.sendTemporaryPassword[this.props.selectedLang]}
                                                </span>
                                            </MenuItem> : <></>}
                                        {(rowData.allowedToSwitchUserType)
                                            ? <MenuItem
                                                id={`setAsPrimary-${rowData.emailId}`}
                                                key={`setAsPrimary-${rowData.emailId}`}
                                                onClick={() => this.handleSetAsPrimaryOrgUserClick(rowData)}>
                                                <IconButton aria-label='setAsPrimary' style={{ color: 'black' }}>
                                                    <PersonIcon fontSize='small' />
                                                </IconButton>
                                                <span style={rowMenuOptionsStyle}>
                                                    {appi18n.setAsPrimary[this.props.selectedLang]}
                                                </span>
                                            </MenuItem> : <></>}
                                    </Menu>
                                </div>
                            }
                            else {
                                return <></>
                            }
                        }
                    }
                }
                columns.push({
                    ...column,
                    ...customCoulmns
                })
            })
            this.setState({ columns })
        }
    }
    private isPrimaryOrSelfAdditionalUser = (rowData: any) => {
        if ((loggedInUser && (loggedInUser !== rowData?.emailId)) &&
            (rowData.orgUserType && rowData.orgUserType !== 'PRIMARY')) {
            return true
        }
        return false
    }
    private getAnchorEl(id: string) {
        return document.getElementById(id);
    }
    private handleMoreActionMenuClick = (rowData: any, event: any) => {
        this.props.setSelectedUserInGrid({
            ...rowData
        })
        this.setState({
            anchorEl: event.currentTarget
        });
    }
    private handleAddNewOrgUserClick = () => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.ADD_NEW_USER_FOR_ORG_USER_MANAGEMENT_BUTTON.name,
            location: ANALYTICS.organization.organizationUserManagementPage
        });
        this.props.setSelectedUserInGrid({});
        this.setState({
            addOrgUserClicked: true,
            editOrgUserClicked: false,
            editOrgUserData: {}
        });
    }
    private handleEditOrgUserClick = (data: any) => {
        this.setState({
            addOrgUserClicked: false,
            editOrgUserClicked: true,
            editOrgUserData: data
        });
    }

    private handleRemoveOrgUserClick = (data: any) => {
        this.setState({
            removeOrgUserClicked: true,
            removeOrgUserData: data
        });
    }

    private handleSendTemporaryPasswordClick = (data: any) => {
        this.setState({
            sendTemporaryPasswordClicked: true,
            sendTemporaryPasswordOrgUserData: data
        });
    }

    private handleSetAsPrimaryOrgUserClick = (data: any) => {
        this.setState({
            setAsPrimaryOrgUserClicked: true,
            setAsPrimaryOrgUserData: data,
        });
    }

    private setInitialStateValuesForActions = () => {
        if (this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess) {
            this.props.getLoggedInUserDetail();
        }
        this.props.setSelectedUserInGrid({
            gridRefresh: (this.props.orgUserManagementState.addOrgUserSuccess
                || this.props.orgUserManagementState.editOrgUserSuccess
                || this.props.orgUserManagementState.removeOrgUserSuccess
                || this.props.orgUserManagementState.removeOrgUserFailure
                || this.props.orgUserManagementState.sendTemporaryPasswordSuccess
                || this.props.orgUserManagementState.sendTemporaryPasswordFailure
                || this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess
                || this.props.orgUserManagementState.setAsPrimaryOrgUserFailure)
        });
        this.setState({
            addOrgUserClicked: false,
            editOrgUserClicked: false,
            isAsdaTheme: false,
            editOrgUserData: {},
            removeOrgUserClicked: false,
            removeOrgUserData: {},
            setAsPrimaryOrgUserClicked: false,
            setAsPrimaryOrgUserData: {},
            sendTemporaryPasswordClicked: false,
            sendTemporaryPasswordOrgUserData: {},
            anchorEl: null,
            editOrgOldEmailId: ''
        })
    }

    private submitOrgUserData = (data: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.SUBMIT_USER_MANAGEMENT_DATA.name,
            location: ANALYTICS.organization.organizationUserManagementPage
        });
        if (data && data.editOfOrgUser) {
            this.props.editUserForOrg({
                ...data,
                // @ts-ignore
                oldEmailId: this.state.editOrgOldEmailId
                    ? this.state.editOrgOldEmailId
                    // @ts-ignore
                    : this.state.editOrgUserData.emailId
            });
            this.setState({
                editOrgUserData: data,
                editOrgOldEmailId: this.state.editOrgOldEmailId
                    ? this.state.editOrgOldEmailId
                    // @ts-ignore
                    : this.state.editOrgUserData.emailId
            });
        }
        else {
            this.props.addUserForOrg(data);
        }
    }

    private handleRemoveOrgUserModalClose = (data: any) => {
        this.props.removeUserForOrg(data);
    }

    private handleSetAsPrimaryOrgUserModalClose = (data: any) => {
        this.props.setAsPrimaryUserForOrg(data);
    }

    private handleSendTemporaryPasswordForOrgUserModalClose = (data: any) => {
        this.props.sendTemporaryPasswordForOrgUser(data);
    }

    public render() {
        const { classes } = this.props;
        let createBtn: any;
        createBtn = (this.props.loggedInUserData)
            ? <Button className={`back-btn ${this.props.classes.buttonStyles}`}
                variant='contained' color='primary'
                onClick={this.handleAddNewOrgUserClick}>
                {appi18n.addNewOrgUserLabel[this.props.selectedLang]}
            </Button> : <></>
        let errorMessage = '';
        let successMessage = '';
        if (this.props.orgUserManagementState.addOrgUserFailure) {
            errorMessage = (this.props.orgUserManagementState.addOrgUserValidationError
                && this.props.orgUserManagementState.addOrgUserErrorMessage)
                ? this.props.orgUserManagementState.addOrgUserErrorMessage
                : appi18n.orgUserGenericError[this.props.selectedLang];
        }
        else if (this.props.orgUserManagementState.editOrgUserFailure) {
            errorMessage = (this.props.orgUserManagementState.editOrgUserValidationError
                && this.props.orgUserManagementState.editOrgUserErrorMessage)
                ? this.props.orgUserManagementState.editOrgUserErrorMessage
                : appi18n.orgUserGenericError[this.props.selectedLang];
        }
        else if (this.props.orgUserManagementState.removeOrgUserFailure) {
            errorMessage = (this.props.orgUserManagementState.removeOrgUserValidationError
                && this.props.orgUserManagementState.removeOrgUserErrorMessage)
                ? this.props.orgUserManagementState.removeOrgUserErrorMessage
                : appi18n.orgUserGenericError[this.props.selectedLang];
        }
        else if (this.props.orgUserManagementState.sendTemporaryPasswordFailure) {
            errorMessage = (this.props.orgUserManagementState.sendTemporaryPasswordValidationError
                && this.props.orgUserManagementState.sendTemporaryPasswordErrorMessage)
                ? this.props.orgUserManagementState.sendTemporaryPasswordErrorMessage
                : appi18n.orgUserGenericError[this.props.selectedLang];
        }
        else if (this.props.orgUserManagementState.setAsPrimaryOrgUserFailure) {
            errorMessage = (this.props.orgUserManagementState.setAsPrimaryOrgUserValidationError
                && this.props.orgUserManagementState.setAsPrimaryOrgUserErrorMessage)
                ? this.props.orgUserManagementState.setAsPrimaryOrgUserErrorMessage
                : appi18n.orgUserGenericError[this.props.selectedLang];
        }
        else {
            errorMessage = '';
        }

        if (this.props.orgUserManagementState.addOrgUserSuccess) {
            successMessage = (this.props.orgUserManagementState.addOrgUserSuccess)
                ? appi18n.addOrgUserSuccessMessage[this.props.selectedLang]
                : '';
        }
        else if (this.props.orgUserManagementState.editOrgUserSuccess) {
            successMessage = (this.props.orgUserManagementState.editOrgUserSuccess)
                ? appi18n.editOrgUserSuccessMessage[this.props.selectedLang]
                : '';
        }
        else if (this.props.orgUserManagementState.removeOrgUserSuccess) {
            successMessage = (this.props.orgUserManagementState.removeOrgUserSuccess)
                ? appi18n.removeOrgUserSuccessMessage[this.props.selectedLang]
                : '';
        }
        else if (this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess) {
            successMessage = (this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess)
                ? appi18n.setAsPrimaryOrgUserSuccessMessage[this.props.selectedLang]
                : '';
        }
        else if (this.props.orgUserManagementState.sendTemporaryPasswordSuccess) {
            successMessage = (this.props.orgUserManagementState.sendTemporaryPasswordSuccess)
                ? appi18n.sendTemporaryPasswordSuccessMessage[this.props.selectedLang]
                : '';
        }
        else {
            successMessage = '';
        }

        if (this.props.orgUserManagementState.addOrgUserSuccess
            || this.props.orgUserManagementState.editOrgUserSuccess
            || this.props.orgUserManagementState.removeOrgUserSuccess
            || this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess
            || this.props.orgUserManagementState.sendTemporaryPasswordSuccess
            || this.props.orgUserManagementState.removeOrgUserFailure
            || this.props.orgUserManagementState.setAsPrimaryOrgUserFailure
            || this.props.orgUserManagementState.sendTemporaryPasswordFailure) {
            setTimeout(() => {
                this.setInitialStateValuesForActions();
            }, 1000);
        }

        return <>
            {createBtn}
            <MuiThemeProvider theme={theme}>
                <SubmissionGridComponent
                    key={this.props.orgUserManagementState.gridDataRefresh}
                    title={appi18n.gridTitle[this.props.selectedLang]}
                    listAlias={this.state.listAlias}
                    columns={this.state.columns}
                    i18n={appi18n}
                    privileges={this.props.privileges}
                    formOptions={this.formOptions}
                    options={this.state.options}
                    selectedLanguage={this.props.selectedLang}
                    remoteDataFunc={this.getData}
                />
            </MuiThemeProvider>
            <OrgUserAddEditDialog
                showDialog={(this.state.addOrgUserClicked && !this.props.orgUserManagementState.addOrgUserSuccess)
                    || (this.state.editOrgUserClicked && !this.props.orgUserManagementState.editOrgUserSuccess)}
                cancel={this.setInitialStateValuesForActions}
                title={(this.state.addOrgUserClicked) ? appi18n.addNewOrgUserLabel[this.props.selectedLang] :
                    ((this.state.editOrgUserClicked) ? appi18n.editOrgUserLabel[this.props.selectedLang] : '')}
                isAsdaTheme={this.state.isAsdaTheme}
                selectedOrgUser={(this.props.orgUserManagementState.addOrgUserFailure
                    || this.props.orgUserManagementState.editOrgUserFailure)
                    ? this.props.orgUserManagementState.failedOrgUserData
                    : ((this.state.editOrgUserClicked) ? this.state.editOrgUserData : {})}
                submitOrgUserData={this.submitOrgUserData}
                editOfOrgUser={this.state.editOrgUserClicked}
                orgId={(this.props.loggedInUserData && this.props.loggedInUserData.id) ? this.props.loggedInUserData.id : ''}
                isPrimaryOrgUserEdit={(this.state.editOrgUserClicked) ?
                    // @ts-ignore
                    (this.state.editOrgUserData && this.state.editOrgUserData.orgUserType === 'PRIMARY') : false}
                isFailure={this.props.orgUserManagementState.addOrgUserFailure || this.props.orgUserManagementState.editOrgUserFailure}
                isLoading={this.props.orgUserManagementState.addOrgUserLoading
                    || this.props.orgUserManagementState.editOrgUserLoading}
                failureMessage={errorMessage}
                countryCode={(this.props.loggedInUserData && this.props.loggedInUserData.countryCode)
                    ? this.props.loggedInUserData.countryCode : ''}
            />
            <OrgUserOtherActionsConfirmDialog
                showDialog={(this.state.removeOrgUserClicked && !(this.props.orgUserManagementState.removeOrgUserSuccess
                    || this.props.orgUserManagementState.removeOrgUserFailure
                    || this.props.orgUserManagementState.removeOrgUserLoading))
                    || (this.state.sendTemporaryPasswordClicked && !(this.props.orgUserManagementState.sendTemporaryPasswordSuccess
                        || this.props.orgUserManagementState.sendTemporaryPasswordFailure
                        || this.props.orgUserManagementState.sendTemporaryPasswordLoading))
                    || (this.state.setAsPrimaryOrgUserClicked && !(this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess
                        || this.props.orgUserManagementState.setAsPrimaryOrgUserFailure
                        || this.props.orgUserManagementState.setAsPrimaryOrgUserLoading))}
                title={(this.state.removeOrgUserClicked) ? appi18n.modal.title.remove[this.props.selectedLang]
                    : ((this.state.sendTemporaryPasswordClicked) ? appi18n.modal.title.sendTemporaryPassword[this.props.selectedLang]
                        : ((this.state.setAsPrimaryOrgUserClicked) ? appi18n.modal.title.setAsPrimary[this.props.selectedLang] : ''))}
                continue={(this.state.removeOrgUserClicked) ? this.handleRemoveOrgUserModalClose
                    : ((this.state.sendTemporaryPasswordClicked) ? this.handleSendTemporaryPasswordForOrgUserModalClose
                        : ((this.state.setAsPrimaryOrgUserClicked) ? this.handleSetAsPrimaryOrgUserModalClose
                            : this.setInitialStateValuesForActions))}
                cancel={this.setInitialStateValuesForActions}
                isAsdaTheme={this.state.isAsdaTheme}
                orgUserData={(this.state.removeOrgUserClicked) ? this.state.removeOrgUserData
                    : ((this.state.sendTemporaryPasswordClicked) ? this.state.sendTemporaryPasswordOrgUserData
                        : ((this.state.setAsPrimaryOrgUserClicked) ? this.state.setAsPrimaryOrgUserData : ''))}
                message={(this.state.removeOrgUserClicked) ? appi18n.modal.message.removeWarningMessage[this.props.selectedLang]
                    : ((this.state.sendTemporaryPasswordClicked)
                        ? appi18n.modal.message.sendTemporaryPasswordWarningMessage[this.props.selectedLang]
                        : ((this.state.setAsPrimaryOrgUserClicked) ? appi18n.modal.message.setAsPrimaryWarningMessage[this.props.selectedLang]
                            : ''))}
            />
            <ErrorSnackbarComponent
                isError={this.props.orgUserManagementState.removeOrgUserFailure
                    || this.props.orgUserManagementState.setAsPrimaryOrgUserFailure
                    || this.props.orgUserManagementState.sendTemporaryPasswordFailure}
                classes={classes} message={errorMessage} />
            <SuccessSnackBarComponent
                isSuccess={this.props.orgUserManagementState.addOrgUserSuccess
                    || this.props.orgUserManagementState.editOrgUserSuccess
                    || this.props.orgUserManagementState.removeOrgUserSuccess
                    || this.props.orgUserManagementState.setAsPrimaryOrgUserSuccess
                    || this.props.orgUserManagementState.sendTemporaryPasswordSuccess}
                classes={classes} message={successMessage} />
            {(this.props.orgUserManagementState.removeOrgUserLoading
                || this.props.orgUserManagementState.sendTemporaryPasswordLoading
                || this.props.orgUserManagementState.setAsPrimaryOrgUserLoading) ? <LoaderComponent fullScreen={true} /> : <></>}
        </>
    };
    private getData = (query: any) => {
        if (this.props.loggedInUserData && Object.keys(this.props.loggedInUserData).length === 0) {
            // @ts-ignore
            return getLoggedInUserDetail().then((resp) => {
                const queryString = `orgId=${resp.id}&pageSize=${query.pageSize}&pageNo=${query.page + 1}`;
                return service.getOrgUserList(queryString).catch(e => {
                    if (e.response && e.response.status === 401) {
                        window.location.href = window.location.origin + landigPageUrl;
                    }
                })
            })
        } else {
            const queryString = `orgId=${this.props.loggedInUserData.id}&pageSize=${query.pageSize}&pageNo=${query.page + 1}`;
            return service.getOrgUserList(queryString).catch(e => {
                if (e.response && e.response.status === 401) {
                    window.location.href = window.location.origin + landigPageUrl;
                }
            })
        }
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    columns: state.submissionState.columnConfig,
    selectedLang: state.selectedLang,
    loggedInUserData: state.loggedInUserState.userDetail,
    orgUserManagementState: state.orgUserManagementState
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    getColumnConfig: (data: GetColumnConfigPayload) => dispatch(getColumnConfig(data)),
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
    setSelectedUserInGrid: (data: any) => dispatch(changeSelectedUser(data)),
    addUserForOrg: (data: any) => dispatch(addOrgUser(data)),
    editUserForOrg: (data: any) => dispatch(editOrgUser(data)),
    removeUserForOrg: (data: any) => dispatch(removeOrgUser(data)),
    setAsPrimaryUserForOrg: (data: any) => dispatch(setAsPrimaryOrgUser(data)),
    sendTemporaryPasswordForOrgUser: (data: any) => dispatch(sendTemporaryPassword(data))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgUserManagementGrid)));