import {FC} from 'react';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    outlinedInfo: {
        color: '#181818',
        border: '1px solid rgba(102, 123, 155, 0.25)',
        boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.04)'
    },
    icon: {
        color: '#181818 !important',
    },
    alertRoot: {
        'padding': '0 16px',
        borderLeft: '4px solid black',
        backgroundColor: '#f1f1f2;'
    }
})

const InformationBar: FC<AlertProps> = (props) => {
    const classes = useStyles();
    return <MuiAlert classes = {{root: classes.alertRoot, outlinedInfo: classes.outlinedInfo, icon: classes.icon}} elevation={6} variant='outlined' {...props} severity='info'/>;
}

export default InformationBar;