import * as yup from 'yup';


import newInstance from '../../../../i18n';
import { getCurrentYear, getTodaysDate } from '../../../../components/common/DateUtils';
import moment from 'moment';

export const defaulOrgData = {
  address: {},
  primaryOrgUser: {},
  certificateSignee: {},
}


export const translate = (key: string) => {
  return newInstance.t(key);
}

export const getAppendixFormValidations = () => {
  const required = translate('orgRegForm.validationMsg.requiredField');
  const stringRule = yup.string().required(required);
  const booleanRule = yup.boolean().required(required).typeError(required);
  const yesNoSchema = (name: string) => (yup.object().shape({
    description: yup.string().test(`${name}-desc`, required,
      (value, context) => {
        return ((!context.parent.value) || (value && value.length > 0)) ? true : false;
      }),
    value: booleanRule
  }))

  const validateEmail = () => {
    return yup.string().required(translate('orgRegForm.validationMsg.requiredField')).email(translate('orgRegForm.validationMsg.email'));
  }

  const todayRule = yup.string().required(required).oneOf([getTodaysDate()], required);

  const validationYOB = 'orgRegForm.validationMsg.yearOfbirth'
  const birthYearRule = yup.number()
    .typeError(translate(validationYOB))
    .required(translate(validationYOB))
    .min(1900, translate(validationYOB))
    .max(getCurrentYear(), translate(validationYOB));

  const acknowledgeRule = yup.boolean()
    .oneOf([true], translate(required));


  const establishmentDateRule = yup.mixed()
    .test('dateValidation', translate('orgRegForm.validationMsg.yearOfbirth'), (value) => {

      if (!moment(value, 'DD-MM-YYYY', true).isValid()) {
        return false;
      }

      const enteredDate = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const todayDate = moment().format('YYYY-MM-DD');

      return moment(enteredDate)
        .isSameOrBefore(todayDate);
    });

  return {
    required,
    stringRule,
    booleanRule,
    yesNoSchema,
    todayRule,
    establishmentDateRule,
    beforeOrTodaysDate: establishmentDateRule,
    dateRule: establishmentDateRule,
    acknowledgeRule,
    birthYearRule,
    validateEmail
  }
}

export const getOrgValidationSchemas = () => {

  const {
    stringRule,
    todayRule,
    validateEmail
  } = getAppendixFormValidations();

  const baseAddress = {
    country: stringRule,
    addressLine1: stringRule,
    postalCode: stringRule
  }
  const address = yup.object().shape(baseAddress)

  const primaryUser = yup.object().shape({
    userName: stringRule,
    userTitle: stringRule,
    userEmail: validateEmail(),
    userTelephone: stringRule
  })

  const addressWithCityState = yup.object().shape({
    ...baseAddress,
    province: stringRule,
    city: stringRule,
  })

  const requestSubmitCertificationSchema = {
    date: todayRule,
    glAccount: stringRule,
    name: stringRule,
    signature: stringRule,
    title: stringRule
  }

  return {
    address,
    addressWithCityState,
    primaryUser,
    requestSubmitCertificationSchema
  }
}
