import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactComponent } from '@formio/react';
import settingsForm from './ComponentFieldSettings';

import FacilitySearchDialog from '../reactComponents/FacilitySearchDialog';

import store from './../../../store';
import { createRoot } from 'react-dom/client';

export default class FacilitySearchComponent extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: 'Facility Search Component',
            icon: 'square',
            group: 'basic',
            documentation: '',
            weight: -10,
            schema: FacilitySearchComponent.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    public static schema() {
        return ReactComponent.schema({
            type: 'facilitySearchComponent',
            label: 'Default Label',
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    public static editForm = settingsForm;

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */
    public attachReact(element: any) {
        const root = createRoot(element);
        return root.render(
            <Provider store={store}>
                <FacilitySearchDialog
                    store={store}
                    title={'Find Facility'}
                    component={this.component} // These are the component settings if you want to use them to render the component.
                    value={this.dataValue} // The starting value of the component.
                    onChange={this.updateValue} // The onChange event to call when the value changes.
                />
            </Provider>
        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    public detachReact(element: any) {
        // console.log('detachReact', element);
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }
}