import { DonationOfMoneyActionTypes, DonationOfMoneyActions } from './donationOfMoneyActions';
import { DonationOfMoneyState } from '../../models/globalDonationState';

export const defaultDonationOfMoneyState: DonationOfMoneyState = {
    organizationAttachments: '',
    donationAttachments: '',
};

export const donationOfMoneyReducer = (state: DonationOfMoneyState = defaultDonationOfMoneyState, action: DonationOfMoneyActions):
    DonationOfMoneyState => {
    switch (action.type) {
        case DonationOfMoneyActionTypes.SAVE_ORG_ATTACHMENT: return {
            ...state,
            organizationAttachments: action.data,
        };
        case DonationOfMoneyActionTypes.SAVE_DONATION_ATTACHMENT: return {
            ...state,
            organizationAttachments: action.data,
        };
        default: return state;
    }
}