import * as React from 'react';
import { navBarClose, navBarOpen } from '../../../../../state/spaceDonation/navbar/navBarActions';

import { connect } from 'react-redux';


export interface Props {
    children: any,
    navBarClose: any,
    navBarOpen: any,
    hideOnMount:boolean,
    showOnUnmount:boolean
}

class SideBarHandler extends React.Component<Props> {
    public componentWillMount() {
        if (this.props.hideOnMount) {
            this.props.navBarClose()
        }
    }
    public componentWillUnmount() {
        if (this.props.showOnUnmount) {
            this.props.navBarOpen();
        }
    }

    public render() {
        return <div>{this.props.children}</div>
    }
}


const mapDispatchToProps = (dispatch: React.Dispatch<any>): any => ({
    navBarClose: () => dispatch(navBarClose()),
    navBarOpen: () => dispatch(navBarOpen())
});

export default connect(null, mapDispatchToProps)(SideBarHandler);