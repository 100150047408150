import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, makeStyles } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DONATION_TYPE } from 'src/constants';

import GoodsAndFoodDonationTab from './goodsAndFoodDonationTab';
import MonetaryDonationTab from './monetaryDonationTab';
import {
    DefaultBenificiariesInformation,
    DefaultCoInvestorsFields,
    DefaultLogisticsAdminQuestions,
    DefaultProjectBeneficiaries,
    DefaultProjectBeneficiariesParticipation,
    DefaultProjectExecution,
    DefaultProjectImpactAndContinuity,
    DefaultProjectJustification,
    DefaultProjectLocation,
    DefaultProjectObjectives,
    DefaultScheduleOfActivities,
    ProjectDetailsProps,
    Sections,
} from './projectDetailsDefaults';
import { GetProjectDetailsValidationSchema } from './projectDetailsValidationSchema';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    formElement: {
        width: '100%'
    }
});



const getProjectData = (projectDetailsData: any, donationType: string) => {
    if (donationType === DONATION_TYPE.MONETARY) {
        const {
            justificationDetails, executionDetails,
            beneficiaries, projectBeneficiariesParticipant,
            impactContinuityDetails, locations,
            projectScheduleOfActivities, coInvestors, observationSummary
        } = projectDetailsData;

        return {
            [Sections.ProjectObjectives]: observationSummary || DefaultProjectObjectives,
            [Sections.ProjectJustification]: justificationDetails || DefaultProjectJustification,
            [Sections.ProjectScheduleOfActivities]: projectScheduleOfActivities ?
                projectScheduleOfActivities.activities : DefaultScheduleOfActivities.activities,
            [Sections.ProjectScheduleOfActivitiesCategory]: projectScheduleOfActivities ?
                projectScheduleOfActivities?.category : null,
            [Sections.ProjectScheduleOfActivitiesSubCategory]: projectScheduleOfActivities ?
                projectScheduleOfActivities?.subCategory : null,
            [Sections.ProjectExecution]: executionDetails || DefaultProjectExecution,
            [Sections.ProjectLocation]: locations || DefaultProjectLocation,
            [Sections.ProjectBeneficiaries]: beneficiaries || DefaultProjectBeneficiaries,
            [Sections.ProjectBeneficiariesParticipation]: projectBeneficiariesParticipant || DefaultProjectBeneficiariesParticipation,
            [Sections.ProjectCoInvestors]: coInvestors || DefaultCoInvestorsFields,
            [Sections.ProjectImpactAndContinuity]: impactContinuityDetails || DefaultProjectImpactAndContinuity,
        };
    } else {
        const {
            locations, beneficiaryInformation,
            beneficiaries, logisticsAdminQuestions
        } = projectDetailsData;
        return {
            [Sections.ProjectLocation]: locations || DefaultProjectLocation,
            [Sections.BeneficiaryInformation]: beneficiaryInformation || DefaultBenificiariesInformation,
            [Sections.ProjectBeneficiaries]: beneficiaries || DefaultProjectBeneficiaries,
            [Sections.LogisticsAdminQuestions]: logisticsAdminQuestions || DefaultLogisticsAdminQuestions,
        }
    }
}



export const ProjectDetails: FC<ProjectDetailsProps> = ({ projectDetailsData, setFormStatus,
    onProjectDetailsSubmit, isFormEditable }) => {
    const classes = useStyles();
    const search = useLocation().search;
    const donationType: string = new URLSearchParams(search).get('type') ?? '';
    const methods = useForm({
        mode: 'onChange', defaultValues: getProjectData(projectDetailsData, donationType),
        resolver: yupResolver(GetProjectDetailsValidationSchema(donationType))
    });

    const { errors } = methods.formState;

    useEffect(() => {
        const timeOut = window.setTimeout(() => {
            methods.trigger();
            window.clearTimeout(timeOut);
        }, 0);
    }, [projectDetailsData])

    const onFormSubmit = (data: any) => {
        onProjectDetailsSubmit(data);
    }

    setFormStatus(errors || {});
    return (<>

        <div className={classes.root}>
            <Grid container={true} spacing={3}>
                <FormProvider {...methods}>
                    <form className={classes.formElement}
                        name='project_details' id='project_details' noValidate={true} autoComplete='off'
                        encType='application/json' onSubmit={methods.handleSubmit(onFormSubmit)}>
                        {donationType === DONATION_TYPE.MONETARY ?
                            <MonetaryDonationTab
                                errors={methods.errors}
                                isFormEditable={isFormEditable ?? false}
                                projectDetailsData={projectDetailsData} />
                            : <GoodsAndFoodDonationTab
                                errors={methods.errors}
                                isFormEditable={isFormEditable ?? false}
                                projectDetailsData={getProjectData(projectDetailsData, donationType)} />
                        }
                    </form>
                </FormProvider>
            </Grid>
        </div>

    </>);
}