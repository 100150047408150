import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@material-ui/core';
import {FC} from 'react';
import clsx from 'clsx';
const useStyles = makeStyles({
    cellHead: {
        fontFamily: 'Bogle',
        fontSize: '14px',
        color: '#000',
    },
    bold: {
        fontWeight: 'bold',
    }

})

export interface MaterialTableProps {
    header: IMTColoumn[]
    getTranslation: (key: string) => string,
    isHeadLight?: boolean,
    children: any
}

export interface IMTColoumn {
    key: string,
    class?: any
    size?: 'small' | 'medium'
    required?: boolean,
}

const MaterialTable: FC<MaterialTableProps> = ({ header, getTranslation,isHeadLight, children}: MaterialTableProps) => {
    const classes = useStyles();

    return (<TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        {
                            header.map((column: any, index: number) => {
                                return (<TableCell key={`header_${index}`}
                                    role = 'heading'
                                    classes={{ head: isHeadLight ? classes.cellHead: clsx(classes.cellHead, classes.bold)}}
                                    size = {column.size ?? 'medium'}>
                                    {column.key === 'actions' ? null : getTranslation(column.key)}
                                    {column.required && '*'}
                                </TableCell>)
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
            </TableContainer>);
}

export default MaterialTable;