import { Action } from 'redux';

export enum DownloadFileDataActionTypes {
    DOWNLOAD_FILE_DATA = 'DOWNLOAD_FILE_DATA',
    DOWNLOAD_FILE_DATA_SUCCESS = 'DOWNLOAD_FILE_DATA_SUCCESS',
    DOWNLOAD_FILE_DATA_FAILURE = 'DOWNLOAD_FILE_DATA_FAILURE'
}

export interface DownloadFileData extends Action {
    type: DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA;
    data: any;
}

export const downloadFileData = (data: any): DownloadFileData => ({
    type: DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA,
    data
});

export interface DownloadFileDataLoadSuccess extends Action {
    type: DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA_SUCCESS;
    data: any;
}

export const downloadfileDataLoadSuccess = (data: any): DownloadFileDataLoadSuccess => ({
    type: DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA_SUCCESS,
    data
});

export interface DownloadFileDataLoadFailure extends Action {
    type: DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA_FAILURE;
    data?: string;
}

export const downloadfileDataLoadFailure = (data: string): DownloadFileDataLoadFailure => ({
    type: DownloadFileDataActionTypes.DOWNLOAD_FILE_DATA_FAILURE,
    data
});

export type DownloadFileDataActions = DownloadFileData | DownloadFileDataLoadSuccess | DownloadFileDataLoadFailure;