import { Theme, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { i18n as i18nI } from 'i18next';
import React, { Dispatch } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import { selectedLang } from '../../state/globalDonationActions';
import { GlobalDonationState } from './../../models/globalDonationState';

// tslint:disable-next-line: no-shadowed-variable
const styles: any = (theme: Theme) => ({
    languageSelectRoot: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontFamily: ' var(--appFont)'
    },
    languageSelectIcon: {
        color: '#fff'
    },
    langSelectFormControl: {
        color: '#fff',
        minWidth: '150px',
        fontFamily: ' var(--appFont)'
    },
    languageSelectLabel: {
        color: '#fff',
        fontFamily: ' var(--appFont)'
    }
})

const appI18n: any= {
    languageChangeLabel:{
        en: 'Change Language',
        es: 'Cambiar idioma',
        fr: 'Changer de langue',
        zh: '改变语言',
        ja: '言語を変更'
    }
}
export interface OwnProps {
    languages: any;
    i18n: i18nI;
    countryCode:string;
}

export interface StateProps {
    selectedValue: string
}
export interface DispatchProps {
    changeLanguage: (data: any) => void;
}

class LangSelectComponent extends React.Component<OwnProps & DispatchProps & StateProps & RouteComponentProps & any>{

    private handleChange = (event:any) => {
        this.props.changeLanguage(event?.target?.value);

        this.props?.countryCode==='MX' ?
        this.props.i18n.changeLanguage(`${event.target.value}_${this.props?.countryCode}`) :
            this.props.i18n.changeLanguage(event.target.value)
    };

    public render() {
        const { classes } = this.props;
        // @ts-ignore
        const menuItems: React.ReactElement[] = [];
        return (
            <div>
                <FormControl className={classes.langSelectFormControl}>
                    <InputLabel classes={{ root: classes.languageSelectLabel }} id='demo-simple-select-label'>
                        {appI18n.languageChangeLabel[window.lang]}
                    </InputLabel>
                    <Select classes={{ root: classes.languageSelectRoot, icon: classes.languageSelectIcon }}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={this.props.selectedValue}
                        onChange={this.handleChange}
                    >
                    {this.props.languages.map((item:any) => {
                        menuItems.push(<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    })}
                    {menuItems}
                    </Select>
                </FormControl>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    changeLanguage: (data: any) => dispatch(selectedLang(data))
});

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedValue: state.selectedLang
})
export default withTranslation()
(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)( LangSelectComponent))));
