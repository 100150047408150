import { Navigate, useLocation } from 'react-router-dom';
import {contextPath} from '../constants';

const PrivateRoute: any = ({ component: Component, authed, loggedInUserloading, ...rest }: any) => {
    const location = useLocation();
    const auth = authed || loggedInUserloading;
    return auth ? <Component {...rest} /> :
    <Navigate to={`${contextPath}/Public/landingPage`} state = {{ from: location }}/>;
  }

  export default PrivateRoute;