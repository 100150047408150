
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { contextPath, donationsStatusText } from "../../../constants";
import { getFacilityLabel, getFacilityTypes } from "../commonFunctions";

import { spacePageType } from './SpaceInternationalDataFunctions';

const getStatusCode = (inputStatus: any, selectedLang: any) => {
  return donationsStatusText["ALL"][selectedLang ?? "en"][inputStatus] ?? "";
};

const projectStatusOptions: any = (inputs: any[], selectedLang: any) => {
  const output: any = [];
  inputs.forEach((inp) =>
    output.push({ name: getStatusCode(inp, selectedLang), code: inp })
  );
  return output;
};

export const fetchSpaceInternationalDashboardColumns = (selectedLang: string, history: any, dt: any, t: any,
  selectedStatus: any, setSelectedStatus: any, selectedDonationProps: any, setSelectedDonationProps: any, initialFilterList: any) => {


  let defaultStatusFilters = initialFilterList?.currentStatusCode?.value;
  const onDonationFromFilterChange = (event: any) => {
    //setSelectedDonationFrom(event.value);
    setSelectedDonationProps({...selectedDonationProps, 'selectedDonationFrom': event.value })
    dt.current.filter(event.value, "locationType", "equals");
  };


  const getLocation = (rowData: any) => {
    let locationNumber = rowData?.facilityDetails?.facilityNo ? getFacilityLabel(t, rowData.locationType) +
      `# ${rowData?.facilityDetails.facilityNo}` : getFacilityLabel(t, rowData.locationType);
    return locationNumber;
  }

  const renderDonationFromFilter = () => {

    const donationFromOptions = getFacilityTypes(t);
    return (
      <Dropdown
      value={selectedDonationProps.selectedDonationFrom}
        optionLabel="label"
        optionValue="value"
        options={donationFromOptions}
        onChange={onDonationFromFilterChange}
        // itemTemplate={statusItemTemplate}
        showClear={true}
        placeholder={t(
          "emergencyDonations.table.searchByDonation"
        )}
        className="p-column-filter"
      />
    );
  };

  const onProjectStatusFilterChange = (event: any) => {
    dt.current.filter([event.value], "currentStatusCode", "equals");
    setSelectedStatus(event.value);
  };

  const renderProjectStatusFilter = () => {
    return (
      <Dropdown
        value={selectedStatus}
        optionLabel="name"
        optionValue="code"
        options={projectStatusOptions(defaultStatusFilters || [], selectedLang)}
        onChange={onProjectStatusFilterChange}
        showClear={true}
        placeholder={t("oneTimeGoodsDonationDashboard.columns.filtersPlaceHolderTxt.projectStatus")}
        className="p-column-filter"
      />
    );
  };

  const donationFromFilter = renderDonationFromFilter();
  const projectStatusFilter = renderProjectStatusFilter();

  return ([
    {
      field: "id",
      header: t("spaceDonations.table.id"),
      sortable: true,
      sortField: "id",
      filter: true,
      filterPlaceholder: t("spaceDonations.table.searchByID"),
      body: (rowData: any) => {
        /* istanbul ignore next */
        return (
          <div>
            <button
              type="button"
              className="p-button p-component p-button-link"
              // @ts-ignore
              onClick={() => {
                history.push({
                  pathname: `${contextPath}/view/${spacePageType}?type=${spacePageType}`,
                  state: { donationId: rowData.id },
                });
              }}
            >
              <span className="p-button-label"
                style={{ fontWeight: "bold", textAlign: "left" }}>
                {rowData?.id}  {rowData.nationalEventDonation && `(${t("spaceDonations.form.nationalHead")})`}
              </span>
            </button>
          </div>
        );
      },
      style: {
        width: "14em",
      },
    },
    {
      field: "currentStatusCode",
      header: t("spaceDonations.table.status"),
      filter: true,
      filterElement: projectStatusFilter,
      body: (rowData: any) => (
        <span>
          {getStatusCode(rowData.currentStatusCode, selectedLang)}
        </span>
      ),
      style: { width: "20em" }
    },
    {
      field: "donationDetails.locationType",
      header: t("spaceDonations.table.donationFrom"),
      filterPlaceholder: t("spaceDonations.table.searchByDonation"),
      sortField: "locationType",
      filter: true,
      filterElement: donationFromFilter,
      body: (rowData: any) => getLocation(rowData.donationDetails),
      style: { width: "16em" }
    },
    {
      field: "eventStartDate",
      header: t("spaceDonations.table.startDate"),
      style: { width: "10em" }
    },
    {
      field: "eventEndDate",
      header: t("spaceDonations.table.endDate"),
      style: { width: "10em" }
    },
    {
      field: "eventStartTime",
      header: t("spaceDonations.table.startTime"),
      style: { width: "10em" }
    },
    {
      field: "eventEndTime",
      header:  t("spaceDonations.table.endTime"),
      style: { width: "10em" }
    },
  ]).map((column: any) => <Column
      key={column.field} {...{ ...column, header: t(column.header) }} />);;


};