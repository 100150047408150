import { useEffect } from 'react';
import { getFormattedDate, getTodaysDate } from '../../common/DateUtils';
import { Trans, useTranslation } from 'react-i18next';
import { wordLengthValidation } from '../utils';
import { Heading, InputBox, FileUploadComponent } from '../../common/index';
import { stringFormat } from '../../common/utils';
import '../../../styles/oneTimeGoodDonations.css';
import '../../../styles/formStyles.css';

export interface OwnProps {
    classes: any;
    isReview?: boolean
    showEmptyFileMessage?: boolean
}

const RecipientDonationDetails = (props: any) => {
    const { t } = useTranslation();
    const dateValue = getTodaysDate();

    useEffect(() => {
        props.setValue('certification.name', props?.data?.certifications?.[1]?.name ?
            props.data.certifications[1].name : '');
        props.setValue('certification.signature', props?.data?.certifications?.[1]?.signature ?
            props.data.certifications[1].signature : '');
        props.setValue('certification.date', props?.data?.certifications?.[1]?.date ?
            getFormattedDate(props.data.certifications[1].date) : getFormattedDate(dateValue));
    }, [props.data]);

    const wordLengthValidate = (value: any) => {
        const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 100);
        return wordLengthValidation(value, message, 100)
    };

    const wordLengthValidate600 = (value: any) => {
        const message = stringFormat(t('common.validationMsg.wordLengthlimit'), 600);
        return wordLengthValidation(value, message, 600)
    };

    const requiredFieldValidation = () => props?.readOnly ? false : t('common.validationMsg.requiredField') as string

    return (
        <>
            <div className={'donation_data'}>
                <Heading
                    id={`cartonDetails-heading`}
                    className={`h16_bold_title cartonDetails-heading mr-bt`}
                    level={'h6'}
                >{t('oneTimeGoodsDonation.recipientData.header')}</Heading>
                <Heading level={'h6'}>
                    <Trans i18nKey='oneTimeGoodsDonation.ack.recipientCertificationTxt' />
                </Heading>
                <div className='row'>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_employee_name'}
                            className={'authorization_employee_name inputBox'}
                            classes={props.classes}
                            formErrors={props.errors}
                            control={props.control}
                            fieldName={'certification.name'}
                            label={t('oneTimeGoodsDonation.recipientData.recipientName')}
                            placeholder={''}
                            defaultValue={''}
                            required={true}
                            disabled={props?.disabled}
                            InputProps={{
                                classes: {
                                    disabled: props?.disabled ? props?.classes?.disabled : ''
                                }
                            }}
                            rules={{
                                required: requiredFieldValidation(),
                                validate: wordLengthValidate
                            }}
                            errorMsg={props.errors?.certification?.name?.message}
                        />
                    </div>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_signature'}
                            className={'authorization_signature'}
                            classes={props.classes}
                            formErrors={props.errors}
                            fieldName={'certification.signature'}
                            control={props.control}
                            label={t('oneTimeGoodsDonation.recipientData.signatureOfRecipient')}
                            placeholder={''}
                            defaultValue={''}
                            required={true}
                            disabled={props?.disabled}
                            InputProps={{
                                classes: {
                                    disabled: props?.disabled ? props?.classes?.disabled : ''
                                }
                            }}
                            rules={{
                                required: requiredFieldValidation(),
                                validate: wordLengthValidate600
                            }}
                            errorMsg={props.errors?.certification?.signature?.message}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                        <InputBox
                            id={'authorization_date'}
                            className={'authorization_date'}
                            classes={props.classes}
                            formErrors={props.errors}
                            control={props.control}
                            label={t('oneTimeGoodsDonation.employeeData.date')}
                            fieldName={'certification.date'}
                            defaultValue={''}
                            required={true}
                            disabled={true}
                            InputProps={{
                                readOnly: true,
                                classes: {
                                    disabled: props?.classes?.disabled
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='row' style={{ marginBottom: '20px' }}>
                <div className='col-md-10'>
                    <FileUploadComponent
                        heading={t('common.heading.uploadFiles')}
                        name={`ackFile`}
                        defaultFiles={props?.data?.certifications[1]?.ackFile ?
                            [props?.data?.certifications[1]?.ackFile] : []}
                        disabled={true}
                        readOnly={true}
                        allowMultiple={true}
                        showFileUpload={props?.isFileUploadEnabled}
                        showEmptyFileMessage={props?.showEmptyFileMessage}
                    />
                </div>
            </div>
        </>
    )
}

export default RecipientDonationDetails;
