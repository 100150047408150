import { IMDDropdownOption, IMXLanguage } from 'src/components/common/Dropdown/keyValueDropdown';
import { IFileDetails } from 'src/components/donations/moreDetailsSections/attachments/attachmentsTable';
import { StringType } from 'src/components/donations/moreDetailsSections/organizationDetails/orgDetailsDefaults';
import { IProjectBeneficiaries } from 'src/components/donations/moreDetailsSections/projectDetails/projectDetailsDefaults';

export type NumberType = number | undefined | null;

export interface IMonetarySocialImpactReport {
  projectName: string
  [MonetarySocialImpactSections.SocialImpactSummary]: ISocialImpactSummary
  [MonetarySocialImpactSections.Beneficiaries]: IProjectBeneficiaries
  [MonetarySocialImpactSections.Activities]: ISocialImpactActivity,
  [MonetarySocialImpactSections.ProjectObjectives]: ISocialImpactObjectives[],
  [MonetarySocialImpactSections.Attachments]: Map<string, IFileDetails>
}

export interface ISocialImpactSummary {
  summary: string,
  retportPeriod: string,
  ngoName: string
}

export interface IActivity extends IBaseActivity {
  sno: number
  activityStatus: string,
  amountUsed: number | null,
  comment: string,
  activityAttachment: IFileDetails | null
  description: string,
  number: string,
  amount: number,
  startDate: string,
  endDate: string,
  balance: number
}
export interface IActivityWithHistory extends IActivity {
  list: IBaseActivity[];
}

interface IBaseActivity {
  comment: string,
  amountUsed: number | null,
  activityAttachment: IFileDetails | null,
  reportDate: string,
  activityStatus: string,
}

export interface ISocialImpactActivity {
  category: IMXLanguage | null,
  subCategory: IMXLanguage | null,
  activityForSocialReport: IActivityWithHistory[]
}
export interface ISocialImpactObjectiveMeasures {
  measure: StringType,
  result: StringType
  actualResultToDate: string
}
export interface ISocialImpactObjectives {
  projectObservation: string,
  measureResults: ISocialImpactObjectiveMeasures[]
}

export const defaultMonetarySocialImpactReport: IMonetarySocialImpactReport = {
  projectName: '',
  socialImpactSummary: {
    summary: '',
    retportPeriod: '',
    ngoName: ''
  },
  beneficiaries: {
    noOfChild: null,
    noOfYoungAdults: null,
    noOfAdults: null,
    noOfSenior: null,
    noOfFamilies: null,
    noOfWomen: null,
    totalIndirect: null,
    calculationIndirectBeneficiaries: null
  },
  activitySummary: {
    category: null,
    subCategory: null,
    activityForSocialReport: []
  },
  projectObjectives: [],
  attachments: new Map()
}

export enum MonetarySocialImpactSections {
  SocialImpactSummary = 'socialImpactSummary',
  Beneficiaries = 'beneficiaries',
  Activities = 'activitySummary',
  ProjectObjectives = 'projectObjectives',
  Attachments = 'attachments'
}

export interface SocialImpactRequest {
  id: string
  donationId: number;
  finalize: boolean;
  socialImpactSummary: string;
  beneficiaries: IProjectBeneficiaries;
  activityForSocialReport: IActivityWithHistory[];
  observationSummary: ISocialImpactObjectives[]
}
export interface ISocialImpactResponse {
  id: string,
  projectName: string;
  orgId: string,
  orgLegalName: string,
  countryCode: string,
  year: string,
  month: string,
  monthInInteger: number,
  donationId: number,
  donationType: string,
  socialImpactSummary: string,
  submittedDate: string,
  beneficiaries: IProjectBeneficiaries
  scheduleOfActivity: ISocialImpactActivity,
  observationSummary: ISocialImpactObjectives[],
  monetaryUploadFiles: { [key: string]: IFileDetails } | null,
  finalized: boolean,
  category: IMDDropdownOption,
  subcategory: IMDDropdownOption
}

export const transformSocialImpactData = (response: ISocialImpactResponse) => {
  const res = {
    activitySummary: response.scheduleOfActivity || defaultMonetarySocialImpactReport.activitySummary,
    attachments: response.monetaryUploadFiles ? new Map(Object.entries(response.monetaryUploadFiles))
      : new Map(),
    beneficiaries: response.beneficiaries || defaultMonetarySocialImpactReport.beneficiaries,
    projectName: response.projectName,
    projectObjectives: response.observationSummary || defaultMonetarySocialImpactReport.projectObjectives,
    socialImpactSummary: {
      ngoName: response.orgLegalName,
      retportPeriod: response.submittedDate,
      summary: response.socialImpactSummary
    }
  };
  if (res?.activitySummary) {
    res.activitySummary.category = response.category?.language ?? null;
    res.activitySummary.subCategory = response.subcategory?.language ?? null;
    res.activitySummary.activityForSocialReport = res.activitySummary.activityForSocialReport.map((act) => {
      act.comment = '';
      return act;
    })
  }
  return res;
}

export const populateData = (responseData: ISocialImpactResponse, data: IMonetarySocialImpactReport,
  fileData: Map<number, IFileDetails | null>): SocialImpactRequest => {
  const req: SocialImpactRequest = {
    beneficiaries: data.beneficiaries,
    socialImpactSummary: data.socialImpactSummary.summary,
    activityForSocialReport: responseData.scheduleOfActivity.activityForSocialReport,
    donationId: responseData.donationId,
    id: responseData.id,
    finalize: false,
    observationSummary: responseData.observationSummary,
  }
  if (data?.activitySummary?.activityForSocialReport) {
    data.activitySummary.activityForSocialReport.forEach((act, ind) => {
      const configData = responseData.scheduleOfActivity.activityForSocialReport[ind]
      if(act.activityStatus && configData.activityStatus !== 'COMPLETED') {
        req.activityForSocialReport[ind].activityStatus = act.activityStatus;
        req.activityForSocialReport[ind].amountUsed = act.amountUsed;
        req.activityForSocialReport[ind].comment = act.comment;
        if ((act.comment).length > 0 && (configData.amountUsed ?? 0) > 0) {
          req.activityForSocialReport[ind].list.push({
            activityAttachment: fileData.get(configData.sno) as IFileDetails,
            amountUsed: configData.amountUsed,
            comment: configData.comment,
            activityStatus: configData.activityStatus,
            reportDate: configData.startDate
          })
        }
      }
    });
  }

  if (data.projectObjectives) {
    data.projectObjectives.forEach((obj, ind) => {
      obj.measureResults.forEach((res, rInd) => {
        req.observationSummary[ind].measureResults[rInd].actualResultToDate = res.actualResultToDate
      })
    });
  }

  return req;
}
