export type StringType = string | undefined | null;
export type NumberType = number | undefined | null;
export type DateType = Date | undefined | null;

export interface TabsCommonProps {
    isLoading?: boolean,
    isFormEditable: boolean | undefined | null,
    setFormStatus: (count: number) => void
}

export interface OrganizationDetailsProps extends Omit<TabsCommonProps, 'setFormStatus'> {
    onOrgSubmit: any,
    orgData: any,
    setFormStatus: (errors: any) => void
    fileDownloader: (data: any) => void;
}



export interface IBoardMembersFields {
    userName: StringType,
    userTitle: StringType,
    birthYear: StringType,
    userEmail: StringType,
    birthPlace: StringType,
    additionalUserDetails: {
        isReceivingSalary: boolean
    }
}

export enum RadioOption {
    Yes = 'yes',
    No = 'no',
    None = 'none'
}
export interface IBoardMembers {
    isReceivingSalary: RadioOption,
    boardMembers: IBoardMembersFields[]
}

export const DefaultBoardMembers: IBoardMembers = {
    isReceivingSalary: RadioOption.No,
    boardMembers: [{
        userName: '',
        userTitle: '',
        birthYear: '',
        userEmail: '',
        birthPlace: '',
        additionalUserDetails: {
            isReceivingSalary: false
        }
    }]
};

export interface IGeographicFields {
    populationType: StringType,
    country: StringType,
    state: StringType,
    city: StringType,
    community: StringType,
    numberOfBeneficiaries: NumberType
}

export const DefaultGeographicData: IGeographicFields[] = [{
    populationType: null,
    country: 'MX',
    state: null,
    city: null,
    community: null,
    numberOfBeneficiaries: null
}];

export interface IIncomeFields {
    type: StringType,
    numberOfDonors: NumberType,
    amount: NumberType,
    percentage: NumberType
}

export interface IOrganizationIncome {
    currentFinancialYear: IIncomeFields[],
    previousFinancialYear: IIncomeFields[]
}

export const DefaultOrganizationIncome: IOrganizationIncome = {
    currentFinancialYear: [{
        type: '',
        numberOfDonors: null,
        amount: null,
        percentage: null
    }],
    previousFinancialYear: [{
        type: '',
        numberOfDonors: null,
        amount: null,
        percentage: null
    }]
}

export interface IExpenseFields {
    type: StringType,
    amount: NumberType,
    percentage: NumberType,
}


export interface IOrganizationExpenses {
    currentFinancialYear: IExpenseFields[],
    previousFinancialYear: IExpenseFields[]
}

export const DefaultOrganizationExpense: IOrganizationExpenses = {
    currentFinancialYear: [{
        type: null,
        amount: null,
        percentage: null
    }],
    previousFinancialYear: [{
        type: null,
        amount: null,
        percentage: null
    }]
}

interface IPrincipleProgramsFields {
    programName: StringType,
    programDescription: StringType,
    startDate: DateType,
    endDate: DateType
}

export const DefaultPrincipalProgramFields: IPrincipleProgramsFields[] = [{
    programName: null,
    programDescription: null,
    startDate: null,
    endDate: null
}]


export const DefaultIncomeAndExpenseTotals = {
    incomeCurrentFinancialYear: 0,
    incomePreviousFinancialYear: 0,
    expenseCurrentFinancialYear: 0,
    expensePreviousFinancialYear: 0,
}


export interface IOrganizationMissionFields {
    missionDetails: StringType,
    personsOnPayroll: NumberType
}

export const DefaultOrgMissionFields: IOrganizationMissionFields = {
    missionDetails: null,
    personsOnPayroll: null
}

export interface IOrganizationProfileFields {
    name: StringType,
}

export const DefaultOrgProfile: IOrganizationProfileFields = {
    name: null,
}




export enum Sections {
    organizationProfile = 'legalRepresentativeDetails',
    organizationMission = 'mission',
    geographicalCoverage = 'geographicalCoverage',
    boardMembers = 'boardMemberSalaryInfo',
    currentIncome = 'currentIncome',
    previousIncome = 'previousIncome',
    incomeAttachments = 'incomeAttachments',
    currentExpense = 'currentExpense',
    previousExpense = 'previousExpense',
    expenseAttachments = 'expenseAttachments',
    principlePrograms = 'principlePrograms',
    percentTotals = 'totals',
    boardMemberSelection = 'boardMemberNoneSelected'
}

const _orgTabErrorsMap = new Map();

_orgTabErrorsMap.set(Sections.organizationProfile, Sections.organizationProfile);
_orgTabErrorsMap.set(Sections.organizationMission, Sections.organizationMission);
_orgTabErrorsMap.set(Sections.geographicalCoverage, Sections.geographicalCoverage);
_orgTabErrorsMap.set(Sections.boardMembers, Sections.boardMembers);

_orgTabErrorsMap.set(Sections.currentIncome, 'income');
_orgTabErrorsMap.set(Sections.previousIncome, 'income');
_orgTabErrorsMap.set(Sections.incomeAttachments, 'income');

_orgTabErrorsMap.set(Sections.currentExpense, 'expense');
_orgTabErrorsMap.set(Sections.previousExpense, 'expense');
_orgTabErrorsMap.set(Sections.expenseAttachments, 'expense');

_orgTabErrorsMap.set(Sections.principlePrograms, Sections.principlePrograms);
_orgTabErrorsMap.set(Sections.boardMemberSelection, Sections.boardMembers);

export const OrgTabErrorsMap = _orgTabErrorsMap;


export interface BaseSectionProps {
    fieldName: string
}