import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const FooterButton = withStyles({
    root: {
        textTransform: 'none',
        fontWeight: 'bold'
    },
    contained: {
        borderRadius: 20,
        backgroundColor: 'var(--buttonColor)',
        '&:hover': {
            backgroundColor: 'var(--buttonColor)',
            color: 'var(--white)'
        }
    },
    outlined: {
        borderRadius: 20, '&:hover': {
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)'
        }
    },
    disabled: {
        pointerEvents: 'none',
        opacity: '0.50',
    }
})(Button)

export default FooterButton;
