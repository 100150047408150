import { SelectedDataActions, SelectedDataActionTypes } from './selectedDataActions';
import { SelectedDataToStore } from '../../models/globalDonationState';

export const defaultSelectedDataState: SelectedDataToStore = {
    addDataToStore: {},
    addDataToStoreLoading: false,
    addDataToStoreSuccess: false,
    addDataToStoreFailure: false
};

export const selectedDataReducer = (state: SelectedDataToStore = defaultSelectedDataState, action: SelectedDataActions):
    SelectedDataToStore => {
    switch (action.type) {
        case SelectedDataActionTypes.SELECTED_DATA_LOAD: return {
            ...state,
            addDataToStoreLoading: true,
            addDataToStoreFailure: false,
            addDataToStoreSuccess: false,
        };
        case SelectedDataActionTypes.SELECTED_DATA_LOAD_SUCCESS: return {
            ...state,
            addDataToStore: {
                ...state.addDataToStore,
                [action.data.data.key]: JSON.parse(JSON.stringify(action.data.data.value))
            },
            addDataToStoreLoading: false,
            addDataToStoreFailure: false,
            addDataToStoreSuccess: true,
        };
        case SelectedDataActionTypes.SELECTED_DATA_LOAD_FAILURE: return {
            ...state,
            addDataToStoreLoading: false,
            addDataToStoreFailure: true,
            addDataToStoreSuccess: false,
        };
        case SelectedDataActionTypes.SET_SELECTED_DATA_LOAD: return {
            ...state,
            addDataToStore: {},
        };
        default: return state;
    }
}