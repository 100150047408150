
import { PAGETYPES } from "../../../constants";
import TypicalDonationTemplateForm from "../CommonComponents/TypicalDonationTemplateForm";
import ServiceDonationView from "./ServiceDonationView";

export const ServiceDonationTemplateContainer = (props: any) => {
    const { donationCategory, selectedLang, donationActionMutate, donationDetailsQuery, history, childType } = props;
    
    switch (childType) {
        case PAGETYPES.VIEW:
            return <ServiceDonationView selectedLang={selectedLang} history={history}
                donationActionMutate={donationActionMutate}
                donationCategory={donationCategory}
                donationDetailsQuery={donationDetailsQuery} />

        case PAGETYPES.CREATE:
            return <TypicalDonationTemplateForm history={history}
                donationCategory={donationCategory}
                data={{}}
                editable={true}
                selectedLang={selectedLang}
                orgType={props.orgType}
                isLocationMandatory={props.isLocationMandatory}
                createDonationMutate={props.createDonationMutate}
                organizationForm={props.organizationForm} />
        default:
            return <>DEFAULT Service CONATINER TEMPLATE</>
    }
};