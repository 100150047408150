import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { reactQueryConfigOptions } from '../../../../../constants';
import { useQuery } from 'react-query';
import Loader from '../loader/loader';
import './SearchBar.css';
import { hoSearchContext } from '../drawer/drawer';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
const service: Service = SpaceDonationService;
const searchIcon = require('../../../../../resources/navigation-search.svg');

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const DIVISION = 'Division';

export const sortLocations = (a: any, b: any) => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const aA = a.value.replace(reA, '');
  const bA = b.value.replace(reA, '');

  if (aA === bA) {
    const aN = parseInt(a.value.replace(reN, ''), 10);
    const bN = parseInt(b.value.replace(reN, ''), 10);
    return aN - bN;
  }
  return aA > bA ? 1 : -1;
};

export const sortBySelected = (fLocs: any, selectedOptions: any[]) => {
  if (selectedOptions?.length) {
    const selectedValues = selectedOptions.map((item: any) => item.value);
    const grouped = fLocs.reduce(
      (acc: any, item: any) => {
        if (selectedValues.includes(item.value)) {
          acc.selected.push(item);
        } else {
          acc.otherOptions.push(item);
        }
        return acc;
      },
      { selected: [], otherOptions: [] }
    );
    grouped.otherOptions = grouped.otherOptions.sort(sortLocations);
    return [...grouped.selected, ...grouped.otherOptions];
  }
  return fLocs;
};

export const disableOptions = (
  fLocs: any,
  selectedOptions: any[],
  inputValue: string
) => {
  if (selectedOptions?.length) {
    const selectedValues = selectedOptions.map((item: any) => item.value);
    const selectedType = getLocationType(selectedValues?.[0]);
    if (selectedType === DIVISION) {
      return fLocs.map((item: any) => {
        if (item.value !== selectedValues[0]) {
          item.isDisabled = true;
        }
        return item;
      });
    } else {
      return fLocs.map((item: any) => {
        const type = getLocationType(item.value);
        if (type !== selectedType) {
          item.isDisabled = true;
        }
        return item;
      });
    }
  }
  if (!inputValue.length || !selectedOptions) {
    return fLocs.map((item: any) => {
      delete item.isDisabled;
      return item;
    });
  }
  return fLocs;
};

export const filterSearchResults = (result: any[], inputValue: string) => {
  const searchResults: any = [];
  result.forEach((item: any) => {
    if (Number.isNaN(Number(inputValue))) {
      if (
        item.label &&
        item.label.toLowerCase().startsWith(inputValue.toLowerCase())
      ) {
        searchResults.push(item);
      } else {
        const word = item.label.split(' ');
        word.forEach((words: any) => {
          const removeSpecialSymbol = words
            .replace(/[^a-zA-Z ]/g, '')
            .toLowerCase();
          if (
            item.label &&
            removeSpecialSymbol.startsWith(inputValue.toLowerCase())
          ) {
            searchResults.push(item);
          }
        });
      }
    } else if (
      item.label &&
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      searchResults.push(item);
    }
  });
  return searchResults;
};

export const getLocationType = (value: string) =>
  value ? value.split('~')[0] : value;

export interface FetchDashboardProps {
  self?: any;
  history?: any[];
  multiple: boolean;
  disableCloseOnSelect: boolean;
  callbackFunction?: any;
}

const useStyles = makeStyles(() => ({
  paper: { margin: 18 },
}));

const SearchBar: React.FunctionComponent<FetchDashboardProps> = () => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [result, setResult] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { setSearchContextVal } = useContext(hoSearchContext);

  const { isLoading, data } = useQuery(
    [
      'usSpaceStoreLocations',
      { key: 'usSpaceStoreLocations', countryCode: 'US' },
    ],
    service.getSpaceDonationConfigurations,
    { ...reactQueryConfigOptions }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResult(data?.usSpaceStoreLocationsList);
    }
    return () => {
      if (setSearchContextVal) {
        setSearchContextVal([]);
      }
    };
  }, [isLoading]);

  useEffect(() => {
    if (result?.length) {
      setOptions(result.slice(0, 50));
    }
  }, [result]);

  useEffect(() => {
    filterLocations(searchText);
  }, [searchText]);

  const filterLocations = (inputValue: string) => {
    if (inputValue) {
      const searchResults: any = filterSearchResults(result, inputValue);

      setOptions(sortBySelected(searchResults, selectedOptions));
    } else {
      if (result?.length) {
        setOptions(result.slice(0, 50));
      }
    }
  };

  const getSearchDataForHo = () => {
    if (selectedOptions) {
      setSearchContextVal(selectedOptions.map((val: any) => val.value));
    }
  };

  const handleOnChange = (e: any, option: any, reason: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedOptions(option);
    setSearchContextVal(option.map((val: any) => val.value));
    if (reason === 'clear') {
      setSearchContextVal(option.map((val: any) => val.value));
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className='home-office-search-bar-container'>
        <div className='home-office-search-box'>
          <div className='home-office-search-box-text'>
            <Autocomplete
              className='home-office-autocomplete-container'
              multiple={true}
              classes={classes}
              id='autocomplete-checkbox'
              limitTags={4}
              options={sortBySelected(
                disableOptions(options, selectedOptions, searchText),
                selectedOptions
              )}
              disableCloseOnSelect={false}
              value={selectedOptions}
              getOptionLabel={(option: any) => option.label}
              getOptionDisabled={(option: any) => option.isDisabled}
              onChange={handleOnChange}
              onClick={(e) => e.stopPropagation()}
              inputValue={searchText}
              onInputChange={(_: any, newInputValue: string) => {
                setSearchText(newInputValue);
              }}
              renderOption={(option: any, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 , color: '#000000' }}
                      checked={selected}
                    />
                    {option.label}
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className='ho-search-label'
                  label='Search for walmart stores'
                />
              )}
            />
          </div>
          <div className='home-office-search-icon-background'>
            <div onClick={getSearchDataForHo}>
              {' '}
              <span className='home-office-search-icon-float'>
                <img
                  className='home-office-search-icon'
                  alt=''
                  src={searchIcon}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
