import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, AccordionCompo } from '../../common';
import { useStyles, getNgoAcknowledgementUpdatePayload } from '../utils';
import { commonFormTranslations } from '../../../constants';
import {
    useFetchOneTimeGoodsDonationDetailsById, useUpdateNGOAcknowledgement,
} from '../../../custom-hooks';
import LoaderComponent from '../../../components/LoaderComponent';
import GenericConfirmDialog from '../../../components/dialogComponents/GenericConfirmDialog';
import InputConfirmDialog from '../../../components/dialogComponents/InputConfirmDialog';
import SuccessAlertDailog from '../../../components/dialogComponents/SuccessAlertDailog';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import '../../../styles/oneTimeGoodDonations.css';
import '../../../styles/formStyles.css';
import { GEAppendixBForm } from '../oneTimeGoods/GEAppendixBFormComponent/GEAppendixBForm'
import { AboutOrganization } from './organisationDetails';
import EmployeeDonationDetails from './EmployeeDonationDetails';
import RecipientDonationDetails from './RecipientDonationDetails';
import { CartonDetails } from './cartonDetails';
import { isAckUploadEnabled, useScrollToError } from '../../../components/common/utils';
import { formErrorEleClass } from '../../../styles/styleUtil';
import { useNavigate } from 'react-router';

export const OneTimeGoodsGEAcknowledgeForm = (props: any) => {
    const { t } = useTranslation();
    const [showActionDailog, setShowActionDailog] = useState(false);
    const [appendixBFormData, setAppendixBFormData] = useState<any>();
    const [formData, setFormData] = useState<any>();
    const [donationData, setData] = useState<any>();
    const [showDailog, setShowDailog] = useState(false);
    const navigateTo = useNavigate();

    const queryClient = useQueryClient()

    // Fetch donation details by id
    const { response, isLoading, isError, isSuccess } = useFetchOneTimeGoodsDonationDetailsById(props.donationId)

    // for updating donation details
    const {
        mutateAsync,
        isUpdateError,
        isUpdateSuccess,
        isUpdateLoading,
        updateError
    } = useUpdateNGOAcknowledgement();

    // If donationId is empty redirect to dashboard

    useEffect(() => {
        if (!props?.donationId) {
            redirectToDashboard()
        }
    }, [props.donationId])

    useEffect(() => {
        if (isSuccess) {
            setData({
                ...response,
                ...response?.goodsOneTimeDonation,
                ...response?.goodsOneTimeDonation?.organization,
                ...response?.goodsOneTimeDonation?.donationDetail
            })
            setAppendixBFormData({
                ...response?.goodsOneTimeDonation?.appendixB,
                donationDetail: {
                    ...response?.goodsOneTimeDonation?.appendixB?.donationDetail,
                },
                currentStatusCode: response?.goodsOneTimeDonation?.currentStatusCode
            })
        };
    }, [isSuccess])

    const onSubmit = (data: any) => {
        setShowActionDailog(true);
        const payload = { ...data };
        setFormData(payload);
    };

    const closeActionDailog = () => {
        setShowActionDailog(false);
    }

    const handleConfirmActionDialog = async (comments: any) => {
        const form = new FormData();
        // @ts-ignore
        const ackFile = formData?.ackFile ? [...formData.ackFile] : [];
        if (Array.isArray(ackFile)) {
            ackFile.forEach((file: any) => {
                form.append('ackFile', file);
            });
        }
        delete formData.ackFile
        setShowActionDailog(false);
        const payload = getNgoAcknowledgementUpdatePayload(formData, response, comments)
        form.append('acknowledgementJson', JSON.stringify(payload));
        await mutateAsync(form);
        queryClient.invalidateQueries('fetchOneTimeGoodsDonationDetailsById')
    }
    const redirectToDashboard = () => navigateTo(props?.redirectUrl)

    const isFileUploadEnabled = isAckUploadEnabled(donationData?.currentStatusCode);

    const formSubmitMethods = useForm({
        mode: 'all',
        reValidateMode: 'onBlur',
        defaultValues: {
            langCode: props.selectedLang,
            certificateSignee: { ...donationData?.appendixB?.organization?.certificateSignee },
            certificateSignedDate: donationData?.appendixB?.organization?.certificateSignedDate,
            ...donationData
        }
    });

    useScrollToError(formSubmitMethods?.errors, formErrorEleClass)
    const classes = useStyles();

    return (
        <>
            <div className={'bodyStyles'}>
                <FormProvider {...formSubmitMethods}>
                    <form
                        onSubmit={formSubmitMethods.handleSubmit(onSubmit)}
                        noValidate={true}
                        autoComplete='off'
                    >
                        {donationData?.goodsOneTimeDonation?.additionalDetails?.appendixBIsFilled &&
                            <AccordionCompo
                                className={'ngo-reviewform-appendixB'}
                                defaultExpanded={true}
                                accordionHeader={'common.heading.appendixB'}
                                accordionDetails={<GEAppendixBForm
                                    selectedLang={props.selectedLang}
                                    isReview={true}
                                    classes={classes}
                                    data={appendixBFormData}
                                    appendixBConsentDisabled={true}
                                    appendixBPrimaryUserDetailsDisabled={true}
                                    appendixBCertificationDisabled={true}
                                    appendixBDonationDetailsDisabled={true}
                                    appendixBOrgDetailsDisabled={true}
                                />}
                            />}
                        <AccordionCompo
                            className={'ngo-reviewform-appendixC'}
                            defaultExpanded={true}
                            accordionHeader={'common.heading.appendixC'}
                            accordionDetails={<>
                                <AboutOrganization
                                    control={formSubmitMethods.control}
                                    errors={formSubmitMethods.errors}
                                    selectedLang={props?.selectedLang}
                                    classes={classes}
                                    setValue={formSubmitMethods.setValue}
                                    data={donationData}
                                />
                                <CartonDetails
                                    cartonDetails={donationData?.carton}
                                    countryCode={donationData?.goodsOneTimeDonation?.countryCode}
                                />
                                <EmployeeDonationDetails
                                    control={formSubmitMethods.control}
                                    errors={formSubmitMethods.errors}
                                    selectedLang={props?.selectedLang}
                                    classes={classes}
                                    setValue={formSubmitMethods.setValue}
                                    data={donationData}
                                />
                                <RecipientDonationDetails
                                    control={formSubmitMethods.control}
                                    errors={formSubmitMethods.errors}
                                    isFileUploadEnabled={isFileUploadEnabled}
                                    showEmptyFileMessage={true}
                                    selectedLang={props?.selectedLang}
                                    classes={classes}
                                    setValue={formSubmitMethods.setValue}
                                    data={donationData}
                                />
                            </>}
                        />
                        <div className='row' style={{ marginTop: '25px' }}>
                            <div className='col-md-2'>
                                <Button
                                    className={'cancel_button_styles mr-20 ngo_acknowledgement_cancelBtn'}
                                    type='button'
                                    title={t('common.buttonLabels.cancel')}
                                    onClick={() => setShowDailog(true)}
                                />
                            </div>
                            {donationData?.waaSTaskMessage?.formProperties?.length > 0 &&
                                <div className='col-md-2'>
                                    <Button
                                        id={'ngoForm_submit'}
                                        className={'submit_button_styles ngo_next_button'}
                                        type={'submit'}
                                        title={t('common.buttonLabels.submitBtn')}
                                    />
                                </div>}
                        </div>
                    </form>
                </FormProvider>
            </div>
            <GenericConfirmDialog
                id={'ngo_acknowledgement_cancel_popup'}
                showDialog={showDailog}
                cancel={() => setShowDailog(false)}
                confirm={redirectToDashboard}
                title={t('common.buttonLabels.cancel')}
                message={t('common.popUpMsg.cancelDonationRequest.msg')}
                cancelButton={t('common.buttonLabels.no')}
                confirmButton={t('common.buttonLabels.yes')}
            />

            <InputConfirmDialog
                id={'ngo-form-inputConfirmDialog'}
                title={t('status.additionalInformation')}
                showDialog={showActionDailog}
                onClose={closeActionDailog}
                onSubmit={handleConfirmActionDialog}
            />
            <SuccessAlertDailog
                id={'successTitle-donationSuccess'}
                showDialog={isUpdateSuccess}
                maxWidth={'lg'}
                ok={redirectToDashboard}
                title={t('common.successTitle.donationSuccess')}
                message={
                    <Trans i18nKey='common.successMsg.donationUpdateMsg'>
                        Donation has been updated Successfully
                    </Trans>
                }
            />
            <ErrorSnackbarComponent
                isError={isError}
                classes={props.classes}
                message={response?.errorMessage || commonFormTranslations.genericErrorMessage[props.selectedLang]}
            />
            <ErrorSnackbarComponent
                isError={isUpdateError}
                classes={props.classes}
                // @ts-ignore
                message={updateError?.errorMessage || commonFormTranslations.genericErrorMessage[props.selectedLang]}
            />
            {(isLoading || isUpdateLoading) &&
                <LoaderComponent fullScreen={true} />
            }
        </>
    )

}
