import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core';
import { NgoReviewFromComponent } from '../components/NgoReviewFromComponent';
import { GlobalDonationState } from '../../../../models/globalDonationState';
import { clearSelectedData } from '../../../../state/selectedData/selectedDataActions';
import BreadcrumbsComponent from '../../../common/BreadcrumbsComponent/BreadcrumbsComponent';
import { homeOfficeReviewBreadcrumbs, PageHeading, theme } from '../../utils';
import { getUserRoles, HOME_OFFICE } from '../../../common/utils';
import { contextPath } from 'src/constants';
import { GEReviewFromComponent } from '../GEAppendixBFormComponent/GEReviewFromComponent'
import analytics from '../../../../adobe-analytics/analytics';
import { ANALYTICS } from '../../../../adobe-analytics/analytics-dataelemets';

export interface OwnProps {
    history: any;
    appi18n: any;
    classes?: any;
    t: (key: string, options?: any) => (string);
}

export interface DispatchProps {
    clearSelectedData: (data: any) => void;
}

export interface StateProps {
    selectedLang: any;
    selectedDonationData: any;
    orgData: any;
}

class OneTimeGoodsReviewComponent extends React.PureComponent<OwnProps & StateProps & DispatchProps> {
    public componentDidMount()
    {
        analytics.trackPageInfoGdmsExternal(ANALYTICS.oneTimeGoodsDonation.dashboardPage);
    }
    public render() {
        const selectedData = (this.props.selectedDonationData?.ONE_TIME_GOODS) ?
            JSON.parse(JSON.stringify(this.props.selectedDonationData?.ONE_TIME_GOODS)) : {};

        return (<>
            <BreadcrumbsComponent
                breadcrumbsData={homeOfficeReviewBreadcrumbs}
                history={this.props.history}
                options={{ id: selectedData?.id }}
            />
            <PageHeading heading={this.props.t('oneTimeGoodsDonation.oneTimeGoodsDonation')} />
            <MuiThemeProvider theme={theme}>
                {selectedData?.organization?.organizationType?.keyText === 'org_type_ngo' &&

                    <NgoReviewFromComponent
                        role={getUserRoles()}
                        donationId={selectedData?.id}
                        modifyOrg={true}
                        selectedLang={this.props.selectedLang}
                        redirectUrl={`${contextPath}/dashboard/one-time-goods?type=one-time-goods`}
                        source={HOME_OFFICE}
                        organization={this?.props?.orgData}
                    />
                }

                {selectedData?.organization?.organizationType?.keyText === 'org_type_governmental' &&

                    <GEReviewFromComponent
                        role={getUserRoles()}
                        donationId={selectedData?.id}
                        modifyOrg={true}
                        selectedLang={this.props.selectedLang}
                        redirectUrl={`${contextPath}/dashboard/one-time-goods?type=one-time-goods`}
                        source={HOME_OFFICE}
                        organization={this?.props?.orgData}
                    />
                }
            </MuiThemeProvider>
        </>)
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    selectedDonationData: state.selectedDataState.addDataToStore,
    orgData: state.loggedInUserState.userDetail
});
/* istanbul ignore next */
export const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    clearSelectedData: (data) => dispatch(clearSelectedData(data)),
});

export default withTranslation()
    (connect(mapStateToProps, mapDispatchToProps)(OneTimeGoodsReviewComponent));

