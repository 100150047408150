import { FoodSurplusConfirmationActions, FoodSurplusConfirmationTypes } from './foodSurplusConfirmationAction';
import { FoodSurplusConfirmationState } from '../../models/globalDonationState';

export const defaultFoodSurplusConfirmationState: FoodSurplusConfirmationState = {
    foodSurplusConfirmation: [],
    foodSurplusConfirmationLoading: false,
    foodSurplusConfirmationSuccess: false,
    foodSurplusConfirmationFailure: false
};

export const foodSurplusConfirmationReducer = (state: FoodSurplusConfirmationState
                                                   = defaultFoodSurplusConfirmationState, action: FoodSurplusConfirmationActions):
    FoodSurplusConfirmationState => {
    switch (action.type) {
        case FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD: return {
            ...state,
            foodSurplusConfirmationLoading: true,
            foodSurplusConfirmationSuccess: false,
            foodSurplusConfirmationFailure: false,
        };
        case FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD_SUCESS: return {
            ...state,
            foodSurplusConfirmation: action.data,
            foodSurplusConfirmationLoading: false,
            foodSurplusConfirmationFailure: false,
            foodSurplusConfirmationSuccess: true,
        };
        case FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD_FAILURE: return {
            ...state,
            foodSurplusConfirmation: action.data,
            foodSurplusConfirmationLoading: false,
            foodSurplusConfirmationFailure: true,
            foodSurplusConfirmationSuccess: false,
        };
        default: return state;
    }
}