import { BaseTextFieldProps, createStyles, FormControl, FormLabel, makeStyles, TextField } from '@material-ui/core';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessageComponent } from 'src/components/common/formComponents/ErrorMessage';
import { resolvePath } from 'src/components/common/utils';

export interface InputTextFieldProps extends BaseTextFieldProps {
    required?: boolean
    multiline?: boolean
    disabled?: boolean
    className?: string
    classes?: any
    onChange?: any
    fieldName: string
    id: string | undefined
    label?: string
    helperText?: string
    defaultValue?: string | number
    rules?: any
    InputProps?: any
    value?: any
    errorMsgTranslationKey?: string
    placeholder?: string
    rows?: number
    errorKeyName?: string,
    customStyle?: any,
    readOnly?: boolean,
    hideError?: boolean,
}

const useStyles = makeStyles(() => createStyles({
    input: {
        '& textarea, input': {
            color: '#495057 !important'
        },
        '& textarea:disabled, input:disabled': {
            backgroundColor: '#e9ecef'
        }
    }
}));

export const InputTextField: FC<InputTextFieldProps> = ((props: InputTextFieldProps) => {

    const { control, errors, watch } = useFormContext()
    const customClasses = useStyles();
    const errorKey = props?.errorKeyName || '';
    const fieldError = resolvePath(errorKey, errors, '')?.message;
    const readOnly = watch('readOnly');

    const defaultCustomStyle = {
        containerMarginTop: '0px',
        containerMarginBottom: '0px',
        fieldWidth: '100%',
        errorTextColor: 'red'
    }
    const style = {
        ...defaultCustomStyle,
        ...props?.customStyle
    }

    const label = props.label;
    const propCopy = { ...props }
    delete propCopy.label;
    return (
        <div
            className={props?.className}
            id={props.id}
            style={{ marginBottom: style.containerMarginBottom }}
        >
            <FormControl
                required={props.required}
                style={{ width: style.fieldWidth }}
                classes={{ root: props?.classes?.inputFormControl }}
                variant='outlined'
                error={fieldError ? true : false}
            >
                {props?.label && <FormLabel required={props.required ?? false}>
                    {label}
                </FormLabel>}
                <Controller
                    id={props.fieldName}
                    name={props.fieldName}
                    control={control}
                    defaultValue={props.defaultValue}
                    as={
                        <TextField
                            {...propCopy}
                            onChange={props.onChange}
                            id={props.fieldName}
                            rows={props?.rows}
                            required={props.required}
                            helperText={props?.helperText}
                            classes={{ root: props?.classes?.selectFormControl }}
                            className={customClasses.input}
                            InputProps={{
                                ...(props?.InputProps),
                                'data-testid': props?.fieldName
                            }
                            }
                            defaultValue={props.defaultValue}
                            disabled={props.disabled ?? readOnly ?? false}
                            multiline={props.multiline}
                            variant = 'outlined'
                            placeholder={props?.placeholder ? props.placeholder : ''}
                            error={fieldError ? true : false}
                        />
                    }
                    rules={{ ...props?.rules }}
                />
                {!props.hideError && <ErrorMessageComponent errors={errors} fieldName={errorKey} />}
            </FormControl>
        </div>
    )
})

InputTextField.defaultProps = {
    variant: 'outlined',
    InputLabelProps: { shrink: true },
    fullWidth: true,
    errorKeyName: '',
    required: true
}

