import './../../styles/orgProfile.css';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { getTodaysDate } from '../common/DateUtils';
import { resolvePath, isValidYearOfBirth } from '../common/utils';
import { ErrorMessageComponent } from '../common/formComponents/ErrorMessage';
import { countryList } from '../../static/countryList';
import KeyValueDropdown from '../common/Dropdown/keyValueDropdown'
import { useStyles } from '../donations/utils';

const termsAndConditionsKeys = ['certificationCheckBox1',
'certificationCheckBox2',
'certificationCheckBox3',
'consentCheckBox1',
'consentCheckBox2'
]
export const OrgProfileGovtForm: FC<any> = (props: any) => {

    // const classes = useMemo(() => ({ ...props.classes }), [props.classes]);
    const orgProfileData = useMemo(() => ({ ...props.orgProfileData }), [props.orgProfileData]);
    const { t } = useTranslation();
    const classes: any = useStyles()
    const provinceList: React.ReactElement[] = [];

    const initialState = {
        orgProfileData
    }

    const {
        handleSubmit,
        errors,
        control,
        setValue,
        getValues,
        trigger,
        register } = useForm({
            mode: 'onChange',
            defaultValues: {
                ...initialState,
                ...props.submission,
                address: {
                    ...((orgProfileData && orgProfileData.address) ? orgProfileData.address : {}),
                    province: (props.provinceList && props.provinceList.length > 0 && orgProfileData
                        && orgProfileData.address && orgProfileData.address.province
                        && props.provinceList.includes(orgProfileData.address.province)) ? orgProfileData.address.province : ''
                }
            }
        });


    const [selectedProvince, setSelectedProvince] = useState((props.provinceList && props.provinceList.length > 0 && orgProfileData
        && orgProfileData.address && orgProfileData.address.province
        && props.provinceList.includes(orgProfileData.address.province)) ? orgProfileData.address.province : '');
    const [updatedFields,] = useState(new Map());
    const [certificationCheckBox1Value, setCertificationCheckBox1Value] = useState(false);
    const [certificationCheckBox2Value, setCertificationCheckBox2Value] = useState(false);
    const [certificationCheckBox3Value, setCertificationCheckBox3Value] = useState(false);
    const [consentCheckBox1Value, setConsentCheckBox1Value] = useState(false);
    const [consentCheckBox2Value, setConsentCheckBox2Value] = useState(false);
    const [success,] = useState(false)

    const consentDescription = (orgProfileData && orgProfileData.countryCode && orgProfileData.countryCode === 'GB') ?
        'organizationProfileDetails.orgCertification.consentTextUk' : 'organizationProfileDetails.orgCertification.consentTextNonUk';


    const requiredFieldTranslation = t('common.validationMsg.requiredField')

    const onSubmit = (data: any) => {
      registerValidation();
      if(agreedTermsAndConditions()) {
          const submissionData = {
            ...orgProfileData,
            ...data,
            establishedDate: data.establishedDate ? data.establishedDate : ''
        }
        props.onSubmitForm(submissionData);
      } else {
        trigger(termsAndConditionsKeys);
      }
    }

    const goBack = () => {
        setInitialValuesForForm();
        props.goToDashboard();
    }

    const agreedTermsAndConditions = () => {
      return Object.values(getValues(termsAndConditionsKeys)).every(consent => consent);
    }

    const registerValidation = () => {

        register('certificationCheckBox1', {
            required: requiredFieldTranslation,
            validate: () => {
                setCertificationCheckBox1Value(!certificationCheckBox1Value);
                return true;
            },
        });
        register('certificationCheckBox2', {
            required: requiredFieldTranslation,
            validate: () => {
                setCertificationCheckBox2Value(!certificationCheckBox2Value);
                return true;
            },
        });
        register('certificationCheckBox3', {
            required: requiredFieldTranslation,
            validate: () => {
                setCertificationCheckBox3Value(!certificationCheckBox3Value);
                return true;
            },
        });
        register('consentCheckBox1', {
            required: requiredFieldTranslation,
            validate: () => {
                setConsentCheckBox1Value(!consentCheckBox1Value);
                return true;
            },
        });
        register('consentCheckBox2', {
            required: requiredFieldTranslation,
            validate: () => {
                setConsentCheckBox2Value(!consentCheckBox2Value);
                return true;
            },
        });
    }

    useEffect(() => {
        if (props.orgProfileData && props.orgProfileData.id) {
            setValuesForMandatoryFields(props.orgProfileData, props.provinceList);
            setValuesForConsentAndCertifyFields(props.orgProfileData);
        }
    }, [props.orgProfileData])

    useEffect(() => {
        if (props.failedOrgProfileData && props.failedOrgProfileData.id) {
            setValuesForMandatoryFields(props.failedOrgProfileData, props.provinceList);
            setValuesForConsentAndCertifyFields(props.failedOrgProfileData);
        }
    }, [props.failedOrgProfileData])


    useEffect(() => {
        setValue('address.province', (props.provinceList && props.provinceList.length > 0 && orgProfileData
            && orgProfileData.address && orgProfileData.address.province
            && props.provinceList.includes(orgProfileData.address.province)) ? orgProfileData.address.province : '')
    }, [props.provinceList])

    const setValuesForMandatoryFields = (orgData: any, provinceListData: any) => {
        setValue('address.addressLine1', resolvePath('address.addressLine1', orgData, ''));
        setValue('country', resolvePath('country', orgData, ''));
        setValue('address.city', resolvePath('address.city', orgData, ''));
        setValue('address.postalCode', resolvePath('address.postalCode', orgData, ''))

        const province = (provinceListData && provinceListData.length > 0 && orgData && orgData.address
            && orgData.address.province && provinceListData.includes(orgData.address.province)) ? orgData.address.province : ''
        setValue('address.province', province)
        setSelectedProvince(province);

        setValue('certificateSignedDate', getTodaysDate())
        setValue('consentSignedDate', getTodaysDate())
    }

    const setValuesForConsentAndCertifyFields = (orgData: any) => {
        setValue('certificateSignedDate', getTodaysDate())
        setValue('consentSignedDate', getTodaysDate())
        setCertificationCheckBox1Value(true)
        trigger('certificationCheckBox1')
        setCertificationCheckBox2Value(true)
        trigger('certificationCheckBox2')
        setCertificationCheckBox3Value(true)
        trigger('certificationCheckBox3')
        setConsentCheckBox1Value(true)
        trigger('consentCheckBox1')
        setConsentCheckBox2Value(true)
        trigger('consentCheckBox2')

        setValue('certificateSignee.signature', resolvePath('certificateSignee.signature', orgData, ''));
        setValue('certificateSignee.userTitle', resolvePath('certificateSignee.userTitle', orgData, ''));
        setValue('certificateSignee.employer', resolvePath('certificateSignee.employer', orgData, ''));

        setValue('consentSignee.userName', resolvePath('consentSignee.userName', orgData, ''));
        setValue('consentSignee.userTitle', resolvePath('consentSignee.userTitle', orgData, ''));
        setValue('consentSignee.birthYear', resolvePath('consentSignee.birthYear', orgData, 0));
        setValue('consentSignee.countryOfResidence', resolvePath('consentSignee.countryOfResidence', orgData, 0));
    }

    const setInitialValuesForForm = () => {
        setStateValuesToInitialValues();
        setValue('country', '');
        setValue('address.addressLine1', '');
        setValue('address.city', '');
        setValue('address.postalCode', '');
        setValue('address.province', '');
        setValue('certificateSignee.signature', '');
        setValue('certificateSignee.userTitle', '');
        setValue('certificateSignee.employer', '');
        setValue('certificateSignedDate', '');
        setValue('consentSignee.userName', '');
        setValue('consentSignee.userTitle', '');
        setValue('consentSignee.birthYear', 0);
        setValue('consentSignee.countryOfResidence', '');
        setValue('consentSignedDate', '');
    }

    const setStateValuesToInitialValues = () => {
        setSelectedProvince('');
        setCertificationCheckBox1Value(false);
        setCertificationCheckBox2Value(false);
        setCertificationCheckBox3Value(false);
        setConsentCheckBox1Value(false);
        setConsentCheckBox2Value(false);
    }

    const onValidate = (data: any, path: string) => {
        const actualValue = resolvePath(path, orgProfileData)
        const isUpdated = updatedFields.has(path);

        if (!isUpdated || actualValue && (actualValue !== data)) {
            updatedFields.set(path, data);
        } else {
            updatedFields.delete(path);
        }
        return true;
    }

    return (
        <div className='org-details-form bodyStyles'>
            <div className='org-details-header'>
                {t('organizationProfileDetails.common.orgProfileHeader')}
            </div>
            <div className={`org-profile-form-group-container`}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <label className='col-form-label'>
                                {t('organizationProfileDetails.common.orgLegalName')}
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div>
                                <label className='non-editable-text'>
                                    <b>{resolvePath('orgLegalName', orgProfileData, '-')}</b>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div style={{ textAlign: 'left', float: 'left', marginRight: '20%' }}>
                                <div>
                                    <label className='col-form-label'>
                                        {t('organizationProfileDetails.common.orgId')}
                                    </label>
                                </div>
                                <div>
                                    <label className='non-editable-text'>
                                        <b>{resolvePath('id', orgProfileData, '-')}</b>
                                    </label>
                                </div>
                            </div>
                            <div style={{ textAlign: 'left', float: 'left' }}>
                                <div>
                                    <label className='col-form-label'>
                                        {t('orgRegForm.labels.taxId')}
                                    </label>
                                </div>
                                <div>
                                    <label className='non-editable-text'>
                                        <b>{resolvePath('taxId', orgProfileData, 'N/A')}</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <label className='col-form-label org-sub-header'>
                                <b>{t('organizationProfileDetails.common.orgAddress')}</b>
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 col-md-10 col-xl-8'>
                            <Controller
                                control={control}
                                name='address.addressLine1'
                                id='address.addressLine1'
                                defaultValue={''}
                                style={{ marginBottom: '3px' }}
                                as={
                                    <TextField
                                        required={true}
                                        disabled={props.readOnlyMode}
                                        id='address.addressLine1'
                                        label={t('organizationProfileDetails.common.address')}
                                        helperText=''
                                        defaultValue={''}
                                        fullWidth={true}
                                        margin='normal'
                                        classes={{ root: classes.textFormControl }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant='outlined'
                                        error={errors.address?.addressLine1?.message}
                                    />
                                }
                                rules={{
                                    required: requiredFieldTranslation,
                                    validate: (data) => onValidate(data, 'address.addressLine1')
                                }}
                            />
                            <ErrorMessageComponent errors={errors} fieldName={'address.addressLine1'} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                            <Controller
                                control={control}
                                name='country'
                                id='country'
                                defaultValue={''}
                                style={{ marginBottom: '3px' }}
                                as={
                                    <TextField
                                        required={true}
                                        disabled={true}
                                        id='country'
                                        label={t('organizationProfileDetails.common.country')}
                                        helperText=''
                                        defaultValue={''}
                                        fullWidth={true}
                                        margin='normal'
                                        classes={{ root: classes.textFormControl }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant='outlined'
                                        error={errors?.country?.message}
                                    />
                                }
                                rules={{
                                    required: requiredFieldTranslation
                                }}
                            />
                            <ErrorMessageComponent errors={errors} fieldName={`country`} />
                        </div>
                        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4 dropdown-label' style={{ marginTop: '15px' }}>
                            <FormControl classes={{ root: classes.selectFormControl }}
                                fullWidth={true}
                                size='medium' variant='outlined'
                                error={errors.address?.province?.message}>
                                <InputLabel classes={{ root: classes.selectLabel }} id='province-simple-select-label'>
                                    {t('organizationProfileDetails.common.state')}
                                </InputLabel>
                                <Controller
                                    control={control}
                                    name='address.province'
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <Select
                                            classes={{ root: classes.selectRoot, icon: classes.selectIcon }}
                                            labelId='province-select-label'
                                            id='address.province'
                                            name='address.province'
                                            autoWidth={true}
                                            defaultValue={''}
                                            disabled={props.readOnlyMode}
                                            displayEmpty={true}>
                                            {(props.provinceList && props.provinceList.length > 0) ?
                                                props.provinceList.map((provinceItem: any) => {
                                                    if (provinceItem) {
                                                        provinceList.push(
                                                            <MenuItem classes={{
                                                                root: classes.menuItemRoot,
                                                                selected: classes.menuItemselected
                                                            }}
                                                                key={provinceItem}
                                                                value={provinceItem}>{provinceItem}</MenuItem>
                                                        )
                                                    }
                                                })
                                                : <></>}
                                            {provinceList}
                                        </Select>
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                        validate: (data: any) => {
                                            if (selectedProvince !== data) {
                                                setSelectedProvince(data);
                                                onValidate(data, 'address.province')
                                            }
                                            return true;
                                        }
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName={`address.province`} />
                            </FormControl>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                            <Controller
                                control={control}
                                name='address.city'
                                id='address.city'
                                defaultValue={''}
                                style={{ marginBottom: '3px' }}
                                as={
                                    <TextField
                                        required={true}
                                        disabled={props.readOnlyMode}
                                        id='address.city'
                                        label={t('organizationProfileDetails.common.city')}
                                        helperText=''
                                        defaultValue={''}
                                        fullWidth={true}
                                        margin='normal'
                                        classes={{ root: classes.textFormControl }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant='outlined'
                                        error={errors.address?.city?.message}
                                    />
                                }
                                rules={{
                                    required: requiredFieldTranslation,
                                    validate: (data) => onValidate(data, 'address.city')
                                }}
                            />
                            <ErrorMessageComponent errors={errors} fieldName={`address.city`} />
                        </div>
                        <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                            <Controller
                                control={control}
                                name='address.postalCode'
                                id='address.postalCode'
                                defaultValue={''}
                                style={{ marginBottom: '3px' }}
                                as={
                                    <TextField
                                        required={true}
                                        disabled={props.readOnlyMode}
                                        id='address.postalCode'
                                        label={t('organizationProfileDetails.common.postalCode')}
                                        helperText=''
                                        defaultValue={''}
                                        fullWidth={true}
                                        margin='normal'
                                        classes={{ root: classes.textFormControl }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant='outlined'
                                        error={errors.address?.postalCode?.message}
                                    />
                                }
                                rules={{
                                    required: requiredFieldTranslation,
                                    validate: (data) => onValidate(data, 'address.postalCode')
                                }}
                            />
                            <ErrorMessageComponent errors={errors} fieldName={`address.postalCode`} />
                        </div>
                    </div>
                    <>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='col-form-label org-sub-header'>
                                    <b>{t('organizationProfileDetails.orgCertification.certificationTitle')}</b>
                                </label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <FormControlLabel
                                    value={certificationCheckBox1Value}
                                    disabled={props.readOnlyMode}
                                    control={(props.readOnlyMode)
                                        ? <Checkbox color='primary' checked={true} />
                                        : <Checkbox color='primary' />}
                                    label={t('organizationProfileDetails.orgCertification.checkbox1')}
                                    name={`certificationCheckBox1`}
                                    inputRef={register}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='certificationCheckBox1' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <FormControlLabel
                                    value={certificationCheckBox2Value}
                                    disabled={props.readOnlyMode}
                                    control={(props.readOnlyMode)
                                        ? <Checkbox color='primary' checked={true} />
                                        : <Checkbox color='primary' />}
                                    label={
                                      <Trans i18nKey='organizationProfileDetails.orgCertification.checkbox2'>
                                        Government Entity  understands that the Company's Global Anti-Corruption Policy is available at
                                        (<a target='_blank' href='https://walmartethics.com'>https://walmartethics.com</a>)
                                    </Trans>}
                                    name={`certificationCheckBox2`}
                                    inputRef={register}
                                />

                                <ErrorMessageComponent errors={errors} fieldName='certificationCheckBox2' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <FormControlLabel
                                    value={certificationCheckBox3Value}
                                    disabled={props.readOnlyMode}
                                    control={(props.readOnlyMode)
                                        ? <Checkbox color='primary' checked={true} />
                                        : <Checkbox color='primary' />}
                                    label={t('organizationProfileDetails.orgCertification.checkbox3')}
                                    name={`certificationCheckBox3`}
                                    inputRef={register}
                                />

                                <ErrorMessageComponent errors={errors} fieldName='certificationCheckBox3' />
                            </div>
                        </div>  <div className='row'>
                            <div className='col-sm-12 col-md-10 col-xl-8'>
                                <Controller
                                    control={control}
                                    name='certificateSignee.signature'
                                    id='certificateSignee.signature'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={props.readOnlyMode}
                                            id='certificateSignee.signature'
                                            label={t('organizationProfileDetails.orgCertification.fullNameorSignature')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.certificateSignee?.signature?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='certificateSignee.signature' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                                <Controller
                                    control={control}
                                    name='certificateSignee.userTitle'
                                    id='certificateSignee.userTitle'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={props.readOnlyMode}
                                            id='certificateSignee.userTitle'
                                            label={t('organizationProfileDetails.orgCertification.userTitle')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.certificateSignee?.userTitle?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='certificateSignee.userTitle' />
                            </div>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                                <Controller
                                    control={control}
                                    name='certificateSignee.employer'
                                    id='certificateSignee.employer'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={props.readOnlyMode}
                                            id='certificateSignee.employer'
                                            label={t('organizationProfileDetails.orgCertification.employer')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.certificateSignee?.employer?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='certificateSignee.employer' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                                <Controller
                                    control={control}
                                    name='certificateSignedDate'
                                    id='certificateSignedDate'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={true}
                                            id='certificateSignedDate'
                                            label={t('organizationProfileDetails.orgCertification.date')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.certificateSignedDate?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='certificateSignedDate' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className='col-form-label org-sub-header'>
                                    <b>{t('organizationProfileDetails.orgCertification.consentTitle')}</b>
                                </label>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <Trans i18nKey={consentDescription}>
                                    Consent text injected dynamically based on key
                                </Trans>
                            </div>
                        </div>
                    </>
                    <>
                        <div className='row'>
                            <div className='col-md-10'>
                                <FormControlLabel
                                    value={consentCheckBox1Value}
                                    disabled={props.readOnlyMode}
                                    control={(props.readOnlyMode)
                                        ? <Checkbox color='primary' checked={true} />
                                        : <Checkbox color='primary' />}
                                    label= {
                                      <Trans i18nKey='organizationProfileDetails.orgCertification.consentCheckbox1'>
                                      I confirm that I have reviewed Walmart Privacy Notice
                                      (<a target='_blank'
                                      href='https://corporate.walmart.com/privacy-security/walmart-anti-corruption-due-diligence-privacy-notice'>
                                      https://corporate.walmart.com/privacy-security/walmart-anti-corruption-due-diligence-privacy-notice
                                      </a>)
                                  </Trans>}
                                    name={`consentCheckBox1`}
                                    inputRef={register}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='consentCheckBox1' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-10'>
                                <FormControlLabel
                                    value={consentCheckBox2Value}
                                    disabled={props.readOnlyMode}
                                    control={(props.readOnlyMode)
                                        ? <Checkbox color='primary' checked={true} />
                                        : <Checkbox color='primary' />}
                                    label={t('organizationProfileDetails.orgCertification.consentCheckbox2')}
                                    name={`consentCheckBox2`}
                                    inputRef={register}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='consentCheckBox2' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-10 col-xl-8'>
                                <Controller
                                    control={control}
                                    name='consentSignee.userName'
                                    id='certificateSignee.userName'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={props.readOnlyMode}
                                            id='consentSignee.userName'
                                            label={t('organizationProfileDetails.orgCertification.fullNameorSignature')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.consentSignee?.userName?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='consentSignee.userName' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                                <Controller
                                    control={control}
                                    name='consentSignee.userTitle'
                                    id='consentSignee.userTitle'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={props.readOnlyMode}
                                            id='consentSignee.userTitle'
                                            label={t('organizationProfileDetails.orgCertification.userTitle')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.consentSignee?.userTitle?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='consentSignee.userTitle' />
                            </div>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                                <Controller
                                    control={control}
                                    name='consentSignee.birthYear'
                                    id='consentSignee.birthYear'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={props.readOnlyMode}
                                            id='consentSignee.birthYear'
                                            label={t('organizationProfileDetails.orgCertification.yearOfBirth')}
                                            type='number'
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.consentSignee?.birthYear?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                        validate: (value: any) => isValidYearOfBirth(value, t),
                                        valueAsNumber: true
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='consentSignee.birthYear' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4' style={{ marginTop: '16px' }}>
                                <KeyValueDropdown
                                    id={'countryOfResidence'}
                                    inputLabel={t('appendixBCertification.countryOfResidence')}
                                    fieldName={'consentSignee.countryOfResidence'}
                                    defaultValue={''}
                                    options={countryList}
                                    errors={errors}
                                    control={control}
                                    disabled={props.readOnlyMode}
                                    valueKey='label'
                                    labelKey='label'
                                    rules={{
                                        required: requiredFieldTranslation
                                    }}
                                    errorKeyName={'consentSignee.countryOfResidence'}
                                />
                            </div>
                            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                                <Controller
                                    control={control}
                                    name='consentSignedDate'
                                    id='consentSignedDate'
                                    defaultValue={''}
                                    style={{ marginBottom: '3px' }}
                                    as={
                                        <TextField
                                            required={true}
                                            disabled={true}
                                            id='consentSignedDate'
                                            label={t('organizationProfileDetails.orgCertification.todayDate')}
                                            helperText=''
                                            defaultValue={''}
                                            fullWidth={true}
                                            margin='normal'
                                            classes={{ root: classes.textFormControl }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            variant='outlined'
                                            error={errors.consentSignedDate?.message}
                                        />
                                    }
                                    rules={{
                                        required: requiredFieldTranslation,
                                    }}
                                />
                                <ErrorMessageComponent errors={errors} fieldName='consentSignedDate' />
                            </div>
                        </div>
                    </>

                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='org-profile-btn-group'>
                                <button className={`org-profile-action-btn mr-right-2 mr-right-2-bottom-1`}
                                    type='button'
                                    data-testid='cancel-btn'
                                    onClick={goBack}
                                >
                                    {t('common.buttonLabels.cancel')}
                                </button>
                                <button className={`org-profile-submit-btn`}
                                    type='submit'
                                    data-testid='submit-btn'>
                                    {t('organizationProfileDetails.common.saveChangesBtn')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <SuccessSnackBarComponent
                isSuccess={success}
                message={t('common.successMsg.sendForDDMsg')} />
        </div>
    );
}

OrgProfileGovtForm.displayName = 'Organization Profile Government';
