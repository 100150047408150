import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { InputBox, FileUploadComponent } from '../../common'
import { getFormattedDate, getTodaysDate } from '../../common/DateUtils'
import { FormBlockHeading } from '../../donations/utils'
import { isValidYearOfBirth } from '../utils'
import { CustomCheckBox } from './CustomCheckBox'
import { countryList } from '../../../static/countryList';
import KeyValueDropdown from '../Dropdown/keyValueDropdown'
export interface AppendixBCertificationProps {
    data: any
    disabled?: boolean
    classes: any
    uploadedOrgFiles?: any
    isReview?: boolean
    showEmptyFileMessage?: boolean
    displaydata?: boolean
    errorClassName?:string
}

export const AppendixBCertification: FC<AppendixBCertificationProps> = ({ data, disabled = false,displaydata = false, isReview = false, classes, ...props }) => {
    const { t } = useTranslation()
    const { errors, control, setValue } = useFormContext()
    const requiredFieldTranslation = t('common.validationMsg.requiredField')
    const { certificateSignee, certificateSignedDate } = data

    let signedDate;
    useEffect(() => {
        if (isReview === false) {
            signedDate = getTodaysDate();
        }
        else if (certificateSignedDate) {
            signedDate = getFormattedDate(certificateSignedDate);
        }
        else {
            signedDate = getTodaysDate();
        }
        setValue('certificateSignedDate', signedDate);
    }, [isReview, data.certificateSignedDate])

    return (<div>
        <FormBlockHeading heading={t('appendixBCertification.certification')} />
        <div className='row'>
            <div className='col-md-10'>
                <CustomCheckBox
                    required={true}
                    style={{ labelColor: '#2E2F32', labelFontWeight: 'bold' }}
                    id={'certificationCheckBox1'}
                    fieldName={'certificationCheckBox1'}
                    label={t('appendixBCertification.checkbox1')}
                    defaultValue={data?.certificationCheckBox1}
                    disabled={disabled}
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorMsg={errors?.certificationCheckBox1?.message}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-10'>
                <CustomCheckBox
                    required={true}
                    style={{ labelColor: '#2E2F32', labelFontWeight: 'bold' }}
                    id={'certificationCheckBox2'}
                    fieldName={'certificationCheckBox2'}
                    label={
                    <Trans i18nKey='appendixBCertification.checkbox2'>
                        Organization understands that the Company's Global Anti-Corruption Policy is available at <a target='_blank' href='https://walmartethics.com' rel='noreferrer'>https://walmartethics.com</a>
                    </Trans>
                    }
                    defaultValue={data?.certificationCheckBox2}
                    disabled={disabled}
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorMsg={errors?.certificationCheckBox2?.message}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-10'>
                <CustomCheckBox
                    required={true}
                    style={{ labelColor: '#2E2F32', labelFontWeight: 'bold' }}
                    id={'certificationCheckBox3'}
                    fieldName={'certificationCheckBox3'}
                    label={t('appendixBCertification.checkbox3')}
                    defaultValue={data?.certificationCheckBox3}
                    disabled={disabled}
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorMsg={errors?.certificationCheckBox3?.message}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-sm-12 col-md-10 col-xl-8'>
                <InputBox
                    id={'certificateSignee-signature'}
                    className={'certificateSignee-signature'} 
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.fullNameOrSignature')}
                    fieldName={'certificateSignee.signature'}
                    defaultValue={disabled || displaydata ? certificateSignee?.signature : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                    }}
                    errorMsg={errors?.certificateSignee?.signature?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'certificateSignee-userTitle'}
                    className={'certificateSignee-userTitle'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.userTitle')}
                    fieldName={'certificateSignee.userTitle'}
                    defaultValue={disabled || displaydata ? certificateSignee?.userTitle : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                    }}
                    errorMsg={errors?.certificateSignee?.userTitle?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'certificateSignee-birthYear'}
                    className={'certificateSignee-birthYear'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.yearOfBirth')}
                    fieldName={'certificateSignee.birthYear'}
                    defaultValue={disabled || displaydata ? certificateSignee?.birthYear : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                        validate: (value: any) => isValidYearOfBirth(value, t)
                    }}
                    errorMsg={errors?.certificateSignee?.birthYear?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4' style={{ marginTop: '16px' }}>
                <KeyValueDropdown
                    id={'countryOfResidence'}
                    inputLabel={t('appendixBCertification.countryOfResidence')}
                    fieldName={'certificateSignee.countryOfResidence'}
                    defaultValue={disabled || displaydata ? certificateSignee?.countryOfResidence : ''}
                    options={countryList}
                    errors={errors}
                    control={control}
                    disabled={disabled}
                    required={true}
                    valueKey='label'
                    labelKey='label'
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorKeyName={'certificateSignee.countryOfResidence'}
                    errorClassName={props.errorClassName}
                />
            </div>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'certificateSignee-employer'}
                    className={'certificateSignee-employer'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.employer')}
                    fieldName={'certificateSignee.employer'}
                    defaultValue={disabled || displaydata ? certificateSignee?.employer : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                    }}
                    errorMsg={errors?.certificateSignee?.employer?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'certificateSignedDate'}
                    className={'certificateSignedDate'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.todayDate')}
                    fieldName={'certificateSignedDate'}
                    disabled={true}
                    defaultValue={signedDate}
                    required={true}
                    InputProps={{
                        classes: {
                            disabled: classes?.disabled
                        }
                    }}
                />
            </div>
        </div>
        <div className='row' style={{ marginBottom: '20px' }}>
            <div className='col-md-10'>
            {
                !displaydata &&
                <FileUploadComponent
                    heading={t('common.heading.orgDocuments')}
                    name={`organizationAttachments`}
                    defaultFiles={props?.uploadedOrgFiles}
                    disabled={true}
                    readOnly={true}
                    allowMultiple={true}
                    showFileUpload={!disabled}
                    showEmptyFileMessage={props?.showEmptyFileMessage}
                />
            }
            </div>
        </div>
    </div>)
}
