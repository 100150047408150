import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessageComponent } from 'src/components/common/formComponents/ErrorMessage';
import InformationBar from '../common/informationBar';
import { InputTextField } from 'src/components/common/Input/InputTextField';
import { SectionContainer } from '../../donationOfMoney/sectionsContainer';
import { BaseSectionProps, IBoardMembers, IBoardMembersFields, RadioOption, Sections } from './orgDetailsDefaults';

/* tslint:disable */

interface IBoardMembersProps extends BaseSectionProps {
    boardMembersList?: IBoardMembers
}

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important'
    },
    bmTableContainer: {
        maxWidth: '840px',
        width: '100%'
    }
});

export const BoardMembers: FC<IBoardMembersProps> = (props: IBoardMembersProps) => {
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);

    const columns = [
        { id: 'additionalUserDetails.isReceivingSalary', title: 'ReceiveSalary' },
        { id: 'userName', title: getTranslation('orgBoaName') },
        { id: 'userTitle', title: getTranslation('orgBoaTitle') },
        { id: 'birthYear', title: getTranslation('orgBoaYOB') },
        { id: 'countryOfResidence', title: getTranslation('orgBoaCOR') }
    ];
    const methods = useFormContext();
    const { fieldName } = props;
    const boardMembersFields = useFieldArray({
        control: methods.control,
        name: `${fieldName}.boardMembers`
    });
    const [isAllSelected, setisAllSelected] = useState<boolean>(false);
    const [isReceivingSalary, setIsReceivingSalary] = useState<RadioOption>(RadioOption.No);
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);
    const classes = useStyles();
    const boardMemberWatch = methods.watch(`${fieldName}.boardMembers`);
    const isReceivingSalaryWatch = methods.watch(`${fieldName}.isReceivingSalary`);

    useEffect(() => {
        const isReceivingSalary = boardMembersFields.fields.
            filter((field) => (field.additionalUserDetails.isReceivingSalary === true)).length > 0;
        setIsReceivingSalary(isReceivingSalary ? RadioOption.Yes : RadioOption.No);

    }, []);

    useEffect(() => {

        setIsDisabled(isReceivingSalaryWatch === RadioOption.Yes ? false : true);
        setIsReceivingSalary(isReceivingSalaryWatch);

        const selectedCount = boardMemberWatch?.filter((field: IBoardMembersFields) =>
            (field.additionalUserDetails.isReceivingSalary === true)).length

        if ((selectedCount !== undefined)
            && (selectedCount === boardMemberWatch?.length)) {
            setIsIndeterminate(false);
            setisAllSelected(true);
        } else if (selectedCount === 0) {
            setIsIndeterminate(false);
            setisAllSelected(false);
        }

    }, [methods.watch]);

    useEffect(() => {
      if(isReceivingSalary === RadioOption.Yes) {
        methods.trigger(`${props.fieldName}.personsOnPayroll`)
      }
    }, [isReceivingSalary])

    const selectAllorNone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const formValues = methods.getValues();
        let boardMembers = { ...formValues[fieldName] };

        boardMembers.boardMembers = boardMembers.boardMembers.map((member: IBoardMembersFields) => {
            member.additionalUserDetails.isReceivingSalary = event.target.checked;
            return member;
        });

        methods.setValue(fieldName, { ...boardMembers }, { shouldValidate: true });
        setisAllSelected(event.target.checked)
    }

    const handleIsReceivingSalary = (checked: RadioOption) => {
        const values = methods.getValues()[fieldName];

        if (checked === RadioOption.No) {

            values.boardMembers = values.boardMembers.map((bm: IBoardMembersFields) => {
                const newValue = { ...bm };

                newValue.additionalUserDetails.isReceivingSalary = false;

                return newValue;
            })

            methods.setValue(fieldName, { ...values }, { shouldValidate: true })
        }
    }
    return (
        <SectionContainer title={getTranslation('orgBoaMemTitle')}>
           <Grid container={true} item={true} spacing={3}>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} >
                    <FormControl component='fieldset'  >
                        <FormLabel color='secondary' >{getTranslation('orgBoaRecSal')}</FormLabel>
                        <Controller
                            control={methods.control}
                            defaultValue={isReceivingSalary}
                            name={`${fieldName}.isReceivingSalary`}
                            render={({ onBlur, onChange, value }) => {
                                return (<RadioGroup row={true} value={value} onBlur={onBlur}
                                    onChange={(event) => {
                                        onChange(event.target.value); handleIsReceivingSalary(event.target.value as RadioOption)
                                    }} >
                                    <FormControlLabel value={RadioOption.Yes} control={<Radio color='default' data-testid='boardmembersyes' />} label={getTranslation('orgBoaRecSalYes')} />
                                    <FormControlLabel value={RadioOption.No} control={<Radio color='default' data-testid='boardmembersno' />} label={getTranslation('orgBoaRecSalNo')} />
                                </RadioGroup>);
                            }}
                        />
                        <ErrorMessageComponent errors={methods.errors} fieldName={`${props.fieldName}.isReceivingSalary`} />
                    </FormControl>
                </Grid>
                {isReceivingSalary === RadioOption.Yes && <Grid item={true} xs={12} className={classes.bmTableContainer}>
                    <TableContainer classes={{ root: classes.root }} component={Paper}>
                        <Table className={classes.table} aria-label='board members table' size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column, idx) => {
                                            if (column.title === 'ReceiveSalary') {
                                                return (<TableCell key={`header${idx}`}><Checkbox color='default'
                                                    indeterminate={isIndeterminate}
                                                    checked={isAllSelected}
                                                    disabled={isDisabled}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => selectAllorNone(event)}
                                                />
                                                </TableCell>);
                                            }
                                            return <TableCell key={`header${idx}`}>{column.title}</TableCell>
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {boardMembersFields.fields.map((field: any, rowIdx: number) => {
                                    const partialKey = `${fieldName}.boardMembers[${rowIdx}]`;
                                    return (<TableRow key={field.id}>
                                        <TableCell>
                                            <Controller
                                                name={`${partialKey}.additionalUserDetails.isReceivingSalary`}
                                                control={methods.control}
                                                defaultValue={field.additionalUserDetails.isReceivingSalary}

                                                render={(...props) => {
                                                    // @ts-ignore
                                                    return (<Checkbox inputProps={{ 'data-testid': `bm_chk_${rowIdx}` }}
                                                        {...props[0]} disabled={isDisabled} color='default'
                                                        checked={props[0].value}
                                                        onChange={(e) => props[0].onChange(e.target.checked)} />
                                                    )
                                                }} />
                                        </TableCell>
                                        <TableCell>{field.userName}
                                            <input type='hidden' value={field.userName} data-testid={`bm_name_${rowIdx}`}
                                                // @ts-ignore
                                                name={`${partialKey}.userName`} ref={methods.register} />
                                        </TableCell>
                                        <TableCell>
                                            {field.userTitle}
                                            <input type='hidden' value={field.userTitle} data-testid={`bm_title_${rowIdx}`}
                                                // @ts-ignore
                                                name={`${partialKey}.userTitle`} ref={methods.register} />
                                        </TableCell>
                                        <TableCell>{field.birthYear}
                                            <input type='hidden' value={field.birthYear} data-testid={`bm_birthyr_${rowIdx}`}
                                                // @ts-ignore
                                                name={`${partialKey}.birthYear`} ref={methods.register} />
                                        </TableCell>
                                        <TableCell> {field.birthPlace}
                                            <input type='hidden' value={field.birthPlace} data-testid={`bm_residence_${rowIdx}`}
                                                // @ts-ignore
                                                name={`${partialKey}.birthPlace`} ref={methods.register} />
                                        </TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>}
                <ShowTableError receivingSalaryWatch={isReceivingSalaryWatch} boardMembersWatch={boardMemberWatch} fieldName={`boardMemberNoneSelected`} />
            </Grid>
            { isReceivingSalary === RadioOption.Yes &&
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12} md={4}>
                        <InputTextField
                            fieldName={`${props.fieldName}.personsOnPayroll`}
                            type={'number'}
                            label={getTranslation('orgMisNum')}
                            id={'orgMissionPurpose'}
                            errorKeyName={`${props.fieldName}.personsOnPayroll`}
                        />
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                        <Box paddingTop = {'24px'}>
                        <InformationBar>
                          {getTranslation('orgMisNumDesc')}
                          </InformationBar>
                        </Box>
                    </Grid>
                </Grid>}
          </Grid>
        </SectionContainer>
    );
}


const ShowTableError: FC<{
    receivingSalaryWatch: string,
    boardMembersWatch: IBoardMembersFields[],
    fieldName: string
}> = ({ receivingSalaryWatch, boardMembersWatch, fieldName }) => {
    const { register, setValue, errors, getValues } = useFormContext();


    const totalCallBack = useCallback(() => {
        if (receivingSalaryWatch === RadioOption.Yes) {
            const isAnySelected = boardMembersWatch.filter((mem: any) => (mem.additionalUserDetails.isReceivingSalary === true)).length > 0
            const values = getValues()[Sections.boardMemberSelection];
            if (!isAnySelected) {
                setValue(fieldName, RadioOption.No, { shouldValidate: true })
            } else if (values === RadioOption.No) {
                setValue(fieldName, RadioOption.Yes, { shouldValidate: true })
            }
        } else {
            setValue(fieldName, RadioOption.Yes, { shouldValidate: true })
        }
    }, [receivingSalaryWatch, boardMembersWatch]);


    useEffect(() => {
        totalCallBack();

    }, [totalCallBack])

    return (<div style={{ marginTop: '5px' }}>
        <input type='hidden' name={fieldName} ref={register} />
        <ErrorMessageComponent errors={errors} fieldName={fieldName} />
    </div>)

}