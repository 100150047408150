


import {TextField} from '@material-ui/core';
import {Controller, useForm} from 'react-hook-form';
import {orgUserManagementTranslations} from '../../constants';
import 'src/styles/orgUser.css'

const OrgUserRestPasswordForm = (props: any) => {
    const {classes} = props;
    const intitialState: any = {};


    const {
        handleSubmit,
        control,
        errors,
        getValues,
        trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            ...intitialState,
            ...props.selectedOrgUser
        }
    });


    const validatePassword = (value: any) => {
        const passwordValue = getValues('newPassword');
        if(value!==passwordValue){
            return `${orgUserManagementTranslations.resetPasswordModal.msgPasswordNotMatching[props.selectedLang]}`;
        }
        return true
    }
    const onSubmit = (data: any) => {
        props.resetPassword({
            ...data,
        });
    }

    return (
        <div className={`org-user-form-container`}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off' style={{width: '60%'}}>
                <div>
                    <Controller
                        control={control}
                        name='newPassword'
                        defaultValue={''}
                        style={{marginTop: '0px', marginBottom: '3px'}}
                        as={
                            <div className='reset-pwd-dl'>
                                <TextField
                                    required={true}
                                    type='password'
                                    id='newPassword'
                                    label={orgUserManagementTranslations.resetPasswordModal.password[props.selectedLang]}
                                    helperText=''
                                    defaultValue={''}
                                    fullWidth={true}
                                    margin='normal'
                                    classes={{root: classes.selectFormControl}}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {fontWeight: 'bold', fontSize: '1rem', backgroundColor: 'white'},
                                    }}
                                    variant='outlined'
                                    error={errors?.newPassword?.message}
                                />
                            </div>
                        }
                        rules={{
                            required: orgUserManagementTranslations.resetPasswordModal.msgInValidPassword[props.selectedLang],
                            pattern: {
                                value: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/,
                                message: orgUserManagementTranslations.resetPasswordModal.lblPasswordType[props.selectedLang]
                            },
                            validate: ()=>{
                                trigger('confirmPass');
                                return true;
                            }
                        }}
                    />
                    {errors.newPassword?.message && <span className='form-error-container'
                    style={{color: 'red'}}>{errors.newPassword?.message}</span>}
                </div>
                <div>
                    <Controller
                        control={control}
                        name='confirmPass'
                        defaultValue={''}
                        style={{marginTop: '0px', marginBottom: '3px'}}
                        as={
                            <div className='reset-pwd-dl'>
                                <TextField
                                    required={true}
                                    type='password'
                                    id='confirmPass'
                                    label={orgUserManagementTranslations.resetPasswordModal.confirmPassword[props.selectedLang]}
                                    helperText=''
                                    defaultValue={''}
                                    fullWidth={true}
                                    margin='normal'
                                    classes={{root: classes.selectFormControl}}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: {fontWeight: 'bold', fontSize: '1rem', backgroundColor: 'white'},
                                    }}
                                    variant='outlined'
                                    error={errors?.confirmPass?.message}
                                />
                            </div>
                        }
                        rules={{
                            required: orgUserManagementTranslations.resetPasswordModal.msgInValidPassword[props.selectedLang],
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
                                message: orgUserManagementTranslations.resetPasswordModal.lblPasswordType[props.selectedLang]
                            },
                            validate:validatePassword
                        }}
                    />
                    {errors.confirmPass?.message && <span className='form-error-container'
                    style={{color: 'red'}}>{errors.confirmPass?.message}</span>}
                </div>
                <div>
                    <div className='org-user-btn-group' style={{marginBottom:'20px'}}>
                        <div className='org-user-btn-group'>
                            <div className='inline-flex-container'
                                 style={{marginTop: '-8px', alignItems: 'center'}}>
                                <button className={`sip-submit-btn`} style={{width:'auto', padding:'0px 20px 0px 20px'}}
                                        type='submit'>
                                    {orgUserManagementTranslations.resetPasswordModal.yesButtonText[props.selectedLang]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default OrgUserRestPasswordForm;
