
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, InputLabel } from '@material-ui/core';
import { ISocialImpactSummary, MonetarySocialImpactSections } from '../socialImpactReportData';
import {InputTextField} from '../../../common/Input/InputTextField';

interface OwnProps {
    socialImpactSummary: ISocialImpactSummary
}

const SocialImpactSummary: FC<OwnProps> = ({socialImpactSummary}) => {
    const {t} = useTranslation();
    const fieldName = MonetarySocialImpactSections.SocialImpactSummary;
    return <Fragment>
        <Box>
            <Box my = {2}>
                <InputLabel required={true}>
                    {t('socialImpactReport.describeActivities').replace('{0}', socialImpactSummary.ngoName)}
                </InputLabel>
            </Box>
            <Box mb ={2} width='100%'>
                <InputTextField
                    required={true}
                    fieldName={`${fieldName}.summary`}
                    errorKeyName={`${fieldName}.summary`}
                    label={t('socialImpactReport.descriptionInput')}
                    id={`${fieldName}.summary`}
                    InputProps={{readOnly: false}}
                    fullWidth={true}
                    rows={4}
                    multiline={true}
                    defaultValue={socialImpactSummary.summary}
                />
            </Box>
        </Box>
    </Fragment>
}

export default SocialImpactSummary;
