//@ts-nocheck
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAppendixTypes } from './../../service/apiService';
import DownloadAppendixModal from './../../components/common/download-appendix/DownloadAppendixDialog';
import { paginator } from './../../components/common/paginatorConfig';
import LoaderComponent from './../../components/LoaderComponent';
import { useDashboardData } from '../donations/donationDashboard/useDashboardData';
import { hanldeError } from './../../service/apiService';
import { DownloadAppendixProps } from './../../types/downloadPdfTypes';
import { donationColumnList, onPage, onSort, onFilter, exportHeader } from '../../components/donations/utils';
import { getQuaetersDateRangeForAppendixC } from '../../components/common/DateUtils';
import { DONATION_TYPE_VALUES, DONATION_TYPE_MAP } from '../../constants';
import { type } from 'os';
//import { DONATION_TYPE_VALUES } from "../../../constants";
import { getAppConfigTypes } from "../../../service/apiService";

const Table = (props: any) => {
  /* istanbul ignore next */
  const initlDownloadAppendixProps = {
    countryCode: props?.countryCode,
    donationDetails: {},
    appendixTypeList: [{ label: '', value: '' }],
    showDialog: false,
    closeDailog: () => setShowAppendixDownloadModal(false),
    onDownload: (_data: any) => setShowAppendixDownloadModal(false),
    errorMessage: '',
    selectedLang: props?.selectedLang,
    defaultValue: props?.selectedLang,
    quartersList: props?.type === DONATION_TYPE_VALUES.GOODSANDFOOD ? getQuaetersDateRangeForAppendixC() : []
  }
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<any>();
  const rowFilters = props.tableRowFilters;
  const [selectedDonationProps, setSelectedDonationProps] = 
            useState<any>({...rowFilters});

  useEffect(() => {
    setSelectedDonationProps({...selectedDonationProps,serviceTypes:props.serviceTypes})
  
  }, [props.serviceTypes])
  
  const [dateFilter, setDateFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [error, setError] = useState<any>('');
  const [appendixMsg, setAppendixMsg] = useState<string>('');
  const [, setShowAppendixDownloadModal] = useState(false);
  const [, setSelectedDonation] = useState<any>();
  // @ts-ignore
  const [downloadAppendixProps, setDownloadAppendixProps] = useState<DownloadAppendixProps>(initlDownloadAppendixProps);
  const dt: any = useRef();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    pageSize: 10,
    pageNumber: 0,
    page: 0,
    filters: { ...props?.defaultFilters },
    countryCode: props?.countryCode,
    sortBy: 'id',
    sortOrder: -1,
    searchText: '',
    globalSearch: false
  });

  useEffect(() => {
    const defaultStatusCodes = props?.defaultFilters?.currentStatusCode?.value?.length > 0 ? [...props?.defaultFilters?.currentStatusCode?.value] : [props?.defaultFilters?.currentStatusCode?.value]
    dt.current.filter(defaultStatusCodes, 'currentStatusCode', 'equals');
    setSelectedStatus(defaultStatusCodes);
    setLazyParams({
      ...lazyParams,
      searchText: props?.searchText ?? '',
      globalSearch: props?.searchText ? true : false,
      pageNumber: lazyParams.page + 1,
      pageSize: lazyParams.rows,
      sortBy: lazyParams.sortBy
    })

    const payload: any = {
      value: {
      },
      // @ts-ignore
      key: DONATION_TYPE_MAP[props?.type]
    }
    
    if (props?.addDataToStore)
      props.addDataToStore(payload);
  }, [], props.serviceTypes)

  useEffect(() => {
    setLazyParams({
      ...lazyParams,
      searchText: props?.searchText ?? '',
      globalSearch: props?.searchText ? true : false,
    })
  }, [props.searchText])

  const { data: results, isLoading, isError, isFetching } = useDashboardData({
    ...lazyParams, sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC', type: props.type
  });

  return (<>
    <div className={`one-time-goods`} data-testid={'dashboard-testId'}>
      <div className='card'>
        <DataTable
          ref={dt}
          className='p-datatable-sm'
          header={props?.showExportExcel ?
            exportHeader(t, setLoading, lazyParams, setDownloadError, props.type) : ''}
          lazy={true}
          value={results?.data}
          dataKey='id'
          paginator={true}
          paginatorTemplate={paginator.paginatorTemplate}
          currentPageReportTemplate={t('orgMngmtTbl.labels.paginationText')}
          rows={lazyParams.pageSize}
          rowsPerPageOptions={paginator.rowsPerPageOptions}
          first={lazyParams.first}
          totalRecords={results?.totalCount}
          onPage={(event: any) => onPage(event, lazyParams, setLazyParams)}
          onSort={(event: any) => onSort(event, lazyParams, setLazyParams)}
          sortField={lazyParams.sortBy}
          sortOrder={lazyParams?.sortOrder as DataTableSortOrderType}
          onFilter={(event: any) => onFilter(event, lazyParams, setLazyParams)}
          filters={lazyParams.filters}
          loading={isLoading || isFetching}
          scrollable={true}
          scrollHeight='620px'
          emptyMessage={t('common.errorMesg.noRecordFound')}
          autoLayout={true}
          style={{ minBodyHeight: '450px', maxBodyHeight: '600px', width: '100%' } as any}
          filterDelay={600}
        >
          {donationColumnList(t, dt, dateFilter, props?.selectedLang, setLoading,
            getAppendixTypes, setShowAppendixDownloadModal, setDownloadAppendixProps, setAppendixMsg, hanldeError,
            setSelectedDonation, setError, setDateFilter, downloadAppendixProps, props?.addDataToStore,
            props?.ngoAcknowledgementReviewURL, props?.reviewDonationUrl, props?.history,
            props.showStatusFilter,
            selectedStatus,
            setSelectedStatus,
            setDownloadError,
            props.type, selectedDonationProps, setSelectedDonationProps, {...props?.defaultFilters})}
        </DataTable>
      </div>
    </div>
    <DownloadAppendixModal {
      ...{
        ...downloadAppendixProps,
        donationType: props?.type,
        quartersList: props?.type === DONATION_TYPE_VALUES.GOODSANDFOOD ? getQuaetersDateRangeForAppendixC() : []
      }} />
    <ErrorSnackbarComponent isError={isError} message={t('common.errorMesg.genericError')} />
    <ErrorSnackbarComponent isError={(error || downloadError) ? true : false} message={t('notificationMsg.failedToDownload')} />
    <ErrorSnackbarComponent isError={appendixMsg ? true : false} message={appendixMsg} />
    {(loading || isLoading) && <LoaderComponent fullScreen={true} />}
  </>
  );
}
export default React.memo(Table);
n