import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Modal,
} from "@walmart-web/livingdesign-components";
import React from "react";
import { FC } from "react";

export interface OwnProps {
  setOpen: any;
  isOpen: boolean;
  modalConfig: any;
}

const CommonLDModal: FC<OwnProps> = ({ setOpen, isOpen, modalConfig }) => {

  return (
    <Modal
      actions={
        modalConfig.buttons && <ButtonGroup>
          {modalConfig.buttons.map((btn: any, idx: number) => (
            <Button
              variant={btn.variant}
              data-testid={`modalBtn-${idx}`}
              onClick={() => {
                btn.callback();
                setOpen(false);
              }}
            >
              {btn.text}
            </Button>
          ))}
        </ButtonGroup>
      }
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      size={modalConfig.size || 'medium'}
      title={modalConfig.header}
    >
      {modalConfig.children}
    </Modal>
  );
};

export interface CommonModalProps {
  buttons?: {
    variant: ButtonVariant;
    callback: any;
    text: string;
  }[];
  header: string;
  children: React.ReactNode;
}

export default CommonLDModal;
