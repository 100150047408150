import GlobalDonationDataService from '../service';
import { useMutation, useQueryClient } from 'react-query'

export const useDownloadFile = () => {
    const queryClient = useQueryClient()
    const { data, mutateAsync, isError, isLoading, isSuccess, error } = useMutation(GlobalDonationDataService.getFileData, {
        onSettled: () => {
            queryClient.invalidateQueries(['downloadAttachment'])
        }
    })
    return { response: data, mutateAsync, isError, isLoading, isSuccess, error }
}
