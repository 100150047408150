import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { stringFormat } from '../utils';
import { useNavigate } from 'react-router-dom';
interface Props {
  rowClassName?: any,
  colClassName?: any,
  className?: string,
  breadcrumbsData: Array<{
    name: string,
    path?: string,
    active?: boolean,
    applyFormat?: boolean
  }>
  history?: any,
  options?: any
}

const BreadcrumbsComponent: FC<Props> = ({
  rowClassName = '', colClassName = '', className = '', breadcrumbsData,
  ...props
}) => {
  const { t } = useTranslation();
  const donationId = props?.options?.id;
  const navigateTo = useNavigate()
  const handleOnclick = (path: string) => {
    navigateTo(path)
  }

  const getData = (breadcrumb: any): string => {
    return breadcrumb?.applyFormat && donationId ?
    stringFormat(t(breadcrumb.name), donationId) :
    t(breadcrumb.name)
  }
  return (
    <div className={`row ${rowClassName ? 'rowClassName' : ''}`} style={{ marginTop: '10px', marginBottom: '10px' }}>
      <div className={`col-md-12 ${colClassName ? colClassName : ''}`}>
        <Breadcrumbs aria-label='breadcrumb'>
          {breadcrumbsData.map((breadcrumb, index) => (
            <div key={`BreadcrumbsComponent-${index}`}>
              {breadcrumb.active ?
                <Typography color='textPrimary'>
                  {
                    getData(breadcrumb)
                  }
                </Typography> :
                <Link
                  style={{ cursor: 'pointer' }}
                  color='inherit'
                  className={className}
                  key={`${breadcrumb.name}-key-${index}`}
                  onClick={() => handleOnclick(`${breadcrumb.path}`)}
                >
                  {getData(breadcrumb)}
                </Link>}
            </div>
          ))}
        </Breadcrumbs>
      </div>
    </div>
  )
}

export default BreadcrumbsComponent;