import { Action } from 'redux';

export enum DonationOfGoodsActionTypes {
    GET_NGO_PAGE_DATA = 'GET_NGO_PAGE_DATA',
    GET_NGO_PAGE_DATA_SUCCESS = 'GET_NGO_PAGE_DATA_SUCCESS',
    GET_NGO_PAGE_DATA_FAILURE = 'GET_NGO_PAGE_DATA_FAILURE',
}

export interface GetNgoPageData extends Action {
    type: DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA;
    data: any
}
/* istanbul ignore next */
export const getNgoPageData = (data: any): GetNgoPageData => ({
    type: DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA,
    data
});

export interface GetNgoPageDataSuccess extends Action {
    type: DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA_SUCCESS;
    data: any;
}
/* istanbul ignore next */
export const getNgoPageDataSuccess = (data: any): GetNgoPageDataSuccess => ({
    type: DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA_SUCCESS,
    data
});

export interface GetNgoPageDataFailure extends Action {
    type: DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA_FAILURE;
    data?: any;
}
/* istanbul ignore next */
export const getNgoPageDataFailure = (data: any): GetNgoPageDataFailure => ({
    type: DonationOfGoodsActionTypes.GET_NGO_PAGE_DATA_FAILURE,
    data
});



export type GetStoreBudgetBalanceActions = GetNgoPageData | GetNgoPageDataSuccess | GetNgoPageDataFailure;