import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@material-ui/core';
import { FC, useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AccordionCompo, AlertBox } from '../../../components/common';
import FooterButton from '../../../components/common/FooterButton';
import { getRecurringGoodsAppendixCValidationSchema } from './appendixCValidationSchema';
import FormSection from '../../common/FormSection/FormSection';
import {
    getCompanyEmpCertificationSection,
    getDonationRecipientSection,
    getRecipientCertificationSection,
} from './appendixCFormConfig';
import {
    getTransformedReviewActions,
    isRecipientSectionFielDisabled,
    resolvePath,
    scrollToElement,
    showAppendixCForm,
    showRGAppendixCDefaultValuesDonationStatus,
    useScrollToError
} from '../../../components/common/utils';
import { useFetchDonationDetailsByIdAndType } from '../../../custom-hooks/index';
import LoaderComponent from '../../../components/LoaderComponent';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import {
    commonFormTranslations, contextPath
} from '../../../constants';
import { getTodaysDate } from '../../../components/common/DateUtils';
import IAppendixCDonationInfo from '../../../models/iAppendixCDonationInfo';
import { useMutation } from 'react-query';
import { approveDonationRequest } from '../../../service/apiService';
import SuccessAlertDailog from '../../../components/dialogComponents/SuccessAlertDailog';
import InputConfirmDialog from '../../../components/dialogComponents/InputConfirmDialog';
import { ReviewSectionComponent } from '../oneTimeGoods/components/ReviewSectionComponent';
import { HighLightedBox } from '../../../styles/styleUtil';
import { useStyles } from '../utils';
import AppendixCHistory from './AppendixCHistory';

export interface RecurringGoodsAppendixCProps {
    donationId: string | number
    selectedLang: string
}
const defaultApeenidxCValues: IAppendixCDonationInfo = {

    orgLegalName: '',
    telephone: '',
    donationReceivedDate: '',
    reasonOfDonation: '',
    address: {
        country: '',
        addressLine1: '',
        postalCode: '',
    },
    recipientInfo: {
        name: '',
        signature: '',
        date: getTodaysDate(),
    },
    companyEmployee: {
        name: '',
        title: '',
        date: getTodaysDate(),
        signature: ''
    }
}
let savedData: any;

const getRecurringGoodsAppendixCDefaultValues = (defaultValues: any): IAppendixCDonationInfo => {
    const data = showRGAppendixCDefaultValuesDonationStatus.includes(defaultValues?.currentStatusCode) ?
        defaultValues : defaultApeenidxCValues
    return {
        orgLegalName: resolvePath('organization.orgLegalName', data, ''),
        telephone: resolvePath('organization.telephone', data, ''),
        donationReceivedDate: resolvePath('appendixC.donationReceivedDate', data, ''),
        reasonOfDonation: resolvePath('appendixC.reasonOfDonation', data, ''),
        address: {
            country: resolvePath('organization.address.country', data, ''),
            addressLine1: resolvePath('organization.address.addressLine1', data, ''),
            postalCode: resolvePath('organization.address.postalCode', data, ''),
        },
        recipientInfo: {
            name: resolvePath('appendixC.recipientInfo.name', data, ''),
            signature: resolvePath('appendixC.recipientInfo.signature', data, ''),
            date: resolvePath('appendixC.recipientInfo.date', data, getTodaysDate()),
        },
        companyEmployee: {
            name: resolvePath('appendixC.companyEmployee.name', data, ''),
            title: resolvePath('appendixC.companyEmployee.title', data, ''),
            date: resolvePath('appendixC.companyEmployee.date', data, getTodaysDate()),
            signature: resolvePath('appendixC.companyEmployee.signature', data, ''),
        },
        currentStatusCode: defaultValues?.currentStatusCode
    }
}
const RecurringGoodsAppendixC: FC<RecurringGoodsAppendixCProps> = ({ donationId, selectedLang }) => {

    const { t } = useTranslation();
    const navigateTo = useNavigate()
    const classes = useStyles();
    const [showActionDialog, setShowActionDialog] = useState(false);
    const [appendixcDefaultValues, setAppendixcDefaultValues] = useState<IAppendixCDonationInfo>(defaultApeenidxCValues)
    // Fetch donation details by id
    const { donationData,
        waaSTaskMessage,
        isLoading,
        isSuccess,
        isError,
        error
    } = useFetchDonationDetailsByIdAndType(donationId)

    const { data: respoonseData, isError: isApprovalError,
        isLoading: isApprovalLoading, isSuccess: isApprovalSuccess, mutate, error: approvalError } =
        useMutation(approveDonationRequest);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: getRecurringGoodsAppendixCDefaultValues(donationData),
        resolver: yupResolver(getRecurringGoodsAppendixCValidationSchema())
    });

    useEffect(() => {
        methods.reset(getRecurringGoodsAppendixCDefaultValues(donationData))
        setAppendixcDefaultValues(getRecurringGoodsAppendixCDefaultValues(donationData))
    }, [isSuccess])

    useEffect(() => {
        scrollToElement('appendix-c-section')
    }, [])

    useScrollToError(methods?.errors, 'Mui-error')

    const onSubmit = async (data: any) => {
        savedData = data;
        setShowActionDialog(true);
    };

    const onBack = () => {
        navigateTo(`${contextPath}/dashboard/donation-goods?type=donation-goods`);
    };
    const closeActionDialog = () => {
        setShowActionDialog(false);
    }
    const handleConfirmActionDialog = async (comments: string) => {
        if (savedData) {
            const requestData = {
                payload: [
                    {
                        donation: {
                            ...donationData,
                            appendixC: {
                                ...savedData
                            }
                        },
                        waaSTask: {
                            taskId: waaSTaskMessage?.taskId,
                            requestId: waaSTaskMessage?.requestId,
                            taskName: waaSTaskMessage.taskName,
                            comments,
                            variables: {
                                userAction: savedData.action?.value ?? '',
                            }
                        }
                    }
                ],
                donationType: donationData?.donationType
            }
            mutate(requestData)
            savedData = null;
        }
        setShowActionDialog(false);
    }
    return (
        <Box mt={2} className='appendx-c' data-testid='recurring-goods-appendix-c'>
            {showAppendixCForm.includes(donationData?.currentStatusCode) && <AccordionCompo
                className={'appendix-c'}
                defaultExpanded={true}
                accordionHeader='appendices.c.heading'
                accordionDetails={
                    <>
                        {donationData?.donationDetail?.reviewComments &&
                            <AlertBox
                                rowStyles={{ marginBottom: '30px' }}
                                severity={'warning'}
                                message={donationData?.donationDetail?.reviewComments}
                                colGrid={'col-md-12'}
                            />
                        }
                        <FormProvider {...methods}>
                            <form
                                onSubmit={methods.handleSubmit(onSubmit)}
                                noValidate={true}
                                autoComplete={'off'}
                            >
                                <FormSection
                                    heading={t('appendices.c.donationRecipient.heading')}
                                    subTitle={t('appendices.c.donationRecipient.subTitle')}
                                    configs={getDonationRecipientSection(true, getRecurringGoodsAppendixCDefaultValues(donationData))}
                                />

                                <FormSection
                                    heading={t('appendices.c.recipient.heading')}
                                    subTitle={t('appendices.c.recipient.subTitle')}
                                    configs={getRecipientCertificationSection(
                                        isRecipientSectionFielDisabled(donationData?.currentStatusCode),
                                        getRecurringGoodsAppendixCDefaultValues(donationData))}
                                />

                                <FormSection
                                    heading={t('appendices.c.companyEmployee.heading')}
                                    subTitle={t('appendices.c.companyEmployee.subTitle')}
                                    configs={getCompanyEmpCertificationSection(true, appendixcDefaultValues)}
                                />
                                {
                                    waaSTaskMessage?.formProperties &&
                                    waaSTaskMessage.formProperties.length > 0 &&
                                    <HighLightedBox elevation={2} >
                                        <ReviewSectionComponent
                                            formErrors={methods.errors}
                                            control={methods.control}
                                            fieldName={'action'} required={true}
                                            rules={{ required: t('common.dropDown.reviewDropDownLabel') }}
                                            defaultValue={''}
                                            classes={classes}
                                            actionOptions={getTransformedReviewActions(waaSTaskMessage?.formProperties)}
                                        />
                                        <Box display='flex' m={2}>
                                            <FooterButton variant='outlined' onClick={onBack}>{t('common.buttonLabels.cancel')}</FooterButton>
                                            <Box component='span' mr={2} />
                                            <FooterButton type='submit' color='primary' variant='contained'>
                                                {t('common.buttonLabels.submitBtn')}
                                            </FooterButton>
                                        </Box>
                                    </HighLightedBox>
                                }
                            </form>
                        </FormProvider>
                    </>}
            />}
            <AccordionCompo
                defaultExpanded={true}
                accordionHeader='appendices.c.history.heading'
                accordionDetails={
                    <Box p={2} m={2}>
                        <AppendixCHistory donationId={donationId} />
                    </Box>
                }
            />
            <SuccessAlertDailog
                showDialog={isApprovalSuccess && respoonseData?.successfulOperations > 0}
                maxWidth={'lg'}
                ok={onBack}
                title={t('common.successTitle.donationSuccess')}
                message={
                    <Trans i18nKey='common.successMsg.donationUpdateMsg'>
                        Donation has been created Successfully
                    </Trans>
                }
            />
            <InputConfirmDialog
                id={'recurring-appendixc-textarea'}
                data-testid={'ngo-form-inputConfirmDialog'}
                title={t('status.additionalInformation')}
                showDialog={showActionDialog}
                onClose={closeActionDialog}
                onSubmit={handleConfirmActionDialog}
            />
            {(isLoading || isApprovalLoading) && <LoaderComponent fullScreen={true} />}
            <ErrorSnackbarComponent isError={isError}
                // @ts-ignore
                message={error?.errorMessage || commonFormTranslations.genericErrorMessage[selectedLang]} />
            <ErrorSnackbarComponent isError={isApprovalError}
                // @ts-ignore
                message={approvalError?.errorMessage || commonFormTranslations.genericErrorMessage[selectedLang]} />
            <ErrorSnackbarComponent isError={respoonseData?.failedOperations > 0}
                // @ts-ignore
                message={t('common.errorMesg.failedToApproveDonation')} />
        </Box>
    );
};

export default memo(RecurringGoodsAppendixC);
