import { DONATION_TYPE } from 'src/constants';
import instance from 'src/i18n';
import * as yup from 'yup';

import {
    validateBoolean,
    validateDate,
    validateFloat,
    validateInteger,
    validateMaxLength,
    validateString,
    validateStringWithMaxLength,
} from 'src/components/common/validationDefinitions';


export const GetProjectDetailsValidationSchema = (donationType: string = DONATION_TYPE.MONETARY) => {
    let schema = yup.object().shape({});
    const projectDetailsValidations = {
        justificationDetails: yup.object().shape({
            projectNeed: validateStringWithMaxLength(1000),
            economicStatusDirectBeneficiaries: validateStringWithMaxLength(1000),
            requestAmount: validateFloat(),
            expertiseInArea: validateStringWithMaxLength(1000),
            projectCarriedOut: validateStringWithMaxLength(1000),
            projectStartDate: validateDate(),
            projectEndDate: validateDate(),
        }),
        projectScheduleOfActivitiesCategory: validateString(),
        projectScheduleOfActivities: yup.array(yup.object().shape({
            number: validateInteger(),
            description: validateString(),
            amount: validateFloat(),
            startDate: validateDate(),
            endDate: validateDate(),
        })),
        projectScheduleOfActivitiesSubCategory: yup.string().nullable().test('projectScheduleOfActivitiesSubCategory',
            instance.t('orgRegForm.validationMsg.requiredField'), function (val) {
                const category = (this as any)?.parent.projectScheduleOfActivitiesCategory;
                if (category === 'activities_category_special') {
                    return true;
                }
                return val && val.length > 0 ? true : false;
            }),
        executionDetails: yup.object().shape({
            transparencyDetails: validateStringWithMaxLength(1000),
            methodsAndPurpose: validateStringWithMaxLength(1000),
            riskAndLimitations: validateStringWithMaxLength(1000),
        }),
        locations: yup.array(yup.object().shape({
            geographicalLocation: validateString(),
            others: validateString(),
            state: validateString(),
            city: validateString(),
        })),
        beneficiaries: yup.object().shape({
            noOfChild: validateInteger(),
            noOfYoungAdults: validateInteger(),
            noOfAdults: validateInteger(),
            noOfSenior: validateInteger(),
            noOfFamilies: validateInteger(),
            noOfWomen: validateInteger(),
            totalIndirect: validateInteger(),
            calculationIndirectBeneficiaries: validateStringWithMaxLength(1000),
        }),
        projectBeneficiariesParticipant: yup.object().shape({
            provideFinancialResources: validateStringWithMaxLength(1000),
            provideMaterialResources: validateStringWithMaxLength(1000),
            functionsOrActivities: validateStringWithMaxLength(1000),
            participationMechanism: validateStringWithMaxLength(1000),
        }),
        coInvestors: yup.array(yup.object().shape({
            coInvestorDonor: validateString(),
            supportingActivities: validateString(),
            donationAmount: validateFloat(),
            percentage: validateFloat()
        })),
        impactContinuityDetails: yup.object().shape({
            projectLongTermVisionFit: validateStringWithMaxLength(1000),
            noOfJobsProjectCreate: validateInteger(),
            noYearsOfImpact: validateStringWithMaxLength(1000),
        }),
        logisticsAdminQuestions: yup.object().shape({
            methodsToCollectDonations: validateStringWithMaxLength(1000),
            recurringProdAgreement: validateStringWithMaxLength(1000),
            howOffenAgreement: validateString(),
            describeDistributionOfGoods: validateStringWithMaxLength(1000),
            experienceDeliveryFood: validateStringWithMaxLength(1000),
            donorsLegalNames: validateString(),
            explainUseOfDonations: validateStringWithMaxLength(1000),
            howOrgTransparency: validateStringWithMaxLength(1000),
            whatCorporatePurpose: validateMaxLength(1000)
        }),
        beneficiaryInformation: yup.object().shape({
            listOfBeneficiaries: validateBoolean(),
            grantAgreement: validateStringWithMaxLength(1000),
            averageIncomeLevel: validateStringWithMaxLength(1000)
        }),
        observationSummary: yup.array().of(yup.object().shape(
            {
                projectObservation: validateString(),
                measureResults: yup.array().of(yup.object().shape({
                    measure: validateString(),
                    result: validateString()
                })).required(instance.t('monetaryDonation.fieldValidation.measure'))
                    .min(1, instance.t('monetaryDonation.fieldValidation.measure'))
            }
        )).min(1, instance.t('monetaryDonation.fieldValidation.measure')),
        justificationTotal: yup.number().test('justificationTotal',
            instance.t('monetaryDonation.fieldValidation.projectScheduleTotalAmountValidation'), (value: any, context: yup.TestContext) => {

                const requestAmount: number = context.resolve(yup.ref('justificationDetails.requestAmount')) || 0;;
                if (value < requestAmount || value > requestAmount) {
                    return false;
                }
                return true;

            })
    };


    if (donationType === DONATION_TYPE.MONETARY) {
        schema = yup.object().shape({
            observationSummary: projectDetailsValidations.observationSummary,
            justificationDetails: projectDetailsValidations.justificationDetails,
            projectScheduleOfActivities: projectDetailsValidations.projectScheduleOfActivities,
            projectScheduleOfActivitiesCategory: projectDetailsValidations.projectScheduleOfActivitiesCategory,
            projectScheduleOfActivitiesSubCategory: projectDetailsValidations.projectScheduleOfActivitiesSubCategory,
            executionDetails: projectDetailsValidations.executionDetails,
            locations: projectDetailsValidations.locations,
            beneficiaries: projectDetailsValidations.beneficiaries,
            projectBeneficiariesParticipant: projectDetailsValidations.projectBeneficiariesParticipant,
            coInvestors: projectDetailsValidations.coInvestors,
            impactContinuityDetails: projectDetailsValidations.impactContinuityDetails,
            justificationTotal: projectDetailsValidations.justificationTotal,
        });
    } else if (donationType === DONATION_TYPE.GOODSANDFOOD) {
        schema = yup.object().shape({
            locations: projectDetailsValidations.locations,
            beneficiaryInformation: projectDetailsValidations.beneficiaryInformation,
            beneficiaries: projectDetailsValidations.beneficiaries,
            logisticsAdminQuestions: projectDetailsValidations.logisticsAdminQuestions,
        });
    }

    return schema;
}

