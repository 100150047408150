import React, { useState, useEffect, useContext } from 'react';
import SpaceDonationSpaceChooser from './spaceDonationSpaceSelector';
import SpaceDonationStoreChooser from './spaceDonationStoreSelector';
import SpaceDonationSlotChooser from './spaceDonationSlotSelector';
import QuestionForm from './questionForm';
import ConfirmationScreen from './spaceDonationReqConfirmation';
import SuccessScreen from './spaceDonationCreateSuccess';
import SideBarHandler from '../../commonComponents/appComponents/sideBar/sideBarHadler';
// import { navBarClose , navBarOpen} from '../../../../state/spaceDonation/navbar/navBarActions';
import withRouter from 'src/polyfils/customRouter';
import UserContext from '../../store/user-context';

// tslint:disable-next-line
export interface SpaceDonationRequestFormProps {
    history:any;
}

const formList = {
    QuestionForm: 'QuestionForm',
    SpaceDonationSpaceChooser: 'SpaceDonationSpaceChooser',
    SpaceDonationStoreChooser: 'SpaceDonationStoreChooser',
    SpaceDonationSlotChooser: 'SpaceDonationSlotChooser',
    ConfirmationScreen: 'ConfirmationScreen',
    SuccessScreen: 'SuccessScreen'
}


class  SpaceDonationRequestForm extends React.Component<SpaceDonationRequestFormProps>{
    public render() {
        return <SpaceDonationRequestFormSection history = {this.props.history}/>
    }
}
// @ts-ignore
export default withRouter(SpaceDonationRequestForm);


const SpaceDonationRequestFormSection: React.SFC<SpaceDonationRequestFormProps> = (props: SpaceDonationRequestFormProps) => {
    const userContext = useContext(UserContext);
    const [selectedForm, setSelectedForm] = useState(formList.QuestionForm);
    const [selectedQuestion, setSelectedQuestion] = useState();
    const [selectedStore, setSelectedStore] = useState();
    const [selectedSpace, setSelectedSpace] = useState();
    const [selectedSlot, setSelectedSlot] = useState();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [prevStoreSearchString, setPrevStoreSearchString] = useState(null);

    const [customContact, setCustomContact] = useState([]);
    const [customContactSelected, setCustomContactSelected] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
       }, []);

    return <SideBarHandler hideOnMount={true} showOnUnmount={false}>
        {selectedForm === formList.QuestionForm &&
            <QuestionForm
                customContact={customContact}
                setCustomContact={setCustomContact}
                customContactSelected={customContactSelected}
                setCustomContactSelected={setCustomContactSelected}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                setSelectedForm={setSelectedForm}
                nextPage={formList.SpaceDonationStoreChooser}
            />
        }

        {selectedForm === formList.SpaceDonationStoreChooser &&
            <SpaceDonationStoreChooser
                userContext={userContext}
                setSelectedForm={setSelectedForm}
                setSelectedStore={setSelectedStore}
                selectedStore={selectedStore}
                nextPage={formList.SpaceDonationSpaceChooser}
                prevPage={formList.QuestionForm}
                prevSearchString={prevStoreSearchString}
                setPrevSearchString={setPrevStoreSearchString}
            />
        }
        {selectedForm === formList.SpaceDonationSpaceChooser &&
            <SpaceDonationSpaceChooser
                selectedSpace={selectedSpace}
                selectedStore={selectedStore}
                setSelectedForm={setSelectedForm}
                setSelectedSpace={setSelectedSpace}
                nextPage={formList.SpaceDonationSlotChooser}
                prevPage={formList.SpaceDonationStoreChooser}
            />}
        {selectedForm === formList.SpaceDonationSlotChooser &&
            <SpaceDonationSlotChooser
                userContext={userContext}
                selectedDate={selectedDate}
                selectedSlot={selectedSlot}
                setSelectedForm={setSelectedForm}
                setSelectedDate={setSelectedDate}
                setSelectedSlot={setSelectedSlot}
                selectedStore={selectedStore}
                selectedSpace={selectedSpace}
                nextPage={formList.ConfirmationScreen}
                prevPage={formList.SpaceDonationSpaceChooser}
            />}

        {selectedForm === formList.ConfirmationScreen &&
            <ConfirmationScreen
                customContact={customContact}
                customContactSelected={customContactSelected}
                userContext={userContext}
                setSelectedForm={setSelectedForm}
                dateTime={selectedDate}
                spaceDetails={selectedSpace}
                slotDetails={selectedSlot}
                storeDetails={selectedStore}
                eventDetails={selectedQuestion}
                nextPage={formList.SuccessScreen}
                prevPage={formList.SpaceDonationSlotChooser}
                questionForm={formList.QuestionForm}
                spaceDonationSpaceChooser={formList.SpaceDonationSpaceChooser}
                spaceDonationStoreChooser={formList.SpaceDonationStoreChooser}
                spaceDonationSlotChooser={formList.SpaceDonationSlotChooser}
                history = {props.history}
            />}
        {selectedForm === formList.SuccessScreen && <SuccessScreen />
        }
    </SideBarHandler>
}
