import { useMutation, useQuery, useQueryClient } from 'react-query'
import { reactQueryConfigOptions } from '../constants';
import { getOneTimeFoodDonationDataById, updateOneTimeFoodDonation } from '../service/apiService';

export const useFetchOneTimeFoodDonationDetailsById = (donationId: number) => {
    const {
        isLoading,
        isSuccess,
        error,
        isError,
        data
    } = useQuery(['fetchOneTimeFoodDonationDetailsById', { donationId }], getOneTimeFoodDonationDataById,
        { ...reactQueryConfigOptions, cacheTime: 0 });

    return { response: data ?? {
      foodOneTimeDonation: null,
      waaSTaskMessage: null
    }, isLoading, isSuccess, isError, error };
}

export const useSaveOneTimeFoodDonation = () => {
  const queryClient = useQueryClient()
  const { data, mutateAsync, isError, isLoading, isSuccess, error } = useMutation(updateOneTimeFoodDonation, {
      onSettled: () => {
          queryClient.invalidateQueries(['fetchOneTimeFoodDonationDetailsById', 'oneTimeFoodDonations'])
      }
  })
  return { donationId: data, mutateAsync, failedUpdate:isError, isUpdating: isLoading, saveSuccess: isSuccess, updateError:error }
}
