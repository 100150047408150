export default {
    borderStyle: 'solid 1px rgba(102, 123, 155, 0.25)',
    borderRadius: '4px',
    boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.04)',
    appFont: 'Bogle',
    pageHeaderFontSize: 24,
    textColor: '#041f41',
    subHeaderTextColor: 'rgba(4, 31, 65, 0.4);',
    textFontSize: 14,
}

