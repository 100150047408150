import React from 'react';
import VerifiedOnboarding from './verifiedOnboarding';
import {  Navigate } from 'react-router-dom';
import withRouter from 'src/polyfils/customRouter';
import { contextPath } from 'src/constants';
import SideBarHandler from '../../../commonComponents/appComponents/sideBar/sideBarHadler';


const isMobileResolution = () => window.innerWidth <= 425;

class VerifiedOnboardingParent extends React.Component {

    public render() {
        // @ts-ignore
        const data = this.props.location && this.props.location.state && this.props.location.state.data;
        return <>
            {data && data.cid ?
                <SideBarHandler hideOnMount={true} showOnUnmount={!isMobileResolution()}>
                    <Component {...this.props} data={data} />
                </SideBarHandler> : <Navigate to={`${contextPath}/space-donation/create`} />}
        </>
    }
}

const Component = (props: any) => {
    return <>
        <VerifiedOnboarding
            data={props.data}
            history={props.history}
            contextPath={contextPath} />
    </>
}

// @ts-ignore
export default withRouter(VerifiedOnboardingParent);