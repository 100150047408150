import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC, useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormBlockHeading } from 'src/components/donations/utils';
import { countryCodeTranslations } from 'src/constants';

import AddRow from '../common/AddRow';
import { BiLingualDropdownContext } from '../common/biLingualDropdown';
import { CustomCell, CustomHeaderCell } from '../common/CustomCell';
import { InputTextField } from 'src/components/common/Input/InputTextField';
import KeyValueDropdown from 'src/components/common/Dropdown/keyValueDropdown';

export interface IGeographicCoverage {
    fieldName: string
}

const columnHeader = [{ key: 'popType', width: '15%' }, { key: 'country', width: '20%' },
{ key: 'state', width: '20%' }, { key: 'city', width: '15%' }, { key: 'community', width: '15%' },
{ key: 'numberOfBeneficiaries', width: '10%' }, { key: 'actions', width: '5%' }];

export const GeographicCoverage: FC<IGeographicCoverage> = ({ fieldName }) => {
    const { control, errors } = useFormContext();
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.organizationDetailsSection.${key}`);
    const geoCoverageFields = useFieldArray({
        name: fieldName,
        control
    })
    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField');
    const handleRemove = (index: number) => {
        geoCoverageFields.remove(index);
    }
    const dropdownContext = useContext(BiLingualDropdownContext);
    const countryList = countryCodeTranslations.map(country => ({
        label: country.label?.[dropdownContext.languageKey],
        value: country.code
    }));

    return (
        <Box style={{
            width: '100%', padding: '10px',
            boxShadow: 'box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1)', borderRadius: '6px'
        }}
            bgcolor='#fbfbfb'>
            <FormBlockHeading heading={getTranslation('geoCovSum')} />
            <TableContainer >
                <Table aria-label='Geographic Coverage Table' size='small'>
                    <TableHead>
                        <TableRow>
                            {columnHeader.map((column, index) => {

                                return (<CustomHeaderCell
                                     key={`header_${index}`}
                                    style={{ width: column.width }}>
                                    {column.key === 'actions' ? null : getTranslation(column.key)}
                                </CustomHeaderCell>)
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {geoCoverageFields.fields.map((field, index) => {
                            const partialErrKey = `${fieldName}.${index}`;
                            return (<TableRow key={field.id} >
                                <CustomCell>
                                    <InputTextField
                                        required={true}
                                        fieldName={`${fieldName}[${index}].populationType`}
                                        id={field.id}
                                        multiline={true}
                                        rows={2}
                                        defaultValue={field.populationType}
                                        errorKeyName={`${partialErrKey}.populationType`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell  >
                                    <KeyValueDropdown
                                       id={field.id}
                                        fieldName={`${fieldName}[${index}].country`}
                                        defaultValue='MX'
                                        control={control}
                                        options={countryList}
                                        errors={errors}
                                        errorKeyName={`${fieldName}[${index}].country`}
                                        disabled={true}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell >
                                    <KeyValueDropdown
                                       id={field.id}
                                        fieldName={`${fieldName}[${index}].state`}
                                        defaultValue={field.state}
                                        control={control}
                                        options={dropdownContext.stateList}
                                        errors={errors}
                                        errorKeyName={`${fieldName}[${index}].state`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell >
                                    <InputTextField
                                        fieldName={`${fieldName}[${index}].city`}
                                        id={field.id}
                                        defaultValue={field.city}
                                        errorKeyName={`${partialErrKey}.city`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell  >
                                    <InputTextField
                                        fieldName={`${fieldName}[${index}].community`}
                                        id={field.id}
                                        defaultValue={field.community}
                                        errorKeyName={`${partialErrKey}.community`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell>
                                <CustomCell >
                                    <InputTextField
                                        fieldName={`${fieldName}[${index}].numberOfBeneficiaries`}
                                        id={field.id}
                                        type={'number'}
                                        defaultValue={field.numberOfBeneficiaries}
                                        errorKeyName={`${partialErrKey}.numberOfBeneficiaries`}
                                        rules={{
                                            required: requiredFieldMsg
                                        }}
                                    />
                                </CustomCell >
                                <CustomCell >
                                    <IconButton color='default' onClick={() => handleRemove(index)}
                                        disabled={geoCoverageFields?.fields?.length === 1} id={field.id}
                                        aria-label='delete row' component='span'>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </CustomCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddRow
                handler = {() => {
                    geoCoverageFields.append({
                        populationType: '',
                        country: localStorage.getItem('countryCode') ?? '',
                        state: '',
                        city: '',
                        community: '',
                        numberOfBeneficiaries: ''
                    })
                }}
                key = 'addGeographicalCoverage'
                t = {t}
            />
        </Box>

    );
}