import {FC} from 'react';
import {BaseSectionProps} from '../organizationDetails/orgDetailsDefaults';
import {useTranslation} from 'react-i18next';
import {
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import InformationBar from '../common/informationBar';
import {IBenificiariesInformation} from './projectDetailsDefaults';
import {InputTextField} from '../../../common/Input/InputTextField';
import {Controller, useFormContext} from 'react-hook-form';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';
import {FormLabel} from 'react-bootstrap';
import {ErrorMessageComponent} from 'src/components/common/formComponents/ErrorMessage';

interface BeneficiaryInformationProps extends BaseSectionProps {
    data: IBenificiariesInformation
}

const useStyles = makeStyles({
    container: {
        margin: '12px 14px 6px'
    },
    tittle: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    subDesc: {
        fontSize: '12px',
        color: '#74767c;',
        marginLeft: '16px',
        marginTop: '4px'
    },
    titleGrid: {
        marginTop: '15px',
        marginBottom: '10px'
    },
    inputLabel: {
        color: '#2e2f32',
        marginBottom: 0
    }
});

const BeneficiaryInformation: FC<BeneficiaryInformationProps> = ({fieldName, data}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {control, errors} = useFormContext();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);

    return  <Grid container = {true} className = {classes.container}>
        <Grid item={true} xs={12}>
            <Typography classes={{ root: classes.tittle }}>{getTranslation('beneficiaryDetails')}</Typography>
        </Grid>
        <Grid xs = {12} item = {true} container = {true} spacing = {4}>
            <Grid xs = {6} item = {true}>
                <FormControl component='fieldset'  >
                    <FormLabel color='secondary' >{getTranslation('doYouHavelob')}</FormLabel>
                    <Controller
                        control={control}
                        defaultValue={data.listOfBeneficiaries}
                        name={`${fieldName}.listOfBeneficiaries`}
                        render={({ onBlur, onChange, value }) => {
                            return (<RadioGroup row={true} value={value} onBlur={onBlur}
                                                onChange={(event) => {
                                                    onChange(event.target.value === 'true');
                                                }} >
                                <FormControlLabel value={true}
                                                  control={<Radio color='default' />} label={t('common.buttonLabels.yes')} />
                                <FormControlLabel value={false}
                                                  control={<Radio color='default' />} label={t('common.buttonLabels.no')} />
                            </RadioGroup>);
                        }}
                    />
                    <ErrorMessageComponent errors={errors} fieldName={`${fieldName}.listOfBeneficiaries`} />
                </FormControl>
            </Grid>
            <Grid xs = {6} item = {true}>
                <InformationBar>
                    {getTranslation('orgKeepRecord')}
                </InformationBar>
            </Grid>
        </Grid>
        <Grid classes={{root: classes.titleGrid}}
              spacing = {3}
              xs = {12} item = {true} container = {true}>
            <Grid xs = {6} item = {true}>
                <InputLabel
                    id={`${fieldName}.explenationLabel`}
                    classes = {{root: classes. inputLabel}}
                    required = {true}>
                    {getTranslation('explainInDetail')}
                </InputLabel>
            </Grid>
        </Grid>
        <Grid xs = {12} item = {true} container = {true} spacing = {4}>
            <Grid xs = {6} item = {true}>
                <InputTextField
                    required={true}
                    fieldName={`${fieldName}.grantAgreement`}
                    id={`${fieldName}.grantAgreement`}
                    errorKeyName = {`${fieldName}.grantAgreement`}
                    InputProps={{readOnly: false}}
                    fullWidth={true}
                    rows={5}
                    multiline={true}
                    defaultValue={data.grantAgreement}
                />
                <TextFieldMaxSizeIndicator
                    fieldName = {`${fieldName}.grantAgreement`}
                    control = {control}
                    maxSize = {1000}/>
            </Grid>
            <Grid xs = {6} item = {true}>
                <InformationBar>
                    {getTranslation('orgKeepRecord')}
                </InformationBar>
            </Grid>
        </Grid>
        <Grid classes={{root: classes.titleGrid}}
              spacing = {3}
              xs = {12} item = {true} container = {true}>
            <Grid xs = {6} item = {true}>
                <InputLabel
                    classes = {{root: classes. inputLabel}}
                    id={`${fieldName}.incomeLevelDescriptionLabel`}
                    required = {true}>
                    {getTranslation('averageIncome')}
                </InputLabel>
            </Grid>
        </Grid>
        <Grid xs = {12} item = {true} container = {true} spacing = {4}>
            <Grid xs = {6} item = {true}>
                <InputTextField
                    required={true}
                    fieldName={`${fieldName}.averageIncomeLevel`}
                    id={`${fieldName}.averageIncomeLevel`}
                    errorKeyName = {`${fieldName}.averageIncomeLevel`}
                    InputProps={{readOnly: false}}
                    fullWidth={true}
                    rows={5}
                    multiline={true}
                    defaultValue={data.averageIncomeLevel}
                />
                <TextFieldMaxSizeIndicator
                    fieldName = {`${fieldName}.averageIncomeLevel`}
                    control = {control}
                    maxSize = {1000}/>
            </Grid>
            <Grid xs = {6} item = {true}>
                <InformationBar>
                    {getTranslation('calculateAvgIncome')}
                </InformationBar>
            </Grid>
        </Grid>
    </Grid>

}

export default BeneficiaryInformation;