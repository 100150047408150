import { FacilitySearchActions, FaccilitySearchActionTypes } from './facilitySearchActions';
import { FacilitySearchState } from '../../models/globalDonationState';

export const defaultFacilitySearchState: FacilitySearchState = {
    facilityData: [],
    facilitySearchLoading: false,
    facilitySearchSuccess: false,
    facilitySearchFailure: false
};

export const facilitySearchReducer = (state: FacilitySearchState = defaultFacilitySearchState, action: FacilitySearchActions):
    FacilitySearchState => {
    switch (action.type) {
        case FaccilitySearchActionTypes.FACILITY_DATA_LOAD: return {
            ...state,
            facilitySearchLoading: true,
            facilitySearchSuccess: false,
            facilitySearchFailure: false,
        };
        case FaccilitySearchActionTypes.FACILITY_LOAD_SUCCESS: return {
            ...state,
            facilityData: action.data,
            facilitySearchLoading: false,
            facilitySearchFailure: false,
            facilitySearchSuccess: true,
        };
        case FaccilitySearchActionTypes.FACILITY_LOAD_FAILURE: return {
            ...state,
            facilitySearchLoading: false,
            facilitySearchFailure: true,
            facilitySearchSuccess: false,
        };
        default: return state;
    }
}