import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { InputBox } from '../../common'
import { getFormattedDate, getTodaysDate } from '../../common/DateUtils'
import { isValidYearOfBirth } from '../utils'
import { FormBlockHeading } from '../../donations/utils'
import { CustomCheckBox } from './CustomCheckBox'
import { countryList } from '../../../static/countryList';
import KeyValueDropdown from '../Dropdown/keyValueDropdown'
export interface AppendixBConsentProps {
    data: any
    disabled?: boolean
    classes: any
    isReview?: boolean
    getDefaultValue? :boolean
    errorClassName?:string
}

export const AppendixBConsent: FC<AppendixBConsentProps> = ({ data, disabled = false, getDefaultValue=false, classes, isReview = false, errorClassName }) => {
    const { t } = useTranslation()
    const { errors, control, setValue } = useFormContext()
    const requiredFieldTranslation = t('common.validationMsg.requiredField')
    const {
        consentSignee,
        consentSignedDate,
        countryCode
    } = data
    const consentTextKey = countryCode === 'GB' ? 'appendixBCertification.consentTextUk' : 'appendixBCertification.consentTextNonUk';

    let signedDate;
    useEffect(() => {
        if (isReview === false) {
            signedDate = getTodaysDate();
        }
        else if (consentSignedDate) {
            signedDate = getFormattedDate(consentSignedDate);
        }
        else {
            signedDate = getTodaysDate();
        }
        setValue('consentSignedDate', signedDate);
    }, [isReview, data.consentSignedDate])

    return (<div>
        <FormBlockHeading heading={t('appendixBCertification.consentTitle')} />
        <div className='row' style={{ marginBottom: '20px', fontSize: '14px' }}>
            <div className='col-md-10'>
                <Trans i18nKey={consentTextKey}>
                    Consent text injected dynamically based on key
                </Trans>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-10'>
                <CustomCheckBox
                    required={true}
                    style={{ labelColor: '#2E2F32', labelFontWeight: 'bold' }}
                    id={'consentCheckBox1'}
                    fieldName={'consentCheckBox1'}
                    label={
                        <Trans i18nKey='appendixBCertification.consentCheckbox1'>
                            I confirm I have reviewed Walmart Privacy Notice (<a target='_blank' href='https://corporate.walmart.com/privacy-security/walmart-anti-corruption-due-diligence-privacy-notice'>https://corporate.walmart.com/privacy-security/walmart-anti-corruption-due-diligence-privacy-notice</a>)
                        </Trans>
                    }
                    defaultValue={data?.consentCheckBox1}
                    disabled={disabled}
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorMsg={errors?.consentCheckBox1?.message}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-10'>
                <CustomCheckBox
                    required={true}
                    style={{ labelColor: '#2E2F32', labelFontWeight: 'bold' }}
                    id={'consentCheckBox2'}
                    fieldName={'consentCheckBox2'}
                    label={t('appendixBCertification.consentCheckbox2')}
                    defaultValue={data?.consentCheckBox2}
                    disabled={disabled}
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorMsg={errors?.consentCheckBox2?.message}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-sm-12 col-md-10 col-xl-8'>
                <InputBox
                    id={'consentSignee-userName'}
                    className={'consentSignee-userName'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.fullNameOrSignature')}
                    fieldName={'consentSignee.userName'}
                    defaultValue={disabled || getDefaultValue ? consentSignee?.userName : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                    }}
                    errorMsg={errors?.consentSignee?.userName?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'consentSignee-userTitle'}
                    className={'consentSignee-userTitle'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.userTitle')}
                    fieldName={'consentSignee.userTitle'}
                    defaultValue={disabled || getDefaultValue ? consentSignee?.userTitle : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                    }}
                    errorMsg={errors?.consentSignee?.userTitle?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'consentSignee-birthYear'}
                    className={'consentSignee-birthYear'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.yearOfBirth')}
                    fieldName={'consentSignee.birthYear'}
                    defaultValue={disabled || getDefaultValue ? consentSignee?.birthYear : ''}
                    multiline={true}
                    required={true}
                    rules={{
                        required: requiredFieldTranslation,
                        validate: disabled ? true : (value: any) => isValidYearOfBirth(value, t)
                    }}
                    errorMsg={errors?.consentSignee?.birthYear?.message}
                    disabled={disabled}
                    InputProps={{
                        readOnly: disabled,
                        classes: {
                            disabled: disabled ? classes.disabled : ''
                        }
                    }}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4' style={{ marginTop: '16px' }}>
                <KeyValueDropdown
                    id={'countryOfResidence'}
                    inputLabel={t('appendixBCertification.countryOfResidence')}
                    fieldName={'consentSignee.countryOfResidence'}
                    defaultValue={disabled || getDefaultValue ? consentSignee?.countryOfResidence : ''}
                    options={countryList}
                    errors={errors}
                    control={control}
                    disabled={disabled}
                    required={true}
                    valueKey='label'
                    labelKey='label'
                    rules={{
                        required: requiredFieldTranslation
                    }}
                    errorKeyName={'consentSignee.countryOfResidence'}
                    errorClassName={errorClassName}
                />
            </div>
            <div className='col-xs-12 col-md-10 col-lg-6 col-xl-5 col-xxl-4'>
                <InputBox
                    id={'consentSignedDate'}
                    className={'consentSignedDate'}
                    classes={classes}
                    formErrors={errors}
                    control={control}
                    label={t('appendixBCertification.todayDate')}
                    fieldName={'consentSignedDate'}
                    disabled={true}
                    required={true}
                    defaultValue={signedDate}
                    InputProps={{
                        classes: {
                            disabled: classes?.disabled
                        }
                    }}
                />
            </div>
        </div>
    </div>)
}
