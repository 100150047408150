import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import SideBarHandler from '../../commonComponents/appComponents/sideBar/sideBarHadler';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponent from '../../commonComponents/appComponents/loader/Spinner';
import AppLogoHandler from '../../commonComponents/appComponents/topBar/AppLogoHadler';
import withRouter from 'src/polyfils/customRouter';
import analytics from '../../../../adobe-analytics/analytics';
import { SPACEANALYTICS } from '../../../../adobe-analytics-space/analytics-space-dataelements';

const service: Service = SpaceDonationService;

const Downloader = (props:any) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const mutation = useMutation((data: any) => service.downloadProjectFile({
        queryKey: ['', {
            fileId: data.fileId,
            container: 'space-donation',
            directory: data.directory,
            type: data.type
        }]
    }));
    useEffect(() => {
        if (mutation.isSuccess) {
            const a = document.createElement('a');
            a.href = mutation.data;
            a.setAttribute('download', mutation.variables.fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setDataLoaded(true);
            window.close();
        }
    }, [mutation.isSuccess]);
    useEffect(()=>{
        mutation.mutate({
            fileId: props.downloadObj.fileId,
            directory: props.downloadObj.directory,
            fileName:props.downloadObj.fileName,
            type: props.downloadObj.type
        })
    },[]);
    return <SideBarHandler hideOnMount={true} showOnUnmount={false}>
        <AppLogoHandler>
            {!dataLoaded && <LoaderComponent />}
        </AppLogoHandler>
    </SideBarHandler>
}

class UserPolicyDownloader extends React.Component{
    public state = {
        fileId: '',
        directory: '',
        fileName: '',
        type: ''
    }
    public componentDidMount() {
        analytics.trackPageInfoSpaceExternal(SPACEANALYTICS.FAQSpaceExternalPage);
         // @ts-ignore
        const { location } = this.props;
        const query = new URLSearchParams(location.search);

        this.setState({
            fileId: query.get('fileId'),
            directory: query.get('directory'),
            fileName: query.get('fileName'),
            type: query.get('type')
        });
    }
    public render() {
        // @ts-ignore
        return <>{this.state.fileId!=='' && <Downloader downloadObj={this.state}/>}</>
    }
}

// @ts-ignore
export default withRouter(UserPolicyDownloader);