
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { contextPath, donationsStatusText } from "../../../constants";
import { getCurrencySymbol } from '../utils';
import { getCustomFacilityTypes, servicePageType } from './serviceDonationDataFunctions';

const getLocation = (rowData: any) => {
  switch (rowData.locationType) {
    case "DC":
        return `DC ${rowData.dcNumber ? "#" + rowData.dcNumber : ""}`
    case "STORE":
        return `STORE ${rowData.storeNumber ? "#" + rowData.storeNumber : ""}`
    case "HOME_OFFICE":
        return `HOME OFFICE ${rowData.homeofficeNumber ? "#" + rowData.homeofficeNumber : ""}`
    case "OTHER":
        return `OTHER`
    default:
        return "NA"
}
}

const getStatusCode = (inputStatus: any, selectedLang: any) => {
  return donationsStatusText["ALL"][selectedLang ?? "en"][inputStatus] ?? "";
};

const projectStatusOptions: any = (inputs: any[], selectedLang: any) => {
  const output: any = [];
  inputs.forEach((inp) =>
    output.push({ name: getStatusCode(inp, selectedLang), code: inp })
  );
  return output;
};


export const fetchServiceDonationDashboardColumns = (selectedLang: string, history: any, dt: any, t: any,
  selectedStatus: any, setSelectedStatus: any, selectedDonationProps: any,
  setSelectedDonationProps: any, initialFilterList: any) => {

    //console.log("::::;", selectedDonationProps.serviceTypes);
  let defaultStatusFilters = initialFilterList?.currentStatusCode?.value;

  const renderDonationFromFilter = () => {
    let donationFromOptions = getCustomFacilityTypes(t);
    return (
      <Dropdown
        value={selectedDonationProps.selectedDonationFrom}
        optionLabel="label"
        optionValue="value"
        options={donationFromOptions}
        // onChange={onDonationFromFilterChange}
        onChange={(e: any) => { onDropDownValueChange(e, 'locationType') }}
        showClear={true}
        placeholder={t(
          "serviceDonations.table.filtersPlaceHolderTxt.searchByLocation"
        )}
        className="p-column-filter"
      />
    );
  };

  const onDropDownValueChange = (event: any, propName: string) => {
    
      if (propName === 'currentStatusCode') {
        setSelectedStatus(event.value);
        dt.current.filter([event.value], propName, "equals");
      }
      else if (propName === 'locationType') {
        setSelectedDonationProps({ ...selectedDonationProps, 'selectedDonationFrom': event.value });
        dt.current.filter(event.value, propName, "equals");
      }
      else if (propName === 'serviceType') {
        setSelectedDonationProps({ ...selectedDonationProps, 'selectedServiceType': event.value });
        dt.current.filter([event.value], propName, "equals");
      }

  };

  const renderProjectStatusFilter = () => {
    return (
      <Dropdown
        value={selectedStatus}
        optionLabel="name"
        optionValue="code"
        options={projectStatusOptions(defaultStatusFilters || [], selectedLang)}
        //onChange={(e: any) => { onProjectStatusFilterChange(e, 'currentStatusCode') }}
        onChange={(e: any) => { onDropDownValueChange(e, 'currentStatusCode') }}
        showClear={true}
        placeholder={t("serviceDonations.table.filtersPlaceHolderTxt.projectStatus")}
        className="p-column-filter"
      />
    );
  };

  const donationFromFilter = renderDonationFromFilter();
  const projectStatusFilter = renderProjectStatusFilter();

  const renderServiceTypeFilter = () => {
    return (
      <Dropdown
        value={selectedDonationProps.selectedServiceType}
        optionLabel={`language.${selectedLang}`}
        optionValue='keyText'
        options={selectedDonationProps.serviceTypes}
        //onChange={onServiceTypeFilterChange}
        onChange={(e: any) => { onDropDownValueChange(e, 'serviceType') }}
        itemTemplate={serviceTypeOptionTemplate}
        showClear={true}
        placeholder={t('serviceDonations.table.filtersPlaceHolderTxt.serviceType')}
        className='p-column-filter'
      />
    );
  }

  const serviceTypeOptionTemplate = (serviceType: any) => {
    return (
      <div>
        {serviceType?.language?.[selectedLang]}
      </div>
    );
  }
  const serviceTypeFilterElement = renderServiceTypeFilter();

  return ([
    {
      field: "id",
      header: t("serviceDonations.table.id"),
      sortable: true,
      sortField: "id",
      filter: true,
      filterPlaceholder: t("serviceDonations.table.searchByID"),
      body: (rowData: any) => {
        /* istanbul ignore next */
        return (
          <div>
            <button
              type="button"
              className="p-button p-component p-button-link"
              // @ts-ignore
              onClick={() => {
                history.push({
                  pathname: `${contextPath}/view/${servicePageType}?type=${servicePageType}`,
                  state: { donationId: rowData.id },
                });
              }}
            >
              <span className="p-button-label"
                style={{ fontWeight: "bold", textAlign: "left" }}>
                {rowData?.id}
              </span>
            </button>
          </div>
        );
      },
      style: {
        width: "10em",
      },
    },
    {
      field: "currentStatusCode",
      header: t("serviceDonations.table.status"),
      filter: true,
      filterElement: projectStatusFilter,
      body: (rowData: any) => (
        <span>
          {getStatusCode(rowData.currentStatusCode, selectedLang)}
        </span>
      ),
      style: { width: "14em" }
    },
    {
      field: "donationDetails.locationType",
      header: t("serviceDonations.table.donationFrom"),
      filterPlaceholder: t("serviceDonations.table.searchByDonation"),
      sortField: "locationType",
      filter: true,
      filterElement: donationFromFilter,
      body: (rowData: any) => getLocation(rowData.donationDetails),
      style: { width: "16em" }
    },
    {
      field: `donationDetails.serviceType.lang.${selectedLang}`,
      header: t("serviceDonations.table.serviceType"),
      sortable: false,
      sortField: 'serviceType',
      filter: true,
      filterField: 'serviceType',
      filterElement: serviceTypeFilterElement,
      body: (rowData: any) => {
        const serviceType = rowData?.serviceType
        return (
          <div>
            {serviceType?.language?.[selectedLang]}
          </div>
        );
      },
      style: {
        width: '20em'
      }

    },
    {
      field: 'donationDetails.donationAmount',
      header: t('serviceDonations.table.donationAmount'),
      body: (rowData: any) => {
        return (
          <>
            <span>{`${getCurrencySymbol(rowData.countryCode)} ${rowData?.donationDetails?.donationAmount}`}</span>
          </>
        );
      },
      sortable: false,
      style: { width: '10em' },
    },
    {
      field: "eventStartDate",
      header: t("serviceDonations.table.startDate"),
      style: { width: "10em" }
    },
    {
      field: "eventEndDate",
      header: t("serviceDonations.table.endDate"),
      style: { width: "10em" }
    },
    {
      field: 'donationDetails.donationTitle',
      header: t("serviceDonations.table.donationTitle"),
      sortable: false,
      style: { width: '14em' },
    }
  ]).map((column: any) => <Column
    key={column.field} {...{ ...column, header: t(column.header) }} />);

};