
import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core';
import { contextPath } from '../../../constants';
import { OneTimeGoodsNGOAcknowledgeForm } from './oneTimeGoodsNGOAcknowledgeForm';
import { OneTimeGoodsGEAcknowledgeForm } from './oneTimeGoodsGEAcknowledgeForm';
import { GlobalDonationState } from '../../../models/globalDonationState';
import { clearSelectedData } from '../../../state/selectedData/selectedDataActions';
import BreadcrumbsComponent from '../../common/BreadcrumbsComponent/BreadcrumbsComponent';
import { ngoAcknowledgementReviewBreadcrumbs, FormBlockHeading, PageHeading, theme } from '../utils';
import { getUserRoles, HOME_OFFICE } from '../../common/utils';

export interface OwnProps {
    history: any;
    appi18n: any;
    classes?: any;
    t: (key: string, options?: any) => (string);
}

export interface DispatchProps {
    clearSelectedData: (data: any) => void;
}

export interface StateProps {
    selectedLang: any;
    selectedDonationData: any;
}

class NGOAcknowledgeComponent extends React.PureComponent<OwnProps & StateProps & DispatchProps> {
    public render() {
        const selectedData = (this.props.selectedDonationData?.ONE_TIME_GOODS) ?
            JSON.parse(JSON.stringify(this.props.selectedDonationData?.ONE_TIME_GOODS)) : {};
        return (<>
            <BreadcrumbsComponent
                breadcrumbsData={ngoAcknowledgementReviewBreadcrumbs}
                history={this.props.history}
                options={{ id: selectedData?.id }}
            />
            <PageHeading heading={this.props.t('oneTimeGoodsDonation.oneTimeGoodsDonation')} />
            <FormBlockHeading heading={this.props.t('oneTimeGoodsDonation.header')} />
            <MuiThemeProvider theme={theme}>
                {selectedData?.organization?.organizationType?.keyText === 'org_type_ngo' &&

                    <>
                        <OneTimeGoodsNGOAcknowledgeForm
                            role={getUserRoles()}
                            donationId={selectedData?.id}
                            modifyOrg={false}
                            history={this.props.history}
                            selectedLang={this.props.selectedLang}
                            redirectUrl={`${contextPath}/dashboard/one-time-goods?type=one-time-goods`}
                            source={HOME_OFFICE}
                        />
                    </>

                }
                {selectedData?.organization?.organizationType?.keyText === 'org_type_governmental' &&

                    <>
                        <OneTimeGoodsGEAcknowledgeForm
                            role={getUserRoles()}
                            donationId={selectedData?.id}
                            modifyOrg={false}
                            history={this.props.history}
                            selectedLang={this.props.selectedLang}
                            redirectUrl={`${contextPath}/dashboard/one-time-goods?type=one-time-goods`}
                            source={HOME_OFFICE}
                        />
                    </>

                }
            </MuiThemeProvider>
        </>)
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    selectedDonationData: state.selectedDataState.addDataToStore,
});
/* istanbul ignore next */
export const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    clearSelectedData: (data) => dispatch(clearSelectedData(data)),
});

export default withTranslation()
    (connect(mapStateToProps, mapDispatchToProps)(NGOAcknowledgeComponent));

