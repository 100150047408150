import { GDMSCONSTANT } from './adobe-analytics-gdms-constants';
import { IAnalyticsGdmsPage } from './adobe-analytics-gdms-model';
export const ANALYTICS: { [key: string] : {[key: string]: IAnalyticsGdmsPage }} = {
  socialImpact: {
    dashboardPage: {
      id: 'socialImpact_dashboardPage',
      name: 'socialImpact_dashboardPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    goodsRecurringReportPage: {
      id: 'socialImpact_goodsRecurringReportPage',
      name: 'socialImpact_goodsRecurringReportPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    monetaryReportPage: {
      id: 'socialImpact_monetaryReportPage',
      name: 'socialImpact_monetaryReportPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  landingPage: {
    welcomePage: {
      id: 'landingPage_welcomePage',
      name: 'landingPage_welcomePage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  login: {
    loginPage: {
      id: 'login_loginPage',
      name: 'login_loginPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    resetPassword: {
      id: 'login_ResetPassword',
      name: 'login_ResetPassword',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  registerPage: {
    registerOrgDetailsPage1: {
      id: 'registerPage_registerOrgDetailsPage1',
      name: 'registerPage_registerOrgDetailsPage1',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    registerOrgDetailsPage2: {
      id: 'registerPage_registerOrgDetailsPage2',
      name: 'registerPage_registerOrgDetailsPage2',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  organization: {
    organizationProfilePage: {
      id: 'organization_organizationProfilePage',
      name: 'organization_organizationProfilePage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    organizationUserManagementPage: {
      id: 'organization_organizationUserManagementPage',
      name: 'organization_organizationUserManagementPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  dashboard: {
    dashboardPage: {
      id: 'dashboard_dashboardPage',
      name: 'dashboard_dashboardPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  communityGrant: {
    dashboardPage: {
      id: 'communityGrant_dashboardPage',
      name: 'communityGrant_dashboardPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  goodsDonation: {
    dashboardPage: {
      id: 'goodsDonation_dashboardPage',
      name: 'goodsDonation_dashboardPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    newrequestsPage : {
      id: 'goodsDonation_newrequestsPage',
      name: 'goodsDonation_newrequestsPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  foodSurplus: {
    foodSurplusPage: {
      id: 'foodSurplus_foodSurplusPage',
      name: 'foodSurplus_foodSurplusPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  monetaryDonation: {
    dashboardPage: {
      id: 'monetaryDonation_dashboardPage',
      name: 'monetaryDonation_dashboardPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
    requestPage: {
      id: 'monetaryDonation_requestPage',
      name: 'monetaryDonation_requestPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
  oneTimeGoodsDonation: {
    dashboardPage: {
      id: 'oneTimeGoodsDonation_dashboardPage',
      name: 'oneTimeGoodsDonation_dashboardPage',
      applicationName: GDMSCONSTANT.app_name,
      applicationType: GDMSCONSTANT.app_type,
    },
  },
};
