import {Table, TableCell, TableCellProps, TableHead, TableRow} from '@material-ui/core';
import {FC, useCallback, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ErrorMessageComponent} from 'src/components/common/formComponents/ErrorMessage';
import {formatCurrency} from 'src/components/common/utils';

import {TotalTableStyles} from '../common/styles';
import {ICoInvestorsFields} from './projectDetailsDefaults';


interface ICoInvestorsTotalsProps {
    watchedFields: ICoInvestorsFields[],
    totalFieldName: string,
    getTranslation: (key: string) => string
}

export const CoInvestorsTotals: FC<ICoInvestorsTotalsProps> = ({ watchedFields, getTranslation, totalFieldName }) => {
    const classes = TotalTableStyles();
    const [fieldsTotal, setFieldsTotal] = useState<any>();
    const cellProps: TableCellProps = {
        variant: 'head',
        classes: { head: classes.totalCellHead, root: classes.cell }
    }
    const { register, setValue, errors } = useFormContext();

    const totalCallBack = useCallback(() => {
        if (watchedFields?.length > 0) {
            const fields = watchedFields?.reduce((acc, current) => {
                // @ts-ignore
                // tslint:disable-next-line: radix
                acc.donationAmount = acc.donationAmount + parseInt(current.donationAmount || 0);
                // @ts-ignore
                // tslint:disable-next-line: radix
                acc.percentage += parseInt(current.percentage || 0);
                // @ts-ignore
                // tslint:disable-next-line: radix
                return acc;
            }, { donationAmount: 0, percentage: 0 });
            setValue(totalFieldName, fields.percentage, { shouldValidate: true });
            setFieldsTotal(fields);
        }
    }, [watchedFields]);


    useEffect(() => {
        totalCallBack();

    }, [totalCallBack])

    return (<div>
        <Table aria-label={'Totals Row'} size='small' classes={{ root: classes.tableRoot }}>
            <TableHead>
                <TableRow>
                    <TableCell {...cellProps} style={{ width: '25%' }}>
                        {getTranslation('total')}
                    </TableCell>
                    <TableCell style={{ width: '35%' }} />
                    <TableCell {...cellProps} style={{ width: '15%' }}>
                        {formatCurrency(fieldsTotal?.donationAmount)}
                    </TableCell>
                    <TableCell {...cellProps} style={{ width: '15%' }}>
                        {fieldsTotal?.percentage}%
                    </TableCell>
                    <TableCell {...cellProps} style={{ width: '5%' }}>
                        <input type='hidden' ref={register} name={totalFieldName} />
                    </TableCell>
                </TableRow>
            </TableHead>
        </Table>
        <ErrorMessageComponent errors={errors} fieldName={totalFieldName} />
    </div>);
}
