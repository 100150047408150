import React, {useEffect} from 'react';
// import  TopBar  from '../../commonComponents/appComponents/topBar/topBar';
import Drawer from '../../commonComponents/appComponents/drawer/drawer';
// import '@walmart-web/livingdesign-components/livingdesign-components.esm.css';
import 'fix-date';
import './styles.css';
// import ResolutionHandler from '../../commonComponents/appComponents/screenResolutionHandler/resolutionHandler';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';
import FooterComponent from '../../commonComponents/appComponents/footerComponent/footerComponent';
const queryClient = new QueryClient()
// tslint:disable-next-line
export interface SpaceDonationHomeProps {
}

// tslint:disable-next-line
export interface SpaceDonationHomeState {
}

const SpaceDonationHome: React.FC<SpaceDonationHomeProps> = () => {
    useEffect(()=>{
        document.title = 'Space Request Tool';
    })
    return <QueryClientProvider client={queryClient}>
        <CookiesProvider>
            <div className='space-app-root'>
                    <Drawer />
                    <FooterComponent/>
            </div>
        </CookiesProvider>
    </QueryClientProvider>
}
export default SpaceDonationHome;
