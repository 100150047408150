import { Alert } from "@walmart-web/livingdesign-components";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PAGETYPES } from "src/constants";
import { GlobalDonationState } from "../../../models/globalDonationState";
import withRouter from "../../../polyfils/customRouter";
import {
  getOrgProfile
} from "../../../state/OrgProfile/orgProfileActions";
import WmSpinner from "../../common/Loaders/WmSpinner";
import { getLangObject, getOrganizationObject, getThankyouRoute } from "../../common/utils";
import { addDonationBreadcrumbArray } from './BreadCrumbRouting';
import { baseSubContextUrl, RdaCheck, useCreateDonationApi } from "./commonApi";
import { CustomTemplateContainer } from "./CustomTemplate";
import { PageBanner } from "./PageBanner";

export interface OwnProps {
  history: any;
  selectedLang: any;
  donationCategory: any;
}

export interface StateProps {
  orgProfile: any;
}

export interface DispatchProps {
  getOrgProfile: (data: any) => void;
}


const CreateDonationTemplate: FC<OwnProps & DispatchProps & StateProps> = ({
  history,
  selectedLang,
  getOrgProfile,
  orgProfile,
  donationCategory
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [isLocationMandatory, setIsLocationMandatory] = useState(false);
  const rdaCheckQuery = RdaCheck(setIsLocationMandatory, setError);
  const createDonationMutate = useCreateDonationApi(history, setError, `${baseSubContextUrl}/create`, getThankyouRoute(donationCategory));

  let [organizationForm, setOrganizationForm] = useState<any>([]);
  const langObjectPath = getLangObject(donationCategory);
  // Get org details via api calls from redux
  useEffect(() => {
    const email: string = localStorage.getItem("userEmail") || "";
    getOrgProfile({ email });
  }, []);

  useEffect(() => {
    if (orgProfile.id) {
      setLoading(false);
      rdaCheckQuery.mutate({
        orgTypes: [
          "org_type_ngo",
          "org_type_governmental",
          "org_type_individual",
        ],
        marketCode: orgProfile.countryCode,
        donationType: donationCategory
      });
      setOrganizationForm(getOrganizationObject(orgProfile, langObjectPath));

    }
  }, [orgProfile]);

  // Add loader while any data is loading
  if (loading || rdaCheckQuery.isLoading || createDonationMutate.isLoading) {
    return (
      <WmSpinner></WmSpinner>
    );
  }

  return (
    <>
      <PageBanner breadcrumbsData={addDonationBreadcrumbArray(langObjectPath, donationCategory)} history={history}
        heading={t(`${langObjectPath}.form.newDonation`)} />
      <CustomTemplateContainer 
        selectedLang={selectedLang} history={history}
        donationCategory={donationCategory}
        orgType={orgProfile?.organizationType?.keyText}
        isLocationMandatory={isLocationMandatory}
        createDonationMutate={createDonationMutate}
        organizationForm={organizationForm}
        childType={PAGETYPES.CREATE} />

      {error || createDonationMutate.data?.errorMessage || createDonationMutate.error ? (
        <Alert variant='error' style={{ marginBottom: "10px" }}>
          {t(`${langObjectPath}.form.errorResponse`)}
        </Alert>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  orgProfile: state.orgProfile.orgProfile
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
  getOrgProfile: (data: any) => dispatch(getOrgProfile(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDonationTemplate));
