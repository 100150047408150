import { Action } from 'redux';

export enum GlobalDonationActionTypes {
    GLOBALDONATION_LOAD_SUCCESS = 'GLOBALDONATION_LOAD_SUCCESS',
    GLOBALDONATION_LOAD_FAILURE = 'GLOBALDONATION_LOAD_FAILURE',
    SELECTED_MENU = 'SELECTED_MENU',
    SELECTED_LANG = 'SELECTED_LANG'
}

export interface GlobalDonationDataSuccess extends Action {
    type: GlobalDonationActionTypes.GLOBALDONATION_LOAD_SUCCESS;
    data: string;
}

export const globalDonationDataSuccess = (data: string): GlobalDonationDataSuccess => ({
    type: GlobalDonationActionTypes.GLOBALDONATION_LOAD_SUCCESS,
    data
});

export interface GlobalDonationDataFailure extends Action {
    type: GlobalDonationActionTypes.GLOBALDONATION_LOAD_FAILURE;
    data?: string;
}

export const globalDonationDataFailure = (data: string): GlobalDonationDataFailure => ({
    type: GlobalDonationActionTypes.GLOBALDONATION_LOAD_FAILURE,
    data
});

export interface SelectedMenu extends Action {
    type: GlobalDonationActionTypes.SELECTED_MENU;
    data?: string;
}

export const selectedMenu = (data: string): SelectedMenu => ({
    type: GlobalDonationActionTypes.SELECTED_MENU,
    data
});
export interface SelectedLang extends Action {
    type: GlobalDonationActionTypes.SELECTED_LANG;
    data?: string;
}

export const selectedLang = (data: string): SelectedLang => ({
    type: GlobalDonationActionTypes.SELECTED_LANG,
    data
});

export type GlobalDonationDataActions = GlobalDonationDataSuccess | GlobalDonationDataFailure | SelectedMenu | SelectedLang;