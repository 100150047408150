import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useHistory } from '../../../polyfils/customRouter';
import { BreadcrumbsComponent } from 'src/components/common';
import { convertFromUtcToLocal } from 'src/components/common/DateUtils';
import { getTransformedReviewActions, OrganizationTypes, useScrollToError } from 'src/components/common/utils';
import { commonFormTranslations, contextPath } from 'src/constants';
import { AppendixBRequestDTO, IFoodOneTimeResponseDTO, IFoodReviewDTO } from 'src/models/iOneTimeFoodDTO';
import {
  useFetchOneTimeFoodDonationDetailsById,
  useSaveOneTimeFoodDonation
} from '../../../custom-hooks';
import { getAppendixBValidationSchema } from './common/appendixBValidation';
import BackWithHeading from './common/BackWithHeading';
import FoodNGOForm from './components/FoodNGOForm';
import StoreSelectionComponent from './components/StoreSelectionComponent';

const breadcrumbsData = [
  {
    name: 'oneTimeGoodsDonation.nav.home',
    path: '/',
  },
  {
    name: 'oneTimeFoodDonation.oneTimeFoodDonation',
    path: `${contextPath}/dashboard/one-time-food?type=one-time-food`,
  },
  {
    name: '',
    active: true,
    applyFormat: false
  }
];
interface OwnProps {
  donationId: number
  countryCode: string,
  selectedLang: string,
  appendixB: any,
  storeNumber: number
}

const NGOReviewDonationOfFood: FC<OwnProps> = ({
  donationId,
  countryCode,
  selectedLang,
  appendixB,
  storeNumber
}) => {
  const history = useHistory()

  breadcrumbsData[2].name = donationId?.toString();
  const { response: { foodOneTimeDonation, waaSTaskMessage }, isLoading: isFetching, isError, error } =
    useFetchOneTimeFoodDonationDetailsById(donationId)

  const { mutateAsync, updateError, saveSuccess, isUpdating, failedUpdate } = useSaveOneTimeFoodDonation()

  const methods = useForm({
    mode: 'onChange',
    defaultValues: appendixB,
    resolver: yupResolver(getAppendixBValidationSchema(countryCode, OrganizationTypes.NGO))
  });
  const attachFiles = (name: string, formData: FormData, formField: string) => {
    const donationAttachments = methods.getValues(name);
    donationAttachments?.forEach((file: File) => {
      formData.append(formField, file)
    })
  }
  useScrollToError(methods?.errors, 'Mui-error')

  useEffect(() => {
    let appendixBData;
    if (foodOneTimeDonation?.additionalDetails?.appendixBIsFilled && foodOneTimeDonation?.appendixB) {
      appendixBData = {
        ...foodOneTimeDonation?.appendixB,
      }
      appendixBData.organization.establishedDate = convertFromUtcToLocal(appendixBData.organization.establishedDate)
      appendixBData.organization.certificateSignedDate = convertFromUtcToLocal(appendixBData.organization.certificateSignedDate)
      appendixBData.organization.consentSignedDate = convertFromUtcToLocal(appendixBData.organization.consentSignedDate)
      appendixBData.organization = {
        ...appendixBData.organization,
        certificationCheckBox1: true,
        certificationCheckBox2: true,
        certificationCheckBox3: true,
        consentCheckBox1: true,
        caUserCheckBox: true,
        consentCheckBox2: true
      }
      methods.reset(appendixBData)
    }
  }, [foodOneTimeDonation])

  const submitDonation = (submissionData: AppendixBRequestDTO) => {
    if (submissionData) {
      const foodOneTime: IFoodOneTimeResponseDTO = { ...foodOneTimeDonation };

      foodOneTime.comments = submissionData.comments;
      foodOneTime.appendixB = {
        ...foodOneTime.appendixB,
        ...submissionData,
      };
      foodOneTime.appendixB.organization = {
        ...foodOneTimeDonation?.appendixB?.organization,
        ...foodOneTime.appendixB.organization,
      }
      if (waaSTaskMessage) {
        const requestData: IFoodReviewDTO = {
          foodOneTimeDonation: foodOneTime,
          waaSTaskMessage,
          waaSTask: {
            requestId: waaSTaskMessage.requestId,
            taskId: waaSTaskMessage.taskId,
            taskName: waaSTaskMessage.taskName,
            user: waaSTaskMessage.user,
            variables: {
              userAction: submissionData.action?.value ?? '',
              appendixAIsFilled: foodOneTimeDonation?.additionalDetails.appendixAIsFilled ?? false,
              appendixBIsFilled: true
            }
          }
        }
        const formData = new FormData();
        formData.append('donationWaaSTask', JSON.stringify(requestData));
        formData.append('modifyOrg', 'true');
        formData.append('deductBudget', 'false');
        attachFiles('organizationAttachments', formData, 'organizationAttachments');
        attachFiles('donationAttachments', formData, 'donationAttachments');
        mutateAsync(formData);
      }
    }
  }

  return <>
    <BreadcrumbsComponent breadcrumbsData={breadcrumbsData} history={history} />
    <BackWithHeading url='/dashboard/one-time-food?type=one-time-food' heading='oneTimeFoodDonation.oneTimeFoodDonation' />

    <StoreSelectionComponent
      countryCode={countryCode}
      handleFacilityChange={() => null}
      storeNumber={storeNumber}
      selectedLang={selectedLang}
      isReview={true}
      message={foodOneTimeDonation?.comments}
    />
    <FoodNGOForm
      isFetching={isFetching}
      appendixB={foodOneTimeDonation?.appendixB ?? appendixB}
      countryCode={countryCode}
      isError={isError || failedUpdate}
      isLoading={isUpdating}
      isSuccess={saveSuccess}
      methods={methods}
      showSubmit={waaSTaskMessage?.formProperties?.length > 0}
      submitDonation={submitDonation}
      isReview={true}
      successMessage={<Trans i18nKey='common.successMsg.donationUpdateMsg'>
        Donation has been updated Successfully
      </Trans>}
      // @ts-ignore
      errorMessage={updateError?.errorMessage || error?.errorMessage
        || commonFormTranslations.genericErrorMessage[selectedLang]}
      disabledReview={foodOneTimeDonation?.carton?.cartonStatus === 'APPROVED'}
      actionOptions={getTransformedReviewActions(waaSTaskMessage?.formProperties)}
    />
  </>
}


export default NGOReviewDonationOfFood;
