import React, { useState, useEffect } from 'react';
import { StaticDatePicker as MUIDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Card from '../../commonComponents/uiComponents/LDCard';
import Divider from '../../commonComponents/uiComponents/LDDivider';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import Button from '../../commonComponents/uiComponents/LDButton';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import { useMutation, useQuery } from 'react-query';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import {
    getTimeFromStoreTimeZone,
    DEFAULT_TIME_ZONE,
    usTimeZonesToLocaesMap
} from 'src/components/spacedonation/scenes/common/timeZoneHelperUtil';
import moment from 'moment';
import { Alert } from '@walmart-web/livingdesign-components';
import LDModal from '../../commonComponents/uiComponents/LDModal';
import { TextField } from '@mui/material';
const service: Service = SpaceDonationService;

export interface Props {
    nextPage?: any,
    prevPage?: any,
    setSelectedForm?: any,
    setSelectedSlot?: any,
    setSelectedDate?: any,
    selectedDate?: any,
    selectedSlot?: any,
    selectedStore?: any,
    selectedSpace?: any
    userContext?: any
}


export interface SlotProps {
    slotName?: string,
    slotStartTime?: string,
    slotEndTime?: string,
    label?: string,
    slotId?: any,
    numberOfHours?: any,
    active?: boolean
}

let slotList:SlotProps[] = [];
// @ts-ignore
let lookAheadNumberOfDays:number;


const slotDisableRules = {
    0: [1, 2, 3],
    1: [0],
    2: [0],
    3: [0]
}

const SpaceDonationSlotChooser: React.FC<Props> = (props) => {
    const [date, setDate] = useState(props.selectedDate);
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const [popupMsg, setPopupMsg] =useState('');
    const [month, setMonth] = useState(moment(date).format('MM'));
    const mutation = useMutation((data: any) => service.fetchSlots(data));
    const mutationLimitBooking = useMutation((data: any) => service.limitBooking(data));
    const { data:slotsListData, isFetching:slotsListIsFetching, isSuccess:slotsListIsSuccess } =
      useQuery(['fetchSlotsList', { key: 'slotsList', countryCode: 'US' }],
      service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false });
    const { data:lookAheadData, isFetching:lookAheadIsFetching, isSuccess:lookAheadIsSuccess } =
      useQuery(['fetchEventLookAheadNumber', { key: 'homeOfficeSettings', countryCode: 'US' }],
      service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false });
    useEffect(() => {
        if (slotsListIsSuccess) {
            slotList = slotsListData.default;
        }
    }, [slotsListIsSuccess]);
    useEffect(() => {
        if (lookAheadIsSuccess) {
            lookAheadNumberOfDays = lookAheadData?.['non-profit']?.eventLookAheadDays?.
            [props.userContext?.organization?.isVerified?'verified':'non-verified']?.value;
        }
    }, [lookAheadIsSuccess]);
    useEffect(() => {
        mutation.mutate({
            storeNbr: props.selectedStore.storeNbr,
            month: moment().format('MM'),
            year: moment().format('YYYY'),
            spaceName: props.selectedSpace.name
        })
    }, []);
    useEffect(() => {
        mutation.mutate({
            storeNbr: props.selectedStore.storeNbr,
            month: moment(date).format('MM'),
            year: moment(date).format('YYYY'),
            spaceName: props.selectedSpace.name
        })
    }, [month]);
    return <><div>
        {mutation.isLoading && <LoaderComponent />}
        {mutationLimitBooking.isLoading && <LoaderComponent />}{slotsListIsFetching && lookAheadIsFetching && <LoaderComponent />}
        {!mutation.isLoading && mutation.isSuccess && slotsListIsSuccess && lookAheadIsSuccess && <DatePicker
            mutationLimitBooking={mutationLimitBooking}
            setShowSuccessPopUp={setShowSuccessPopUp}
            setPopupMsg={setPopupMsg}
            data={mutation.data} date={date} setMonth={setMonth} month={month} setDate={setDate}{...props} />}
    </div>
        <LDModal title=' '
                 size='small'
                 onClose={() => setShowSuccessPopUp(false)}
                 style={{ 'width': '18vw !important', height: '40vh !important' }}
                 isOpen={showSuccessPopUp}
                 setIsOpen={setShowSuccessPopUp}>
            {popupMsg}
            <div>
                <Button className='verification-buttons' onClick={() => setShowSuccessPopUp(false)} variant='primary'>OK</Button>
            </div>
        </LDModal>
    </>
}

export default SpaceDonationSlotChooser;

const DatePicker = (props: any) => {
    useEffect(() => {
        if (props.mutationLimitBooking.isSuccess) {
            props.setSelectedForm(props.nextPage);
        }
        }, [props.mutationLimitBooking.isSuccess]);
    useEffect(() => {
        if (props.mutationLimitBooking.isError) {
            props.setShowSuccessPopUp(true);
            props.setPopupMsg(props.mutationLimitBooking?.error?.response?.data?.errorMessage);
        }
    }, [props.mutationLimitBooking.isError]);
    const blockedDate = Object.keys(props.data).filter(date => (
        (props.data[date].length === slotList.length - 1
            &&
            `0${props.data[date].map((z: any) => z.slotId).sort((a: any, b: any) => a - b).join('')}`
            === slotList.map((z: any) => z.slotId).sort((a: any, b: any) => a - b).join(''))
        ||
        props.data[date].filter((z: any) => z.slotId === 0).length))
        .map(date => date);
       
      const disableDates = (date: any) => {
        for (const x of blockedDate) {
          if (
            moment(date).format("MM") === moment(x).format("MM") &&
            moment(date).format("YYYY") === moment(x).format("YYYY") &&
            moment(date).format("DD") === moment(x).format("DD")
          ) {
            return true;
          }
        }
        return false;
      };
    const [selectedDate, setSelectedDate] = React.useState(props.date);

    // const emptyArray: string[] = props.selectedSlot ? props.selectedSlot : [];
    const emptyArray: string[] = [];
    const [slots, setSlots] = useState(emptyArray);
    const handleDateChange = (date: any) => {
        if (moment(date).format('MM') === props.month) {
            setSelectedDate(date);
            setSlots([]);
        } else {
            handleMonthChange(date);
        }
    };

    const handleMonthChange = (date: any) => {
        props.setDate(moment(date).isBefore(moment()) ? new Date() : date);
        props.setMonth(moment(date).format('MM'));
        setSlots([]);
    };
    return <><div><Card className='slot-chooser-container'>
        <CardItem className='no-default-padding'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MUIDatePicker
                                  inputFormat='MM/dd/yyyy'
                                  value={selectedDate}
                                  disablePast={true}
                                  shouldDisableDate={disableDates}
                                  onChange={handleDateChange}
                                  onMonthChange = {handleMonthChange}
                                  showToolbar={false}
                                  views={['day']}
                                  displayStaticWrapperAs='desktop'
                                  renderInput={(params) =>
                                  <TextField {...params} />}
                                />
                       </LocalizationProvider>
        </CardItem>
        <Divider />
        <CardItem className='no-default-padding'>
            <AccordionSection {...props} selectedDate={selectedDate} slots={slots} setSlots={setSlots} />
        </CardItem>
        <CardItem className='row slot-chooser-desktop-button-section'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12' />
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 right-aligned'>
                <Button
                    variant='primary'
                    onClick={() => {
                        props.setSelectedSlot(slots);
                        props.setSelectedDate(selectedDate);
                        // props.setSelectedForm(props.nextPage);
                        props.mutationLimitBooking.mutate({
                            storeNbr: props.selectedStore.storeNbr,
                            eventDate: moment(selectedDate).format('YYYY-MM-DD'),
                            spaceOrgId: props.userContext.organization.id,
                            isOrgVerified: props.userContext.organization.isVerified
                        })
                    }}
                    disabled={disableButton(slots)[0]}
                >Continue</Button></div>
        </CardItem>
    </Card>
        <CardItem className='slot-chooser-phone-button-section'>
            <Button className='slot-chooser-phone-button'
                variant='primary'
                onClick={() => {
                    props.setSelectedSlot(slots);
                    props.setSelectedDate(selectedDate);
                    // props.setSelectedForm(props.nextPage);
                    props.mutationLimitBooking.mutate({
                        storeNbr: props.selectedStore.storeNbr,
                        eventDate: moment(selectedDate).format('YYYY-MM-DD'),
                        spaceOrgId: props.userContext.organization.id,
                        isOrgVerified: props.userContext.organization.isVerified
                    })
                }}
                // @ts-ignore
                disabled={disableButton(slots)[0]}
            >Continue</Button>
        </CardItem>
        </div>
    </>
}

const disableButton = (slots: any[]) => {
    if (!slots.length) { return [true,false]; }
    else if (slots.length &&
        slots.length === 2 &&
        4 === slots.map(x => x.slotId).reduce((a, b = 0) => a + b)) {
        return [true,'Non-Continuous slots are not allowed to select.'];
    }
    else if (slots.length &&
        8 < slots.map(x => x.numberOfHours).reduce((a, b = 0) => a + b)) {
        return [true,'Selected slots exceeds 8 hours. Please select slots less than 8 hours to proceed.'];
    }
    return [false,''];
}

const AccordionSection = (props: any) => {
    const isDST = moment.tz(moment(props.selectedDate).format('YYYY-MM-DD'),
        usTimeZonesToLocaesMap()[props?.selectedStore?.timeZoneCode]).isDST();
    const timeZoneAbbr = moment.tz([moment(props.selectedDate).year(), moment(props.selectedDate).month()+1],
        usTimeZonesToLocaesMap()[props?.selectedStore?.timeZoneCode]).zoneAbbr();
    let bookedslotIds: any[] = [];
    if (props.data && props.data[moment(props.selectedDate).format('YYYY-MM-DD')] && props.data[moment(props.selectedDate).format('YYYY-MM-DD')].length > 0) {
        bookedslotIds = props.data[moment(props.selectedDate).format('YYYY-MM-DD')].map((slot: any) => slot.slotId);
        let slotsToDisable: any[] = [];
        if(bookedslotIds.length){
            bookedslotIds.forEach((x)=>
             // @ts-ignore
             slotsToDisable = slotsToDisable.concat(slotDisableRules[x]));
        }
        bookedslotIds = [...new Set(bookedslotIds.concat(slotsToDisable))];
    }
    slotList = slotList.map(slot => ({ ...slot, active: !bookedslotIds.includes(slot.slotId) }));
    const isSlotEnbled = (value: any) =>
        props.slots.filter((s: any) => s.active).map((s: any) => s.slotName).includes(value);
    const [openAccord, setOpenAccord] = useState(slotList.map((s: any) => s.slotName));
    const accordClick = (slotName: any) => {
        openAccord.includes(slotName) ?
            setOpenAccord(openAccord.filter(x => x !== slotName)) :
            setOpenAccord([...openAccord, slotName])
    }


    const toggle = (slotId: any, slotName: any) => {
        const selectedSlots = isSlotEnbled(slotName) ?
            // @ts-ignore
            props.slots.filter((s: any) => s.active).filter(x => x.slotName !== slotName) :
            [...props.slots, slotList.filter((s: any) => s.active).filter(x => x.slotName === slotName)[0]]
             // @ts-ignore
            .filter((slot:any) => !slotDisableRules[slotId].includes(slot.slotId));
            props.setSlots(selectedSlots);
    }
    // slot disabling logic for current day --start
    const tz = props.selectedStore.timeZoneCode ? props.selectedStore.timeZoneCode : DEFAULT_TIME_ZONE;

    // to disable slots for current day based on current time
    const filterSlotsForToday = (date: Date, slots: any[]) => {
        let res = slots
        const selectedDate = moment(date).format('YYYY-MM-DD');
        const currentDate = moment(new Date()).format('YYYY-MM-DD');
        if (selectedDate === currentDate) {
            res = slots.map(slot => ({
                ...slot,
                active: slot.active && moment(getTimeFromStoreTimeZone(`${moment(selectedDate).format('YYYY-MM-DD')}T${slot.slotStartTime}`,
                    'YYYY-MM-DDTHH:mm:ssZ', tz.toUpperCase())) > moment(),
            }));
        }
        return res;
    }
    slotList = filterSlotsForToday(props.selectedDate, slotList);

    // slot disabling logic for current day --end
    return <>
        {disableButton(props.slots)[1] && <div className='slot-chooser-warning'><Warning message={disableButton(props.slots)[1]}/></div>}
        {new Date(props.selectedDate.toDateString()) >= new Date(new Date().toDateString()) && <div>
            <div className='padding-10'>
                <div className='slot-chooser-panel-header' >Select All Day or up to two consecutive timeslots</div>
                <div className='slot-chooser-panel-timezone' >Selected Store Timezone :
                    {isDST ? ` `+timeZoneAbbr: ` `+props?.selectedStore?.timeZoneCode}</div>
            </div>
            <CardItem className='no-default-padding'>
                <div className='accordion-section'>
                    {slotList.sort((b: any, a: any) => b.slotId - a.slotId).map(slot => {
                        return <Accordion key={slot.slotName} expanded={openAccord.includes(slot.slotName)}
                            onChange={() =>
                                accordClick(slot.slotName)
                            }
                        >
                            <AccordionSummary
                                aria-controls='panel1bh-content'
                                id='panel1bh-header'
                            >
                                <div className='slot-chooser-panel-header' >{slot.slotName}</div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Button className='slot-chooser-button' disabled={!slot.active}
                                    onClick={() => toggle(slot.slotId, slot.slotName)}
                                    variant={isSlotEnbled(slot.slotName) ? 'primary' : 'secondary'}
                                >
                                    {slot.label}
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    })}
                </div>
                <div className='phone-buffer-section' />
            </CardItem>
        </div>
    }</>
}

const Warning = (props:any) => {
    return <Alert variant='warning'>
        {props.message}
    </Alert>
}
