import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FC, useEffect, useState } from 'react'
import LoaderComponent from '../../../components/LoaderComponent';
import { getAppendixCHistory } from '../../../service/apiService'
import { AppendixCHistoryForm } from './AppendixCHistoryForm';
import { getFormattedAppendixCHistoryList } from './helper';

interface AppendixCHistroyProps {
    donationId: string | number
}

const AppendixCHistory: FC<AppendixCHistroyProps> = ({ donationId }: AppendixCHistroyProps) => {
    const [appendixHistoryList, setAppendixHistoryList] = useState<any[]>([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [isLoading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true)
        getAppendixCHistory(donationId).then((data) => {
            setLoading(false)
            setAppendixHistoryList(getFormattedAppendixCHistoryList(data))
        }
        ).catch((_error: any) => {
            setLoading(false)
        });
    }, []);

    const rowExpansionTemplate = (data: any) => {
        return (
            <AppendixCHistoryForm donationData={data} />
        );
    }

    const DataTableItem = DataTable as any;
    return (
        <div className='appendix-c'>
            <DataTableItem
                value={appendixHistoryList}
                expandedRows={expandedRows}
                onRowToggle={(e: any) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey='date'>
                <Column expander={true} style={{ width: '3em' }} />
                <Column field='date' header='Date last sent' />
            </DataTableItem>
            {isLoading && <LoaderComponent fullScreen={true} />}
        </div>
    );
}

export default AppendixCHistory