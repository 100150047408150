import * as React from 'react';
import Button from '../../uiComponents/LDButton';

export interface AccountInfoCardTextItemProps {
    header: any,
    showEditButton?: boolean,
    buttonHandler?: any,
    children?: any
}

const AccountInfoCardTextItem: React.SFC<AccountInfoCardTextItemProps> = ({ header, children = null,
  showEditButton = false, ...props }) => {
    return <>{showEditButton && <div className='info-card-text-container'><div className='info-card-text-item'>
        <div className='info-card-text-item-header'>{header}</div>
        <div className='info-card-text-item-value'>{children}</div>
    </div>{showEditButton && <Button variant='tertiary' onClick={() => {
                  if(props?.buttonHandler) {
                    props?.buttonHandler(true)
                  }
                }}>Edit</Button>}</div>}
        {!showEditButton && <div className='info-card-text-item'>
            <div className='info-card-text-item-header'>{header}</div>
            <div className='info-card-text-item-value'>{children}</div>
        </div>}
    </>
}

export default AccountInfoCardTextItem;