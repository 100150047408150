import {Grid, Typography} from '@material-ui/core';
import {FC, useEffect} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import InformationBar from '../common/informationBar';
import {sectionClasses} from '../common/styles';
import {InputTextField} from '../../../common/Input/InputTextField';
import {BaseSectionProps} from '../organizationDetails/orgDetailsDefaults';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';

export interface ProjectBeneficiariesProps extends BaseSectionProps {
    data: any
}

export const ProjectBeneficiaries: FC<ProjectBeneficiariesProps> = (props: ProjectBeneficiariesProps) => {
    const { t } = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
    const getSnackbar = (translationKey: string) => {
        return (<InformationBar>
            {getTranslation(translationKey)}</InformationBar>
        );
    }
    const formMethods = useFormContext();

    const noOfChildWatcher = useWatch<any>({ name: `${props.fieldName}.noOfChild`, control: formMethods.control });
    const noOfYoungAdultsWatcher = useWatch<any>({ name: `${props.fieldName}.noOfYoungAdults`, control: formMethods.control });
    const noOfAdultsWatcher = useWatch<any>({ name: `${props.fieldName}.noOfAdults`, control: formMethods.control });
    const noOfSeniorWatcher = useWatch<any>({ name: `${props.fieldName}.noOfSenior`, control: formMethods.control });

    useEffect(() => {
        const total = parseInt(noOfChildWatcher || 0, 10) + parseInt(noOfYoungAdultsWatcher || 0, 10) +
            parseInt(noOfAdultsWatcher || 0, 10) + parseInt(noOfSeniorWatcher || 0, 10);

        formMethods.setValue(`${props.fieldName}.noOfDirectBeneficiaries`, total, { shouldValidate: true });
    }, [noOfChildWatcher, noOfYoungAdultsWatcher, noOfAdultsWatcher, noOfSeniorWatcher])

    return (<div className={sectionClasses.root}>
        <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
                <Typography classes={{ root: sectionClasses.title }}>{getTranslation('directBeneficiaries')}</Typography>
            </Grid>
        </Grid>
        <Grid container={true} spacing={3}>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        fieldName={`${props.fieldName}.noOfChild`}
                        errorKeyName={`${props.fieldName}.noOfChild`}
                        label={getTranslation('children')}
                        id={'noOfChild'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfChild}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('childrenDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        label={getTranslation('youngAdults')}
                        fieldName={`${props.fieldName}.noOfYoungAdults`}
                        errorKeyName={`${props.fieldName}.noOfYoungAdults`}
                        id={'noOfYoungAdults'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfYoungAdults}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('youngAdultsDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        label={getTranslation('adults')}
                        fieldName={`${props.fieldName}.noOfAdults`}
                        errorKeyName={`${props.fieldName}.noOfAdults`}
                        id={'noOfAdults'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfAdults}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('adultsDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        label={getTranslation('seniors')}
                        fieldName={`${props.fieldName}.noOfSenior`}
                        errorKeyName={`${props.fieldName}.noOfSenior`}
                        id={'noOfSenior'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfSenior}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('seniorsDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        disabled={true}
                        label={getTranslation('totalDirectBeneficiaries')}
                        fieldName={`${props.fieldName}.noOfDirectBeneficiaries`}
                        id={'totalDirectBeneficiaries'}
                        // InputProps={{ value: totalBeneficiaries }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfDirectBeneficiaries}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('totalDirectBeneficiariesDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        label={getTranslation('families')}
                        fieldName={`${props.fieldName}.noOfFamilies`}
                        errorKeyName={`${props.fieldName}.noOfFamilies`}
                        id={'noOfFamilies'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfFamilies}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('familiesDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        type={'number'}
                        label={getTranslation('womenDirectBeneficiaries')}
                        fieldName={`${props.fieldName}.noOfWomen`}
                        errorKeyName={`${props.fieldName}.noOfWomen`}
                        id={'noOfWomen'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.noOfWomen}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('womenDirectBeneficiariesDesc')}
                </Grid>
            </Grid>


            <Grid item={true} xs={12}>
                <Typography classes={{ root: sectionClasses.title }}>{getTranslation('indirectBeneficiaries')}</Typography>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        type={'number'}
                        required={true}
                        label={getTranslation('indirectBeneficiaries')}
                        fieldName={`${props.fieldName}.totalIndirect`}
                        errorKeyName={`${props.fieldName}.totalIndirect`}
                        id={'totalIndirect'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={props?.data?.totalIndirect}
                    />
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('indirectBeneficiariesDesc')}
                </Grid>
            </Grid>
            <Grid container={true} item={true} spacing={3}>
                <Grid item={true} xs={12} md={4}>
                    <InputTextField
                        required={true}
                        label={getTranslation('explainCalculationOfIndirect')}
                        fieldName={`${props.fieldName}.calculationIndirectBeneficiaries`}
                        errorKeyName={`${props.fieldName}.calculationIndirectBeneficiaries`}
                        id={'calculationIndirectBeneficiaries'}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        multiline={true}
                        rows={4}
                        defaultValue={props?.data?.calculationIndirectBeneficiaries}
                    />
                     <TextFieldMaxSizeIndicator
                        fieldName = {`${props.fieldName}.calculationIndirectBeneficiaries`}
                        maxSize = {1000}/>
                </Grid>
                <Grid item={true} xs={12} md={5}>
                    {getSnackbar('explainCalculationOfIndirectDesc')}
                </Grid>
            </Grid>
        </Grid>
    </div>
    );
}