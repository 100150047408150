import * as React from 'react';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useFieldArray, useForm} from 'react-hook-form';
import {Button, IconButton, withStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import withRouter, { RouteComponentProps }  from 'src/polyfils/customRouter';
import {GlobalDonationState} from 'src/models/globalDonationState';
import {loggedInUserData} from 'src/state/loggedInUser/loggedInUserActions';
import {updateForm} from 'dynamic-form-components/lib/state/form/formDataActions';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import './../../styles/inkindReport.css';
import {contextPath, socialImpactTranslations, uploadFileSize} from '../../constants';
import LoaderComponent from '../LoaderComponent';
import SocialImpactConfirmDialog from '../dialogComponents/SocialImpactConfirmDialog';
import SuccessAlertDailog from '../dialogComponents/SuccessAlertDailog';
import moment from 'moment';
import {viewOrDownloadFile} from 'src/state/ManageWorkflow/fileActions';
import {getEncryptedString} from 'dynamic-form-components/lib/crypto';
import {deleteFile} from 'src/state/downloadFile/deleteFileActions';
import {submissionData} from 'src/state/submission/submissionDataActions';
import {getDuplicateFacilityDetails, validateTextLimit} from './validationUtil';
import {getFormattedDate} from '../common/DateUtils';
import {getUploadFileForm} from '../../components/common/utils';
import {uploadAssignment, viewOrDownloadAssignment} from '../../state/uploadDownloadFile/uploadDownloadFileActions';
import SuccessSnackBarComponent from 'dynamic-form-components/lib/components/menu/SuccessSnackBarComponent';
import ReportModel from './ReportModel';
import {useTranslation, withTranslation} from 'react-i18next';

const config = require(`../../config.${process.env.NODE_ENV}.json`);

const styles: any = (_theme: any) => ({
    selectRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)',
    },
    outlined: {
        color: 'red'
    },
    selectIcon: {
        color: 'var(--inputTextColor) !important'
    },
    selectFormControl: {
        color: 'var(--inputTextColor)',
        minWidth: '200px',
        fontFamily: ' var(--appFont)',
        backgroundColor: '#fff',
        marginRight:'1rem',
        marginBottom: '1rem'
    },
    selectLabel: {
        color: 'var(--inputTextColor)',
        fontFamily: ' var(--appFont)',
        marginLeft: '10px',
        backgroundColor:'#fff'
    },
    menuItemRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)'
    },
    menuItemselected: {
        color: 'var(--inputTextColor);'
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonGroup: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonStyles: {
        top: '6px',
        marginBottom:'2rem'
    },
    closeBtn:{
        fontSize:'18px',
        backgroundColor: '#F4F6F9 !important',
        '&:hover': {
            backgroundColor: '#F4F6F9 !important',
            color:'red'
        }
    }
})

const FILE_SIZE = uploadFileSize;
const SUPPORTED_FORMATS = [
    'application/pdf',
    'application/doc',
    'application/docx',
    'application/txt',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain'
]

const validationMsg = {
    ...socialImpactTranslations.form.validationMsg
}

const formLabels = {
    ...socialImpactTranslations.form.labels
}

const formBtns = {
    ...socialImpactTranslations.form.btn
}

const report = {
    ...socialImpactTranslations.report
}
const monthText = {
    ...socialImpactTranslations.monthText
}

const momentLocales = {
    ...socialImpactTranslations.momentLocales
}

const EditReportForm = (props: any) => {
    const { t } = useTranslation()
    const { submittedDate, donationReceiptSummaryList, uploadedFile, readOnly } = props.submission;
    const intialState = {
        year: '',
        month: '',
        socialImpactSummary: '',
        noOfChildBeneficiary: '',
        noOfYoungAdultsBeneficiary: '',
        noOfAdultsBeneficiary: '',
        noOfSeniorBeneficiary: '',
        totalDirectBeneficiary: '',
        noOfFamilies: '',
        noOfWomenBeneficiary: '',
        totalIndirectBeneficiary: '',
        file: '',
        fileDescription: '',
        donationNotCollected: false
    }
    const defaultValues = {
        ...intialState,
        ...props.submission,
        donationReceiptSummaryList,
        uploadedFile,
        fileDescription: uploadedFile && uploadedFile.fileAdditionalDetails ? uploadedFile.fileAdditionalDetails.fileDescription : '',
    }
    const [socialImpactReport, setSocialImpactReport] = useState(intialState);
    const [donationNotCollected, setDonationNotCollected] = useState(defaultValues.donationNotCollected);

    // @ts-ignore
    const [totalDirectBeneficiary, setTotalDirectBeneficiary] = useState(0);

    const [totalCartons, setTotalCartons] = useState(0);

    const [totalAmountReceived, setTotalAmountReceived] = useState(0);

    const [totalFacilities, setTotalFacilities] = useState(0);

    const [fileData, setFileData] = useState({
        name: '',
        size: '',
        type: ''
    });

    const [fileList, setFileList] = useState();

    const [modalDialOption, setModalDialOption] = useState({
        showConfirmDialog: false,
        isDownloadCliked:false,
        isDonationNotCollectedChecked: false,
    });

    const [formData, setFormData] = useState({});
    const [maxFileSizeExceeded, setMaxFileSizeExceeded] = useState(false);
    const [isfileDeleted, setIsfileDeleted] = useState(false);

    const [showDonationWasNotCollectedDailog, setShowDonationWasNotCollectedDailog] = useState(false);

    const [removeFileDailog, setRemoveFileDailog] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [showGoBackConfirmDialog, setShowGoBackConfirmDialog] = useState(false);

    const [socialImpactSummaryTextCount, setSocialImpactSummaryTextCount] = useState(defaultValues.socialImpactSummary.length);
    const [fileDescriptionTextCount, setFileDescriptionTextCount] = useState(defaultValues.fileDescription.length);
    const [updatedFieldValues, setUpdatedFieldValues] = useState({});

    const {
        register,
        unregister,
        handleSubmit,
        control,
        errors,
        formState,
        trigger,
        setValue,
        getValues,
        reset } = useForm({
            mode: 'onChange'
    });

    const { fields, insert, append, remove } = useFieldArray({
        name: 'donationReceiptSummaryList',
        control
    });

    useEffect(() => {
        if (formState.errors.file && fileData && fileData.name) {
            setValue('file', getValues().file && getValues().file.length > 0 ? getValues().file : fileList);
            trigger('file');
        }else{
            calculateTotalAmountReceived();
            calculateTotalCartons();
            calculateTotalFaility();
            getDirectBeneficiaryTotal(getValues())
            setValue('totalDirectBeneficiary', totalDirectBeneficiary, { shouldValidate: true })
            if (!donationNotCollected && !readOnly) {
                registerValidation()
            }
        }
        if (formState.touched && Object.keys(formState.touched).length > 0) {
            setFormChanged(true)
        }
    }, [formState]);

    useEffect(() => {
        reset({ ...defaultValues })
        if (modalDialOption.isDownloadCliked) {
            reset({ ...updatedFieldValues })
        }
        if (!donationNotCollected && !readOnly){
            registerValidation()
        }
        setFileDescriptionTextCount(defaultValues && defaultValues.fileDescription ? defaultValues.fileDescription.length: 0);
        setSocialImpactSummaryTextCount(defaultValues && defaultValues.socialImpactSummary ? defaultValues.socialImpactSummary.length: 0)
        setDonationNotCollected(defaultValues.donationNotCollected)
    }, [props.submission])

    useEffect(() => {
        reset({ ...defaultValues })
        calculateTotalAmountReceived();
        calculateTotalCartons();
        calculateTotalFaility();
        if (!donationNotCollected && !readOnly) {
            registerValidation()
        }
    }, [reset])

    useEffect(() => {
        getDirectBeneficiaryTotal(getValues())
        setValue('totalDirectBeneficiary', totalDirectBeneficiary, {shouldValidate: true})
        if (!donationNotCollected && !readOnly) {
            registerValidation()
        }
    }, [socialImpactReport])

    useEffect(() => {
        calculateTotalAmountReceived();
        calculateTotalCartons();
        calculateTotalFaility();
    }, [donationReceiptSummaryList])

    useEffect(() => {
        if (formState && formState.errors && Object.keys(formState.errors).length > 0){
            const donationReceiptSummaryFields:any[string] = [];
            if (formState.errors.donationReceiptSummaryList &&
                formState.errors.donationReceiptSummaryList.length >0){
                formState.errors.donationReceiptSummaryList.map((_fieldName:string, index:number) => {
                    donationReceiptSummaryFields.push(`donationReceiptSummaryList[${index}].facilityNo`),
                    donationReceiptSummaryFields.push(`donationReceiptSummaryList[${index}].noOfCartons`)
                    donationReceiptSummaryFields.push(`donationReceiptSummaryList[${index}].amountReceived`)
                })
            }
            trigger([...Object.keys(formState.errors), ...donationReceiptSummaryFields]);
        }
        import(`moment/locale/${momentLocales[props.selectedLang]}`).then((_data: any) => {
            moment.locale(momentLocales[props.selectedLang])
        });
    }, [props.selectedLang]);

    useEffect(() => {
        if (donationNotCollected && !readOnly && !props.initialLoad &&
            !modalDialOption.isDonationNotCollectedChecked) {
            fields.length = 0;
            setShowDonationWasNotCollectedDailog(true);
        } else {
            registerValidation()
        }
    }, [donationNotCollected, isfileDeleted]);

    useEffect(()=>{
        if (!donationNotCollected){
            fields.map((_entry:any, index:number) => {
                register(`donationReceiptSummaryList[${index}].facilityNo`, {
                    required: validationMsg.required[props.selectedLang],
                    pattern: {
                        value: /^\d+$/,
                        message: validationMsg.numbersOnly[props.selectedLang]
                    },
                    validate: validateFacility
                });

                register(`donationReceiptSummaryList[${index}].noOfCartons`, {
                    required: validationMsg.required[props.selectedLang],
                    pattern: {
                        value: /^\d+$/,
                        message: validationMsg.numbersOnly[props.selectedLang]
                    },
                    validate: validateNumberRange
                });
                register(`donationReceiptSummaryList[${index}].amountReceived`, {
                    required: validationMsg.required[props.selectedLang],
                    pattern: {
                        value: /^\d{0,6}(\.\d{1,3})?$/,
                        message: validationMsg.amountReceived[props.selectedLang]
                    }
                });
            })
            registerValidation()
        }
    }, [fields])

    const registerValidation = () => {
        register('socialImpactSummary', {
            validate: (value:string) => {
                return validateSummaryLen(value, 4000)
            }
        });
        register('noOfChildBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfYoungAdultsBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfAdultsBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfSeniorBeneficiary', {
            required: validationMsg.required[props.selectedLang],
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            validate: validateNumberRange
        });
        register('noOfFamilies', {
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            // validate: validateNumberRange
        });
        register('noOfWomenBeneficiary', {
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            // validate: validateNumberRange
        });
        register('totalIndirectBeneficiary', {
            pattern: {
                value: /^\d+$/,
                message: validationMsg.numbersOnly[props.selectedLang]
            },
            // validate: validateNumberRange
        });
        register('fileDescription', {
            validate: (value: string) => {
                return validateFileDescriptionLen(value, 2000)
            }
        })
        fields.map((_entry: any, index: number) => {
            register(`donationReceiptSummaryList[${index}].facilityNo`, {
                required: validationMsg.required[props.selectedLang],
                pattern: {
                    value: /^\d+$/,
                    message: validationMsg.numbersOnly[props.selectedLang]
                },
                validate: validateFacility
            });

            register(`donationReceiptSummaryList[${index}].noOfCartons`, {
                required: validationMsg.required[props.selectedLang],
                pattern: {
                    value: /^\d+$/,
                    message: validationMsg.numbersOnly[props.selectedLang]
                },
                validate: validateNumberRange
            });
            register(`donationReceiptSummaryList[${index}].amountReceived`, {
                required: validationMsg.required[props.selectedLang],
                pattern: {
                    value: /^\d{0,6}(\.\d{1,3})?$/,
                    message: validationMsg.amountReceived[props.selectedLang]
                }
            });
        })
        if (!readOnly && !(uploadedFile && uploadedFile?.fileName)) {
            register('file', {
                required: validationMsg.required[props.selectedLang],
                validate: validateFile
            });
        }
        if (!readOnly && !(uploadedFile && uploadedFile?.fileName || isfileDeleted)) {
            register('file', {
                required: validationMsg.required[props.selectedLang],
                validate: validateFile
            });
        }
    }
    const inputChangeHandler = (event:any) => {
        const newValues = {
            ...socialImpactReport,
            [event.target.name]: event.target.value
        }
        setSocialImpactReport(newValues)
        getDirectBeneficiaryTotal(newValues)
    }

    const getDirectBeneficiaryTotal = (data:any) => {
        const { noOfChildBeneficiary, noOfYoungAdultsBeneficiary, noOfAdultsBeneficiary, noOfSeniorBeneficiary } = data;
        if (isValidNum(noOfChildBeneficiary) && isValidNum(noOfYoungAdultsBeneficiary) &&
            isValidNum(noOfAdultsBeneficiary) && isValidNum(noOfSeniorBeneficiary)){
            const total = parseInt(noOfChildBeneficiary, 10) +
                parseInt(noOfYoungAdultsBeneficiary, 10) + parseInt(noOfAdultsBeneficiary, 10) + parseInt(noOfSeniorBeneficiary,10)
            setTotalDirectBeneficiary(total)
        }
    }
    const isValidNum = (num:string) => {
        const regex = RegExp('^[0-9]+$');
        return regex.test(num);
    }
    const onSubmit = (data: any) => {
        const fileDetails = !data.donationNotCollected ? {
            ...props.submission.uploadedFile,
            fileAdditionalDetails: {
                fileDescription: data.fileDescription
            }
        } : {};
        const formDetails = {
            ...props.submission,
            ...data,
            uploadedFile: fileDetails
        }
        setFormData(formDetails)
        if (!modalDialOption.isDownloadCliked){
            setModalDialOption({
                showConfirmDialog: true,
                isDownloadCliked:false,
                isDonationNotCollectedChecked: !donationNotCollected
            })
        }else{
            setModalDialOption({
                showConfirmDialog: false,
                isDownloadCliked: false,
                isDonationNotCollectedChecked: !donationNotCollected
            })
        }
    }
    const handleConfirmation = () => {
        const form = new FormData();

        form.append('report', JSON.stringify(formData));
        form.append('langCode', props.selectedLang);
        // @ts-ignore
        form.append('file', formData.file ? formData.file[0] : null)
        // @ts-ignore
        form.append('fileDescription', formData.fileDescription)
        props.onSubmit(form);
        setModalDialOption({
            showConfirmDialog: false,
            isDownloadCliked:false,
            isDonationNotCollectedChecked: donationNotCollected ? false : true
        })
    }

    const handleGoBackCancelAction = () => {
        setFormChanged(false)
        setShowGoBackConfirmDialog(false)
    }

    const toUploadAssignment = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > FILE_SIZE) {
                setMaxFileSizeExceeded(true)
            } else {
                setMaxFileSizeExceeded(false)
                const bulkUploadFormData = getUploadFileForm(file, props?.submission)
                props.uploadAssignment({
                    formData: bulkUploadFormData,
                    dataUri: `${config.appBaseUrl}/social-impact-reports/createReportByBulkUpload`
                })
            }
        }
    }

    const downloadAssignment = () => {
        const fileInfo = {
            templateKey: 'SOCIAL_IMPACT_GOODS_DONATION_BULK_UPLOAD_BENEFICIARIES_TEMPLATE',
            downloadDir: props?.submission?.countryCode ? props.submission.countryCode : ''
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `files/privacy-policy?fileInfo=${encryptedFileInfo}`;
        props.viewOrDownloadAssignment({
            name: 'Social Impact Goods Donation Beneficiaries Template',
            type: 'downloadTemplate',
            actionUrl: `${config.appBaseUrl}/${dataUri}`
        });
    }

    const handleGoBackConfirmation = () => {
        setFormChanged(false)
        setShowGoBackConfirmDialog(false)
        props.history.push(`${contextPath}/dashboard/social-impact?type=social-impact`)
    }
    const goBack = () => {
        if (formChanged && !readOnly) {
            setShowGoBackConfirmDialog(true)
            setFormChanged(false);
        } else {
            props.history.push(`${contextPath}/dashboard/social-impact?type=social-impact`)
        }
    }
    const validateFile = (data:any) => {
        const file = data && data[0] ? data[0] : {}
        setFileData(file);
        setFileList(data);
        if (file.size > FILE_SIZE){
            return validationMsg.fileSize[props.selectedLang]
        }
        if(!SUPPORTED_FORMATS.includes(file.type)){
            return validationMsg.fileFormat[props.selectedLang]
        }
        return true;
    }
    const validateSummaryLen = (text:string, size:number) => {
        setSocialImpactSummaryTextCount(text.length);
        if (!text) {
            return validationMsg.required[props.selectedLang]
        }
        if (!validateTextLimit(text, size)){
            return validationMsg.summaryError[props.selectedLang]
        }
        return true;
    }
    const validateFileDescriptionLen = (text: string, size: number) => {
        setFileDescriptionTextCount(text.length)
        if (!text) {
            return validationMsg.required[props.selectedLang]
        }
        if (!validateTextLimit(text, size)) {
            return validationMsg.fileDescError[props.selectedLang]
        }
        return true;
    }

    const validateFacility = (value: string) => {
        const facilityList: any = getValues().donationReceiptSummaryList;
        const uniqueValues = new Set(facilityList.map((item: any) => item.facilityNo));
        const iterator = uniqueValues.values();
        const firstEntry = iterator.next();
        const total = uniqueValues && uniqueValues.size === 1 && (firstEntry.value === '0' || !firstEntry.value) ? 0 : uniqueValues.size;
        setTotalFacilities(total);
        if (uniqueValues.size < facilityList.length) {
            const duplicateFaclities = getDuplicateFacilityDetails(getValues);
            const duplicateEntry = duplicateFaclities[value]
            if (duplicateEntry && duplicateEntry.isDuplicate && duplicateEntry.value === value){
                return `${validationMsg.duplicateStoreNo[props.selectedLang]} ${value}`
            }
        }
        return true;
    }
    const validateNumberRange = (value:string) => {
        const val = parseInt(value, 10);
        return (val >= 0 && val <= 999999) ? true : `${validationMsg.numberRange[props.selectedLang]}`;
    }
    const calculateTotalCartons = () => {
        const cartons: any = getValues().donationReceiptSummaryList || [];
        const cartonsCount = cartons.reduce((sum:number, item:any) => {
            return isValidNum(item.noOfCartons) ? sum + parseInt(item.noOfCartons, 10) : sum;
        }, 0);
        setTotalCartons(cartonsCount);
    }
    const calculateTotalAmountReceived = () => {
        const amountDetails: any = getValues().donationReceiptSummaryList || [];
        const regex = /^\d{0,6}(\.\d{1,3})?$/
        const totalAmount = amountDetails.reduce((sum: string, item: any) => {
            return regex.test(item.amountReceived) ? parseFloat(sum) + parseFloat(item.amountReceived) : parseFloat(sum);
        }, 0);
        if (!isNaN(totalAmount)){
            setTotalAmountReceived(totalAmount);
        }
    }
    const calculateTotalFaility = () => {
        const facilityList: any = getValues().donationReceiptSummaryList || [];
        const uniqueValues = new Set(facilityList.map((item: any) => item.facilityNo));
        const iterator = uniqueValues.values();
        const firstEntry = iterator.next();
        const total = uniqueValues && uniqueValues.size === 1 && (firstEntry.value === '0' || !firstEntry.value) ? 0 : uniqueValues.size;
        setTotalFacilities(total);
    }
    const { classes } = props;

    const handleCancelAction = () => {
        setModalDialOption({
            showConfirmDialog:false,
            isDownloadCliked:false,
            isDonationNotCollectedChecked: donationNotCollected
        })
    }
    const handleNoAction = () => {
        setShowDonationWasNotCollectedDailog(false)
        setDonationNotCollected(!donationNotCollected);
        reset({ ...defaultValues })
    }

    const hanldeRemoveFileNoAction = () => {
        setRemoveFileDailog(false)
    }

    const hanldeRemoveFileYesAction = () => {
        props.submission.reportAdditionalDetails = {
            ...props.submission.uploadedFile
        }
        delete props.submission.uploadedFile;
        setIsfileDeleted(true);
        setRemoveFileDailog(false)
        setFormChanged(true);
        // props.onDelete(fileInfo);
    }
    const removeFile = (_fileInfo:any) => {
        setRemoveFileDailog(true)
    }

    const downloadFile = (data:any) => {
        setUpdatedFieldValues(getValues())
        setModalDialOption({
            showConfirmDialog: false,
            isDownloadCliked:true,
            isDonationNotCollectedChecked: donationNotCollected
        })
        props.onDownload(data)
    }
    const handleYesAction = () => {
        setShowDonationWasNotCollectedDailog(false)
        reset({ donationNotCollected });
        setTotalDirectBeneficiary(0);
        setTotalCartons(0);
        setTotalAmountReceived(0);
        setTotalFacilities(0);
        setFileDescriptionTextCount(0);
        setSocialImpactSummaryTextCount(0);
        unregister([
            'socialImpactSummary',
            'noOfChildBeneficiary',
            'noOfYoungAdultsBeneficiary',
            'noOfYoungAdultsBeneficiary',
            'noOfAdultsBeneficiary',
            'noOfSeniorBeneficiary',
            'noOfFamilies',
            'noOfWomenBeneficiary',
            'totalIndirectBeneficiary',
            'donationReceiptSummaryList[0].facilityNo',
            'donationReceiptSummaryList[0].noOfCartons',
            'donationReceiptSummaryList[0].amountReceived',
            'file',
            'fileDescription'
        ]);
        insert(0, { facilityNo: 0, noOfCartons: 0, amountReceived: 0 });
        setFileList(undefined)
        setFileData({name:'', size:'', type:''})
        delete props.submission.uploadedFile;
    }

    return (
        <div>
            <button className={`social-imapct-back-btn mr-bottom-2`}
                onClick={goBack}>
                {formBtns.back[props.selectedLang]}
            </button>
            <div className='report-header'>
                {report.header[props.selectedLang]}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true} autoComplete='off'>
                <div className='row'>
                    <div className='col-md-12 form-group-container'>
                        <div className='mr-right-2'>
                            <label className='col-form-label'>
                                {formLabels.yearEdit[props.selectedLang]}
                            </label>
                            <div>{props.submission.year}</div>
                        </div>
                        <div className='mr-right-2'>
                            <label className='col-form-label'>
                                {formLabels.monthEdit[props.selectedLang]}
                            </label>
                            <div>
                                {
                                    props.submission && props.submission.month ?
                                        monthText[props.submission.month].label[props.selectedLang] :
                                        ''
                                }
                            </div>
                        </div>
                        <div className='mr-right-2'>
                            <label className='col-form-label'>
                                {formLabels.submittedOn[props.selectedLang]}
                            </label>
                            <div>{getFormattedDate(submittedDate)}</div>
                        </div>
                    </div>
                </div>
                <div className='row' style={{marginBottom: '1rem', marginTop: '1rem'}}>
                    <div className='col-md-8'>
                        <div className='form-check-inline'>
                            <input className='form-check-input' type='checkbox'
                                name='donationNotCollected'
                                defaultValue={donationNotCollected}
                                defaultChecked={donationNotCollected}
                                ref={register()}
                                disabled={readOnly}
                                onChange={() => {
                                    setDonationNotCollected(!donationNotCollected)
                                }}
                            />
                            <label className='form-check-label col-form-label'>
                                <b>{formLabels.donationNotCollected[props.selectedLang]}</b>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <label className='col-form-label field-required'>
                            {formLabels.summuryTxt[props.selectedLang]}
                        </label>
                        <textarea name='socialImpactSummary'
                            placeholder={formLabels.summaryPlaceHolder[props.selectedLang]} className={`form-control ${donationNotCollected || readOnly ? 'sip-disabled': ''}`} rows={4}
                            ref={register}
                            style={{ borderColor: errors.socialImpactSummary && 'red' }}
                        />
                        {  !(donationNotCollected || readOnly) &&
                            <><span>{`${socialImpactSummaryTextCount}/4000`}</span><br /></>
                        }
                        {errors.socialImpactSummary?.message &&
                        <span className='error-container'>{errors.socialImpactSummary?.message}</span>}
                    </div>
                </div>
                {!readOnly && <div className='bulkUpload-wrapper social-impact-btn-group'>
                    <div>
                        <label className='upload-assignment-label'>
                            <input
                                style={{ display: 'none' }}
                                id='upload-assignment'
                                className='upload-assignment'
                                name='upload-assignment'
                                type='file'
                                accept='.xlsx'
                                onChange={toUploadAssignment}
                            />
                            <Button variant='outlined' component='span'>
                                {t('notificationMsg.bulkUpload')}
                            </Button>
                        </label>
                        <Button
                            variant='outlined'
                            onClick={downloadAssignment}
                            className='download-assignment'
                        >
                            {t('notificationMsg.downloadTemplate')}
                        </Button>
                    </div>
                    <label className='col-form-label'>
                        {t('notificationMsg.uploadFileSizeExceeded')}
                    </label>
                </div>}
                <div className='report-sub-header'>{report.subHeader1[props.selectedLang]}</div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.children[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfChildBeneficiary'
                                onChange={inputChangeHandler}
                                readOnly={donationNotCollected || readOnly}
                                ref={register}
                                style={{ borderColor: errors.noOfChildBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfChildBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfChildBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.younAdults[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfYoungAdultsBeneficiary'
                                onChange={inputChangeHandler}
                                readOnly={donationNotCollected || readOnly}
                                ref={register}
                                style={{ borderColor: errors.noOfYoungAdultsBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfYoungAdultsBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfYoungAdultsBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.adults[props.selectedLang]}
                            </label>
                            <input type='text'
                                ref={register}
                                name='noOfAdultsBeneficiary'
                                onChange={inputChangeHandler}
                                readOnly={donationNotCollected || readOnly}
                                style={{ borderColor: errors.noOfAdultsBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfAdultsBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfAdultsBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label field-required'>
                                {formLabels.seniors[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfSeniorBeneficiary'
                                onChange={inputChangeHandler}
                                readOnly={donationNotCollected || readOnly}
                                ref={register}
                                style={{ borderColor: errors.noOfSeniorBeneficiary && 'red' }}
                                className={`form-control input-text2`}
                            />
                            {errors.noOfSeniorBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfSeniorBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalDirectBnfcyCount[props.selectedLang]}
                        </label>
                            <input type='text' name='totalDirectBeneficiary'
                                ref={register}
                                { ...readOnly ? { defaultValue: totalDirectBeneficiary } : { value: totalDirectBeneficiary } }
                                className='form-control input-text2'
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.noOfFmly[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfFamilies'
                                readOnly={donationNotCollected || readOnly}
                                ref={register}
                                style={{ borderColor: errors.noOfFamilies && 'red' }}
                                className={`form-control input-text`}
                            />
                            {errors.noOfFamilies?.message &&
                                <span className='error-container'>{errors.noOfFamilies?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.womenBnfcy[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='noOfWomenBeneficiary'
                                readOnly={donationNotCollected || readOnly}
                                ref={register}
                                style={{ borderColor: errors.noOfWomenBeneficiary && 'red' }}
                                className={`form-control input-text`}
                            />
                            {errors.noOfWomenBeneficiary?.message &&
                                <span className='error-container'>{errors.noOfWomenBeneficiary?.message}</span>}
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalIndirectBnfcyCount[props.selectedLang]}
                            </label>
                            <input type='text'
                                name='totalIndirectBeneficiary'
                                readOnly={donationNotCollected || readOnly}
                                ref={register}
                                style={{ borderColor: errors.totalIndirectBeneficiary && 'red' }}
                                className={`form-control input-text`}
                            />
                            {errors.totalIndirectBeneficiary?.message &&
                                <span className='error-container'>{errors.totalIndirectBeneficiary?.message}</span>}
                        </div>
                    </div>
                </div>
                <div className='report-sub-header'>
                    {report.subHeader2[props.selectedLang] }
                </div>
                <div className='row'>
                    {fields.map((field:any, index:number) => (
                        <div className='col-md-8 form-group-container' key={field.id}>
                                <div className='mr-right'>
                                {index ===0 && <label className='col-form-label field-required'>
                                    {formLabels.facilityNo[props.selectedLang]}
                                    </label> }
                                    <input type='text'
                                        name={`donationReceiptSummaryList[${index}].facilityNo`}
                                        ref={register()}
                                        readOnly={donationNotCollected || readOnly}
                                        defaultValue={field.facilityNo}
                                        placeholder={ formLabels.facilityNo[props.selectedLang] }
                                        style={{
                                        borderColor: errors && errors.donationReceiptSummaryList &&
                                            errors?.donationReceiptSummaryList[index]
                                            && errors?.donationReceiptSummaryList[index].facilityNo && 'red' }}
                                        className={`form-control input-text`}
                                    />
                                    {
                                    errors && errors.donationReceiptSummaryList && errors?.donationReceiptSummaryList[index]
                                    && errors?.donationReceiptSummaryList[index].facilityNo &&
                                        <span className='error-container'>
                                            {errors.donationReceiptSummaryList[index].facilityNo.message}
                                        </span>
                                    }
                                </div>
                                <div className='mr-right'>
                                   {index === 0 && <label className='col-form-label field-required'>
                                    {formLabels.noOfCartons[props.selectedLang]}
                                    </label>}
                                    <input type='text' name={`donationReceiptSummaryList[${index}].noOfCartons`}
                                        ref={register()}
                                        onChange={calculateTotalCartons}
                                        readOnly={donationNotCollected || readOnly}
                                        defaultValue={field.noOfCartons}
                                        placeholder={formLabels.noOfCartons[props.selectedLang]}
                                        style={{
                                            borderColor: errors && errors.donationReceiptSummaryList &&
                                            errors?.donationReceiptSummaryList[index]
                                            && errors?.donationReceiptSummaryList[index].noOfCartons && 'red'
                                        }}
                                        className={`form-control input-text`}
                                    />
                                    {
                                        errors && errors.donationReceiptSummaryList && errors?.donationReceiptSummaryList[index]
                                    && errors?.donationReceiptSummaryList[index].noOfCartons &&
                                        <span className='error-container'>
                                            {errors.donationReceiptSummaryList[index].noOfCartons.message}
                                        </span>
                                    }
                                </div>
                                <div className='mr-right'>
                                    {index === 0 && <label className='col-form-label field-required'>
                                    {formLabels.amtReceived[props.selectedLang]}
                                    </label>}
                                    <input type='text' name={`donationReceiptSummaryList[${index}].amountReceived`}
                                        ref={register()}
                                        readOnly={donationNotCollected || readOnly}
                                        defaultValue={field.amountReceived}
                                        placeholder={formLabels.amtReceived[props.selectedLang]}
                                        style={{
                                            borderColor: errors && errors.donationReceiptSummaryList &&
                                            errors?.donationReceiptSummaryList[index]
                                                && errors?.donationReceiptSummaryList[index].amountReceived && 'red'
                                        }}
                                        onChange={calculateTotalAmountReceived}
                                        className={`form-control input-text ${errors.donationReceiptSummaryList}`}
                                    />
                                    {
                                        errors && errors.donationReceiptSummaryList && errors?.donationReceiptSummaryList[index]
                                        && errors?.donationReceiptSummaryList[index].amountReceived &&
                                        <span className='error-container'>
                                            {errors.donationReceiptSummaryList[index].amountReceived.message}
                                        </span>
                                    }
                                </div>
                                {
                                (fields.length !==1) ?
                                    <IconButton onClick={() => {
                                        setFormChanged(true)
                                        remove(index)
                                        }}
                                        classes={{ root: classes.closeBtn }}
                                        style={{ display: readOnly ? 'none' : 'block' }}
                                        className={`${donationNotCollected || readOnly ? 'sip-disabled' : ''}`}>
                                        <CloseIcon classes={{ root: classes.closeBtn }}
                                            style={{ marginTop: index === 0 ? '1rem' : 0 }}/>
                                    </IconButton> : <></>
                                }
                        </div>
                    ))}
                </div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <button type='button' name='addfacility'
                            style={{ display: readOnly ? 'none' : 'block' }}
                            className={`add-facility-btn ${donationNotCollected || readOnly ? 'sip-disabled' : ''}`}
                            disabled={donationNotCollected}
                            onClick={() => append({ facilityNo: '', noOfCartons: '', amountReceived: '' })}>
                            {formBtns.addFacility[props.selectedLang]}
                        </button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8 form-group-container'>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalFclty[props.selectedLang]}
                            </label>
                            <input type='text' name='totalFacilities' className='form-control input-text'
                                ref={register()}
                                // @ts-ignore
                                {...readOnly ? { defaultValue: totalFacilities } : { value: totalFacilities }}
                                readOnly={true}/>
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalCartons[props.selectedLang]}
                            </label>
                            <input type='text' name='totalCartons' className='form-control input-text'
                                {...readOnly ? { defaultValue: totalCartons } : { value: totalCartons }}
                                ref={register()}
                                readOnly={true}
                            />
                        </div>
                        <div className='mr-right'>
                            <label className='col-form-label'>
                                {formLabels.totalAmountReceived[props.selectedLang]}
                            </label>
                            <input type='number' name='totalAmountReceived' className='form-control input-text'
                                {...readOnly ? { defaultValue: totalAmountReceived.toFixed(3) } : { value: totalAmountReceived.toFixed(3) }}
                                ref={register()}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='bulk-upload-container form-group-container'>
                            <div>
                                <div className='upload-file'>
                                    <p className='field-required'>
                                        {readOnly ? formLabels.uploadedFile[props.selectedLang] : formLabels.receipt[props.selectedLang]}
                                    </p>
                                </div>
                                {!readOnly && !donationNotCollected && (!(uploadedFile && uploadedFile?.fileName) || isfileDeleted) ?
                                <div className='bulk-upload-button'
                                    style={{ borderColor: errors.file && 'red' }}>
                                    <span>
                                        {(fileData && fileData.name) || formLabels.upload[props.selectedLang]}
                                        <label className='custom-file-upload'>
                                            <input type='file'
                                                name='file'
                                                ref={register()}
                                                disabled={donationNotCollected}
                                                className={`form-control ${donationNotCollected ? 'sip-disabled' : ''}`}
                                            />
                                            {formLabels.browse[props.selectedLang]}
                                        </label>
                                    </span>
                                </div> : <></>}
                                <div className={` ${!isfileDeleted && (uploadedFile && uploadedFile?.fileName) && !donationNotCollected ? 'file-upload-bg' : '' }`}>
                                    {uploadedFile && uploadedFile?.fileName && !isfileDeleted && !donationNotCollected &&
                                        <div className='btn-link'
                                            style={{ display: 'inline-flex', color:'#637897'}}
                                            onClick={ ()=> {
                                                downloadFile(uploadedFile)
                                            }}
                                        >
                                            <p className='line-clamp'>{uploadedFile?.fileName}</p>
                                        </div>
                                    }
                                    {
                                        !readOnly && !isfileDeleted && (uploadedFile && uploadedFile?.fileName) && !donationNotCollected ?
                                        <IconButton onClick={() => { removeFile(uploadedFile)}}
                                            classes={{ root: classes.closeBtn }}
                                                style={{
                                                    display: readOnly ? 'none' : 'inline-flex', padding: '5px',margin: '20px'}}>
                                            <CloseIcon classes={{ root: classes.closeBtn }}
                                                style={{ marginTop: 0, backgroundColor: 'none'}}
                                            />
                                        </IconButton> : <></>
                                    }
                                </div>
                                {errors.file?.message && !readOnly && !(uploadedFile && uploadedFile?.fileName) && !donationNotCollected &&
                                    <span className='error-container'>{errors.file?.message}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-5'>
                        <div>
                            <label className='col-form-label field-required'>
                                {formLabels.receiptDesc[props.selectedLang]}
                            </label>
                            <input type='text' name='fileDescription'
                                ref={register}
                                readOnly={donationNotCollected || readOnly }
                                style={{
                                    borderColor: errors.fileDescription?.message  && 'red'
                                }}
                                className={`form-control`}
                            />
                            { !(donationNotCollected || readOnly) &&
                                <><span>{`${fileDescriptionTextCount}/2000`}</span><br /></>
                            }
                            {errors.fileDescription?.message &&
                                <span className='error-container'>{errors.fileDescription?.message}</span>}
                        </div>
                    </div>
                </div>
                {
                    props.formState.formSaveFailure ?
                        <div className='row'>
                            <div className='col-md-12'>
                                    <span style={{color:'red'}}>{props.formState.formActionMessage.errorMessage}</span>
                            </div>
                        </div>
                    :
                        <></>
                }
                { !readOnly ? <div className='row'>
                    <div className='col-md-12'>
                        <div className='social-impact-btn-group'>
                            <button className={`social-imapct-cancel-btn mr-right-2`}
                                type='button'
                                onClick={goBack}
                                >
                                {formBtns.cancel[props.selectedLang]}
                            </button>
                            <button className={`sip-submit-btn`}
                                type='submit'>
                                {formBtns.submit[props.selectedLang]}
                            </button>
                        </div>
                    </div>
                </div> : <></>}
            </form>
            <SocialImpactConfirmDialog showDialog={modalDialOption.showConfirmDialog}
                isAsdaTheme={false}
                maxWidth={'md'}
                cancelButton={formBtns.cancel[props.selectedLang]}
                confirmButton={formBtns.submit[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.confirmMsg[props.selectedLang]}
                cancel={handleCancelAction}
                confirm={handleConfirmation}
            />
            {/*  Donation was not collected confirmation */}
            <SocialImpactConfirmDialog showDialog={showDonationWasNotCollectedDailog}
                isAsdaTheme={false}
                maxWidth={'sm'}
                cancelButton={formBtns.no[props.selectedLang]}
                confirmButton={formBtns.yes[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.donationNotCollectedMsgEdit[props.selectedLang]}
                cancel={handleNoAction}
                confirm={handleYesAction}
            />

            {/*  Remove file confirmation */}
            <SocialImpactConfirmDialog showDialog={removeFileDailog}
                isAsdaTheme={false}
                maxWidth={'sm'}
                cancelButton={formBtns.no[props.selectedLang]}
                confirmButton={formBtns.yes[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.removeFile[props.selectedLang]}
                cancel={hanldeRemoveFileNoAction}
                confirm={hanldeRemoveFileYesAction}
            />
            {/*  Go back / Cancel confirmation */}
            <SocialImpactConfirmDialog showDialog={showGoBackConfirmDialog}
                isAsdaTheme={false}
                maxWidth={'sm'}
                cancelButton={formBtns.no[props.selectedLang]}
                confirmButton={formBtns.yes[props.selectedLang]}
                title={socialImpactTranslations.actionMsg.title[props.selectedLang]}
                message={socialImpactTranslations.actionMsg.warningMsg[props.selectedLang]}
                cancel={handleGoBackCancelAction}
                confirm={handleGoBackConfirmation}
            />
            <ErrorSnackbarComponent
                isError={maxFileSizeExceeded}
                message={t('notificationMsg.uploadFileSizeExceeded')}
            />
        </div>
    );
}

export interface DispatchProps {
    uploadAssignment: (data:any) => void;
    viewOrDownloadAssignment: (data:any) => void;
    getLoggedInUserDetail: () => void;
    updateReport: (data:any) => void;
    downloadFile: (data: any) => void;
    deleteFile: (data: any) => void;
    getReportById: (data: any) => void;
}

export interface StateProps {
    selectedLang: string;
    loggedInUserDetail: any;
    formState:any;
    selectedReportData:any;
    submission:any;
    uploadDownloadData:any;
}
export interface OwnProps {
    classes?: any;
    loggedInUserDetail: any;
    t: (key: string, options?: any) => (string);
}

class EditReportComponent extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    constructor(props: any) {
        super(props);
    }
    public state = {
        selectedReport:{
            readOnly:false,
            id: ''
        },
        initialLoad:true,
        language: this.props.selectedLang,
        showGoBackConfirmDialog:false,
        isLoader: false,
        showDialog: false
    }
    public UNSAFE_componentWillMount(){
        const selectedReport = (this.props.selectedReportData.socialImpactReport) ?
            JSON.parse(JSON.stringify(this.props.selectedReportData.socialImpactReport)) : {};
        this.setState({ selectedReport })
        this.props.getReportById({ id: selectedReport.id})
        import(`moment/locale/${momentLocales[this.props.selectedLang]}`).then((_data:any) => {
            moment.locale(momentLocales[this.props.selectedLang])
        });
    }

    public UNSAFE_componentWillReceiveProps(newProps:any){
        if (newProps.selectedLang !== this.props.selectedLang) {
            import(`moment/locale/${momentLocales[newProps.selectedLang]}`).then((_data: any) => {
                moment.locale(momentLocales[newProps.selectedLang])
                this.setState({ language: newProps.selectedLang })
            });
        }
        if (this.props.submission.submissionLoading) {
            setTimeout(() => {
                this.updateStateValues()
            }, 150);
        }
        if (newProps?.uploadDownloadData?.uploadingAssignmentLoading) {
            this.setState({ isLoader: true })
        }

        if (this.state.isLoader && (newProps?.uploadDownloadData?.uploadingAssignmentSuccess)) {
            this.setState({
                isLoader: false,
                showDialog: true,
            })
        }
        else if (this.state.isLoader && (newProps?.uploadDownloadData?.uploadingAssignmentFailure)) {
            this.setState({
                isLoader: false,
                showDialog: false,
            })
        }
        if ((newProps.uploadDownloadData.uploadingAssignmentSuccess !== this.props?.uploadDownloadData?.uploadingAssignmentSuccess) &&
            newProps.uploadDownloadData.uploadingAssignmentSuccess === true) {
            this.props.getReportById({ id: this.state.selectedReport?.id })
        }
    }

    private submit = (data:any) => {
        this.props.updateReport(
            {
                loadFormData: {
                    formAlias: '',
                    submissionUrl: `${config.appBaseUrl}/social-impact-reports`,
                },
                payload: data
            }
        )
    }

    private handleConfirmation = () => {
        this.setState({ showGoBackConfirmDialog:false})
        this.props.history.push(`${contextPath}/dashboard/social-impact?type=social-impact`)
    }

    private updateStateValues = () => {
        this.setState({ initialLoad:false})
    }
    private downloadFile = (param:any) => {
        const fileInfo = {
            fileId: param.fileName,
            fileName: param.fileName,
            directory: param.uploadDir,
            container: param.container
        }
        const encryptedFileInfo = encodeURIComponent(getEncryptedString(fileInfo));
        const dataUri = `files?fileInfo=${encryptedFileInfo}`;
        this.props.downloadFile({
            name: `${param.fileName}`,
            type: 'downloadFiles',
            actionUrl: `${config.appBaseUrl}/${dataUri}`
        });
    }

    private deleteFile = (param: any) => {
        const fileInfo = {
            fileId: param.fileName,
            fileName: param.fileName,
            directory: param.uploadDir,
            container: param.container
        }
        this.props.deleteFile(encodeURIComponent(getEncryptedString(fileInfo)));
    }
    private closeDialog = () => this.setState({ showDialog: false });
    public render() {
        const { classes, t } = this.props;
        return <div>
            <EditReportForm
                selectedLang={this.state.language}
                classes={this.props.classes}
                onSubmit={this.submit}
                history={this.props.history}
                formState={this.props.formState}
                submission={{ ...this.props.submission.submissionData, readOnly: this.state.selectedReport.readOnly}}
                initialLoad={this.state.initialLoad}
                updateValues={this.updateStateValues}
                onDownload={this.downloadFile}
                onDelete={this.deleteFile}
                uploadAssignment={this.props.uploadAssignment}
                viewOrDownloadAssignment={this.props.viewOrDownloadAssignment}
            />

            <SuccessSnackBarComponent
                isSuccess={this.props.uploadDownloadData.uploadingAssignmentSuccess}
                message={this.props.uploadDownloadData.uploadingAssignmentMessage}
            />

            <SuccessAlertDailog
                showDialog={this.props.formState.formEditSuccess}
                ok={this.handleConfirmation}
                title={socialImpactTranslations.successMsg.title[this.props.selectedLang]}
                message={socialImpactTranslations.successMsg.updateTxt[this.props.selectedLang]}
            />
            <ErrorSnackbarComponent
                isError={this.props.uploadDownloadData.downloadDataFailure}
                classes={classes}
                message={t('notificationMsg.failedToDownload')}
            />
            <ErrorSnackbarComponent
                isError={this.props.uploadDownloadData.uploadingAssignmentFailure}
                classes={classes}
                message={t('notificationMsg.failedToUpload')}
            />
            <ErrorSnackbarComponent isError={this.props.formState.formEditFailure} classes={classes}
                message={this.props.formState.formActionMessage.errorMessage ||
                    `Failed to create report, please try again!`}
            />
            <ReportModel
                showDialog={this.state.showDialog}
                onClose={this.closeDialog}
                content={this.props?.uploadDownloadData?.data}
            />
            {(this.props.formState.formEditLoading ||
                this.props.uploadDownloadData.downloadDataLoading ||
                this.state.isLoader ||
                this.props.submission.submissionLoading) ?
                <LoaderComponent fullScreen={true} /> : <></>
            }
        </div>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    loggedInUserDetail: state.loggedInUserState.userDetail,
    formState:state.formState,
    selectedReportData: state.selectedDataState.addDataToStore,
    submission: state.submissionDataState,
    uploadDownloadData: state.uploadDownloadActionState
});
const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    uploadAssignment: (data: any) => dispatch(uploadAssignment(data)),
    viewOrDownloadAssignment: (data: any) => dispatch(viewOrDownloadAssignment(data)),
    updateReport: (data: any) => dispatch(updateForm(data)),
    getLoggedInUserDetail: () => dispatch(loggedInUserData()),
    downloadFile: (data) => dispatch(viewOrDownloadFile(data)),
    deleteFile: (data) => dispatch(deleteFile(data)),
    getReportById: (data) => dispatch(submissionData(data))
});
export default withStyles(styles)(withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditReportComponent))));
