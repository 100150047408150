import './CampaignFooter.css';
import CampaignFooterModal from './CampaignFooterModal';

interface ModalProps {
  userAction: any;
  bodyText: any;
}

function CampaignApprovalModal({ userAction, bodyText }: ModalProps) {
  return (
    <div className='row'>
      <CampaignFooterModal
        headerText='Are you sure you want to cancel this request'
        bodyText={bodyText}
        cancelBtn={{ label: 'No, go back', actionFn: ()=>userAction('cancel') }}
        applyBtn={{
          label: 'Yes, cancel Request',
          actionFn: ()=>userAction('approve'),
        }}
      />
    </div>
  );
}

export default CampaignApprovalModal;
