import { Action } from 'redux';

export enum SidebarActionTypes {
    SIDEBAR_DATA_LOAD = 'SIDEBAR_DATA_LOAD',
    SIDEBAR_LOAD_SUCCESS = 'SIDEBAR_LOAD_SUCCESS',
    SIDEBAR_LOAD_FAILURE = 'SIDEBAR_LOAD_FAILURE'
}

export interface SidebarData extends Action {
    type: SidebarActionTypes.SIDEBAR_DATA_LOAD;
}

export const SidebarData = (): SidebarData => ({
    type: SidebarActionTypes.SIDEBAR_DATA_LOAD,
});

export interface SidebarLoadSuccess extends Action {
    type: SidebarActionTypes.SIDEBAR_LOAD_SUCCESS;
    data: any;
}

export const SidebarLoadSuccess = (data: any): SidebarLoadSuccess => {return {
    type: SidebarActionTypes.SIDEBAR_LOAD_SUCCESS,
    data
}};

export interface SidebarLoadFailure extends Action {
    type: SidebarActionTypes.SIDEBAR_LOAD_FAILURE;
    data?: string;
}

export const sidebarLoadFailure = (data: string): SidebarLoadFailure => ({
    type: SidebarActionTypes.SIDEBAR_LOAD_FAILURE,
    data
});

export type SidebarActions = SidebarData | SidebarLoadSuccess | SidebarLoadFailure;