import { Badge, Typography } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { makeStyles } from '@material-ui/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface TextWithBadgeProps {
    text?: string,
    isSaved?: boolean,
    translationKey?: string
}


const useStyles = makeStyles({

    badge: {
        right: '-10px'
    }, typography: {
        fontWeight: 'bold'
    }
});


export const TextWithBadge: FC<TextWithBadgeProps> = ({ text = '', translationKey = '', isSaved = false }) => {

    const customStyles = useStyles();
    const { t } = useTranslation();

    return (<Badge classes={{ badge: customStyles.badge }} color='default'
        variant='standard' badgeContent={isSaved ? <CheckOutlinedIcon fontSize='small' /> : <ReportProblemOutlinedIcon fontSize='small' />}>
        <Typography classes={{ root: customStyles.typography }}  >{translationKey ? t(translationKey) : text}</Typography>
    </Badge>

    );
}