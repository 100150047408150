import { makeStyles } from '@material-ui/core';
import { FC, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import GlobalDonationDataService from '../../service';
import ErrorSnackbarComponent from 'dynamic-form-components/lib/components/menu/ErrorSnackbarComponent';
import LoaderComponent from '../LoaderComponent';
import { useTranslation } from 'react-i18next';
import GenericConfirmDialog from '../dialogComponents/GenericConfirmDialog';
import analytics from '../../adobe-analytics/analytics';
import tracker from '../../adobe-analytics/tracker';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';



const useStyles = makeStyles({
    yesButton: {
        '&:hover': {
            color: '#041E42'
        }
    },
    noButton: {
        '&:hover': {
            color: '#041E42'
        }
    }
});
interface OwnProps {
    dashboardPath: string,
    donationId: string
}
const DeleteDonation: FC<OwnProps> = ({ dashboardPath,donationId }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigateTo = useNavigate()
    const classes = useStyles();
    const {t} = useTranslation()
    const deleteDonation = async (shouldDelete: boolean) => {
        if(shouldDelete) {
            setPageLoading(true);
            setShowError(false);
            setDialogOpen(false);
            const isDeleted =  await GlobalDonationDataService.deleteDonation(donationId)
            if(isDeleted) {
                analytics.trackEventInfo({
                    type: tracker.EVENTS.CLICK,
                    actionOn: tracker.ACTION_ON.DELETE_INFO.name,
                    location: ANALYTICS.dashboard.dashboardPage
                });
                setPageLoading(false);
                navigateTo(dashboardPath);
            } else {
                setPageLoading(false);
                setShowError(true);
            }
        } else {
            setDialogOpen(false);
        }
    }
    return <Fragment>
        <button className='btn btn-danger btn-md'
            id = 'deleteDonationBtn'
            data-testid = 'deleteDonationBtn'
            onClick={() => setDialogOpen(true)}>
            {t('deleteDonation.delete')}
        </button>
        <GenericConfirmDialog showDialog={dialogOpen}
                title={t('deleteDonation.deleteDonationTitle')}
                message={t('deleteDonation.deleteDonationMessage')}
                confirmButton={t('common.buttonLabels.yes')}
                cancelButton={t('common.buttonLabels.no')}
                cancel={() => deleteDonation(false)}
                confirm={() => deleteDonation(true)}
                cancelBtnClasses={classes.noButton}
                confirmBtnClasses={classes.yesButton} />
        <ErrorSnackbarComponent isError={showError}
                        message={t('deleteDonation.deleteFailureMessage')} />
          {pageLoading &&
                <LoaderComponent fullScreen={true} />}
    </Fragment>
}

export default DeleteDonation;
