import { Avatar, Badge, Theme, withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { getCookieValue } from 'dynamic-form-components/lib/service';
import { i18n as i18nI } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { countryCodeToLanguageCode, getLangaugeDropDownOptionsByCountryCode } from '../../constants';
import { GlobalDonationState } from '../../models/globalDonationState';
import GlobalDonationDataService, { Service } from '../../service';
import { selectedLang } from '../../state/globalDonationActions';
import { loggedInUserLoadSuccess } from '../../state/loggedInUser/loggedInUserActions';
import LangSelectComponent from './LangSelectComponent';
import styles from './ThemeStyle';
import { SidebarLoadSuccess } from 'src/state/sidebar/sidebarActions';

/* tslint:disable */
export let service: Service = GlobalDonationDataService;
const { getLoggedInUserDetail } = service;
const { getSidebarDetail } = service;

export interface OwnProps {
    classes: any;
    theme: Theme;
    container: any;
    mobileOpen: boolean;
    mobileMoreAnchorEl?: boolean;
    anchorEl?: boolean;
    anchorNotificationEl?: boolean;
    notificationCount: number;
    showNotificationExhaust: boolean;
    pageLoading: boolean;
    handleMobileMenuOpen: (event: any) => void;
    handleDrawerToggle: () => void;
    handleNotificationMenuOpen: (event: any) => void;
    handleProfileMenuOpen: (event: any) => void;
    i18n: i18nI
}

export interface DispatchProps {
    changeLanguage: (data: any) => void;
    loggedInUserLoadSuccess: (data: any) => void;
    sidebarLoadSuccess: (data: any) => void;
}

class AppBarComponent extends React.PureComponent<GlobalDonationState & OwnProps & DispatchProps> {
    public language: any = {
        EN: [
            {
                value: 'en',
                label: 'English'
            }
        ],
        FR: [
            {
                value: 'en',
                label: 'English'
            },
            {
                value: 'fr',
                label: 'Française'
            },
        ],
        ES: [
            {
                value: 'en',
                label: 'English'
            },
            {
                value: 'es',
                label: 'Español'
            },
        ],
        JA: [
            {
                value: 'en',
                label: 'English'
            },
            {
                value: 'ja',
                label: '日本人'
            },
        ],
        ZH: [
            {
                value: 'en',
                label: 'English'
            },
            {
                value: 'zh',
                label: '中文'
            },
        ]
    }

    public UNSAFE_componentWillMount() {
        if (this.props.loggedInUserState.userDetail && Object.keys(this.props.loggedInUserState.userDetail).length === 0) {
            getLoggedInUserDetail().then((resp: any) => {
                this.props.loggedInUserLoadSuccess(resp);
                const languageCode = (this.getPreferredLanguageForUser() && this.getPreferredLanguageForUser().toLowerCase()) || 'en';
                this.props.changeLanguage(languageCode);
                this.props.i18n.changeLanguage(languageCode === 'es' ? 'es_MX': languageCode);
            });
        } else {
            const languageCode = (this.getPreferredLanguageForUser() && this.getPreferredLanguageForUser().toLowerCase()) || 'en';
            this.props.changeLanguage(languageCode);
            this.props.i18n.changeLanguage(languageCode === 'es' ? 'es_MX': languageCode);
        }
            getSidebarDetail().then((resp: any) => {
                this.props.sidebarLoadSuccess(resp);
            });
    }

    public render() {
        const { classes } = this.props;
        const { anchorEl, anchorNotificationEl } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isNotificationOpen = Boolean(anchorNotificationEl);
        const userName = this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
            this.props.loggedInUserState.userDetail.orgLegalName;
        const marketCode = this.props.loggedInUserState && this.props.loggedInUserState.userDetail &&
            this.props.loggedInUserState.userDetail.countryCode;
        return (
            <AppBar position='fixed' className={`nav-items-txt ${classes.appBar}`}>
                <Toolbar>{
                    marketCode === 'GB' ? (
                        <h5 style={{ marginLeft: '240px', fontWeight: 'bold' }} className={classes.gdmsTitle}>Global Donation Management</h5>)
                        : <div />
                }
                    <IconButton
                        color='inherit'
                        aria-label='Open drawer'
                        onClick={this.props.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <img className='app-logo' src={require('src/resources/landing_logo.svg')}/> */}
                    <div className={classes.grow} />
                    <div className='lang'><LangSelectComponent
                        // @ts-ignore
                        languages={this.getLanguagesForUser()}
                        countryCode={this.props?.loggedInUserState?.userDetail?.countryCode}
                    /></div>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            aria-haspopup='true'
                            aria-owns={isNotificationOpen ? 'material-appbar' : undefined}
                            color='inherit'
                            onClick={this.props.handleNotificationMenuOpen}
                        >
                            <Badge badgeContent={this.props.notificationCount} color='secondary'>
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                            aria-haspopup='true'
                            onClick={this.props.handleProfileMenuOpen}
                            color='inherit'
                        >
                            <Avatar className={classes.orangeAvatar}>
                                {userName}
                            </Avatar>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton aria-haspopup='true' onClick={this.props.handleMobileMenuOpen} color='inherit'>
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

    private getLanguagesForUser = () => {
        return getLangaugeDropDownOptionsByCountryCode(this.props.loggedInUserState.userDetail.countryCode)
    }

    private getPreferredLanguageForUser = () => {
        const userPrefLang = this.props.loggedInUserState.userDetail.primaryOrgUser ?
            [this.props.loggedInUserState.userDetail.primaryOrgUser?.prefLanguage?.toUpperCase()] :
            countryCodeToLanguageCode[this.props.loggedInUserState.userDetail.countryCode] || ['EN'];
        const languageCode = getCookieValue('locale') ? getCookieValue('locale').replace(/"/g, '').toUpperCase() : '';
        const preferedLang = languageCode ? languageCode.split('_') : userPrefLang;
        return preferedLang && preferedLang.length > 0 ? preferedLang[0] : 'EN';
    }
}
const mapStateToProps = (state: GlobalDonationState): GlobalDonationState => {
    return state;
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
    changeLanguage: (data: any) => dispatch(selectedLang(data)),
    loggedInUserLoadSuccess: (data: any) => dispatch(loggedInUserLoadSuccess(data)),
    sidebarLoadSuccess: (data: any) => dispatch(SidebarLoadSuccess(data))
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppBarComponent)));
