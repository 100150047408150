import { Action } from 'redux';

export enum UploadDownloadActionTypes {
  UPLOAD_ASSIGNMENT = 'UPLOAD_ASSIGNMENT',
  UPLOAD_ASSIGNMENT_SUCCESS = 'UPLOAD_ASSIGNMENT_SUCCESS',
  UPLOAD_ASSIGNMENT_FAILURE = 'UPLOAD_ASSIGNMENT_FAILURE',

  VIEW_OR_DOWNLOAD_ASSIGNMENT = 'VIEW_OR_DOWNLOAD_ASSIGNMENT',
  VIEW_OR_DOWNLOAD_ASSIGNMENT_SUCCESS = 'VIEW_OR_DOWNLOAD_ASSIGNMENT_SUCCESS',
  VIEW_OR_DOWNLOAD_ASSIGNMENT_FAILURE = 'VIEW_OR_DOWNLOAD_ASSIGNMENT_FAILURE',
}

export interface UploadAssignment extends Action {
  type: UploadDownloadActionTypes.UPLOAD_ASSIGNMENT;
  data: any;
}
export const uploadAssignment = (data: string): UploadAssignment => ({
  type: UploadDownloadActionTypes.UPLOAD_ASSIGNMENT,
  data
});

export interface UploadAssignmentSuccess extends Action {
  type: UploadDownloadActionTypes.UPLOAD_ASSIGNMENT_SUCCESS;
  data: any;
}
export const uploadAssignmentSuccess = (data: any): UploadAssignmentSuccess => ({
  type: UploadDownloadActionTypes.UPLOAD_ASSIGNMENT_SUCCESS,
  data
});

export interface UploadAssignmentFailure extends Action {
  type: UploadDownloadActionTypes.UPLOAD_ASSIGNMENT_FAILURE;
  data?: any;
}
export const uploadAssignmentFailure = (data: string): UploadAssignmentFailure => ({
  type: UploadDownloadActionTypes.UPLOAD_ASSIGNMENT_FAILURE,
  data
});

export interface ViewOrDownloadAssignment extends Action {
  type: UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT,
  data: any;
}

export const viewOrDownloadAssignment = (data: any): ViewOrDownloadAssignment => ({
  type: UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT,
  data
});

export interface ViewOrDownloadAssignmentSuccess extends Action {
  type: UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT_SUCCESS,
  data: any
}

export const viewOrDownloadAssignmentSuccess = (data: any): ViewOrDownloadAssignmentSuccess => ({
  type: UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT_SUCCESS,
  data
});

export interface ViewOrDownloadAssignmentFailure extends Action {
  type: UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT_FAILURE,
  data: any
}

export const viewOrDownloadAssignmentFailure = (data: any): ViewOrDownloadAssignmentFailure => ({
  type: UploadDownloadActionTypes.VIEW_OR_DOWNLOAD_ASSIGNMENT_FAILURE,
  data
});

export type UploadDownloadActions = UploadAssignment | UploadAssignmentSuccess | UploadAssignmentFailure
  | ViewOrDownloadAssignment | ViewOrDownloadAssignmentFailure | ViewOrDownloadAssignmentSuccess;
