import * as yup from 'yup';
import instance from '../../../i18n';
import {
    validateFloatTest,
    validateInteger,
    validateString,
} from 'src/components/common/validationDefinitions';
const getSocialImpactReportValidations = () => {
  const summaryValidation = {
      summary: validateString()
  }

  const beneficiariesValidation = {
      noOfChild: validateInteger(),
      noOfYoungAdults: validateInteger(),
      noOfAdults: validateInteger(),
      noOfSenior: validateInteger(),
      noOfFamilies: validateInteger(),
      noOfWomen: validateInteger(),
      totalIndirect: validateInteger(),
  }

  const activityValidation = {
      activityStatus: yup.string()
      .test('is-completed', instance.t('socialImpactReport.activityStatusInvalid'), (value: any, context: yup.TestContext) => {
        const status = value;
        const balance = Number(context.resolve(yup.ref('balance')));
        const amountUsed = Number(context.resolve(yup.ref('amountUsed'))) || 0;
        const amount = Number(context.resolve(yup.ref('amount'))) || 0;
        if(!status) {
          return true;
        }
        if(balance === amountUsed && amountUsed !== 0) {
          return status === 'COMPLETED'
        } else if(amountUsed > 0 || balance < amount) {
          return status === 'IN_PROGRESS' || status === 'COMPLETED'
        }
        return true;
      }),
      comment:yup.string()
      .test('comment', instance.t('orgRegForm.validationMsg.requiredField'), (value: any, context: yup.TestContext) => {
          const status = context.resolve(yup.ref('activityStatus'));
          if(!status) {
            return true;
          }
          if (!value) {
              return false;
          }
          return true;
      }),
      amountUsed: validateFloatTest()
          .test('amountUsed', instance.t('socialImpactReport.amountUsedValidation'), (value: any, context: yup.TestContext) => {
              const requestAmount: number = Number(context.resolve(yup.ref('balance'))) || 0;
              const status = context.resolve(yup.ref('activityStatus'));
              if(!status) {
                return true;
              }

              if (isNaN(value) || value > requestAmount) {
                  return false;
              }
              return true;
          })
  }

  const socialImpactValidation = yup.object().shape({
      socialImpactSummary:yup.object().shape(summaryValidation),
      beneficiaries: yup.object().shape(beneficiariesValidation),
      activitySummary: yup.object().shape({
        activityForSocialReport: yup.array(yup.object().shape(activityValidation))
      }),
      projectObjectives: yup.array(yup.object().shape({
          measureResults: yup.array(yup.object().shape({
              actualResultToDate: validateString()
          })),
      })),
  });

  return socialImpactValidation;
}

export default getSocialImpactReportValidations;
