import { useQuery } from 'react-query';

import { reactQueryConfigOptions } from '../constants';
import { getSocialImpactMonetaryDonations } from '../service/apiService';


export const useFetchSocialImpactReportMonetaryDonations = (payload:any) => {
    const {
        isLoading,
        isSuccess,
        error,
        isError,
        isFetching,
        data
    } = useQuery(['socialImpactMonetaryDonations', { ...payload, pageNum:payload?.page }], getSocialImpactMonetaryDonations,
    {...reactQueryConfigOptions, keepPreviousData:true});

    return {data, isLoading, isSuccess, isError, isFetching, error};
}
