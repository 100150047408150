
import { FC, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { MonetarySocialImpactSections } from '../socialImpactReportData';
import GridContainer from '../../../common/Grid/GridContainer';
import GridItem from '../../../common/Grid/GridItem';
import { InputTextField } from '../../../common/Input/InputTextField';
import { useFormContext, useWatch } from 'react-hook-form';
import { IProjectBeneficiaries } from 'src/components/donations/moreDetailsSections/projectDetails/projectDetailsDefaults';

interface OwnProps {
    beneficaries: IProjectBeneficiaries
}

const Beneficiaries: FC<OwnProps> = ({beneficaries}) => {
    const {t} = useTranslation();
    const fieldName = MonetarySocialImpactSections.Beneficiaries;
    const formMethods = useFormContext();
    const noOfChildWatcher = useWatch<number>({ name: `${fieldName}.noOfChild`, control: formMethods.control });
    const noOfYoungAdultsWatcher = useWatch<number>({ name: `${fieldName}.noOfYoungAdults`, control: formMethods.control });
    const noOfAdultsWatcher = useWatch<number>({ name: `${fieldName}.noOfAdults`, control: formMethods.control });
    const noOfSeniorWatcher = useWatch<number>({ name: `${fieldName}.noOfSenior`, control: formMethods.control });

    useEffect(() => {
        const total = +noOfChildWatcher! + +noOfYoungAdultsWatcher! + +noOfAdultsWatcher! + +noOfSeniorWatcher! ;
        formMethods.setValue(`${fieldName}.calculationIndirectBeneficiaries`,total);
    }, [noOfChildWatcher, noOfYoungAdultsWatcher, noOfAdultsWatcher, noOfSeniorWatcher])

    return <Fragment>
      <Typography variant='body2'>
          { t('socialImpactReport.noOfBeneficiries')}
        </Typography>
        <Box mt = {2}>
            <GridContainer spacing = {2}>
                <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.noOfChild`}
                        errorKeyName={`${fieldName}.noOfChild`}
                        label={t('monetaryDonation.projectDetailsSection.children')}
                        id={`${fieldName}.noOfChild`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.noOfChild!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.noOfYoungAdults`}
                        errorKeyName={`${fieldName}.noOfYoungAdults`}
                        label={t('monetaryDonation.projectDetailsSection.youngAdults')}
                        id={`${fieldName}.noOfYoungAdults`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.noOfYoungAdults!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.noOfAdults`}
                        errorKeyName={`${fieldName}.noOfAdults`}
                        label={t('monetaryDonation.projectDetailsSection.adults')}
                        id={`${fieldName}.noOfAdults`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.noOfAdults!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.noOfSenior`}
                        errorKeyName={`${fieldName}.noOfSenior`}
                        label={t('monetaryDonation.projectDetailsSection.seniors')}
                        id={`${fieldName}.noOfSenior`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.noOfSenior!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.calculationIndirectBeneficiaries`}
                        errorKeyName={`${fieldName}.calculationIndirectBeneficiaries`}
                        label={t('monetaryDonation.projectDetailsSection.totalDirectBeneficiaries')}
                        id={`${fieldName}.calculationIndirectBeneficiaries`}
                        InputProps={{ readOnly: true }}
                        fullWidth={true}
                        defaultValue={beneficaries.calculationIndirectBeneficiaries!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.noOfFamilies`}
                        errorKeyName={`${fieldName}.noOfFamilies`}
                        label={t('monetaryDonation.projectDetailsSection.families')}
                        id={`${fieldName}.noOfFamilies`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.noOfFamilies!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.noOfWomen`}
                        errorKeyName={`${fieldName}.noOfWomen`}
                        label={t('monetaryDonation.projectDetailsSection.womenDirectBeneficiaries')}
                        id={`${fieldName}.noOfWomen`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.noOfWomen!}
                    />
                </GridItem>
               <GridItem lg = {3} md = {4} sm = {6} xs = {12}>
                    <InputTextField
                        required={true}
                        disabled={true}
                        type={'number'}
                        fieldName={`${fieldName}.totalIndirect`}
                        errorKeyName={`${fieldName}.totalIndirect`}
                        label={t('monetaryDonation.projectDetailsSection.indirectBeneficiaries')}
                        id={`${fieldName}.totalIndirect`}
                        InputProps={{ readOnly: false }}
                        fullWidth={true}
                        defaultValue={beneficaries.totalIndirect!}
                    />
                </GridItem>
            </GridContainer>
        </Box>
    </Fragment>
}

export default Beneficiaries;
