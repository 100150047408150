import { Snackbar } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import React, { FC } from 'react'

interface OwnProps {
  open: boolean,
  onClose: () => void
  message: string
  severity?: Color
}
const MUISnackBar: FC<OwnProps> = ({open, onClose, message, severity}) => {
  return <Snackbar open={open} autoHideDuration={6000} onClose={onClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
  <Alert onClose={onClose} severity={`${severity ? severity : 'error'}`}>
      { message }
  </Alert>
  </Snackbar>
}

export default React.memo(MUISnackBar);