import * as React from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { GlobalDonationState } from '../../models/globalDonationState';
import OrgUserForm from './OrgUserForm';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LoaderComponent from 'src/components/LoaderComponent';

const styles: any = (_theme: any) => ({
    selectRoot: {
        color: 'var(--inputTextColor) !important',
        fontFamily: ' var(--appFont)',
    },
    outlined: {
        color: 'red'
    },
    selectIcon: {
        color: 'var(--inputTextColor) !important'
    },
    selectFormControl: {
        color: '#495057 !important',
        minWidth: '200px',
        fontFamily: ' var(--appFont)',
        backgroundColor: '#fff',
        marginRight: '1rem',
        marginBottom: '1rem',
        fontWeight:'bold'
    },
    selectLabel: {
        color: '#495057 !important',
        fontFamily: ' var(--appFont)',
        marginLeft: '10px',
        backgroundColor: '#fff'
    },
    menuItemRoot: {
        color: 'var(--inputTextColor);',
        fontFamily: ' var(--appFont)'
    },
    menuItemselected: {
        color: 'var(--inputTextColor);'
    },
    selectBoxBorder: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonGroup: {
        marginBottom: '12px',
        marginRight: '12px',
        display: 'inline-flex'
    },
    buttonStyles: {
        marginBottom: 10,
        color: '#fff !important',
        background: '#007bff !important',
        borderRadius: '20px',
        textAlign: 'center',
        textTransform: 'none',
        minWidth:'80px'
    },
    buttonContainer:{
        justifyContent: 'center !important',
    },
    closeBtn: {
        fontSize: '18px',
        backgroundColor: '#F4F6F9 !important',
        '&:hover': {
            backgroundColor: '#F4F6F9 !important',
            color: 'red'
        }
    }
})

interface OwnProps {
    showDialog: boolean;
    cancel: () => void;
    title: string;
    message: string;
    isAsdaTheme?:boolean;
}

export interface StateProps {
    selectedLang: string;
    orgUserManagementState: any;
}

class OrgUserAddEditDialog extends React.PureComponent<OwnProps & StateProps & any>{

    public render() {
        const { classes } = this.props;
        return <Dialog
                fullWidth={true}
                maxWidth={'md'}
                disableEnforceFocus={true}
                disableBackdropClick={true}
                open={this.props.showDialog}
                onClose={this.props.cancel}
                aria-labelledby='add-edit-user-dialog'
                aria-describedby='add-edit-user-dialog'>
                    <DialogTitle id='add-edit-user-dialog'>
                        <div>
                            <div style={{textAlign:'left', width:'50%', float:'left'}}>
                                <b>{this.props.title}</b>
                            </div>
                            <div style={{textAlign:'right', width:'50%', float:'right'}}>
                                <IconButton size='small' onClick={this.props.cancel} style={{color:'black'}}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {
                            (this.props.isFailure && this.props.failureMessage)
                            ? <div style={{width:'80%', margin:'auto'}}>
                                <div style={{color:'#721c24',
                                            backgroundColor: '#f8d7da',
                                            padding : '.75rem 1.25rem',
                                            marginTop: '1rem',
                                            marginBottom: '1rem',
                                            border:'1px solid',
                                            borderColor: '#f5c6cb',
                                            borderRadius: '0.25rem'}}>
                                    {this.props.failureMessage}
                                </div>
                               </div>
                            : <></>
                        }
                        <OrgUserForm
                            classes={classes}
                            selectedLang={this.props.selectedLang}
                            selectedOrgUser={this.props.selectedOrgUser}
                            orgId={this.props.orgId}
                            submitOrgUserData={this.props.submitOrgUserData}
                            editOfOrgUser={this.props.editOfOrgUser}
                            countryCode={this.props.countryCode}
                            isPrimaryOrgUserEdit={this.props.isPrimaryOrgUserEdit}
                        />
                        {(this.props.isLoading) ? <LoaderComponent fullScreen={true} /> : <></>}
                    </DialogContent>
            </Dialog>
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    selectedLang: state.selectedLang,
    orgUserManagementState: state.orgUserManagementState
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)((OrgUserAddEditDialog)));