import { SUPPORTED_FORMATS } from 'src/constants';
import instance from 'src/i18n';
import * as yup from 'yup';

import {
    percentageValidate,
    validateDate,
    validateInteger,
    validatePercentageTotals,
    validateString,
} from 'src/components/common/validationDefinitions';
import { RadioOption, Sections } from './orgDetailsDefaults';


export const GetOrganizationDetailsSchema = () => {
    const requiredMsg = instance.t('orgRegForm.validationMsg.requiredField')

    const incomeValidation = yup.array(yup.object().shape({
        type: validateString(),
        numberOfDonors: validateInteger(),
        amount: validateInteger().typeError(instance.t('orgRegForm.validationMsg.requiredField')),
        percentage: percentageValidate().typeError(instance.t('orgRegForm.validationMsg.requiredField'))
    }));

    const attachmentValidation = yup.array().min(1, instance.t('monetaryDonation.fieldValidation.fileRequired'))
        .test('is-required', instance.t('monetaryDonation.fieldValidation.fileRequired'), (val) => checkIfFilesRequired(val))
        // @ts-ignore
        .test('is-correct-file', instance.t('monetaryDonation.fieldValidation.fileSize'), checkIfFilesAreBig)
        // @ts-ignore
        .test('is-correct-file-type', instance.t('monetaryDonation.fieldValidation.supportedExt'), checkIfFilesAreCorrectType);

    const expenseValidation = yup.array(yup.object().shape({
        type: validateString(),
        amount: validateInteger(),
        percentage: percentageValidate()
    }));

    const schema = yup.object().shape({
        mission: yup.object().shape({
            missionDetails: validateString()
        }),
        geographicalCoverage: yup.array(yup.object().shape({
            city: validateString(),
            community: validateString(),
            numberOfBeneficiaries: validateInteger().typeError(instance.t('orgRegForm.validationMsg.requiredField')),
            populationType: validateString(),
            state: validateString()

        })),
        [Sections.currentIncome]: incomeValidation,
        [Sections.previousIncome]: incomeValidation,
        [Sections.incomeAttachments]: attachmentValidation,
        [Sections.currentExpense]: expenseValidation,
        [Sections.previousExpense]: expenseValidation,
        [Sections.expenseAttachments]: attachmentValidation,
        boardMemberSalaryInfo: yup.object().shape({
            isReceivingSalary: yup.mixed().required(requiredMsg)
                .test('isSalarySelected', instance.t('monetaryDonation.fieldValidation.pleaseSelectSalary'), (value) => {

                    return value !== RadioOption.None;
                }),
            boardMembers: yup.array(yup.object().shape({
                userName: yup.string(),
                userTitle: yup.string(),
                birthYear: yup.string(),
                userEmail: yup.string(),
                birthPlace: yup.string(),
                additionalUserDetails: yup.object().shape({
                    isReceivingSalary: yup.boolean()
                })
            })),
            personsOnPayroll: yup.number().integer(instance.t('monetaryDonation.fieldValidation.fractionNotAllowed'))
            .typeError(instance.t('monetaryDonation.fieldValidation.integerAllowed'))
            .min(0, instance.t('monetaryDonation.fieldValidation.positiveNumbers')).test('personsOnPayroll', requiredMsg,
            (value, context) => {
              return value ? true : context.parent.isReceivingSalary !== RadioOption.Yes;
            }),
        }),
        principlePrograms: yup.array(yup.object().shape({
            endDate: validateDate().typeError(instance.t('orgRegForm.validationMsg.requiredField')),
            programDescription: validateString(),
            programName: validateString(),
            startDate: validateDate().typeError(instance.t('orgRegForm.validationMsg.requiredField'))
        })),
        totals: yup.object().shape({
            incomeCurrentFinancialYear: validatePercentageTotals(),
            incomePreviousFinancialYear: validatePercentageTotals(),
            expenseCurrentFinancialYear: validatePercentageTotals(),
            expensePreviousFinancialYear: validatePercentageTotals()
        }),
        boardMemberNoneSelected: yup.string().oneOf([RadioOption.Yes], instance.t('monetaryDonation.fieldValidation.boardMembersReceivingSalary'))
    })

    return schema;

}

const checkIfFilesRequired = (files: File[] | undefined) => {
    if (files) {
        return files.length > 0;
    }
    return false;
}

const checkIfFilesAreBig = (files: File[]) => {
    let valid = true;
    if (files) {
        for (const file of files) {
            const size = file.size / 1024 / 1024;
            if (size > 10) {
                valid = false;
            }
        }
    }

    return valid;
}

const checkIfFilesAreCorrectType = (files: File[]) => {
    let valid = true;

    if (files) {
        for (const file of files) {
            if (file?.type && !SUPPORTED_FORMATS.includes(file.type)) {
                valid = false;
            }
        }
    }

    return valid;
}