import { IAnalyticsSpacePage } from './adobe-analytics-model';
import { SPACECONSTANT } from './adobe-space-constants';
const EVENTS = {
  CLICK: 'click',
  HOVER: 'hover',
  SCROLL: 'scroll'
};

const ACTION_ON : {[key: string]: IAnalyticsSpacePage} = {
  ADD_NEW_ORGANIZATION: {
    id: 'ADD_NEW_ORGANIZATION',
    name: 'addNewOrganizationSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  NON_VERIFIED_ACCOUNT_CONTINUE: {
    id: 'NON_VERIFIED_ACCOUNT_CONTINUE',
    name: 'NonVerifiedAccountContinueSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  NON_VERIFIED_ORGANIZATION_CONTINUE: {
    id: 'NON_VERIFIED_ORGANIZATION_CONTINUE',
    name: 'NonVerifiedOrganizationContinueSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  NON_VERIFIED_ORGANIZATION_BACK: {
    id: 'NON_VERIFIED_ORGANIZATION_BACK',
    name: 'NonVerifiedOrganizationBackSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  NON_VERIFIED_CONTACTS_BACK: {
    id: 'NON_VERIFIED_CONTACTS_BACK',
    name: 'NonVerifiedContactsBackSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  NON_VERIFIED_CONTACT_INFO_COMPLETE: {
    id: 'NON_VERIFIED_CONTACT_INFO_COMPLETE',
    name: 'NonVerifiedContactsInfoCompleteSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  REQUEST_A_SPACE_DONATION: {
    id: 'REQUEST_A_SPACE_DONATION',
    name: 'RequestASpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  REQUEST_A_SPACE_DONATION_CONTINUE: {
    id: 'REQUEST_A_SPACE_DONATION_CONTINUE',
    name: 'RequestContinueSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  SEARCH_NEW_SPACE_REQUESTS: {
    id: 'SEARCH_NEW_SPACE_REQUESTS',
    name: 'SearchNewSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
  CONTINUE_NEW_SPACE_REQUESTS: {
    id: 'CONTINUE_NEW_SPACE_REQUESTS',
    name: 'ContinueNewSpaceExternal',
    applicationName: SPACECONSTANT.app_name,
    applicationType : SPACECONSTANT.app_type
  },
};

export default {
  ACTION_ON,
  EVENTS
};
