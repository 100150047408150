import { Divider } from '@material-ui/core';
import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form';
import { PrimaryUserDetails } from '../../donations/oneTimeGoods/components/PrimaryUserDetails';
import { AppendixBCertification } from './AppendixBCertification';
import { AppendixBConsent } from './AppendixBConsent';
import { AppendixBDonationDetails } from './AppendixBDonationDetails';
import { AppendixBOrgDetails } from './AppendixBOrgDetails';
import { AppendixBOrgMemberQuestionnaire } from './AppendixBOrgMemberQuestionnaire';
import { AppendixBQuestionnaire } from './AppendixBQuestionnaire';
import { OrgMemberComponent } from './OrgMemberComponent';

export interface AppendixBFormProps {
    selectedLang: string
    data?: any
    orgSectionFiledDisabled?: boolean
    classes: any
    appendixBOrgDetailsDisabled?: boolean
    appendixBQuestionaireDisabled?: boolean
    appendixBDonationDetailsDisabled?: boolean
    orgMemberTypeComponentDisabled?: boolean
    appendixBOrgMemberQuestionnaireDisabled?: boolean
    appendixBCertificationDisabled?: boolean
    appendixBConsentDisabled?: boolean
    isReview?: boolean
    showEmptyFileMessage?: boolean
    appendixBPrimaryUserDetailsDisabled?: boolean
    setOrgmebersData?: boolean
}
export const AppendixBForm: FC<AppendixBFormProps> = (props: any) => {
    const { control, setValue, errors } = useFormContext();
    const defaultBoarMemberData = [{
        userName: '',
        userTitle: '',
        birthYear: null,
        cityOfResidence: '',
        stateOfResidence: '',
        countryOfResidence: ''
    }]

    useEffect(() => {
        if (props?.setOrgmebersData) {
            setValue('boardMembers', props?.data?.organization?.boardMembers?.length ?
                props.data.organization.boardMembers : defaultBoarMemberData);
            setValue('executiveOfficers', props?.data?.organization?.executiveOfficers?.length ?
                props.data.organization.executiveOfficers : defaultBoarMemberData);
            setValue('keyPersons', props?.data?.organization?.keyPersons.length ?
                props.data.organization.keyPersons : defaultBoarMemberData);
        }
    }, [props?.data])
    return (<>
        <AppendixBOrgDetails
            heading={'oneTimeGoodsDonation.heading.aboutOrganization'}
            selectedLang={props.selectedLang}
            classes={props.classes}
            data={props?.data}
            disabled={props?.appendixBOrgDetailsDisabled}
        />
        <PrimaryUserDetails
            control={control}
            errors={errors}
            selectedLang={props.selectedLang}
            classes={props.classes}
            setValue={setValue}
            data={props?.data?.organization}
            disabled={props?.appendixBPrimaryUserDetailsDisabled}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '10px' }} />
        <AppendixBQuestionnaire
            heading={'common.heading.questionnaire'}
            classes={props.classes}
            data={props?.data?.organization}
            disabled={props?.appendixBQuestionaireDisabled}
            readOnly={false}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '10px' }} />
        <AppendixBDonationDetails
            control={control}
            errors={errors}
            selectedLang={props?.selectedLang}
            classes={props?.classes}
            setValue={setValue}
            data={props?.data}
            showEmptyFileMessage={true}
            disabled={props?.appendixBDonationDetailsDisabled}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '10px' }} />
        <OrgMemberComponent
            title='common.orgMembers.boardMembers'
            parentFieldName='boardMembers'
            disabled={props?.orgMemberTypeComponentDisabled}
            classes={props?.classes}
        />
        <OrgMemberComponent
            title='common.orgMembers.executiveOfficers'
            parentFieldName='executiveOfficers'
            disabled={props?.orgMemberTypeComponentDisabled}
            classes={props?.classes}
        />
        <OrgMemberComponent
            title='common.orgMembers.keyPersons'
            parentFieldName='keyPersons'
            disabled={props?.orgMemberTypeComponentDisabled}
            classes={props?.classes}
        />
        <AppendixBOrgMemberQuestionnaire
            classes={props.classes}
            data={{
                govtOrPoliticalOffice: props?.data?.organization?.govtOrPoliticalOffice,
                govtOrPoliticalOfficeDetails: props?.data?.organization?.govtOrPoliticalOfficeDetails ?
                    props.data.organization.govtOrPoliticalOfficeDetails : '',
                govtOrPoliticalOfficeRelative: props?.data?.organization?.govtOrPoliticalOfficeRelative,
                govtOrPoliticalOfficeRelativeDetails: props?.data?.organization?.govtOrPoliticalOfficeRelativeDetails ?
                    props.data.organization.govtOrPoliticalOfficeRelativeDetails : '',
            }}
            disabled={props?.appendixBOrgMemberQuestionnaireDisabled}
            readOnly={props?.appendixBOrgMemberQuestionnaireDisabled}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '20px' }} />
        <AppendixBCertification
            classes={props.classes}
            data={{
                ...props?.data?.organization,
                certificationCheckBox1: props?.appendixBCertificationDisabled,
                certificationCheckBox2: props?.appendixBCertificationDisabled,
                certificationCheckBox3: props?.appendixBCertificationDisabled
            }}
            showEmptyFileMessage={true}
            isReview={props?.isReview}
            uploadedOrgFiles={props?.data?.donationDetail?.orgUploadFiles}
            disabled={props?.appendixBCertificationDisabled}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '10px' }} />
        <AppendixBConsent
            isReview={props?.isReview}
            classes={props.classes}
            data={{
                ...props?.data?.organization,
                consentCheckBox1: props?.appendixBConsentDisabled,
                consentCheckBox2: props?.appendixBConsentDisabled
            }}
            disabled={props?.appendixBConsentDisabled}
        />
    </>)
}
