
import Reservations from '../spacedonation/scenes/reservations/reservation';
import NgoEvent from '../spacedonation/scenes/ngoEvent/ngoEvent';
import CalendarViewNgo from '../spacedonation/scenes/ngoEvent/CalendarViewNgo';
import FaqPage from '../spacedonation/scenes/faq/faq';
import SpaceDonationSuccess from '../spacedonation/scenes/createSpaceDonation/spaceDonationCreateSuccess';
import SpaceDonationForm from '../spacedonation/scenes/createSpaceDonation/spaceDonationReqForm';
import ReservationDetails from '../spacedonation/scenes/reservations/reservationDetail';
import SignOut from '../spacedonation/scenes/signOut/signOut';
import NonVerifiedOnboardingParetPage from '../spacedonation/scenes/onboarding/nonVerified/nonVerifiedOnboardingParent';
import VerifiedOnboardingParetPage from '../spacedonation/scenes/onboarding/verified/verifiedOnboardingParent';
import ImpactSurvey from '../spacedonation/scenes/reservations/impactSurvey';
import UserPolicyDownloader from '../spacedonation/scenes/createSpaceDonation/spaceUserPolicyDownloader';
import CustomActionPage from '../spacedonation/scenes/customUserAction/customActionPage';
import Onboarding from '../spacedonation/scenes/onboarding/onboarding';
import Account from '../spacedonation/scenes/account/account';
import SpaceDonationHome from '../../components/spacedonation/scenes/homePage/homepage';

import { SpacePrivateRoute } from '../spacedonation/commonComponents/appComponents/drawer/drawer';
import { Navigate, Route, Routes } from 'react-router';
import { contextPath } from '../../constants';
import { useContext } from 'react';
import UserContext, { UserContextProvider } from '../spacedonation/store/user-context';
import RequestSpace from '../spacedonation/scenes/requestSpaces/requestSpace';
import NcNgoCreateEventDetails from '../spacedonation/scenes/ngoNcCreateEvents/NcNgoCreateEventDetails';
import NgoNcSpaceReqStoreListComp from '../spacedonation/scenes/ngoNcCreateEvents/NgoNcSpaceReqStoreListComp';
import NgoNcSelectStoreComp from '../spacedonation/scenes/ngoNcCreateEvents/ngoNcSelectStoreComp';
import NgoNcEditSelectStoreComp from '../spacedonation/scenes/ngoNcEditSelectStore/ngoNcEditSelectStoreComp';
import NgoNcSpaceReqStoreListEditComp from '../spacedonation/scenes/ngoNcEditEvents/NgoNcEditStoreListComp';
import ConfirmationScreen from '../spacedonation/scenes/confirmationScreen/confirmationScreen';
import NcNgoEditEventDetails from '../spacedonation/scenes/ngoNcEditEventNext/NcNgoEditEventDetails';
import EventView from '../spacedonation/scenes/EventView/eventView';

const SpaceRoutes = () => {

  const ctx = useContext(UserContext);

  return <Routes >
  <Route path={`*`}
  element={<SpaceDonationHome />} >
    <Route
          path={``} element = {<Navigate to = {`${contextPath}/space-donation/onboarding`} />} />

      <Route
          path={`onboarding`} element = {<Onboarding />} />
      <Route
             path={`impact-survey`} element = { <ImpactSurvey/>} />
      <Route
             path={`custom-action/:uuid/:action`} element = {<CustomActionPage/>} />
      <Route
          path={`signout`} element = {<SignOut />} />
      <Route path={`events`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<EventView />}
          path={`/events`}>
      </SpacePrivateRoute>} />
      <Route path={`events/ngo-detail`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<NgoEvent />}
          path={`/events/ngo-detail`} />} />
      <Route path={`events/ngo-detail/calendar`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<CalendarViewNgo />}
          path={`/events/ngo-detail/calendar`} />} />
      <Route path={`account`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<Account />}
          path={`/account`} />} />
      <Route path={`faq`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<FaqPage />}
          path={`/faq`} />} />
      <Route path={`events/detail`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<ReservationDetails />}
          path={`/events/detail`} />} />
      <Route path={`create`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<RequestSpace />}
          path={`/create`} />} />
      <Route path={`create/new`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<SpaceDonationForm />}
          path={`/create/new`} />} />
      <Route path={`create/success`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<SpaceDonationSuccess />}
          path={`/create/success`} />} />
      <Route path={`nonVerifiedOnboarding`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<NonVerifiedOnboardingParetPage />}
          path={`/nonVerifiedOnboarding`} />} />
      <Route path={`verifiedOnboarding`} element={
      <SpacePrivateRoute exact={true}
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<VerifiedOnboardingParetPage />}
          path={`/verifiedOnboarding`} />} />
      <Route path={`downloadPolicies`} element={
      <SpacePrivateRoute
          authed={ctx.isLoggedIn}
          orgData={ctx.organization}
          isLoading={ctx.isLoading}
          element = {<UserPolicyDownloader />}
          path={`/downloadPolicies`} /> }/>

          <Route
              path={`agenda-view`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<Reservations />}
                      path={`/agenda-view`}
                  />
              }
          />
          <Route
              path={`requestspace`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<RequestSpace />}
                      path={`/requestspace`}
                  />
              }
          />
          <Route
              path={`create`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<RequestSpace />}
                      path={`/create`}
                  />
              }
          />
          <Route
              path={`create/confirmation`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<NcNgoCreateEventDetails />}
                      path={`/create/confirmation`}
                  />
              }
          />
          <Route
              path={`create/store-details`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<NgoNcSpaceReqStoreListComp />}
                      path={`/create/store-details`}
                  />
              }
          />
          <Route
              path={`create/store-select`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<NgoNcSelectStoreComp />}
                      path={`/create/store-select`}
                  />
              }
          />
          <Route
              path={`edit/store-select`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<NgoNcEditSelectStoreComp />}
                      path={`/edit/store-select`}
                  />
              }
          />
          <Route
              path={`edit/store-list`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<NgoNcSpaceReqStoreListEditComp />}
                      path={`/edit/store-list`}
                  />
              }
          />
          <Route
              path={`edit/store-details`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<NcNgoEditEventDetails />}
                      path={`/edit/store-details`}
                  />
              }
          />
          <Route
              path={`create/submitted-nc`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<ConfirmationScreen />}
                      path={`/create/submitted-nc`}
                  />
              }
          />
          <Route
              path={`edit/submitted-nc`}
              element={
                  <SpacePrivateRoute
                      authed={ctx.isLoggedIn}
                      orgData={ctx.organization}
                      isLoading={ctx.isLoading}
                      element={<ConfirmationScreen />}
                      path={`/edit/submitted-nc`}
                  />
              }
          />

      <Route path='*' element = {<Navigate to={`/onboarding`} />} />

</Route>
</Routes>
}




const SpaceRoutesContainer = () => {
  return <UserContextProvider>
      <SpaceRoutes />
  </UserContextProvider>
}


export default SpaceRoutesContainer
