import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FormBlockHeading } from '../../../components/donations/utils';
import { useTranslation } from 'react-i18next';

interface AccordionCompoProps {
  className?: string,
  accordionHeader: string,
  defaultExpanded: boolean
  accordionDetails: any
}

const AccordionCompo : React.FC<AccordionCompoProps>  = ({
  className = '', defaultExpanded, accordionHeader, accordionDetails

}) => {
  const { t } = useTranslation();
  return (
    <div className={`${className} accordionCompo-wrapper`} style={{ marginBottom: '20px' }}>
      <Accordion
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={'accordionSummary'}
        >
          <Typography>
            <FormBlockHeading heading={t(`${accordionHeader}`)} />
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <div style={{ width: '100%' }}>{accordionDetails}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AccordionCompo;
