import { Divider, withStyles } from "@material-ui/core";
import ErrorSnackbarComponent from "dynamic-form-components/lib/components/menu/ErrorSnackbarComponent";
import SuccessSnackBarComponent from "dynamic-form-components/lib/components/menu/SuccessSnackBarComponent";
import * as React from "react";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "src/polyfils/customRouter";
import {
  cityStateMappingDataLoad,
  getOrgProfile,
  saveOrgProfile,
} from "src/state/OrgProfile/orgProfileActions";
import { commonFormTranslations } from "../../constants";
import { GlobalDonationState } from "../../models/globalDonationState";
import "../../styles/donationOfMoney.css";
import "../../styles/formStyles.css";
import "../../styles/oneTimeGoodDonations.css";
import "../../styles/orgProfile.css";
import { formErrorEleClass } from "../../styles/styleUtil";
import LoaderComponent from "../LoaderComponent";
import { AppendixBCertification } from "../common/formComponents/AppendixBCertification";
import { AppendixBConsent } from "../common/formComponents/AppendixBConsent";
import { AppendixBOrgMemberQuestionnaire } from "../common/formComponents/AppendixBOrgMemberQuestionnaire";
import { AppendixBQuestionnaire } from "../common/formComponents/AppendixBQuestionnaire";
import { OrgMemberComponent } from "../common/formComponents/OrgMemberComponent";
import { Button } from "../common/index";
import { PrimaryUserDetails } from "../donations/oneTimeGoods/components/PrimaryUserDetails";
import styles from "../menu/ThemeStyle";
import { tableTranslations } from "./../../constants";
import { UseSaveOrgProfileApi, useGetOrgProfileApi } from "./orgProfileApi";

import { useLocation } from "react-router";
import { OrgProfileForm } from "../common/formComponents/OrgProfileForm";
import { useScrollToError } from "../common/utils";
import { useStyles } from "../donations/utils";
import {
  detectChanges,
  getOrgGovernanceDetails,
  getOrgGovernanceDetailsFormat,
  getOrgMembersFormat,
} from "./helper";
import { Spinner } from "@walmart-web/livingdesign-components";
import { loggedInUserData } from "src/state/loggedInUser/loggedInUserActions";

export interface OwnProps {
  formAlias: string;
  classes: any;
  appi18n: any;
}

export interface StateProps {
  submissionData?: any;
  selectedLang: any;
  selectedDonationData?: any;
  orgProfile: any;
  orgProfileState: any;
  formState: any;
  loggedInUserDataLoading: boolean;
}

export interface DispatchProps {
  editOrgProfile: (data: any) => void;
  getOrgProfile: (data: any) => void;
  getLoggedInUserDetail: () => void;
  getCityStateMapping: (data: any) => void;
}

const appi18n: any = {
  organizationDetailsLabel: {
    en: "Organization Details",
    es: "Detalles de la organización",
    fr: "Détails de l'organization",
    ja: "組織の詳細",
    zh: "机构详细资料",
  },
  genericSuccessMessage: {
    en: "Successfully updated the organization profile",
    es: "Perfil de la organización actualizado correctamente",
    fr: "Le profil de l'organization a bien été mis à jour",
    ja: "組織プロファイルが正常に更新されました",
    zh: "成功更新組織資料",
  },
  genericFailureMessage: {
    en: "Failed to update organization profile",
    es: "No se pudo actualizar el perfil de la organización",
    fr: "Échec de la mise à jour du profil de l'organization",
    ja: "組織プロファイルの更新に失敗しました",
    zh: "無法更新組織資料",
  },
  ...tableTranslations,
};

const OrganizationProfile: FC<DispatchProps & StateProps> = (props: any) => {
  const { orgProfileState, loggedInUserDataLoading } = props;
  const { t } = useTranslation();

  const appendixbMethods = useForm({
    mode: "all",
  });
  
  const onProfileUpdateSuccess = (data: any) => {
    const res: any = getOrgGovernanceDetails(
      data?.boardMembers,
      data?.executiveOfficers,
      data?.keyPersons
    );

    appendixbMethods.setValue("boardMembers", res?.boardMembers || []);
    appendixbMethods.setValue("executiveOfficers", res.executiveOfficers || []);
    appendixbMethods.setValue("keyPersons", res?.keyPersons || []);
  };

  const onFetchSuccess = (data: any) => {
    const orgProfileData = getOrgProfileQuery.data
      ? JSON.parse(JSON.stringify(getOrgProfileQuery.data))
      : {};
    const countryCodeFromLocalStorage =
      localStorage.getItem("countryCode") ?? "";
    const countryCode = orgProfileData?.countryCode
      ? orgProfileData.countryCode
      : countryCodeFromLocalStorage || "";
    if (countryCode) {
      props.getCityStateMapping({
        countryCode,
      });
    }

    appendixbMethods.reset({
      langCode: "en",
      ...getOrgProfileQuery.data,
      ...getOrgProfileQuery.data?.organization,
      boardMembers:
        data?.boardMembers?.length > 0
          ? data.boardMembers
          : defaultBoarMemberData,
      executiveOfficers:
        data?.executiveOfficers?.length > 0
          ? data?.executiveOfficers
          : defaultBoarMemberData,
      keyPersons:
        data?.keyPersons?.length > 0 ? data?.keyPersons : defaultBoarMemberData,
    });
  };

  const getOrgProfileQuery = useGetOrgProfileApi(onFetchSuccess);

  const { mutateAsync, isError, isSuccess, isLoading } = UseSaveOrgProfileApi(
    onOrgProfileSuccess,
    onProfileUpdateSuccess
  );

  const { state } = useLocation();

  function onOrgProfileSuccess() {
    props.getOrgProfile();
    props.getLoggedInUserDetail();

    if (state) {
      props.history.push(state.currentPath);
    }
  }
  const classes = useStyles();

  React.useEffect(() => {
    
    const email: string = localStorage.getItem("userEmail") || "";

    props.getOrgProfile({ email });
  }, [])
  
  const onSubmit = (data: any) => {
    const oldData: any = getOrgGovernanceDetails(
      getOrgProfileQuery.data?.boardMembers,
      getOrgProfileQuery.data?.executiveOfficers,
      getOrgProfileQuery.data?.keyPersons
    );
    const boardMembersChanges = detectChanges(
      oldData.boardMembers,
      data?.boardMembers
    );
    const executiveOfficersChanges = detectChanges(
      oldData.executiveOfficers,
      data?.executiveOfficers
    );
    const keyPersonsChanges = detectChanges(
      oldData.keyPersons,
      data?.keyPersons
    );

    const governaceDetails: any = {
      added: [],
      updated: [],
      deleted: [],
    };
    const bmRes = getOrgMembersFormat(
      oldData.boardMembers,
      boardMembersChanges,
      "boardMembers"
    );
    const eoRes = getOrgMembersFormat(
      oldData.executiveOfficers,
      executiveOfficersChanges,
      "executiveOfficers"
    );
    const kpRes = getOrgMembersFormat(
      oldData.keyPersons,
      keyPersonsChanges,
      "keyPersons"
    );
    getOrgGovernanceDetailsFormat(bmRes, governaceDetails);
    getOrgGovernanceDetailsFormat(eoRes, governaceDetails);
    getOrgGovernanceDetailsFormat(kpRes, governaceDetails);

    const payload = {
      organization: {
        ...getOrgProfileQuery.data,
        ...data.organization,
        ...data,
      },
      governaceDetails,
    };
    mutateAsync(payload);
  };

  const defaultBoarMemberData = [
    {
      _id: "",
      userName: "",
      userTitle: "",
      birthYear: null,
      cityOfResidence: "",
      stateOfResidence: "",
      countryOfResidence: "",
    },
  ];
  useScrollToError(appendixbMethods?.errors, formErrorEleClass);

  // Add loader while any data is loading
  if (getOrgProfileQuery.isLoading) {
    return (
      <div className="flex-container">
        <div className="row">
          <div className="flex-item">
            <Spinner
              color="gray"
              size="large"
              className="emergency-donation-loader"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <p className="page-header">
        {appi18n.organizationDetailsLabel[props.selectedLang]}
      </p>
      <div
        className="donation-of-money-form bodyStyles createDonationForm orgNewProfile"
        style={{ backgroundColor: "#FFFFFF", padding: 15 }}
      >
        <div>
          <FormProvider {...appendixbMethods}>
            <form
              onSubmit={appendixbMethods.handleSubmit(onSubmit)}
              noValidate={true}
              autoComplete="off"
            >
              <OrgProfileForm
                heading={"oneTimeGoodsDonation.heading.aboutOrganization"}
                selectedLang={props.selectedLang}
                classes={classes}
                data={{ organization: getOrgProfileQuery.data }}
                disabled={undefined}
                getDefaultValue
              />

              <Divider style={{ marginBottom: "30px", marginTop: "10px" }} />

              <PrimaryUserDetails
                control={appendixbMethods.control}
                errors={appendixbMethods.errors}
                selectedLang={props.selectedLang}
                classes={classes}
                setValue={appendixbMethods.setValue}
                data={{
                  primaryOrgUser: getOrgProfileQuery.data.primaryOrgUser,
                }}
                disabled={undefined}
              />

              <Divider style={{ marginBottom: "30px", marginTop: "10px" }} />
              <AppendixBQuestionnaire
                heading={"common.heading.questionnaire"}
                classes={classes}
                data={getOrgProfileQuery.data}
                disabled={undefined}
                readOnly={false}
              />

              <Divider style={{ marginBottom: "30px", marginTop: "10px" }} />
              <OrgMemberComponent
                title="common.orgMembers.boardMembers"
                parentFieldName="boardMembers"
                disabled={undefined}
                classes={props?.classes}
                errorClassName={formErrorEleClass}
                data={getOrgProfileQuery.data.boardMembers}
              />
              <OrgMemberComponent
                title="common.orgMembers.executiveOfficers"
                parentFieldName="executiveOfficers"
                disabled={undefined}
                classes={props?.classes}
                errorClassName={formErrorEleClass}
                data={getOrgProfileQuery.data.executiveOfficers}
              />
              <OrgMemberComponent
                title="common.orgMembers.keyPersons"
                parentFieldName="keyPersons"
                disabled={undefined}
                classes={props?.classes}
                errorClassName={formErrorEleClass}
                data={getOrgProfileQuery.data.keyPersons}
              />

              <AppendixBOrgMemberQuestionnaire
                classes={classes}
                data={getOrgProfileQuery.data}
                disabled={props?.appendixBOrgMemberQuestionnaireDisabled}
                readOnly={props?.appendixBOrgMemberQuestionnaireDisabled}
              />

              <Divider style={{ marginBottom: "30px", marginTop: "20px" }} />

              <AppendixBCertification
                classes={classes}
                data={getOrgProfileQuery.data}
                disabled={false}
                showEmptyFileMessage={true}
                isReview={props?.isReview}
                displaydata
                uploadedOrgFiles={props?.data?.donationDetail?.orgUploadFiles}
                errorClassName={formErrorEleClass}
              />
              <Divider style={{ marginBottom: "30px", marginTop: "10px" }} />

              <AppendixBConsent
                isReview={props?.isReview}
                classes={classes}
                data={getOrgProfileQuery.data}
                disabled={false}
                getDefaultValue
                errorClassName={formErrorEleClass}
              />

              <Button
                id={"ngoForm_submit"}
                className={"submit_button_styles ngo_next_button"}
                type={"submit"}
                title={t("common.buttonLabels.submitBtn")}
              />
            </form>
          </FormProvider>
        </div>
      </div>
      <SuccessSnackBarComponent
        isSuccess={isSuccess}
        message={appi18n.genericSuccessMessage[props.selectedLang]}
      />
      <ErrorSnackbarComponent
        isError={isError}
        classes={props.classes}
        // @ts-ignore
        message={commonFormTranslations.genericErrorMessage[props.selectedLang]}
      />
      {/* {(isLoading) && <LoaderComponent fullScreen={true} />}  */}
      {orgProfileState.saveOrgProfileLoading ||
      orgProfileState.cityStateMappingLoading ||
      orgProfileState.updateOrgProfileLoading ||
      orgProfileState.getOrgProfileLoading ||
      loggedInUserDataLoading ||
      isLoading ||
      getOrgProfileQuery.isLoading ? (
        <LoaderComponent fullScreen={true} />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  submissionData: state.submissionDataState.submissionData,
  selectedLang: state.selectedLang,
  selectedDonationData: state.selectedDataState.addDataToStore,
  orgProfile: state.loggedInUserState.userDetail,
  orgProfileState: state.orgProfile,
  formState: state.formState,
  loggedInUserDataLoading: state.loggedInUserState.loggedInUserloading,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
  editOrgProfile: (data: any) => dispatch(saveOrgProfile(data)),
  getOrgProfile: (data: any) => dispatch(getOrgProfile(data)),
  getLoggedInUserDetail: () => dispatch(loggedInUserData()),
  getCityStateMapping: (data: any) => dispatch(cityStateMappingDataLoad(data)),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationProfile))
);
