import { NavBarActions, NavBarActionTypes } from './navBarActions';
import { NavBarState } from '../../../models/globalDonationState';

const isMobileResolution = () => window.innerWidth <= 425;

export const defaultNavBarState: NavBarState = {
    open: !isMobileResolution()
};

export const navBarReducer = (state: NavBarState = defaultNavBarState, action: NavBarActions):
    NavBarState => {
    switch (action.type) {
        case NavBarActionTypes.NAV_BAR_OPEN: return {
            open:true
        };
        case NavBarActionTypes.NAV_BAR_CLOSE: return {
            open:false
        };
        default: return state;
    }
}