import EmergencyDonations from '../../donations/emergencyDonation/EmergencyDonations';
import EmergencyDonationThankYou from '../../donations/emergencyDonation/ThankYou';
import ViewEmergencyDonation from '../../donations/emergencyDonation/ViewEmergencyDonation';
import AddEmergencyDonation from '../../donations/emergencyDonation/AddEmergencyDonation';

const getEmergencyDonationRoutes = () => {
    return [
        {
            routePath: `dashboard/emergency-donation`,
            routeComponent: EmergencyDonations,
            content: 'donationTable'
        },
        {
            routePath: `dashboard/view/emergency-donation`,
            routeComponent: ViewEmergencyDonation,
            content: 'newRequest'
        },
        {
            routePath: `dashboard/thank-you/emergency-donation`,
            routeComponent: EmergencyDonationThankYou,
            content: 'thankYouEmergencyDonation',
        },
        {
            routePath: `dashboard/add/emergency-donation`,
            routeComponent: AddEmergencyDonation,
            content: 'addEmergencyDonation'
        }
    ];
}

export default getEmergencyDonationRoutes;