import { Action, Reducer } from 'redux';
import { GlobalDonationState } from 'src/models/globalDonationState';
import { globalDonationDataReducer , defaultGlobalDonationState as appState} from './globalDonationReducer';
import { formDataReducer } from 'dynamic-form-components/lib/state/form/formDataReducer';
import { submissionDataReducer } from 'dynamic-form-components/lib/state/submission/submissionDataReducer';
import { globalSubmissionDataReducer } from './submission/submissionDataReducer';
import { notificationReducer } from './Globalnotification/notificationDataReducer';
import { translationDataReducer } from './translations/translationReducer';
import { registerUserDataReducer } from './userLogin/registerUserReducer';
import { facilitySearchReducer } from './facilitySearch/facilitySearchReducer';
import { provinceSearchReducer } from './provinceSearch/provinceSearchReducer';
import { selectedDataReducer } from './selectedData/selectedDataReducer';
import { reportDataReducer } from './report/reportDataReducer';
import { orgProfileReducer } from './OrgProfile/orgProfileReducer';
import { foodSurplusConfirmationReducer } from './foodSurplusConfirmation/foodSurplusConfirmationReducer';
import { downloadFileDataReducer } from './downloadFile/downloadFileDataReducer';
import { loggedInUserReducer } from './loggedInUser/loggedInUserReducer';
import { sidebarReducer } from './sidebar/sidebarReducer';
import { donationOfMoneyReducer } from './donationOfMoney/donationOfMoneyReducer';
import { donationApprovalReducer } from './donationOfApproval/donationOfApprovalReducer';
import { logoutUserReducer } from './logout/logoutUserReducer';
import { deleteFileReducer } from './downloadFile/deleteFileReducer';
import { divisionDataReducer } from './divisionData/divisionDataReducer';
import { orgUserManagementReducer } from './orgUserManagement/orgUserManagementReducer';
import { navBarReducer } from './spaceDonation/navbar/navBarReducer';
import {appLogoReducer} from './spaceDonation/AppLogo/AppLogoReducer';
import { uploadDownloadReducer } from '../state/uploadDownloadFile/uploadDownloadFileReducers';

export const defaultGlopbalDonationState: GlobalDonationState = appState;

const globalDonationRootReducer: Reducer<GlobalDonationState> = (
    state: GlobalDonationState = defaultGlopbalDonationState,
    action: Action,
): GlobalDonationState => {
    // add new state elements here
    return {
        ...globalDonationDataReducer(state, action),
        formState: formDataReducer(state.formState, action),
        submissionState: submissionDataReducer(state.submissionState, action),
        submissionDataState: globalSubmissionDataReducer(state.submissionDataState, action),
        notificationDataState: notificationReducer(state.notificationDataState, action),
        translationState: translationDataReducer(state.translationState, action),
        registerUser: registerUserDataReducer(state.registerUser, action),
        orgProfile: orgProfileReducer(state.orgProfile, action),
        facilitySearchState: facilitySearchReducer(state.facilitySearchState, action),
        provinceSearchState: provinceSearchReducer(state.provinceSearchState, action),
        selectedDataState: selectedDataReducer(state.selectedDataState, action),
        downloadReportState: reportDataReducer(state.downloadReportState, action),
        foodSurplusConfirmationState: foodSurplusConfirmationReducer(state.foodSurplusConfirmationState, action),
        downloadFileState: downloadFileDataReducer(state.downloadFileState, action),
        loggedInUserState: loggedInUserReducer(state.loggedInUserState, action),
        sidebarState: sidebarReducer(state.sidebarState, action),
        donationOfMoney: donationOfMoneyReducer(state.donationOfMoney, action),
        donationApprovalState: donationApprovalReducer(state.donationApprovalState, action),
        logoutState: logoutUserReducer(state.logoutState, action),
        deleteFileState: deleteFileReducer(state.deleteFileState, action),
        divisionDataState: divisionDataReducer(state.divisionDataState, action),
        orgUserManagementState: orgUserManagementReducer(state.orgUserManagementState, action),
        navBarState: navBarReducer(state.navBarState, action),
        appLogoState: appLogoReducer(state.appLogoState,action),
        uploadDownloadActionState: uploadDownloadReducer(state.uploadDownloadActionState, action)
    };
};

export default globalDonationRootReducer;