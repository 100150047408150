import React from 'react';
import { withStyles, Tabs } from '@material-ui/core';

const CustomTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#0071DC',
      height:'4px'
    },
})(Tabs);

export default React.memo(CustomTabs)
