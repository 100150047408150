import { Action } from 'redux';

export enum AppLogoActionTypes {
    SHOW_APPLOGO = 'SHOW_APPLOGO',
    HIDE_APPLOGO = 'HIDE_APPLOGO'
}

export interface ShowAppLogo extends Action {
    type: AppLogoActionTypes.SHOW_APPLOGO
}

export const showAppLogo = (): ShowAppLogo => ({
    type: AppLogoActionTypes.SHOW_APPLOGO
});

export interface HideAppLogo extends Action {
    type: AppLogoActionTypes.HIDE_APPLOGO
}

export const hideAppLogo = (): HideAppLogo => ({
    type: AppLogoActionTypes.HIDE_APPLOGO
});

export type AppLogoActions = ShowAppLogo | HideAppLogo;