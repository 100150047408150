import { ReportDataActions, ReportDataActionTypes } from './reportDataActions';
import { DownloadReportState } from '../../models/globalDonationState';

export const defaultReportDataState: DownloadReportState = {
    reportData: {},
    reportDataSuccess: false,
    reportDataFailure: false,
    reportDataLoading: false
};

export const reportDataReducer = (state: DownloadReportState = defaultReportDataState, action: ReportDataActions):
    DownloadReportState => {
    switch (action.type) {
        case ReportDataActionTypes.SEND_REPORT_DATA: return {
            ...state,
            reportDataLoading: true,
            reportDataFailure: false,
            reportDataSuccess: false,
        };
        case ReportDataActionTypes.SEND_REPORT_DATA_SUCCESS: return {
            ...state,
            reportDataSuccess: true,
            reportDataFailure: false,
            reportDataLoading: false,
        };
        case ReportDataActionTypes.SEND_REPORT_DATA_FAILURE: return {
            ...state,
            reportDataLoading: false,
            reportDataFailure: true,
            reportDataSuccess: false,
        };
        default: return state;
    }
}