import {
    Button, Grid, GridColumn, Heading
} from "@walmart-web/livingdesign-components";
import { FC } from "react";
import { contextPath } from "../../constants";
import { BreadcrumbsComponent } from "../common/index";

export interface OwnProps {
    history: any;
    breadcrumbsData: any;
    thankyouSubject: string;
    thankyouHeading: string;
    thankyouButton: string;
    contextUrl: string;
    tabSelected?: Number;
}
const ThankyouComponent: FC<OwnProps> = ({ history, breadcrumbsData, thankyouSubject, thankyouHeading, thankyouButton, contextUrl, tabSelected = 0 }) => {
    return (
        <>
            <BreadcrumbsComponent breadcrumbsData={breadcrumbsData} history={history} />

            <div className={`thank-you-style-element`}>
                <Grid style={{ marginTop: "25px" }}>
                    <GridColumn sm={12}>
                        <Heading as="h1" size="large" weight={700}>
                            {thankyouHeading}
                        </Heading>
                    </GridColumn>

                    <GridColumn sm={12} style={{ marginTop: "5px" }}>
                        <Heading as="h5" size="medium" weight={400}>
                            {thankyouSubject}
                        </Heading>
                    </GridColumn>

                    <GridColumn sm={12} style={{ marginTop: "5px" }}>
                    <Button variant="primary" onClick={() => {
                            history.push(
                                {
                                    pathname:  `${contextPath}/dashboard/${contextUrl}?type=${contextUrl}`,
                                    state: {
                                        tabSelected: tabSelected
                                    }
                                })
                        }}>{thankyouButton}
                        </Button>
                    </GridColumn>
                </Grid>
            </div>
        </>
    );
};
export default ThankyouComponent;
