//NOTE: These components are specific to MX wireframe. If need to reused at other place, move them common folder
import {createStyles, Divider, makeStyles} from '@material-ui/core';
import {FC} from 'react';

const useStyles = makeStyles(() => createStyles({
    dividerRoot: {
        marginTop: '15px'
    }
}));

export const CustomDivider: FC<any> = (props) => {
    const classes = useStyles();
    return <Divider classes={{ root: classes.dividerRoot }} {...props} />;
}}