//@ts-nocheck
import { memo, FC } from 'react'
import IOrganizationInfo from '../../../models/iOrganizationInfo'
import { getAppendixBDataFromOrgData } from './common/helper'
import { AppendixBRequestDTO } from '../../../models/iOneTimeFoodDTO'
import { Trans, useTranslation } from 'react-i18next'
import { getAppendixBValidationSchema } from './common/appendixBValidation'
import { yupResolver } from '@hookform/resolvers/yup'
import { EXTERNAL, OrganizationTypes, useScrollToError } from '../../../components/common/utils'
import { useMutation } from 'react-query'
import { saveOneTimeFoodDonation } from 'src/service/apiService'
import FoodNGOForm from './components/FoodNGOForm'
import { useForm } from 'react-hook-form'
import FoodGEForm from './components/FoodGEForm'


interface OwnProps {
  orgData: IOrganizationInfo
  countryCode: string,
  selectedLang: string,
  storeNumber: number
  orgType: OrganizationTypes
}

const NGOCreateFoodDonation: FC<OwnProps> = ({ orgData, countryCode, storeNumber, orgType }: OwnProps) => {

  const { t } = useTranslation();

  const { data: donationId, isError, isLoading, isSuccess, mutate, error } =
    useMutation(saveOneTimeFoodDonation);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: getAppendixBDataFromOrgData(orgData, orgType),
    resolver: yupResolver(getAppendixBValidationSchema(countryCode, orgType))
  });

  useScrollToError(methods?.errors, 'Mui-error')

  const attachFiles = (name: string, formData: FormData, formField: string) => {
    const donationAttachments = methods.getValues(name);
    donationAttachments?.forEach((file: File) => {
      formData.append(formField, file)
    })
  }

  const submitDonation = (requestData: AppendixBRequestDTO) => {
    const appendixB = new FormData();
    if (requestData?.organization) {
      requestData.organization = {
        ...orgData,
        ...requestData.organization
      }
    }
    /* As part of security fixes, removing the organization id from the request payload and
      retrieving it from the context on the backend
    */
    delete requestData?.organization?.id

    appendixB.append('donationRequestDTO', JSON.stringify({
      countryCode,
      requestSourceApp: EXTERNAL,
      donationTitle: requestData?.donationTitle,
      storeNumber,
      requestAmount: requestData?.amount,
      appendixBRequestDTO: requestData
    }));
    attachFiles('organizationAttachments', appendixB, 'organizationAttachments');
    attachFiles('donationAttachments', appendixB, 'donationAttachments');
    mutate(appendixB);
  }

  return orgType === OrganizationTypes.NGO ?
    <FoodNGOForm
      isFetching={false}
      appendixB={getAppendixBDataFromOrgData(orgData, OrganizationTypes.NGO)}
      countryCode={countryCode}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      methods={methods}
      showSubmit={true}
      isReview={false}
      submitDonation={submitDonation}
      successMessage={<Trans i18nKey='common.successMsg.donationCreateMsg'>
        Donation <strong>{{ donationId }}
        </strong> has been created Successfully
      </Trans>}
      // @ts-ignore
      errorMessage={error?.errorMessage || t('common.errorMesg.failedToCreateDonation')}
    /> :
    <FoodGEForm
      isFetching={false}
      appendixB={getAppendixBDataFromOrgData(orgData, OrganizationTypes.GE)}
      countryCode={countryCode}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      methods={methods}
      showSubmit={true}
      isReview={false}
      submitDonation={submitDonation}
      successMessage={<Trans i18nKey='common.successMsg.donationCreateMsg'>
        Donation <strong>{{ donationId }}
        </strong> has been created Successfully
      </Trans>}
      // @ts-ignore
      errorMessage={error?.errorMessage || t('common.errorMesg.failedToCreateDonation')}
    />
}

export default memo(NGOCreateFoodDonation)

