
import { contextPath, DONATION_TYPE_VALUES } from "../../../constants";
import { spacePageType } from "../gdmsSpaceDonation/SpaceInternationalDataFunctions";
import { servicePageType } from "../serviceDonation/serviceDonationDataFunctions";

const donationHomeRoute = (langObject: string) => {
    return {
        name: `${langObject}.nav.home`,
        path: "/"
    }
};

const getRoutePath = (donationCategory: any) => {
    switch (donationCategory) {
        case DONATION_TYPE_VALUES.SPACE_INTERNATIONAL:
            return spacePageType;
            break;
        case DONATION_TYPE_VALUES.SERVICE_DONATION:
            return servicePageType;
            break;
        default:
            return "default-type";
            break;

    }
}

export const getDefaultBreadCrumbArray = (langObject: string, donationCategory: any) => {
    const routeSubPath = getRoutePath(donationCategory);
    return [
        donationHomeRoute(langObject),
        {
            name: `${langObject}.nav.${langObject}`,
            path: `${contextPath}/dashboard/${routeSubPath}?type=${routeSubPath}`,
        }
    ]
}

export const addDonationBreadcrumbArray = (langObject: string, donationCategory: any) => {
    return [
        ...getDefaultBreadCrumbArray(langObject, donationCategory),
        {
            name: `${langObject}.form.newDonation`,
            active: true,
            applyFormat: false,
        }
    ]
}

export const getViewBreadCrumbData = (label: any, donationId: any, langObject: string, donationCategory: any) => {
    return [
        ...getDefaultBreadCrumbArray(langObject, donationCategory),
        {
            name: `${label} ${donationId ?? ""}`,
            active: true,
            applyFormat: false,
        }
    ]
}

export const getBreadCrumbDashBoardData = (langObject: string) => {
    return [
        donationHomeRoute(langObject),
        {
            name: `${langObject}.nav.${langObject}`,
            active: true,
            applyFormat: false,
        }
    ];
}