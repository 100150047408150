import { Table, TableCell, TableCellProps, TableHead, TableRow } from '@material-ui/core';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessageComponent } from 'src/components/common/formComponents/ErrorMessage';
import { formatCurrency } from 'src/components/common/utils';

import { TotalTableStyles } from '../common/styles';
import { IIncomeFields } from './orgDetailsDefaults';

interface IIncomeTotalsProps {
    watchedFields: IIncomeFields[],
    totalFieldName: string,
    getTranslation: (key: string) => string
}

export const IncomeTotals: FC<IIncomeTotalsProps> = ({ watchedFields, getTranslation, totalFieldName }) => {
    const classes = TotalTableStyles();
    const [fieldsTotal, setFieldsTotal] = useState<any>();
    const cellProps: TableCellProps = {
        variant: 'head',
        classes: { head: classes.totalCellHead, root: classes.cell },
        style: { width: '24%' }
    }
    const { register, setValue, errors } = useFormContext();
    const totalCallBack = useCallback(() => {
        if (watchedFields?.length > 0) {
            const fields = watchedFields?.reduce((acc, current) => {
                // @ts-ignore
                // tslint:disable-next-line: radix
                acc.amount = acc.amount + parseInt(current.amount || 0);
                // @ts-ignore
                // tslint:disable-next-line: radix
                acc.percentage += parseInt(current.percentage || 0);
                // @ts-ignore
                // tslint:disable-next-line: radix
                acc.numberOfDonors += parseInt(current.numberOfDonors || 0);

                return acc;

            }, { numberOfDonors: 0, amount: 0, percentage: 0 });
            setValue(totalFieldName, fields.percentage, { shouldValidate: true });
            setFieldsTotal(fields);
        }
    }, [watchedFields]);


    useEffect(() => {
        totalCallBack();

    }, [totalCallBack])

    return (<div>
        <Table aria-label={'Totals Row'} size='small' classes={{ root: classes.tableRoot }}  >
            <TableHead>
                <TableRow>
                    <TableCell {...cellProps}>
                        {getTranslation('total')}
                    </TableCell>
                    <TableCell {...cellProps}>
                        {fieldsTotal?.numberOfDonors}
                    </TableCell>
                    <TableCell {...cellProps}>
                        {formatCurrency(fieldsTotal?.amount)}
                    </TableCell>
                    <TableCell {...cellProps}>
                        {fieldsTotal?.percentage}%
                    </TableCell>
                    <TableCell {...cellProps}
                        style={{ width: '6%' }} >
                        <input type='hidden' ref={register} name={totalFieldName} />
                    </TableCell>
                </TableRow>
            </TableHead>
        </Table>
        <ErrorMessageComponent errors={errors} fieldName={totalFieldName} />
    </div>);
}