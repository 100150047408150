import { donationsStatusText } from './../../../constants';

export const oneTimeGoodsDonationStatuses: any = {
    en: [
        {
            name: donationsStatusText.ALL.en.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.ALL.en.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.ALL.en.CONTRACT_UPLOADED,
            code: 'CONTRACT_UPLOADED'
        },
        {
            name: donationsStatusText.ALL.en.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.ALL.en.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_COLLECTION,
            code: 'WAITING_FOR_COLLECTION'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_NGO_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_NGO_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_CA_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_CA_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.en.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.ALL.en.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_APPENDIX_B,
            code: 'WAITING_FOR_APPENDIX_B'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_INFORMATION_FROM_STORE,
            code: 'WAITING_FOR_INFORMATION_FROM_STORE'
        },
        {
            name: donationsStatusText.ALL.en.APPROVED,
            code: 'APPROVED'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_INFORMATION_FROM_CA,
            code: 'WAITING_FOR_INFORMATION_FROM_CA'
        },
        {
            name: donationsStatusText.ALL.en.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.ALL.en.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
          name: donationsStatusText.ALL.en.PENDING_AUTHORIZATION,
          code: 'PENDING_AUTHORIZATION'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_INFORMATION_FROM_ORGANIZATION,
            code: 'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'
        },
        {
            name: donationsStatusText.ALL.en.WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT'
        }
    ],
    es: [
        {
            name: donationsStatusText.ALL.es.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.ALL.es.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.ALL.es.CONTRACT_UPLOADED,
            code: 'CONTRACT_UPLOADED'
        },
        {
            name: donationsStatusText.ALL.es.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.ALL.es.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_COLLECTION,
            code: 'WAITING_FOR_COLLECTION'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_NGO_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_NGO_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_CA_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_CA_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.es.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.ALL.es.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_APPENDIX_B,
            code: 'WAITING_FOR_APPENDIX_B'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_INFORMATION_FROM_STORE,
            code: 'WAITING_FOR_INFORMATION_FROM_STORE'
        },
        {
            name: donationsStatusText.ALL.es.APPROVED,
            code: 'APPROVED'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_INFORMATION_FROM_CA,
            code: 'WAITING_FOR_INFORMATION_FROM_CA'
        },
        {
            name: donationsStatusText.ALL.es.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.ALL.es.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
          name: donationsStatusText.ALL.es.PENDING_AUTHORIZATION,
          code: 'PENDING_AUTHORIZATION'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_INFORMATION_FROM_ORGANIZATION,
            code: 'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'
        },
        {
            name: donationsStatusText.ALL.es.WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT'
        }
    ],
    fr: [
        {
            name: donationsStatusText.ALL.fr.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.ALL.fr.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.ALL.fr.CONTRACT_UPLOADED,
            code: 'CONTRACT_UPLOADED'
        },
        {
            name: donationsStatusText.ALL.fr.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.ALL.fr.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_COLLECTION,
            code: 'WAITING_FOR_COLLECTION'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_NGO_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_NGO_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_CA_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_CA_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.fr.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.ALL.fr.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_APPENDIX_B,
            code: 'WAITING_FOR_APPENDIX_B'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_INFORMATION_FROM_STORE,
            code: 'WAITING_FOR_INFORMATION_FROM_STORE'
        },
        {
            name: donationsStatusText.ALL.fr.APPROVED,
            code: 'APPROVED'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_INFORMATION_FROM_CA,
            code: 'WAITING_FOR_INFORMATION_FROM_CA'
        },
        {
            name: donationsStatusText.ALL.fr.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.ALL.fr.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
          name: donationsStatusText.ALL.fr.PENDING_AUTHORIZATION,
          code: 'PENDING_AUTHORIZATION'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_INFORMATION_FROM_ORGANIZATION,
            code: 'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'
        },
        {
            name: donationsStatusText.ALL.fr.WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT'
        }
    ],
    ja: [
        {
            name: donationsStatusText.ALL.ja.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.ALL.ja.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.ALL.ja.CONTRACT_UPLOADED,
            code: 'CONTRACT_UPLOADED'
        },
        {
            name: donationsStatusText.ALL.ja.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.ALL.ja.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_COLLECTION,
            code: 'WAITING_FOR_COLLECTION'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_NGO_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_NGO_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_CA_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_CA_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.ja.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.ALL.ja.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_APPENDIX_B,
            code: 'WAITING_FOR_APPENDIX_B'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_INFORMATION_FROM_STORE,
            code: 'WAITING_FOR_INFORMATION_FROM_STORE'
        },
        {
            name: donationsStatusText.ALL.ja.APPROVED,
            code: 'APPROVED'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_INFORMATION_FROM_CA,
            code: 'WAITING_FOR_INFORMATION_FROM_CA'
        },
        {
            name: donationsStatusText.ALL.ja.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.ALL.ja.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
          name: donationsStatusText.ALL.ja.PENDING_AUTHORIZATION,
          code: 'PENDING_AUTHORIZATION'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_INFORMATION_FROM_ORGANIZATION,
            code: 'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'
        },
        {
            name: donationsStatusText.ALL.ja.WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT'
        }
    ],
    zh: [
        {
            name: donationsStatusText.ALL.zh.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.ALL.zh.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.ALL.zh.CONTRACT_UPLOADED,
            code: 'CONTRACT_UPLOADED'
        },
        {
            name: donationsStatusText.ALL.zh.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.ALL.zh.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_COLLECTION,
            code: 'WAITING_FOR_COLLECTION'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_NGO_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_NGO_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_CA_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_CA_ACKNOWLEDGEMENT'
        },
        {
            name: donationsStatusText.ALL.zh.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.ALL.zh.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_APPENDIX_B,
            code: 'WAITING_FOR_APPENDIX_B'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_INFORMATION_FROM_STORE,
            code: 'WAITING_FOR_INFORMATION_FROM_STORE'
        },
        {
            name: donationsStatusText.ALL.zh.APPROVED,
            code: 'APPROVED'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_INFORMATION_FROM_CA,
            code: 'WAITING_FOR_INFORMATION_FROM_CA'
        },
        {
            name: donationsStatusText.ALL.zh.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.ALL.zh.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
          name: donationsStatusText.ALL.zh.PENDING_AUTHORIZATION,
          code: 'PENDING_AUTHORIZATION'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_INFORMATION_FROM_ORGANIZATION,
            code: 'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'
        },
        {
            name: donationsStatusText.ALL.zh.WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT,
            code: 'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT'
        }
    ]
}

