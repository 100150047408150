import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'src/polyfils/customRouter';
import withRouter from 'src/polyfils/customRouter';
import FormComponent from 'dynamic-form-components/lib/components/FormComponent';
import { GlobalDonationState } from '../../models/globalDonationState';
import { withStyles, Typography, Link } from '@material-ui/core';
import { registerUser, verifyCode, requestVerificationCode, resetPassword } from '../../state/userLogin/registerUserActions';
import styles from '../menu/ThemeStyle';
import '../../styles/registerUser.css'
import LoaderComponent from '../LoaderComponent';
import constants from './registerUserConstants';
import ErrorSnackbar from '../appMessage/ErrorSnackbar';
import { contextPath } from './../../constants';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import tracker from '../../adobe-analytics/tracker';
import analytics from '../../adobe-analytics/analytics';
import { ANALYTICS } from '../../adobe-analytics/analytics-dataelemets';
const resetPageForms: any = {
    resetPassword: require(`./Forms/resetPassword`),
    enterEmail: require(`./Forms/enterEmail`),
    verifyCode: require(`./Forms/verifyCode`),
}


export interface OwnProps {
    formAlias: string;
    submissionUrl?: string;
    classes: any;
    appi18n: any;
    handleSubmit: (data: any) => void;
}

export interface StateProps {
    submissionData?: any;
    selectedLang: any;
    registerUserData: any;
    orgType?: any;
    formAlias?: any;
}

export interface DispatchProps {
    register: (data: any) => void;
    verifyEmailID: (data: any) => void,
    verifyCode: (data: any) => void,
    changePassword: (data: any) => void,
}

class ResetPassword extends React.PureComponent<OwnProps & RouteComponentProps & StateProps & DispatchProps> {
    public state = {
        orgType: 'ngo',
        formAlias: 'enterEmail'
    };

    public options: any;

    public componentWillMount() {
        this.options = {
            ...this.options,
            noAlerts: true,
            language: this.props.selectedLang
        }

        window.lang = localStorage.getItem('lang');
        window.countryName = localStorage.getItem('countryName');
        window.countryCode = localStorage.getItem('countryCode');
        if (!localStorage.getItem('lang') && !localStorage.getItem('countryName')) {
            window.lang = 'en';
            window.countryName = 'India';
            window.countryCode = 'IN';
        }
    }

    public componentWillReceiveProps(newProps: any) {
        if (newProps.selectedLang !== this.props.selectedLang) {
            this.options = {
                ...this.options,
                language: newProps.selectedLang
            }
        }
    }

    public componentDidMount() {
        window.lang = localStorage.getItem('lang');
        window.countryName = localStorage.getItem('countryName');
        window.countryCode = localStorage.getItem('countryCode');
        if (!localStorage.getItem('lang') && !localStorage.getItem('countryName')) {
            window.lang = 'en';
            window.countryName = 'India';
            window.countryCode = 'IN';
        }
        analytics.trackPageInfoGdmsExternal(ANALYTICS.login.ResetPassword);
    }

    public componentDidUpdate() {
        window.lang = localStorage.getItem('lang');
        window.countryName = localStorage.getItem('countryName');
        window.countryCode = localStorage.getItem('countryCode');
        if (!localStorage.getItem('lang') && !localStorage.getItem('countryName')) {
            window.lang = 'en';
            window.countryName = 'India';
            window.countryCode = 'IN';
        }
    }

    private handleSubmit = (formData: any) => {
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.RESET__BUTTON.name,
            location: ANALYTICS.login.ResetPassword
        });
        // this.props.register(formData.data);
        analytics.trackEventInfo({
            type: tracker.EVENTS.CLICK,
            actionOn: tracker.ACTION_ON.RESET_CONTINUE.name,
            location: ANALYTICS.login.ResetPassword
        });
        const { registerUserData } = this.props;
        if (registerUserData.requestVerificationCodeSuccess === false) {
            this.props.verifyEmailID(formData.data)
        } else if (registerUserData.verifyCodeSuccess === false) {
            this.props.verifyCode({ ...formData.data, emailId: registerUserData.requestVerificationCode })
        } else if (registerUserData.resetPasswordSuccess === false) {
            this.props.changePassword({
                ...formData.data, emailId: registerUserData.requestVerificationCode,
                verificationCode: registerUserData.verifyCode
            })
        }
    }
    public render() {
        // @ts-ignore
        const { classes, registerUserData, selectedLang } = this.props;
        const hostName = window.location.origin;
        // @ts-ignore
        const loginUrl = `${hostName}${contextPath}/Public/login?lang=${localStorage.getItem('country') ? localStorage.getItem('country').toUpperCase() : 'EN_IN'}`
        let page: any;
        let errorType: any = 'requestVerificationCodeFailure';
        if (registerUserData.requestVerificationCodeSuccess === false) {
            page = 'verifyEmail'
        } else if (registerUserData.verifyCodeSuccess === false) {
            errorType = 'verifyCodeFailure'
            page = 'verifyCode'
        } else if (registerUserData.resetPasswordSuccess === false) {
            errorType = 'resetPasswordFailure'
            page = 'resetPassword'
        } else {
            page = 'success'
        }
        const formHeader = page !== 'success' && (
            <div className={classes.headerSection}>
                <Typography className={classes.header}>
                    {constants[page].pageHeader[selectedLang]}
                </Typography>
                <Typography className={classes.subHeader}>
                    {constants[page].pageSubHeader[selectedLang]}
                </Typography>
            </div>
        )
        if (registerUserData.resetPasswordLoading || registerUserData.requestVerificationCodeLoading
            || registerUserData.verifyCodeLoading) {
            page = 'loader'
        }
        return (<>
            <HeaderComponent classes={classes} />
            <div className={classes.formCenter}>
                {
                    page !== 'success' && page !== 'loader' &&
                    <div className={classes.formContainer}>
                        {formHeader}
                        <div className={classes.formElement}>
                            <FormComponent
                                key={constants[page].formAlias}
                                formAlias={constants[page].formAlias}
                                formJsonDefinition={resetPageForms[constants[page].formAlias]}
                                options={this.options}
                                submission={this.props.register}
                                onSubmit={this.handleSubmit}
                                submissionUrl={this.props.submissionUrl}
                            />
                        </div>
                    </div>
                }
                {
                    page === 'success' &&
                    <div className={classes.formContainer}>
                        <div className={classes.formElement}>
                            <div className='register-success' />
                            <Typography className={classes.header}>
                                Password changed successfully!
                            </Typography>
                            <Typography className={classes.subHeader}>
                                <Link href={loginUrl}
                                    className='link-subheader'>Login</Link> with new password
                            </Typography>
                        </div>
                    </div>
                }
                {page === 'loader' && <LoaderComponent fullScreen={true} />}
                <ErrorSnackbar errorType={errorType} />
            </div>
            <FooterComponent />
        </>);
    }
}

const mapStateToProps = (state: GlobalDonationState): StateProps => ({
    submissionData: state.submissionDataState.submissionData,
    selectedLang: state.selectedLang,
    registerUserData: state.registerUser,
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
    register: (data: any) => dispatch(registerUser(data)),
    verifyEmailID: (data: any) => dispatch(requestVerificationCode(data)),
    verifyCode: (data: any) => dispatch(verifyCode(data)),
    changePassword: (data: any) => dispatch(resetPassword(data)),
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)));
