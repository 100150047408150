import * as _ from 'lodash'
import { Divider } from '@material-ui/core';
import { FC } from 'react'
import { useFormContext } from 'react-hook-form';
import { GEAppendixBCertification } from './GEAppendixBCertification';
import { GEAppendixBDonationDetails } from './GEAppendixBDonationDetails';
import { GEAppendixBOrgDetails } from './GEAppendixBOrgDetails';
import { PrimaryUserDetails } from '../components/PrimaryUserDetails';

export interface GEAppendixBFormProps {
    selectedLang: string
    data?: any
    disabled?: boolean
    classes: any
    appendixBOrgDetailsDisabled?: boolean
    appendixBDonationDetailsDisabled?: boolean
    appendixBCertificationDisabled?: boolean
    appendixBConsentDisabled?: boolean
    isReview?: boolean
    showEmptyFileMessage?: boolean
    appendixBPrimaryUserDetailsDisabled?: boolean
}
export const GEAppendixBForm: FC<GEAppendixBFormProps> = (props: any) => {

    const { control, setValue, errors } = useFormContext();

    return (<>
        <GEAppendixBOrgDetails
            heading={'oneTimeGoodsDonation.heading.aboutOrganization'}
            selectedLang={props.selectedLang}
            classes={props.classes}
            data={props?.data}
            disabled={props?.appendixBOrgDetailsDisabled}
        />

        <PrimaryUserDetails
            control={control}
            errors={errors}
            selectedLang={props.selectedLang}
            classes={props.classes}
            setValue={setValue}
            data={props?.data?.organization}
            disabled={props?.appendixBPrimaryUserDetailsDisabled}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '10px' }} />
        <GEAppendixBDonationDetails
            control={control}
            errors={errors}
            selectedLang={props?.selectedLang}
            classes={props?.classes}
            setValue={setValue}
            data={props?.data}
            isReview={props?.isReview}
            disabled={props?.appendixBDonationDetailsDisabled}
            showEmptyFileMessage={true}
        />
        <Divider style={{ marginBottom: '30px', marginTop: '20px' }} />
        <GEAppendixBCertification
            classes={props.classes}
            data={{...props?.data?.organization,
                certificationCheckBox1: props?.appendixBCertificationDisabled,
                certificationCheckBox2: props?.appendixBCertificationDisabled,
                certificationCheckBox3: props?.appendixBCertificationDisabled
            }}
            showEmptyFileMessage={true}
            isReview={props?.isReview}
            uploadedOrgFiles={props?.data?.donationDetail?.orgUploadFiles}
            disabled={props?.appendixBCertificationDisabled}
        />
    </>)
}
