import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import store from "./store";
import i18n from "./i18n";
import App from "./App";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./index.css";
import analytics from "./adobe-analytics/analytics";
import { createRoot } from "react-dom/client";

const defineAdobeAnalytics = () => {
  window.__GDMS_PORTAL__ = {
    dataLayer: { analyticsEvent: [] },
  };
  analytics.loadContent(document.getElementsByTagName("head")[0]);
  setTimeout(() => analytics.loadAdobeScript(), 3000);
};
defineAdobeAnalytics();

// Create a client
const queryClient = new QueryClient();

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const AppComponent = () => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>
);

root.render(<AppComponent />);
