import { Grid, IconButton, makeStyles, Paper, TableRow, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FC } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AddRow from '../common/AddRow';
import { CustomCell } from '../common/CustomCell';
import { CustomDivider } from '../common/CustomDivider';
import InformationBar from '../common/informationBar';
import MoreDetailsTable from '../common/MoreDetailsTable';
import { sectionClasses } from '../common/styles';
import { CoInvestorsTotals } from './projectCoInvestorsTotalTable';
import { DefaultCoInvestorsFields, ICoInvestorsFields } from './projectDetailsDefaults';
import {InputTextField} from '../../../common/Input/InputTextField';


const useStyles = makeStyles({
    contentFit: { width: 'max-content' }
})

export interface ProjectCoInvestorsProps {
    fieldName: string,
    data: any
}

const columnHeader = [
    { key: 'coInvestorDonor', width: '30%' },
    { key: 'supportingActivities', width: '35%' },
    { key: 'donationAmount', width: '15%' },
    { key: 'percentage', width: '15%' }];
export const ProjectCoInvestors: FC<ProjectCoInvestorsProps> = ({ fieldName }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const classes = useStyles();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
    const coInvestorsFields = useFieldArray({
        name: fieldName,
        control
    })

    const requiredFieldMsg = t('orgRegForm.validationMsg.requiredField');

    const handleRemove = (index: number) => {
        coInvestorsFields.remove(index);
    }

    const totals = useWatch<ICoInvestorsFields>({ control, name: fieldName });

    const addRowHandler = () => {
        coInvestorsFields.append(DefaultCoInvestorsFields);
    }

    return (<div className={sectionClasses.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Typography
                        classes={{ root: sectionClasses.title }}>{getTranslation('donorrSummary')}</Typography>
                </Grid>
                <Grid item={true} xs={12} >
                    <InformationBar className={classes.contentFit}>{getTranslation('coinvestormsg')}</InformationBar>
                </Grid>
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12}>
                        <CustomTable
                            fields={coInvestorsFields.fields}
                            fieldName={fieldName}
                            header={columnHeader}
                            getTranslation={getTranslation}
                            requiredFieldMsg={requiredFieldMsg}
                            removeHandler={handleRemove}
                            addRowhandler={addRowHandler}
                            totalFieldName={'totals'}
                            watchedFields={totals}
                            t={t}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </div>

    );
}

// @ts-ignore
const CustomTable = ({ fields, fieldName, header, getTranslation, requiredFieldMsg, removeHandler, addRowhandler: addRowHandler, t,
                         // @ts-ignore
                         totalFieldName, watchedFields }) => {
    const styles = { padding: '15px', backgroundColor: '#fbfbfb' }

    return (<>
        <Paper elevation={3} variant='elevation' style={styles}>
            <MoreDetailsTable
                getTranslation={getTranslation}
                header={header}>
                {fields.map((field: any, index: number) => {

                    const errorKey = `${fieldName}.${index}`;
                    return (<TableRow key={field.id}>
                        <CustomCell >
                            <InputTextField
                                required={true}
                                fieldName={`${fieldName}[${index}].coInvestorDonor`}
                                id={field.id}
                                defaultValue={field.coInvestorDonor}
                                errorKeyName={`${errorKey}.coInvestorDonor`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                required={true}
                                fieldName={`${fieldName}[${index}].supportingActivities`}
                                id={field.id}
                                defaultValue={field.supportingActivities}
                                errorKeyName={`${errorKey}.supportingActivities`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <InputTextField
                                required={true}
                                fieldName={`${fieldName}[${index}].donationAmount`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.donationAmount}
                                errorKeyName={`${errorKey}.donationAmount`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>

                        <CustomCell>
                            <InputTextField
                                required={true}
                                fieldName={`${fieldName}[${index}].percentage`}
                                id={field.id}
                                type={'number'}
                                defaultValue={field.percentage}
                                errorKeyName={`${errorKey}.percentage`}
                                rules={{
                                    required: requiredFieldMsg
                                }}
                            />
                        </CustomCell>
                        <CustomCell >
                            <IconButton color='default' id={field.id} onClick={() => removeHandler(index)}
                                        disabled={fields?.length === 1}
                                        aria-label='delete row' component='span'>
                                <DeleteIcon />
                            </IconButton>
                        </CustomCell>

                    </TableRow>)
                })}
            </MoreDetailsTable>
            <CoInvestorsTotals watchedFields={watchedFields} getTranslation={getTranslation}
                               totalFieldName={totalFieldName} />
            <CustomDivider />
            <AddRow
                handler={addRowHandler}
                key='addCoInvesttors'
                t={t}
            />
        </Paper>

    </>);
}

