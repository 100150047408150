import { Action } from 'redux';

export enum FoodSurplusConfirmationTypes {
    FOOD_SURPLUS_CONFIRMATION_LOAD = 'FOOD_SURPLUS_CONFIRMATION_LOAD',
    FOOD_SURPLUS_CONFIRMATION_LOAD_SUCESS = 'FOOD_SURPLUS_CONFIRMATION_LOAD_SUCESS',
    FOOD_SURPLUS_CONFIRMATION_LOAD_FAILURE = 'FOOD_SURPLUS_CONFIRMATION_LOAD_FAILURE'
}

export interface FoodSurplusConfirmation extends Action {
    type: FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD;
    data: any;
}

export const foodSurplusConfirmation = (data: string): FoodSurplusConfirmation => ({
    type: FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD,
    data
});

export interface FoodSurplusConfirmationSuccess extends Action {
    type: FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD_SUCESS;
    data: any;
}

export const foodSurplusConfirmationSuccess = (data: any): FoodSurplusConfirmationSuccess => ({
    type: FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD_SUCESS,
    data
});

export interface FoodSurplusConfirmationFailure extends Action {
    type: FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD_FAILURE;
    data?: string;
}

export const foodSurplusConfirmationFailure = (data: string): FoodSurplusConfirmationFailure => ({
    type: FoodSurplusConfirmationTypes.FOOD_SURPLUS_CONFIRMATION_LOAD_FAILURE,
    data
});

export type FoodSurplusConfirmationActions = FoodSurplusConfirmation | FoodSurplusConfirmationSuccess | FoodSurplusConfirmationFailure;