import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Divider from '../../../commonComponents/uiComponents/LDDivider';
// import Card from '../../../commonComponents/uiComponents/LDCard';
import ButtonSection from './bottonSection';
import FIleUploadComponent from './fileUploadComponent';
import Questions from './questions';
import RatingForm from './RatingForm';
import ThankYou from './thankyou';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import LoaderComponent from '../../../commonComponents/appComponents/loader/LoaderComponent';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import './styles.css';

const service: Service = SpaceDonationService;

export interface FeedbackProps {
    storeName?: string,
    storeCity?: string,
    feedBack?: any,
    donationId?: any,
    setSpaceDonation?: any,
    store?: any,
    feedbacksurvey?: any,
    eventPurpose?: string,
    countryCode?:any
}

const toQuestionsArray = (obj: any) => {
    const qArray: any = [];
    Object.keys(obj).forEach(o => {
        qArray.push({ question: o, answer: obj[o] });
    })
    return qArray;
}

const toQuestionMap = (obj: any) => {
    const qMap: any = {};
    obj.forEach((o: any) => {
        qMap[o.question] = o.answer;
    })
    return qMap;
}

const Feedback: React.SFC<FeedbackProps> = (props: any) => {
    const oldFeedBack = props.feedBack;
    const donationId = props.donationId;
    const purpose = props.eventPurpose ? props.eventPurpose : 'default_questions';
    const hasFeedback = oldFeedBack && Object.keys(oldFeedBack).length > 0;
    const { status, data, isFetching, } = useQuery(['fetchFeedbackQuestions', { key: 'spaceFeedbackQuestions', countryCode: 'US' }],
        service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false, enabled: true });
    const mutation = useMutation((feedback: any) =>
        service.saveCheckoutSurvey({ feedback, donationId }));
    const [questionAnswer, setQuestionAnswer] = useState(hasFeedback && oldFeedBack.feedbackQuestions ?
        toQuestionMap(oldFeedBack.feedbackQuestions) : {});
    const [questions, setQuestions] = useState([]);
    const [activeForm, setActiveForm] = useState(0);
    const [rating, setRating] = useState(hasFeedback ? Number(oldFeedBack.smileyRating) : 0);// smileyRating
    const [ratingComment, setRatingComment] = useState(hasFeedback ? oldFeedBack.comments : '');// comments
    const [file, setFile] = useState('');
    const [fileId, setFileId] = useState(hasFeedback ? oldFeedBack.feedbackFile.fileId : '');// feedbackImageUrl
    const [uploadDir, setUploadDir] = useState(hasFeedback ? oldFeedBack.feedbackFile.container : '');
    const [isFileUploading, setIsFileUploading] = useState(false);
    const { status: imageStatus, data: imageData, isFetching: isImageFetching } = useQuery(['downloadProjectFile', {
        fileId: oldFeedBack?.feedbackFile?.fileId,
        container: 'space-donation',
        directory: oldFeedBack?.feedbackFile?.container,
        type: 'image'
    }],
        service.downloadProjectFile, { refetchOnWindowFocus: false });
    const { control, handleSubmit, errors, getValues } = useForm({
        mode: 'onChange',
        defaultValues: questionAnswer
    });



    useEffect(() => {
        if (imageStatus === 'success') {
            setFile(imageData);
        }
    }, [isImageFetching]);
    useEffect(() => {
        if (status === 'success') {
            setQuestions(data[purpose] ? data[purpose] : data.default_questions);
        }
    }, [isFetching]);

    const onSubmit = () => {
        mutation.mutate({
            smileyRating: rating,
            comments: ratingComment,
            feedbackFile: { fileId, container: uploadDir },
            feedbackQuestions: toQuestionsArray(getValues())
        }
        )
    };

    return <>{isFetching && <LoaderComponent />}
        {!isFetching &&
            <form onSubmit={handleSubmit(onSubmit)}><div className='feedback-rating'>
                {activeForm === 0 &&
                    <RatingForm
                        isNew={props.feedbacksurvey}
                        store={props.store}
                        hasFeedback={hasFeedback}
                        rating={rating}
                        setRating={setRating}
                        ratingComment={ratingComment}
                        setRatingComment={setRatingComment}
                    />}

                {activeForm === 1 &&
                    <FIleUploadComponent
                        setIsFileUploading={setIsFileUploading}
                        setFileId={setFileId}
                        setUploadDir={setUploadDir}
                        file={file}
                        setFile={setFile}
                        getValues={getValues}
                        donationId={donationId}
                        countryCode={props.countryCode}
                        isImageFetching={isImageFetching}
                        isFileUploading={isFileUploading}
                    />}

                {activeForm === 2 &&
                    <Questions questions={questions} setQuestionAnswer={setQuestionAnswer}
                        questionAnswer={questionAnswer}
                        control={control}
                        getValues={getValues}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        errors={errors}
                    />}
                {activeForm === 3 && <ThankYou />}
                {activeForm <= 2 &&
                    <><Divider />
                        <ButtonSection isFileUploading={isFileUploading} disabled={isFileUploading}
                            handleSubmit={handleSubmit} onSubmit={onSubmit} mutation={mutation}
                            setSpaceDonation={props.setSpaceDonation}
                            activeForm={activeForm} setActiveForm={setActiveForm} /></>}
            </div>
            </form>}</>
}

export default Feedback;
