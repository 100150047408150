import { Box } from '@material-ui/core';
import {FC} from 'react'
import { useTranslation } from 'react-i18next';
import FooterButton from '../../common/FooterButton';
interface OwnProps {
    onPageBack: () => void,
    onSave: () => void,
    finalized: boolean;
}
const FooterButtons:FC<OwnProps> = ({onPageBack, onSave, finalized}) => {
    const { t } = useTranslation();

    return <Box display = 'flex' mt = {3}>
        {!finalized && <>
            <FooterButton color='primary' variant='contained' type = 'submit'>{t('socialImpactReport.finalize')}</FooterButton>
            <Box component='span' mr = {2} />
            <FooterButton variant='outlined' onClick = {onSave}>{t('socialImpactReport.save')}</FooterButton>
            <Box component='span' mr = {2} />
        </>}
        <FooterButton variant='outlined' onClick = {onPageBack}>{t('socialImpactReport.cancel')}</FooterButton>
    </Box>
}

export default FooterButtons;
