import React, {useEffect, useState} from 'react';
import CardItem from '../../commonComponents/uiComponents/LDCardItem';
import {Controller, useForm} from 'react-hook-form';
import TextField from '../../commonComponents/uiComponents/LDTextField';
import LDModal from '../../commonComponents/uiComponents/LDModal';
import {useMutation, useQuery} from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import Rating from '../../commonComponents/appComponents/emojiRating/emojiRating';
 import LDCheckBox from '../../commonComponents/uiComponents/LDCheckBox';
import Button from '../../commonComponents/uiComponents/LDButton';
import Card from '../../commonComponents/uiComponents/LDCard';
import LoaderComponent from '../../commonComponents/appComponents/loader/LoaderComponent';
import {contextPath} from '../../../../constants';
import withRouter from '../../../../polyfils/customRouter';
import moment from 'moment';

const service: Service = SpaceDonationService;
const toQuestionsArray = (obj: any) => {
    const qArray: any = [];
    Object.keys(obj).forEach(o => {
        qArray.push({ question: o, answer: obj[o] });
    })
    return qArray;
}
export interface ImpactSurveyProps {
    history: any[]
    location: any
}

class ImpactSurvey extends React.Component<ImpactSurveyProps> {
    public render() {
        return <div>
            <ImpactSurveySection {...this.props}
                                 spaceDonationProp={this.props.location.state.spaceDonation}
                                 waasTaskMessage={this.props.location.state.waaSTaskMessage}/>
        </div>
    }
}

// @ts-ignore
export default withRouter(ImpactSurvey);
const MultiselectCheckbox = (props: any) => {
    const answer = props?.value?.answer?.split(',').map((e:any) => e);
    const filter = props.options?.filter((el:any) => {
        return answer?.find((element:any) => {
            return element === el.label;
        });
    });
    const filter1 = props.options?.filter((el:any) => {
        return !answer?.find((element:any) => {
            return element === el.label;
        });
    });
    const newArr = filter?.map((object: any) => {
        return {...object, checked: true};
    });
    const newArr1 = filter1?.map((object: any) => {
        return {...object, checked: false};
    });
    const [datas, setData] = React.useState(newArr?.concat(newArr1));
    const toggle = (index:any) => {
        const newData = [...datas];
        newData.splice(index, 1, {
            label: datas[index].label,
            checked: !datas[index].checked
        });
        setData(newData);
        props.onChange(newData.filter(x => x.checked).map((e:any) => e.label));
    };
    return (
        <>
            {datas?.map((item: any, index: any) => (
                <div><label key={item.label}>
                    <LDCheckBox
                        label={
                            <div>
                                <label htmlFor={item.label} className='dn'>
                                    {item.label}
                                </label>
                            </div>
                        }
                        onClick={() => toggle(index)}
                        // @ts-ignore
                        checked={datas.filter(x => x.checked).map(e=>e.label).includes(item.label)}
                    />
                    {/*{item.label}*/}
                </label></div>
            ))}
        </>
    );
};
const toQuestionMap = (obj: any) => {
    const qMap: any = {};
    obj.forEach((o: any) => {
        qMap[o.question] = o.answer;
    })
    return qMap;
}
const ImpactSurveySection: any = (props: any) => {
    const [questions, setQuestions] = useState([]);
    const hasFeedback = props?.spaceDonationProp?.impactSurvey?.length > 0;
    const [questionAnswer, setQuestionAnswer] = useState(hasFeedback ?
        toQuestionMap(props?.spaceDonationProp?.impactSurvey) : {});
    const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
    const { status, data, isFetching, } = useQuery(['fetchImpactSurveyQuestions', { key: 'spaceImpactSurveyQuestions', countryCode: 'US' }],
        service.getSpaceDonationConfigurations, { refetchOnWindowFocus: false, enabled: true });
    useEffect(() => {
        if (status === 'success') {
            setQuestionAnswer(props?.spaceDonationProp?.impactSurvey);
          setQuestions(data[props?.spaceDonationProp?.eventPurpose]|| data.default_questions);
        }
    }, [isFetching]);

    const [rating, setRating] = useState( '');
    const [questionAnswerOptions, setQuestionAnswerOptions] = useState( [{}]);
    const mutation = useMutation((feedback: any) =>
        service.saveImpactSurvey({ feedback, donationId: props?.spaceDonationProp?.id }));
    const { control, handleSubmit, setValue, getValues } = useForm({
        mode: 'onChange',
        defaultValues: questionAnswer
    });
    useEffect(() => {
        if (status === 'success') {
            questions?.map((question: any) => {
                const ques = question.question;
                setValue(ques, props?.spaceDonationProp?.impactSurvey?.find((x: any) => x.question === ques)?.answer || '');
            })
        }
    }, [isFetching]);
    const onSubmit = () => {
        setShowSuccessPopUp(true);
        const ratingQues= questions.filter((e:any)=>e.key === 'rating' ? e.question: '').map((e: any)=>e.question).toString()

        const arr = toQuestionsArray(getValues()).filter((e: any)=>e.question !== ratingQues);
        const arr1 = toQuestionsArray(questionAnswerOptions);
        const arr2 = toQuestionsArray({[ratingQues]: rating});
        if(arr1.length> 1) {
            mutation.mutate(arr1.concat(arr).concat(arr2)
                .filter((e:any)=>e.answer !== '' && e.answer!=='on'
                    && ((typeof e.answer) === 'object' && Object.keys(e.answer).length) !== 0))
        } else {
            mutation.mutate(arr.concat(arr2).filter((e:any)=>e.answer !== '' && e.answer!=='on'
                    && ((typeof e.answer) === 'object' && Object.keys(e.answer).length) !== 0))
        }
    };
    return<>
        {isFetching && <LoaderComponent />}
       <div> <div className='impact-heading'>
          Impact Survey
       </div>
           <div className='impact-event-detail'>{moment(props.spaceDonationProp.eventStartDate).format('MMM Do, YYYY')}
           ,  {props.spaceDonationProp.eventSlots.sort((a: any, b: any) => a.slotId - b.slotId).map((slot: any) => {
                    return <div key={slot.name}>
                        {slot?.label?.toUpperCase()}
                    </div>
                })}
               </div>
           <div className='reservation-card-go-back'
                onClick={() => props.history.push(
                    {
                        pathname: `${contextPath}/space-donation/events/detail`,
                        state: { spaceDonation: props.spaceDonationProp, waaSTaskMessage: props.waasTaskMessage }
                    })}>Back</div>
       </div>
        <form onSubmit={handleSubmit(onSubmit)}> <div className='questions-container'>
        <Card className='reservation-content-card impactsurvey'>
        <div className='impact-text-header'>Tell us about your event</div>
        <div className='impact-text-info'>
            We would love to share your story
        </div>
            <br />
        {
            questions?.map((question: any, index: any) => {
            const ques = question.question;
            // const key = question.key;
            return <>{question.answerType && ['number', 'text'].includes(question.answerType) && <CardItem key={index} className='checkout-text-question' >
                    {`${index + 1}. ${ques}`}
                    <Controller
                        defaultValue={''}
                        control={control}
                        name={question.question}
                        data-testid={question.key}
                        as={<TextField type={question.answerType} />
                        }
                        rules={{
                            required: question.required === 'true' ? question.errorMsg : false
                        }}
                    />
                </CardItem>}

                <CardItem key={index} className='checkout-text-question' >
                    {question.answerType && ['checkbox', 'emoji'].includes(question.answerType) ? `${index + 1}. ${ques}`: <></>}
                <Controller
                    defaultValue={''}
                    control={control}
                    name={question.question}
                    data-testid={question.key}
                    as={ ['emoji'].includes(question.answerType) ?
                        <Rating setRating={setRating}
                                rating={Number(questionAnswer?.filter((e:any)=>e.question === ques).map((e:any)=>e.answer).toString())
                                                                           } />: <div> <br /><MultiselectCheckbox
                            value={props.spaceDonationProp?.impactSurvey?.find((e: any) => e.question === ques)}
                            options={question.options}
                            onChange={(dataa: any) => {
                                setQuestionAnswerOptions({...questionAnswerOptions, [ques]: dataa.toString()});
                                setValue(ques, dataa.toString());
                            }}
                        /></div>
                    }
                    rules={{
                        required: question.required === 'true' ? question.errorMsg : false
                    }}
                /></CardItem>
                     </>
        })
    }
            {!isFetching ? <Button style={{float: 'right', margin: '10px'}}
            onClick={() => onSubmit()} variant='primary'>
            Submit survey
        </Button> : null}
        </Card>
    </div></form>
        <LDModal title=' '
                 size='small'
                 onClose={() => setShowSuccessPopUp(false)}
                 style={{ 'width': '18vw !important', height: '40vh !important' }}
                 isOpen={showSuccessPopUp}
                 setIsOpen={setShowSuccessPopUp}>
           <div className='impact-button'>
               <img className='sidebar-logoa' height='100px' width='100px'
                 src={require('src/components/spacedonation/commonComponents/assets/logos/Walmart_Spark.png')} />
                 <div className='feedback-thankyou'>Thank you for you feedback!</div>
               <Button
                className='variant-button'
                onClick={() => { props.history.push({ pathname: `${contextPath}/space-donation/events/detail`, state: '' }); }} variant='primary'>
                Back to home
            </Button>
           </div>
        </LDModal>
    </>
}
