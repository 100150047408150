/* eslint-disable */
import { Link } from "@material-ui/core";
import { FormControl, FormLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Alert, Button,
  ButtonGroup, GridColumn, TextArea, TextField
} from "@walmart-web/livingdesign-components";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FileUploadComponent } from "../../../components/common/FileUploadComponent";
import CommonLDModal from "../../../components/common/CommonLDModal";
import {  contextPath } from "../../../constants";
import { getAppConfigTypes } from "../../../service/apiService";
import DatePickerComponent from "../../common/DatePickerComponent";
import { FacilitySearchComponent } from "../../common/FacilitySearchComponent";
import RadioButtonComponent from "../../common/RadioButtons";
import StoreContactDetails from "../CommonComponents/StoreContact";
import { createButtons, get2DaysAfterDate, LocationTypes, selectionChoices } from "../commonFunctions";
import {
  continueModal, discardModal, getCustomFacilityTypes, getServiceFacilityLabel, servicePageType
} from "./serviceDonationDataFunctions";
import {
  checkServiceDonationValidation, createServiceDonationPayload, errorAttributes,
  isRDALocationAndStoreMandatory, serviceDonationFormAttributes, serviceDonationFormUpdate
} from "./ServiceDonationFormValidation";
import "./styles.css";
import { LdSelectComponent } from "src/components/common/Dropdown/LdSelectComponent";
import moment from "moment";

export interface OwnProps {
  countryCode: any,
  selectedLang: any,
  isReadOnly?: any;
  isContinued?: any;
  history?: any;
  isLocationMandatory?: any;
  createDonationMutate?: any;
  formAttributes?: any;
  data?: any;
  editable?: any;
}

const ServiceDonationForm: FC<OwnProps> = ({
  countryCode,
  selectedLang,
  history,
  isReadOnly, // true only for View donation
  isLocationMandatory,
  createDonationMutate,
  data,
  editable
}) => {
  const serviceDonationMethods = useForm({
    mode: "all",
    defaultValues: {
    },
  });
  let donationData = serviceDonationFormAttributes();

  if (!editable) {
    donationData = {
      ...data?.donationDetails,
      eventEndDate: data?.eventEndDate,
      eventStartDate: data?.eventStartDate,
      errorMessage: errorAttributes,
      donationAmount: data?.donationDetails.donationAmount,
      serviceType: data?.serviceTypePersisted,
      donationTitle: data?.donationDetails.donationTitle,
      description: data?.serviceDescription,
      donationAttachments: data?.donationAttachments
    };
  }
  const { t } = useTranslation();
  const [donationForm, setDonationFrom] = useState<any>(donationData);
  const [serviceTypes, setServiceTypes] = useState<any>([]);

  const [fileAttachments, setFileAttachments] = useState<any>([]);

  const setDonationAttributes = (name: string, value: any, message?: any) => {
    let messages = { ...donationForm.errorMessage, [name]: message };
    let newAttributes = { ...donationForm, [name]: value, errorMessage: messages };
    setDonationFrom(newAttributes);
  }

  const [facilityType, setFacilityType] = useState<any>(LocationTypes.store);
  const [showStoreSearchDialog, setShowStoreSearchDialog] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<any>({})

  const handleFacilityChange = (faciltyDetails: any) => {
    setSelectedFacility({
      ...faciltyDetails,
      loading: true
    })
    setShowStoreSearchDialog(false);
  }

  const handleModalAction = (show: boolean) => {
    setShowStoreSearchDialog(show)
  }

  const updateUploadedFiles = (filesList: any) => {
    setFileAttachments(filesList);
  }
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<any>({});

  const cancelDonationCallback = () => {
    let createModalProps = discardModal(t, setIsPopupOpen, onFormDiscard);
    setModalProps(createModalProps);
    setIsPopupOpen(true);
  }

  const setLocationChoice = (dataValue: string) => {
    setDonationAttributes("locationKnown", dataValue);
  }
  // Submit for add donations flow
  const onFormSubmit = () => {
    let payload = createServiceDonationPayload(donationForm, selectedFacility, facilityType, fileAttachments);
    createDonationMutate.mutate(payload);
    setIsPopupOpen(false);
  };

  const continueDonationCallback = () => {
    let newAttributes = serviceDonationFormUpdate(donationForm, t, facilityType, isLocationMandatory, selectedFacility?.facilityNo);
    let isAnyAttributeHasError = checkServiceDonationValidation(newAttributes);
    if (isAnyAttributeHasError) {
      let modifiedAttributes = { ...donationForm, ...newAttributes };
      setDonationFrom(modifiedAttributes);
    } else {
      setIsPopupOpen(true);
      let submitModalProps = continueModal(t, setIsPopupOpen, onFormSubmit);
      setModalProps(submitModalProps);
    }
  }

  const initialStartDate = get2DaysAfterDate();
  const isReadOnlyClassName = isReadOnly ? `date-time-text-label-disabled` : `date-time-text-label`;

  const updateCalendarDate = (e: any, attributeName: string) => {
    if (attributeName === "eventStartDate") {
      let calendarChangedDate = new Date(e.value);
      setDonationFrom({ ...donationForm, "eventStartDate": e.value.toString(), "eventEndDate": calendarChangedDate.toString() });
    } else {
      setDonationFrom({ ...donationForm, "eventEndDate": e.value.toString() });
    }
  }

  const onFormDiscard = () => {
    setIsPopupOpen(false);
    history.push(`${contextPath}/dashboard/${servicePageType}?type=${servicePageType}`)
  };

  const isLocationAndStoreMandatory = () => {
    let isMandatory = isRDALocationAndStoreMandatory(isLocationMandatory, donationForm.locationKnown,
      facilityType, selectedFacility?.facilityNo);
    return isMandatory;
  };

  let serviceFormButtons = createButtons(cancelDonationCallback, continueDonationCallback,
    t("serviceDonations.form.cancel"), t("serviceDonations.form.continue"), "", "primary")

  useEffect(() => {
    setSelectedFacility({});
  }, [facilityType])

  useEffect(() => {
    getAppConfigTypes('serviceType').then((res) => {
      setServiceTypes(res);
    })
  }, [])


  const clearDonationFormEmailAttributes = () => {
    let messages = { 'primaryEmail': '', 'secondaryEmail': '', 'primaryEmailError': '', 'secondaryEmailError': '' };
    let newAttributes = { ...donationForm, ...messages };
    setDonationFrom(newAttributes);
  }

  return (
    <>
      <div className="service-donation-Form">
        <FormProvider {...serviceDonationMethods}>
          {/* <form onSubmit={() => { }} data-testid='service-form-external'> */}
          {isReadOnly ? "" :
            <> <GridColumn
              sm={12}
              key={"service-donation-location-selection-radio"}
              className={"service-donation-location-selection"}>
              <RadioButtonComponent setSelectedItem={setLocationChoice}
                title={t("serviceDonations.location.locationRadioTitle")}
                selectedItem={"No"} radioTypeInputs={selectionChoices(t)} isReadOnly={isReadOnly}
              />
            </GridColumn>
              <br />
              {!isReadOnly && donationForm.locationKnown === "Yes" && (
                <Alert variant='warning'>
                  {t("serviceDonations.location.warning1")} {" " + getServiceFacilityLabel(t, facilityType) + " "}{t("serviceDonations.location.warning2")}
                </Alert>
              )}
              {donationForm.locationKnown === "Yes" ?
                <>
                  <GridColumn
                    sm={12}
                    key={"store-ho-dc-homeoffice-selection"}
                    style={{ margin: "15px 0px 7px 0px" }}>
                    <RadioButtonComponent setSelectedItem={setFacilityType}
                      title={t("serviceDonations.location.fromWhere")}
                      selectedItem={facilityType}
                      radioTypeInputs={getCustomFacilityTypes(t)} facilityNumber={selectedFacility?.facilityNo}
                      callBack={clearDonationFormEmailAttributes} />
                  </GridColumn>
                  {facilityType && facilityType !== LocationTypes.other ?
                    <GridColumn
                      sm={12}
                      key={"store-ho-dc-homeoffice-selection-type"}
                      style={{ margin: "15px 0px" }}
                      className="locationSelectionButton">
                      <Link color="inherit" component="button" variant="inherit"
                        onClick={() => {
                          handleModalAction(true);
                        }}
                        data-testid={"selectedRadioElementType"} className={"selectionButton"}>
                        {t(`common.selectFacility.${facilityType}`)}{isLocationAndStoreMandatory() ? "*":""}

                      </Link>
                      {isLocationAndStoreMandatory() ? <FormHelperText className="helperText"> {donationForm.errorMessage.locationKnown}</FormHelperText> : ""}
                    </GridColumn> : " "}
                  {selectedFacility?.facilityNo &&
                    <>
                      <GridColumn sm={12}
                        style={{ margin: "10px 0px", padding: "0px 4px" }}>
                        <b>{t("serviceDonations.cards.facility")}</b>:{" "}{selectedFacility?.facilityNo}<br />
                        <b>{t("serviceDonations.cards.facilityType")}</b>:{" "}
                        {selectedFacility?.facilityType}
                        <br />
                        <b>{t("serviceDonations.cards.streetAddr")}</b>:{" "}{selectedFacility?.streetAddress}
                        <br />
                        <b>{t("serviceDonations.cards.city")}</b>:{" "}{selectedFacility?.city}
                        <br />
                        <b>{t("serviceDonations.cards.postalCode")}</b>:{" "}
                        {selectedFacility?.postalCode}
                        <br />
                        <b>{t("serviceDonations.cards.country")} </b>:{" "}
                        {selectedFacility?.province}
                      </GridColumn>
                      <StoreContactDetails
                        disabled={false}
                        readOnly={false}
                        contactDetails={donationForm}
                        setContactDetails={setDonationFrom}
                        isStoreSelected={facilityType.toLowerCase() === "store" && selectedFacility?.facilityNo}
                        languageObj={"serviceDonations"}
                      /></>
                  }
                </> : ""}
            </>}

          {facilityType === LocationTypes.other &&
            <>
              <GridColumn
                sm={12}
                key={"other-location-selection-type"}
                style={{ margin: "15px 0px", fontWeight: "800" }}>
                {t(`serviceDonations.form.otherLocationHeader`)}
              </GridColumn>

              <GridColumn
                sm={6}
                key={"other-location-addressLine1"}
                className={`OtherLocation-type SIZE_TWO`}>
                <TextField
                  data-testid={`serviceDonations-event-addressLine1`}
                  label={`${t(`serviceDonations.form.addressLine1`)} ${isReadOnly ? " " : " * "}`}
                  onChange={(event: any) => setDonationAttributes('addressLine1', event.target.value,
                    event.target.value ? "" : donationForm.errorMessage.addressLine1)}
                  size="large"
                  value={donationForm.addressLine1}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                />
                <FormHelperText className="helperText">{donationForm.errorMessage.addressLine1}</FormHelperText>
              </GridColumn>

              <GridColumn
                sm={3}
                key={"other-location-county"}
                className={`OtherLocation-type SIZE_ONE`}>
                <TextField
                  data-testid={`serviceDonations-event-county`}
                  label={`${t(`serviceDonations.form.county`)} ${isReadOnly ? " " : " * "}`}
                  onChange={(event: any) => setDonationAttributes('county', event.target.value,
                    event.target.value ? "" : donationForm.errorMessage.county)}
                  size="large"
                  value={donationForm.county}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                />
                <FormHelperText className="helperText">{donationForm.errorMessage.county}</FormHelperText>
              </GridColumn>

              <GridColumn
                sm={3}
                key={"other-location-postalCode"}
                className={`OtherLocation-type SIZE_ONE`}>
                <TextField
                  data-testid={`serviceDonations-event-postalCode`}
                  label={`${t(`serviceDonations.form.otherPostalCode`)} ${isReadOnly ? " " : " * "}`}
                  onChange={(event: any) => setDonationAttributes('postalCode', event.target.value,
                    event.target.value ? "" : donationForm.errorMessage.postalCode)}
                  size="large"
                  value={donationForm.postalCode}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                />
                <FormHelperText className="helperText">{donationForm.errorMessage.postalCode}</FormHelperText>
              </GridColumn>
            </>}

          <GridColumn
            sm={12}
            key={"donation-title-name"}
            style={{ margin: "15px 0px" }}>
            <TextField
              data-testid="service-donation-title-input"
              label={`${t("serviceDonations.form.donationTitle")} ${isReadOnly ? " " : " * "}`}
              onChange={(event: any) => setDonationAttributes("donationTitle", event.target.value,
                event.target.value ? "" : t("serviceDonations.form.error.donationTitle"))}
              size="large"
              value={donationForm.donationTitle}
              className={`${donationForm.errorMessage.donationTitle ? "service-donation-input-required" : ""}`}
              disabled={isReadOnly}
              readOnly={isReadOnly}
            />
            <FormHelperText className="helperText">{donationForm.errorMessage.donationTitle}</FormHelperText>
          </GridColumn>
          <GridColumn
            sm={6}
            key={"service-donationAmount"}
            style={{ margin: "15px 5px" }}
            className="SIZE_TWO">
            <TextField
              data-testid="service-donation-donationAmount-input"
              label={`${t("serviceDonations.form.donationAmount")} ${isReadOnly ? " " : " * "}`}
              onChange={(event: any) => setDonationAttributes('donationAmount', Number(event.target.value),  ``)}
              size="large"
              value={donationForm.donationAmount}
              disabled={isReadOnly}
              readOnly={isReadOnly}
              className={`${donationForm.errorMessage.donationAmount ? "service-donation-input-required" : ""}`}
              type="number"
            />
            <FormHelperText className="helperText">{donationForm.errorMessage.donationAmount}</FormHelperText>
          </GridColumn>
          <GridColumn
            sm={6}
            key={"service-serviceType"}
            style={{ margin: "15px 5px" }}
            className="SIZE_TWO">

              <LdSelectComponent
                  id={'service-type'}
                  testId={'service-type'}
                  selectedLang={selectedLang}
                  disabled={isReadOnly}
                  label={`${t("serviceDonations.form.serviceType")} ${isReadOnly ? " " : " * "}`}
                  onChange={(event: any) => setDonationAttributes("serviceType", event,
                  event ? "" : donationForm.errorMessage.serviceType)}
                  defaultValue={donationForm.serviceType ?? false}
                  size='large'
                  options={serviceTypes ?? []}
                  error={
                    donationForm.errorMessage.serviceType
                  }
                  valueKey='keyText'
                  labelKey='language'
                  placeholderTxt={t('serviceDonations.table.filtersPlaceHolderTxt.serviceType')}
                  data-testid='service-donation-serviceType-input'
                />
            
          </GridColumn>
          <GridColumn
            sm={12}
            key={"service-donation-description"}
            style={{ margin: "15px 0px" }}>
            <TextArea
              data-testid="service-donation-description-textarea"
              label={`${t("serviceDonations.form.description")} ${isReadOnly ? " " : " * "}`}
              onChange={(event: any) => setDonationAttributes("description", event.target.value,
                event.target.value ? "" : t("serviceDonations.form.error.description"))}
              size="large"
              value={donationForm.description}
              disabled={isReadOnly}
              readOnly={isReadOnly}
              className={`${donationForm.errorMessage.description ? "service-input-required" : ""}`}
            />
            <FormHelperText className="helperText">{donationForm.errorMessage.description}</FormHelperText>
          </GridColumn>
          <GridColumn
            sm={6}
            key={"eventStartDateFields"}
            className={"custom-margin"}>
            <FormControl className="service-form ">
              <FormLabel className={isReadOnlyClassName}>{t("serviceDonations.form.startDate")} {isReadOnly ? "" : <span> * </span>}</FormLabel>
              <DatePickerComponent dateFilter={isReadOnly? moment(donationForm.eventStartDate, "YYYY-MM-DD").toDate(): new Date(donationForm.eventStartDate)}
                updateCalendarDate={updateCalendarDate}
                attributeName="eventStartDate" errorMessage={donationForm.errorMessage.eventStartDate}
                minDate={new Date(initialStartDate)} isDisabled={isReadOnly} />
            </FormControl>
          </GridColumn>
          <GridColumn
            sm={6}
            key={"evenEndDateField"}
            className={'custom-margin'}>
            <FormControl className="service-form ">
              <FormLabel className={isReadOnlyClassName}>{t("serviceDonations.form.endDate")} {isReadOnly ? "" : <span> * </span>}</FormLabel>
              <DatePickerComponent dateFilter={isReadOnly? moment(donationForm.eventEndDate, "YYYY-MM-DD").toDate(): new Date(donationForm.eventEndDate)}
                updateCalendarDate={updateCalendarDate}
                attributeName="eventEndDate" errorMessage={donationForm.errorMessage.eventEndDate}
                minDate={new Date(donationForm.eventEndDate)} allowEndDate={new Date(donationForm.eventStartDate)}
                isDisabled={isReadOnly} />
            </FormControl>
          </GridColumn>
          <GridColumn sm={12} style={{ margin: "5px 0px", marginBottom: "20px" }}>
              <FileUploadComponent
                required={false}
                heading={t("serviceDonations.form.supportDocument")}
                name={`donationAttachments`}
                className={`donationAttachments`}
                defaultFiles={donationForm.donationAttachments?.length > 0 ? donationForm.donationAttachments : []}
                disabled={isReadOnly}
                readOnly={isReadOnly}
                allowMultiple={true}
                showFileUpload={!isReadOnly}
                showEmptyFileMessage={false}
                callBack={updateUploadedFiles}
              />
            </GridColumn>
          {isReadOnly ? "" :
            <GridColumn sm={12} key={"service-donation-submit-events"}
              style={{ margin: "15px 0px" }}>
              <ButtonGroup style={{ justifyContent: "space-between" }}>
                {serviceFormButtons.map((button: any) => {
                  return (
                    <Button
                      variant={button.variant}
                      data-testid={button.buttoinId}
                      onClick={() => button.callback()}
                      leading={''}
                    >
                      {button.text}
                    </Button>
                  )
                })}
              </ButtonGroup>
            </GridColumn>}

          {showStoreSearchDialog && <FacilitySearchComponent
            countryCode={countryCode}
            selectedLang={selectedLang}
            onFaciltySelection={handleFacilityChange}
            handleModalAction={handleModalAction}
            showModal={showStoreSearchDialog}
            fetchType={facilityType.toLowerCase()}
          />}
          {/* </form> */}
        </FormProvider>
      </div>
      <CommonLDModal setOpen={setIsPopupOpen} isOpen={isPopupOpen}
        modalConfig={modalProps} ></CommonLDModal>
    </>

  );
};

export default ServiceDonationForm;