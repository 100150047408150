const ScrollToElementComponent = (element: string, alignment = 'end') => {
    const container = document.querySelector(`.${element}`);
    // @ts-ignore
    container?.scrollIntoView();
    // @ts-ignore
    container?.scrollIntoView(true);
    // @ts-ignore
    container?.scrollIntoView({ block: alignment });
    // @ts-ignore
    container?.scrollIntoView({ behavior: 'smooth', block: alignment, inline: 'nearest' });
}

export default ScrollToElementComponent