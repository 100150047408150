import {Grid, InputLabel, makeStyles, Typography} from '@material-ui/core';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import InformationBar from '../common/informationBar';
import {InputTextField} from '../../../common/Input/InputTextField';
import TextFieldMaxSizeIndicator from '../common/TextFieldMaxSizeIndicator';
import {BaseSectionProps} from '../organizationDetails/orgDetailsDefaults';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    multilineInput: {
        height: '60px'
    },
    title: {
        fontWeight: 'bold'
    },
    outlinedError: {
        color: 'black',
        border: '1px solid black',
    },
    icon: {
        color: 'black !important',
    },
    alertRoot: {
        'padding': '0 16px',
        borderLeft: '4px solid black'
    }
})

export interface ProjectExecutionProps extends BaseSectionProps {
    data: any
}

export const ProjectExecution: FC<ProjectExecutionProps> = (props: ProjectExecutionProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const getTranslation = (key: string) => t(`monetaryDonation.projectDetailsSection.${key}`);
    const getSnackbar = (translationKey: string) => {
        return (<InformationBar>
            {getTranslation(translationKey)}</InformationBar>);
    }
    return (<div className={classes.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                    <Typography classes={{root: classes.title}}>{getTranslation('executionDetails')}</Typography>
                </Grid>
                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12}>
                        <Grid item={true} xs={12} md={4}>
                            <InputLabel id={'organizationEnsureTransparencyLabel'}>
                                {getTranslation('howOrganizationEnsureTransparency')}
                            </InputLabel>
                        </Grid>
                        <Grid container={true} item={true} spacing={3}>
                            <Grid item={true} xs={12} md={4}>
                                <InputTextField
                                    required={true}
                                    fieldName={`${props.fieldName}.transparencyDetails`}
                                    errorKeyName={`${props.fieldName}.transparencyDetails`}
                                    id={'transparencyDetails'}
                                    InputProps={{readOnly: false}}
                                    fullWidth={true}
                                    rows={4}
                                    multiline={true}
                                    defaultValue={props?.data?.transparencyDetails}
                                />
                                <TextFieldMaxSizeIndicator
                                    fieldName = {`${props.fieldName}.transparencyDetails`}
                                    maxSize = {1000}/>
                            </Grid>
                            <Grid item={true} xs={12} md={5}>
                                {getSnackbar('howOrganizationEnsureTransparencyDesc')}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12}>
                        <Grid item={true} xs={12} md={4}>
                            <InputLabel id={'methodProceduresLabel'}>
                                {getTranslation('explainMethodProcedures')}
                            </InputLabel>
                        </Grid>
                        <Grid container={true} item={true} spacing={3}>
                            <Grid item={true} xs={12} md={4}>
                                <InputTextField
                                    required={true}
                                    fieldName={`${props.fieldName}.methodsAndPurpose`}
                                    errorKeyName={`${props.fieldName}.methodsAndPurpose`}
                                    id={'methodsAndPurpose'}
                                    InputProps={{readOnly: false}}
                                    fullWidth={true}
                                    rows={4}
                                    multiline={true}
                                    defaultValue={props?.data?.methodsAndPurpose}
                                />
                                <TextFieldMaxSizeIndicator
                                    fieldName = {`${props.fieldName}.methodsAndPurpose`}
                                    maxSize = {1000}/>
                            </Grid>
                            <Grid item={true} xs={12} md={5}>
                                {getSnackbar('explainMethodProceduresDesc')}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={3}>
                    <Grid item={true} xs={12}>
                        <Grid item={true} xs={12} md={4}>
                            <InputLabel id={'riskAndLimitationsLabel'}>
                                {getTranslation('describePotentialRisks')}
                            </InputLabel>
                        </Grid>
                        <Grid container={true} item={true} spacing={3}>
                            <Grid item={true} xs={12} md={4}>
                                <InputTextField
                                    required={true}
                                    fieldName={`${props.fieldName}.riskAndLimitations`}
                                    errorKeyName={`${props.fieldName}.riskAndLimitations`}
                                    id={'riskAndLimitations'}
                                    InputProps={{readOnly: false}}
                                    fullWidth={true}
                                    rows={4}
                                    multiline={true}
                                    defaultValue={props?.data?.riskAndLimitations}
                                />
                                <TextFieldMaxSizeIndicator
                                    fieldName = {`${props.fieldName}.riskAndLimitations`}
                                    maxSize = {1000}/>
                            </Grid>
                            <Grid item={true} xs={12} md={5}>
                                {getSnackbar('describePotentialRisksDesc')}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}