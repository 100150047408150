import { FC } from 'react';
import { FormLabel, MenuItem, FormControl, Select } from '@material-ui/core';
import { useStyles } from './DropDownStyles';
import { Controller } from 'react-hook-form';

export interface DropDownProps {
  id?: string,
  className?: string,
  fieldName: string,
  required?: boolean,
  disabled?: boolean,
  classes?: any,
  control: any,
  formErrors: any,
  rules?: any,
  inputLabel: string,
  defaultValue?: string,
  errorMsg?: string,
  dropDownData: Array<{
    value: any,
    label: string,
  }>,
}

const defaultProps: DropDownProps = {
  fieldName: '',
  control: {},
  formErrors: {},
  inputLabel: '',
  dropDownData: [{ value: '', label: '' }],
}

const DropDown: FC<DropDownProps> = (props: any) => {
  const defaultClasses = useStyles();

  /* istanbul ignore next */
  const dropDownChildren = props?.dropDownData && props.dropDownData.length > 0 &&
    props.dropDownData.map((listItem: any, index: number) => (
      <MenuItem
        key={`dropDownChild-${index}`}
        value={listItem?.label}
      >{listItem?.label}</MenuItem>
    ));

  return (
    <div id={props.id} className={props.className}>
      <FormControl
        required={props.required}
        classes={{}}
        fullWidth={true}
        error={props.errorMsg ? props.errorMsg : props.formErrors?.[props?.fieldName]?.message}
      >
        <FormLabel
          classes={{ root: defaultClasses.selectRootLabel }}
          id='dropdown-outlined-label'>
          {props.inputLabel}
        </FormLabel>
        <Controller
          control={props.control}
          name={props.fieldName}
          defaultValue={props?.defaultValue}
          render={(field, _state) =>
            <Select
              id={props.fieldName}
              labelId='select-outlined-label'
              autoWidth={false}
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
              inputRef={field.ref}
              name={field.name}
              defaultValue={props?.defaultValue}
              displayEmpty={true}
              disabled={props.disabled ? props.disabled : false}
              classes={{
                root: defaultClasses.selectRoot,
                icon: defaultClasses.selectIcon,
                disabled: defaultClasses.selectDisabled
              }}
              variant='outlined'
            >
              {dropDownChildren}
            </Select>
          }
          rules={{ ...props.rules }}
        />
        {(props.errorMsg || props.formErrors?.[props.fieldName]?.message) &&
          <span className='reg-form-error-container' style={{ color: 'red' }}>
            {props.errorMsg ? props.errorMsg : props.formErrors?.[props.fieldName]?.message}
          </span>
        }
      </FormControl>
    </div>
  );
};
DropDown.defaultProps = defaultProps;
export default DropDown;