import { getCookieValue } from 'dynamic-form-components/lib/service';
import { i18n as i18nI } from 'i18next';
import { useEffect } from 'react';
import { getFacilityLabel } from '../donations/commonFunctions';
import { spacePageType } from '../donations/gdmsSpaceDonation/SpaceInternationalDataFunctions';
import { getServiceFacilityLabel, servicePageType } from '../donations/serviceDonation/serviceDonationDataFunctions';

import {
    contextPath, DONATION_TYPE,
    DonationStatus, FALLBACK_LNG,
    socialImpactTranslations,
    priceAdjustValueByCountry,
    countryLocaleAndCurrency,
    DONATION_TYPE_VALUES
} from './../../constants';
import { IDropdownOptions } from './Dropdown/keyValueDropdown';


const donationApprovalActionsJson = require(`./../donations/donationApprovalActions.json`);
export const HOME_OFFICE = 'EXTERNAL';
export const EXTERNAL = 'EXTERNAL';
const moment = require('moment');
const ngoDonationFieldEnabledStatuses: string[] = ['WAITING_FOR_INFORMATION_FROM_ORGANIZATION', 'WAITING_FOR_APPENDIX_B']
const months = [...socialImpactTranslations.socialImpactMonths]

// const commonDonationFieldDisabledStatus: string[] = ['REJECTED', 'CANCELLED']

export class Validation {
    public static isEmailAddress = (value: string, errorMessage: string) => {
        const pattern = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) ? true : errorMessage || 'Please enter a valid email id';
    }
    public static isNotEmpty = (value: string, errorMessage: string) => {
        const pattern = /\S+/;
        return pattern.test(value) ? true : errorMessage || 'Field should not be empty';
    }
    public static isNumber = (value: string, errorMessage: string) => {
        const pattern = /^\d+$/;
        return pattern.test(value) ? true : errorMessage || 'Please enter a valid number';
    }
    public static isSame = (value1: string, value2: string, errorMessage: string) => {
        return value1 === value2 ? true : errorMessage || 'values are not matching';
    }
    public static isValidBirthYear = (value: any, errorMessage: string) => {
        const pattern = /^\d+$/;
        return pattern.test(value) && (value >= 1900 && value <= 3000) ? true : errorMessage || 'Please enter a valid year of birth';
    }
    public static validateYearOfBirth = (value: any, errorMessage: string) => {
        const format = /^\d{4}$/;
        const currentYear = new Date().getFullYear();
        const pastYear = currentYear - 130;
        return ((!format.test(value)) || (value > currentYear) || (value < pastYear)) ?
            errorMessage || 'Please enter a valid year of birth' : true;
    }
}

export const changeLang = (isNative: boolean, language: string, i18nObj: i18nI) => {
    if (isNative) {
        i18nObj.changeLanguage(language);
    } else if (i18nObj.language !== FALLBACK_LNG) {
        i18nObj.changeLanguage(FALLBACK_LNG);
    }
}

export function stringFormat(format: string, ..._rest: any) {
    const args = Array.prototype.slice.call(arguments, 1);
    // tslint:disable-next-line: only-arrow-functions
    return format.replace(/{(\d+)}/g, function (match: any, num: number) {
        return typeof args[num] !== 'undefined'
            ? args[num]
            : match
    });
}

const tester = /^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export const validateEmail = (email: string) => {
    if (!email) {
        return false;
    }
    const emailParts = email.split('@');
    if (emailParts.length !== 2) {
        return false
    }
    const account = emailParts[0];
    const address = emailParts[1];
    if (account.length > 64) {
        return false
    } else if (address.length > 255) {
        return false
    }
    const domainParts = address.split('.');
    if (domainParts.some((part) => {
        return part.length > 63;
    })) {
        return false
    }
    if (!tester.test(email)) {
        return false;
    }
    return true;
};

export const currencyFormat = (num: number, currencySymbol: string | undefined) => {
    return `${currencySymbol || '$'}${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

export const getUserRoles = () => {
    return getCookieValue('userRole') ? getCookieValue('userRole').replace(/"/g, '').split('/') : [];
}

export const oneTimeGoodsHeadingHome = 'oneTimeGoodsDonation.nav.home'
export const oneTimeGoodsLink = 'oneTimeGoodsDonation.oneTimeGoodsDonation'

export const dashboardPageBreadcrumbs = [
    {
        name: oneTimeGoodsHeadingHome,
        path: '/',
    },
    {
        name: oneTimeGoodsLink,
        active: true,
        applyFormat: false
    }
];

export const convertFromUtcToLocal = (dateValue: any) => {
    if (dateValue) {
        const utc = (new Date(dateValue)).toUTCString();
        const newDate = moment().utc(true)
            .set('year', new Date(utc).getUTCFullYear())
            .set('month', (new Date(utc).getUTCMonth()))
            .set('date', (new Date(utc).getUTCDate()));
        return newDate.format('DD-MM-YYYY');
    } else {
        return dateValue;
    }
}
export const getMonetaryDonationBreadCrumbs: any = (donationId: string, path: string) => {
    const monetaryDonationsHome = 'monetaryDonation.nav.home';
    const donationMoreDetails = `monetaryDonation.nav.${path === DONATION_TYPE.MONETARY ? 'moreDetails' : 'goodsAndFood'}`;

    const paths: any = [
        {
            name: monetaryDonationsHome,
            path: '/',
        },
        {
            name: donationMoreDetails,
            path: `${contextPath}/dashboard/${path}?type=${path}`
        }
    ]

    paths.push({
        name: donationId,
        active: false,
        applyFormat: false,
        path: `${contextPath}/donation/view/${path}?type=${path}`
    });

    paths.push({
        name: 'monetaryDonation.nav.appendixb',
        path: `${contextPath}/donation/view/${path}?type=${path}`
    });

    paths.push({
        name: `monetaryDonation.nav.addInfo`,
        active: true,
        applyFormat: false
    });


    return paths;
}

export const orgSection = 'ORG'
export const employeeDonationSection = 'Employee'
export const recipientDonation = 'Recipient';

export const isAppendixBRequired = (dueDiligenceStatus: string) => {
    return dueDiligenceStatus === 'DNB_TRACKING_STATUS_COMPLETED' ? true : false
}

export const setAppendixBDonationFields = (setValueFun: (name: string, value: any, config?: any) => void, data: any) => {

    setValueFun('donationDetail.donationTitle', data?.donationTitle ?? '');
    setValueFun('donationDetail.countries', data?.countries ?? '');
    setValueFun('donationDetail.amount', data?.amount ?? '');
    setValueFun('donationDetail.description', data?.description ?? '');
    setValueFun('donationDetail.purpose', data?.purpose ?? '');
    setValueFun('donationDetail.govtSuggestion', data?.govtSuggestion ?? '');
    setValueFun('donationDetail.govtSuggestionDetails', data?.govtSuggestionDetails ?? '');
    setValueFun('donationDetail.isRecurring', data?.isRecurring);
    setValueFun('donationDetail.recurringDonationDetail', data?.recurringDonationDetail ?? '');
}

export const setAppendixBOrgFields = (setValueFun: (name: string, value: any, config?: any) => void, data: any) => {
    setValueFun('organization.orgLegalName', data?.organization?.orgLegalName ?? '');
    setValueFun('organization.taxId', data?.organization?.taxId ?? '');
    setValueFun('organization.address.addressLine1', data?.organization?.address?.addressLine1 ?? '');
    setValueFun('organization.address.province', data?.organization?.address?.province ?? '');
    setValueFun('organization.address.city', data?.organization?.address?.city ?? '');
    setValueFun('organization.address.postalCode', data?.organization?.address?.postalCode ?? '');
    setValueFun('organization.missionAndPurpose', data?.organization?.missionAndPurpose ?? '');
    setValueFun('organization.geographicCoverage', data?.organization?.geographicCoverage ?? '');
    setValueFun('organization.website', data?.organization?.website ?? '');
    setValueFun('organization.establishedDate', data?.organization?.establishedDate ?? '');
    setValueFun('organization.country', data?.organization?.country ?? '');
    setValueFun('organization.establishedDate', data?.organization?.establishedDate ?
        convertFromUtcToLocal(data?.organization?.establishedDate) : '');
    setValueFun('organization.telephone', data?.organization?.telephone ?? '');
}

export const setGEAppendixBOrgFields = (setValueFun: (name: string, value: any, config?: any) => void, data: any) => {
    setValueFun('organization.orgLegalName', data?.organization?.orgLegalName ?? '');
    setValueFun('organization.telephone', data?.organization?.telephone ?? '');
    setValueFun('organization.address.addressLine1', data?.organization?.address?.addressLine1 ?? '');
    setValueFun('organization.address.postalCode', data?.organization?.address?.postalCode ?? '');
    setValueFun('organization.country', data?.organization?.country ?? '');
    setValueFun('organization.address.province', data?.organization?.address?.province ?? '');
    setValueFun('organization.address.city', data?.organization?.address?.city ?? '');
}

export const validateOrgEstablishedDate = (value: any, t: any) => {
    let isValid = moment(value, 'DD-MM-YYYY', true).isValid()
    if (!isValid) {
        return t('orgRegForm.validationMsg.date')
    }
    isValid = moment(value, 'DD-MM-YYYY') < moment()
    if (!isValid) {
        return t('orgRegForm.validationMsg.establishedDate')
    }
    return isValid
}

export const isValidYearOfBirth = (value: any, t: any) => {
    return Validation.validateYearOfBirth(value, t('orgRegForm.validationMsg.yearOfbirth'))
};

export const validateFileInput = (files: any, size: number, formats: string[], options: any) => {

    let isValid = true
    const uploadedFiles = [...files];
    const reducer = (previousValue: number, currentValue: number) => previousValue + currentValue;
    const aggregateSize = uploadedFiles?.reduce(reducer, 0)

    if (aggregateSize > size) {
        isValid = options.fileSizeErrorMsg
    }

    uploadedFiles?.every((file: File) => {
        if (!formats.includes(file.type)) {
            isValid = options.fileFormatErrorMsg
            return false
        }
        return true;
    });
    const singleFile = files && files[0] ? files[0] : {}
    return {
        isValid,
        fileData: singleFile,
        fileList: files
    };
}

export const isFunction = (variable: (data?: any) => void) => {
    return typeof variable === 'function';
}

export const getFileExtension = (file: string) => file?.slice(file?.lastIndexOf('.'));

export const getFileNameWithoutExt = (file: string) => file?.substr(0, file?.lastIndexOf('.'))

export const truncateStr = (str: string, len: number, useWordBoundary: boolean) => {
    if (str?.length <= len) {
        return str
    }
    const subString = str?.substr(0, len - 1)
    return `${(useWordBoundary
        ? subString?.substr(0, subString?.lastIndexOf(' '))
        : subString)}...`;
}

export const getShorterFilename = (fileName: string, len: number) => {
    return `${truncateStr(getFileNameWithoutExt(fileName), len, false)}${getFileExtension(fileName)}`
}

export const getSelectedLanguage = (i18Language: string) => {
    return i18Language?.split('_')?.[0]
}

export const getTransformedReviewActions = (formProperties: any) => {
    const reviewActions: any = [];
    formProperties?.forEach((action: any) => {
        if (action) {
            reviewActions.push(donationApprovalActionsJson[action]);
        }
    });
    return reviewActions
}

export const UPLOAD_FILE_SIZE = 10485760;
export const SUPPORTED_FORMATS = [
    'application/pdf',
    'application/doc',
    'application/docx',
    'application/txt',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain'
]

export const isReviewSectionEnabled = (donationStatus: string) => {
    return ngoDonationFieldEnabledStatuses.includes(donationStatus)
}

export const getFieldDisabledAttributes = (donationStatus: string) => {
    return !ngoDonationFieldEnabledStatuses.includes(donationStatus)
}

export const isValidateFacilityNo = (value: any) => {
    if (value && !/^[0-9]+$/.test(value)) {
        return false;
    }
    return true;
}

export const objectHasValue = (name: string, data: any) => {
    return !!resolvePath(name, data, false);
}

export const resolvePath = (path: string, obj: any, defaultValue: any = null) => {
    return path.split('.').reduce((prev: any, curr: any) => {
        return prev ? prev[curr] || defaultValue : defaultValue;
    }, obj || {})
}


export const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
}

export const getEmptyFileList = () => {
    return new DataTransfer()?.files
}

export const isRequestForInformation = (status: string) => {
    if (status === DonationStatus.RequestForMoreInformation) {
        return true;
    }
    return false;
}


export const isDraft = (status: string) => {

    if (status === DonationStatus.Draft) {
        return true;
    }
    return false;

}

export const isAwaitingForPaymentAcknowledgment = (status: string) => {

    if (status === DonationStatus.AwaitingPaymentAcknowledgment) {
        return true;
    }
    return false;

}

export const isSavedAppendixC = (status: string) => {

    if (status === DonationStatus.SavedAppendixC) {
        return true;
    }
    return false;

}


export const validateFileFormat = (files: any, allowrdFormats: string[]) => {
    const result = {
        error: '',
        isValid: true
    }
    files?.forEach((file: File) => {
        if (!allowrdFormats?.includes(file?.type)) {
            result.error = 'common.errorMesg.fileupload.allowrdFormat'
            result.isValid = false
        }
    });
    return result;
}

export const validateFileSize = (files: any, allowrdFileSize: number) => {
    const result = {
        error: '',
        isValid: true
    }
    files?.forEach((file: File) => {
        if (file?.size > allowrdFileSize) {
            result.error = 'common.errorMesg.fileupload.fileSize'
            result.isValid = false
        }
    });
    return result;
}

export const MAX_ALLOWED_FILES_SIZE = 10485760 * 5; // aggregate of all files size 50mb

export const validateFileSizeAndFormat = (files: any, validationOptions: any) => {

    const uploadedFiles = [...files];
    let res;
    res = validateFileFormat(files, validationOptions?.supportedFormats)
    if (!res.isValid) {
        return res
    }

    res = validateFileSize(files, validationOptions?.fileSize)
    if (!res.isValid) {
        return res
    }
    const reducer = (previousValue: number, current: any) => previousValue + current?.size;
    const aggregateSize = uploadedFiles?.reduce(reducer, 0)
    if (aggregateSize > validationOptions?.maxAllowedFilesSize) {
        res.error = 'common.errorMesg.fileupload.maxAllowedFilesSize'
        res.isValid = false
    }
    return res;
}
export const isUnderCAReview = (status: string) => {

    if (status === DonationStatus.UnderCAReview) {
        return true;
    }
    return false;

}


export const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8); // increase the number for smooth scrolling.
    }
};
export const getYearValues = (minValue: number) => {
    const years: IDropdownOptions[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i >= minValue; i--) {
        years.push({ label: i.toString(), value: i.toString() });
    }
    return years
}


export const getMonthValues = (language: string) => {
    const monthList: IDropdownOptions[] = [];
    months.map((month: any) => {
        return monthList.push({ label: month.label[language], value: month.value });
    })
    return monthList
}

export const downloadLocalFile = (file: File) => {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.target = '_blank'
    link.download = `${file?.name}`;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}

const organizationDonationStatus = ['WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT', 'WAITING_FOR_NGO_ACKNOWLEDGEMENT']
export const isAckUploadEnabled = (donationStatus: string) => {
    return organizationDonationStatus.includes(donationStatus)
}

export const getUploadFileForm = (file: any, submissionData: any) => {
    const formData = new FormData();
    formData.append('bulkUploadFile', file);
    const queryString = {
        year: submissionData?.year ? submissionData.year : '',
        month: submissionData?.month ? submissionData.month : ''
    }
    formData.append('report', JSON.stringify(queryString));
    return formData
}

export const getUploadFileFormForCreate = (file: any, year: any, month: any) => {
    const formData = new FormData();
    formData.append('bulkUploadFile', file);
    const queryString = {
        year: year ? year : '',
        month: month ? month.value as string : ''
    }
    formData.append('report', JSON.stringify(queryString));
    return formData
}

export const getPriceAdjustValue = (countryCode: string) => {
    return priceAdjustValueByCountry[countryCode] || 1
}

export const numberFormat = (value: number, countryCode: string) => {
    const { locale, currency } = countryLocaleAndCurrency[countryCode] || {
        locale: 'en-US',
        currency: 'USD'
    }
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
    }).format(value);
}

export enum OrganizationTypes {
    NGO = 'org_type_ngo',
    GE = 'org_type_governmental',
    INDIVIDUAL = 'org_type_individual'
}

// remove individual org type from the list for the registartion page
export const getFilteredOrgType = (orgTypeList: any) => {
    return orgTypeList.filter((orgType: any) => orgType.keyText !== OrganizationTypes.INDIVIDUAL)
}

export const getFilter = (key: string, value: any, matchType: string, endValue: any) => {
    return {
        'key': key,
        'value': value,
        'matchType': matchType,
        'endValue': endValue
    }
}

export const getTransformedValueForRadioBox = (param: any) => {
    return (param === true || param === 'true') ? true : (param === false || param === 'false') ? false : ''
};

/* istanbul ignore next */
export const scrollToElement = (eleClass: string) => {
    const container = document.querySelector(`.${eleClass}`);
    container?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}
/* istanbul ignore next */
export const useScrollToError = (errors: any, errorEleClass: string) => {
    useEffect(() => {
        if (Object.keys(errors)?.length > 0) {
            const container = document.querySelector(`.${errorEleClass}`);
            if (container && container.scrollIntoView) {
                container.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [errorEleClass, errors])
}

export const showRGAppendixCBtnDonationStatus: string[] = [
    'AUTHORIZED',
    'COMPLETED',
    'WAITING_FOR_NGO_ACKNOWLEDGEMENT',
    'WAITING_FOR_REVIEW_FROM_CA',
    'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'

]
export const showRGAppendixCDefaultValuesDonationStatus: string[] = [
    'AUTHORIZED',
    'COMPLETED',
    'WAITING_FOR_NGO_ACKNOWLEDGEMENT',
    'WAITING_FOR_REVIEW_FROM_CA',
    'WAITING_FOR_INFORMATION_FROM_ORGANIZATION'
]

export const showAppendixCForm: string[] = [
    'WAITING_FOR_NGO_ACKNOWLEDGEMENT',
    'WAITING_FOR_INFORMATION_FROM_ORGANIZATION',
    'WAITING_FOR_REVIEW_FROM_CA'
]
const recipientSectionEnabledStatus: string[] = ['WAITING_FOR_NGO_ACKNOWLEDGEMENT']
// Appenix c recipient section enabled
export const isRecipientSectionFielDisabled = (currentStatusCode: string) => {
    return !recipientSectionEnabledStatus.includes(currentStatusCode)
}


export const getLocationNumber = (rowData: any) => {
    switch (rowData.locationType) {

        case "DC":
            return `DC ${rowData.dcNumber ? "#" + rowData.dcNumber : ""}`
        case "STORE":
            return `STORE ${rowData.storeNumber ? "#" + rowData.storeNumber : ""}`
        case "HOME_OFFICE":
            return `HOME_OFFICE ${rowData.storeNumber ? "#" + rowData.storeNumber : ""}`
        default:
            return "NA"
    }
}

export const validateWalmartEmail = (email: string) => {
    return email.endsWith('@walmart.com')
}

export const validatePrimaryAndSecondaryEmail = (donationData: any, errorMessage1: string, errorMessage2: string, updateCallBack: any) => {
    let formValidation = false;
    if (donationData.locationKnown === "Yes") {
        let primaryEmailErrorMessage = donationData.primaryEmail.length === 0 ? errorMessage1 :
            (!validateWalmartEmail(donationData.primaryEmail) ? errorMessage2 : "");
        if (primaryEmailErrorMessage) {
            formValidation = true;
            updateCallBack("primaryEmailError", primaryEmailErrorMessage);
        }

        if (donationData.secondaryEmail.length > 0 &&
            !validateWalmartEmail(donationData.secondaryEmail)) {
            formValidation = true;
            updateCallBack("secondaryEmailError", errorMessage2);
        }
    }

    return formValidation;
}

export const getOrganizationObject = (orgProfile: any, langDonation: any) => {
    return [
        {
            name: `${langDonation}.form.name`,
            sm: 6,
            field: orgProfile?.orgLegalName ?? ``,
        },
        {
            name: `${langDonation}.form.taxid`,
            sm: 6,
            field: orgProfile?.taxId ?? `-`,
        },
        {
            name: `${langDonation}.form.street`,
            sm: 6,
            field: orgProfile?.address?.addressLine1 ?? `-`,
        },

        {
            name: `${langDonation}.form.city`,
            sm: 6,
            field: orgProfile?.address?.city ?? `-`,
        },

        {
            name: `${langDonation}.form.province`,
            sm: 6,
            field: orgProfile?.address?.province ?? `-`,
        },

        {
            name: `${langDonation}.form.country`,
            sm: 6,
            field: orgProfile?.country ?? orgProfile?.address?.country ?? `-`,
        },
        {
            name: `${langDonation}.form.postalCode`,
            sm: 6,
            field: orgProfile?.address?.postalCode ?? `-`,
        },
        {
            name: `${langDonation}.form.website`,
            sm: 6,
            field: orgProfile?.website ?? `-`,
        },
        {
            name: `${langDonation}.form.primaryName`,
            sm: 6,
            field: orgProfile?.primaryOrgUser?.userName ?? `-`,
        },
        {
            name: `${langDonation}.form.primaryTitle`,
            sm: 6,
            field: orgProfile?.primaryOrgUser?.userTitle ?? `-`,
        },
        {
            name: `${langDonation}.form.telephone`,
            sm: 6,
            field: orgProfile?.telephone ?? `-`,
        },
        {
            name: `${langDonation}.form.userEmail`,
            sm: 6,
            field: orgProfile?.primaryOrgUser?.userEmail ?? `-`,
        },
    ];
}

export const getThankyouRoute = (category: any) => {
    if (category === DONATION_TYPE_VALUES.SPACE_INTERNATIONAL)
        return `${contextPath}/thank-you/${spacePageType}?type=${spacePageType}`;
        if (category === DONATION_TYPE_VALUES.SERVICE_DONATION)
        return `${contextPath}/thank-you/${servicePageType}?type=${servicePageType}`;

    return "";
}

export const getLangObject = (category: any) => {
    switch(category) {
        case DONATION_TYPE_VALUES.SPACE_INTERNATIONAL:
            return "spaceDonations";
            break;
        case DONATION_TYPE_VALUES.SERVICE_DONATION:
            return "serviceDonations";
            break;
        default:
            return "defaultDonations";
            break;
    }
}

export const getFaciltyValue = (category: any, t : any, locationType: string) => {
    switch(category) {
        case DONATION_TYPE_VALUES.SPACE_INTERNATIONAL:
            return getFacilityLabel(t, locationType);
            break;
        case DONATION_TYPE_VALUES.SERVICE_DONATION:
            return  getServiceFacilityLabel(t, locationType);
            break;
        default:
            return "defaultDonations";
            break;
    }
}

export const formatPhoneNumber = ((num:any)=>{
    let match:any = false;
    if(num?.length === 10){
        match = num?.match(/^(\d{3})(\d{3})(\d{3,4})$/);
    }
    if (match) {
       return `+1 (${match[1]})${match[2]}-${match[3]}`;
    }
    return num;
})