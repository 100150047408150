import { Action } from 'redux';

export enum DivisionDataActionTypes {
    DIVISION_DATA_LOAD = 'DIVISION_DATA_LOAD',
    DIVISION_DATA_LOAD_SUCCESS = 'DIVISION_DATA_LOAD_SUCCESS',
    DIVISION_DATA_LOAD_FAILURE = 'DIVISION_DATA_LOAD_FAILURE'
}

export interface DivisionDataLoad extends Action {
    type: DivisionDataActionTypes.DIVISION_DATA_LOAD;
    data: any;
}
export const divisionDataLoad = (data: any): DivisionDataLoad => ({
    type: DivisionDataActionTypes.DIVISION_DATA_LOAD,
    data
});

export interface DivisionDataLoadSuccess extends Action {
    type: DivisionDataActionTypes.DIVISION_DATA_LOAD_SUCCESS;
    data: any;
}

export const divisionDataLoadSuccess = (data: any): DivisionDataLoadSuccess => ({
    type: DivisionDataActionTypes.DIVISION_DATA_LOAD_SUCCESS,
    data
});

export interface DivisionDataLoadFailure extends Action {
    type: DivisionDataActionTypes.DIVISION_DATA_LOAD_FAILURE;
    data?: any;
}

export const divisionDataLoadFailure = (data: any): DivisionDataLoadFailure => ({
    type: DivisionDataActionTypes.DIVISION_DATA_LOAD_FAILURE,
    data
});

export type DivisionDataActions = DivisionDataLoad | DivisionDataLoadSuccess | DivisionDataLoadFailure;