import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { resolvePath } from '../../common/utils';
import { ErrorMessageComponent } from '../formComponents/ErrorMessage';

interface OwnProps {
  question: string,
  description?: string,
  label: string,
  yesLabel?: string,
  noLabel?: string,
  id: string,
  fieldName: string,
  defaultValue: boolean,
  qno: string,
  disabled: boolean
}
const YesOrNoOption: FC<OwnProps> = ({
  id,
  question,
  noLabel ,
  yesLabel,
  fieldName,
  defaultValue,
  qno,
  disabled
}) => {
  const { errors, control} = useFormContext();
  const {t} = useTranslation();

  const fieldError = fieldName ? resolvePath(fieldName, errors, '')?.message : '';

  return <>

      <FormControl error={fieldError ? true : false}>
        <FormLabel id={id}>{qno ?`${qno} .`: ''} {question}</FormLabel>
          <Controller
              control={control}
              name={fieldName}
              defaultValue = {defaultValue?.toString()}
              render={(field: any) => (
                <RadioGroup data-testid = {fieldName}
                  {...field}
                  value = {field.value?.toString()}
                  row = {true}>
                  <FormControlLabel disabled = {disabled} value = {'true'} control={
                  <Radio required = {true} color='primary'/>} label={yesLabel ?? t('common.buttonLabels.yes')} />
                  <FormControlLabel disabled = {disabled}  value = {'false'} control={
                  <Radio required = {true} color='primary'/>} label= {noLabel?? t('common.buttonLabels.no')} />
                </RadioGroup>
              )}
            />
           {fieldError && <ErrorMessageComponent errors={errors} fieldName={fieldName} />}
      </FormControl>
  </>
}
export default YesOrNoOption;
