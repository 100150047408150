

import {Panel, Divider} from '@walmart-web/livingdesign-components';
import { Close} from '../../commonComponents/icons/icon';
import moment from 'moment';
import './styles.css';

const NotificationPanel = (props: any) => {
    return <Panel
        isOpen={true}
        onClose={() => props.setShowPanel(false)}
        position='right'
        size='medium'
        title={<NotificationHeader {...props}/>}
    >
       <PanelBody {...props}/>
    </Panel>
}

export default NotificationPanel;

const PanelBody = (props: any) => {
    return <div className='message-panel'>
        <h4>Home Office Communications</h4>
        <Divider />
        {props?.count <= 0 && <div className='no-message'>No messages to display</div>}
        {props?.data?.map((z: any) => {
            return <div key={z.id} className='message-section'>
                <div  className='message-section-left'>
                    <div>{z.headline}</div>
                    <div style={{ color: 'gray', fontSize: 12, marginLeft: 6 }}>{z.message}</div>
                    <div className='message-created-date'>{moment.utc(z.audit.updatedDtm).local().startOf('seconds').fromNow()}</div>
                </div>
                <div className='message-section-right'>
                    <Close  data-testid='add-id' onClick={()=>props.addToClosedMessageIds(z.id)}/>
                </div>
                <Divider />
            </div>;
        })}
    </div>
}

const NotificationHeader = (props:any) => <div>
    <h4>Notifications</h4>
    <div className='message-count'>Total Unread : {props?.count?props?.count:0}</div>
    {/* <div>{moment.utc(props.lastUpdatedDate).local().startOf('seconds').fromNow()}</div> */}
</div>
