import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      margin: '0'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);