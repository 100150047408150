import { Grid, Link } from '@material-ui/core'
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFacilitynfo } from 'src/models/storeModel';
import { AlertBox, Heading } from '../../../../components/common';

import { HighLightedBox } from '../common/HighLightedBox'
import { FacilitySearchComponent } from '../../../common/FacilitySearchComponent';
import { PageSubHeading } from '../../utils';
interface OwnProps {
  selectedLang: string,
  storeNumber: number | string | null,
  countryCode: string,
  handleFacilityChange: (faciltyDetails: IFacilitynfo) => void,
  isReview?: boolean,
  message?: string
}
const StoreSelectionComponent: FC<OwnProps> = ({
  selectedLang,
  countryCode,
  storeNumber,
  isReview = false,
  message = '',
  handleFacilityChange
}) => {

  const {t} = useTranslation();
  const [showStoreSearchDialog, setStoreSearchDialog] = useState(false);

  const handleModalAction = (open: boolean) => {
    setStoreSearchDialog(open)
  }

  return <>
      <HighLightedBox elevation = {2} style = {{minHeight: 100}}>
                {isReview ?
                      message && <AlertBox rowStyles={{ marginBottom: '30px' }} severity={'warning'}
                        message={message} colGrid={'col-md-12'} />
                  : <PageSubHeading heading={t('oneTimeFoodDonation.selectAstore')} />
                }
                <Grid container = {true} spacing ={2}>

                    {countryCode && <Grid item = {true} lg = {5} md = {6} xl = {4}>
                      {storeNumber ? <Heading
                        key={`form-heading-data-`}
                        id={'create-store-number'}
                        className={'h14_title'}
                        level={'h6'}
                      >
                        {t('oneTimeGoodsDonation.store.number')}
                        <span>
                          {storeNumber}
                        </span>&nbsp;{!isReview && (<span
                          style={{
                            marginLeft: '0px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontSize: 'inherit',
                            fontWeight: 'normal',
                            color: '#0071ce'
                          }}
                          id={'change-store-number'}
                          onClick={() => { handleModalAction(true) }}>
                          {t('common.labels.changeFacilityText')}
                        </span>)}
                      </Heading>:
                    <div style={{ marginBottom: '20px' }}>
                      <Link
                        id='storeNumber'
                        data-testid = 'selectStoreLink'
                        color='inherit'
                        component='button'
                        variant='inherit'
                        onClick={() => {
                          handleModalAction(true)
                        }}
                        style={{ fontSize: '16px', textDecoration: 'underline' }}
                      >
                        {t('common.labels.selectFacilityText')}
                      </Link>
                    </div>
                  }
                    </Grid>}
                </Grid>
          {
            showStoreSearchDialog && <FacilitySearchComponent
            countryCode={countryCode}
            selectedLang={selectedLang}
            onFaciltySelection={handleFacilityChange}
            handleModalAction={handleModalAction}
            showModal={showStoreSearchDialog}
        />
          }
    </HighLightedBox>

  </>
}

export default memo(StoreSelectionComponent);
